import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {
  APP_VERSION,
  BASE_API_URL,
  BASE_APP_URL,
  BASE_OSRM_URL,
  BASE_WEBSOCKET_URL,
  MAPBOX_TOKEN
} from './app/core/utils/injectors';
import { environment } from './environments/environment';

/**
 * Solution to dynamically inject the environment :
 * https://medium.com/@iworb.ne/great-idea-but-theres-a-better-way-e05bac49bc7d
 */

export class Config {
  appVersion: string;
  appUrl: string;
  apiUrl: string;
  apiWebSocketUrl: string;
  osrmUrl: string;
  mapboxToken: string;
}

Promise.all([fetch('./assets/config.json'), fetch('./assets/app-version.json')])
  .then(([response, appVersion]) => Promise.all([response.json(), appVersion.json()]))
  .then(([config, appVersion]: [Config, { version: string }]) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      {
        provide: APP_VERSION,
        useValue: appVersion.version
      },
      {
        provide: BASE_APP_URL,
        useValue: config.appUrl
      },
      {
        provide: BASE_API_URL,
        useValue: config.apiUrl
      },
      {
        provide: BASE_WEBSOCKET_URL,
        useValue: config.apiWebSocketUrl
      },
      { provide: BASE_OSRM_URL, useValue: config.osrmUrl },
      { provide: MAPBOX_TOKEN, useValue: config.mapboxToken }
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
