import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/core/store';
import {
  selectCurCanOptimize,
  selectCurOptimSpaceId
} from 'src/app/core/store/application/application.selectors';
import { selectCurUser } from 'src/app/core/store/auth/auth.selectors';

@Component({
  selector: 'app-parameter-menu-component',
  templateUrl: './parameter-menu-component.component.html',
  styleUrls: ['./parameter-menu-component.component.scss']
})
export class ParameterMenuComponentComponent {
  @Input() dynamicMode: boolean;

  user$ = this.store.select(selectCurUser);
  optimSpaceId$ = this.store.select(selectCurOptimSpaceId);
  canOptimize$ = this.store.select(selectCurCanOptimize);

  constructor(private readonly store: Store<RootState>) {}
}
