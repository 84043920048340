import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/auth.service';
import { WebsocketService } from 'src/app/websocket.service';
import { rehydrateGalia } from '../application/application.actions';
import {
  login,
  loginComplete,
  loginFailure,
  logoutComplete,
  loginDetails,
  logout,
  updateUserInfo,
  updateUserInfoSuccess,
  updateUserInfoFailure
} from './auth.actions';
import { DateTimeUtils } from '../../utils/date-time.utils';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly userSvc: UserService,
    private readonly router: Router,
    private readonly websocketSvc: WebsocketService
  ) {}

  hasAccessToken(response: any): boolean {
    return response.hasOwnProperty('access_token');
  }

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap((action) => {
        return this.userSvc.login(action.mail, action.password).pipe(
          map((response) => {
            if (this.hasAccessToken(response)) {
              return loginDetails({
                token: response.access_token,
                refresh_token: response.refresh_token,
                expires_timestamp: DateTimeUtils.addSecondsToDate(
                  new Date(),
                  response.expires_in_seconds
                ).getTime(),
                routeDest: action.routeDest
              });
            }
            return loginFailure({
              category: 'error',
              message: 'Internal error: did not receive an access token.'
            });
          }),
          catchError(() => {
            const message = 'Invalid credentials.';
            return of(loginFailure({ category: 'error', message }));
          })
        );
      })
    )
  );

  authenticateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginDetails),
      exhaustMap(({ token, routeDest }) => {
        return this.userSvc.getUserProfile$().pipe(
          map((user) => {
            this.websocketSvc.connect(token); // handle errors ?
            if (routeDest) {
              const dus = new DefaultUrlSerializer();
              const dest = dus.parse(routeDest);
              return rehydrateGalia({ user, token, dest });
            }
            return { type: 'NO_ACTION' }; // dummy return to avoid any action
          }),
          catchError(() => {
            const message = 'Authentication required.';
            return of(loginFailure({ category: 'error', message })).pipe(
              tap(() =>
                this.router.navigate(['/login'], { queryParams: { returnUrl: routeDest } })
              )
            );
          })
        );
      })
    )
  );

  initApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rehydrateGalia),
      switchMap(({ user, token }) => of(loginComplete({ user, token })))
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      map(() => {
        this.websocketSvc.disconnect(); // handle errors ?
        return logoutComplete();
      }),
      tap(() => this.router.navigate(['/login']))
    )
  );

  updateUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserInfo),
      switchMap((action) => {
        return this.userSvc
          .updateUserProfile(action.userId, action.email, action.full_name)
          .pipe(
            map((response) => {
              return updateUserInfoSuccess({
                email: response.email,
                full_name: response.full_name
              });
            }),
            catchError((error) => {
              console.log(error.message, error);
              return of(
                updateUserInfoFailure({
                  category: 'error',
                  message: `${error.error.errorMessage}`
                })
              ); // dispatch failure action with error message
            })
          );
      })
    )
  );
}
