const categoriesColors = [
  '#763a89',
  '#8ab800',
  '#f25aff',
  '#008d23',
  '#b900ac',
  '#cfff80',
  '#ff1d8f',
  '#6affbf',
  '#ff2825',
  '#027ad3',
  '#fca800',
  '#e48bff',
  '#ffd450',
  '#b67ec7',
  '#ff7408',
  '#a01158',
  '#b9b35e',
  '#ff5e72',
  '#a25800',
  '#d17e57',
  '#785b6e',
  '#67ea42',
  '#5929e6',
  '#a7e44c',
  '#d839e8',
  '#47b638',
  '#9537d7',
  '#dae33e',
  '#4624a9',
  '#6ae981',
  '#6552e1',
  '#91b639',
  '#dc3fbf',
  '#348839',
  '#aa4ac6',
  '#dddd6d',
  '#86238d',
  '#62e8b5',
  '#e5369c',
  '#78c473',
  '#9a73e9',
  '#dcb53c',
  '#5268d4',
  '#e94e1b',
  '#5acde8',
  '#dd3630',
  '#82e3d3',
  '#e53365',
  '#5bb585',
  '#e4518d',
  '#618f37',
  '#d77cdc',
  '#938a21',
  '#383287',
  '#c4eb9c',
  '#3a1152',
  '#e29034',
  '#5388db',
  '#bc451e',
  '#7caee2',
  '#982412',
  '#4dafa8',
  '#b12e60',
  '#347e57',
  '#b33d8a',
  '#425f1d',
  '#885caf',
  '#9fac68',
  '#6f2d6d',
  '#e1c784',
  '#1f173e',
  '#dbe5c2',
  '#3c132c',
  '#b5d6e1',
  '#6d2118',
  '#4393b3',
  '#e5703f',
  '#46589c',
  '#b26525',
  '#a198de',
  '#6a5420',
  '#dea8e4',
  '#2c4424',
  '#e17ebc',
  '#1b3332',
  '#ef6469',
  '#1a2a3e',
  '#eaa07a',
  '#211623',
  '#ddbed4',
  '#362c19',
  '#e7889c',
  '#346465',
  '#b24036',
  '#689699',
  '#972538',
  '#96af8c',
  '#782751',
  '#8c8547',
  '#443966',
  '#b37e44',
  '#426589',
  '#8c4a24',
  '#7a6d9d',
  '#583017',
  '#d5b7a1',
  '#551723',
  '#607857',
  '#c75662',
  '#35495e',
  '#d47a69',
  '#523542',
  '#bc8381',
  '#ac6995',
  '#887860',
  '#944953',
  '#988fa5',
  '#7c5245'
];

const pickerColors = [
  '#0097b2',
  '#f4b609',
  '#ff5757',
  '#fb8e3d',
  '#40926f',
  '#405092',
  '#9983b5',
  '#7c083f',
  '#85c0de',
  '#155421',
  '#cc0404',
  '#c29984',
  '#80d5ff',
  '#1d3d49',
  '#c660bb',
  '#7857ff',
  '#170758',
  '#ffb0f7',
  '#198a72',
  '#5a6100',
  '#8b69f4',
  '#449e00',
  '#a633ba',
  '#01d287',
  '#ea67ea',
  '#ce9500',
  '#4b42b0',
  '#d5450b',
  '#0297f2',
  '#f62064',
  '#01897c',
  '#ff70c5',
  '#a6d29d',
  '#7a368b',
  '#e7c178',
  '#135785',
  '#9e1753',
  '#d2a6ff',
  '#b57390'
];

export class WarehouseColorUtils {
  static getCategoryColor(categoryId: number): string {
    if (categoryId === -1) {
      return '#aaaaaa';
    }
    return categoriesColors[categoryId % categoriesColors.length];
  }

  static getPickerColor(pickerId: number): string {
    return pickerColors[pickerId % pickerColors.length];
  }
}
