<ng-container
  *ngIf="{
    application: application$ | async,
    user: user$ | async,
    showPackage: showPackage$ | async,
    showMap: showMap$ | async,
    showTimeline: showTimeline$ | async,
    showList: showList$ | async,
    showCaption: showCaption$ | async,
    activeShowCaption: activeShowCaption$ | async,
    showWarehouse: showWarehouse$ | async,
    optimSpace: optimSpace$ | async,
    output: output$ | async,
    outputVisu: outputVisu$ | async,
    mapProvider: mapProvider$ | async,
    canOptimize: canOptimize$ | async
  } as ctx"
>
  <nav class="overflow-hidden" id="sidebar">
    <div *ngIf="!sideBarCollapsed; else sidebarIsCollapsed" class="mt-2">
      <div class="px-4 inline-flex items-center justify-between w-full">
        <!-- Only the name of the application if on an application -->
        <h1
          *ngIf="ctx.application.application.path"
          class="font-extrabold tracking-wide text-neutral-700 text-xl"
        >
          {{ ctx.application.application.name }}
          <div *ngIf="!isVisualizationMode" class="text-neutral-500 text-xs">
            v{{ ctx.application.version }}
          </div>
        </h1>

        <button
          class="absolute -right-5"
          (click)="onSidebarToggle()"
          data-cy="param_button"
          app-button
          color="neutral"
          hierarchy="tertiary"
          size="small-square"
          svgIcon="carret-circle-right"
        ></button>
      </div>
      <h2
        *ngIf="ctx.optimSpace.name"
        class="font-bold tracking-wide text-neutral-500 text-xs break-all whitespace-pre-wrap px-3"
      >
        {{ ctx.optimSpace.name }}
        <span class="text-neutral-400">#{{ ctx.optimSpace.id }}</span>
      </h2>
      <div
        *ngIf="ctx.mapProvider"
        class="font-bold tracking-wide text-neutral-500 text-xs break-all whitespace-pre-wrap px-3"
      >
        Map Provider: {{ ctx.mapProvider }}
      </div>
    </div>
    <ng-template #sidebarIsCollapsed>
      <div class="py-1 flex justify-center items-center w-full">
        <button
          class="hover:bg-neutral-200"
          (click)="onSidebarToggle()"
          data-cy="param_button"
          app-button
          color="neutral"
          hierarchy="tertiary"
          size="small-square"
          svgIcon="carret-circle-left"
        ></button>
      </div>
    </ng-template>

    <div class="mt-6">
      <ng-template [ngIf]="activeShowMap$ | async" [ngIfElse]="inactiveShowMap">
        <a
          data-cy="map"
          (click)="onClickMap()"
          class="block sidelink relative"
          [routerLink]="
            isVisualizationMode
              ? []
              : ['/application', ctx.application.application.path, ctx.application.version]
          "
          queryParamsHandling="merge"
        >
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-middle"
          >
            <phosphor-icon
              class="stroke-neutral-900 fill-neutral-500 w-4 mr-4"
              name="map"
            ></phosphor-icon>
            <span> Map </span>
            <div class="absolute right-2">
              <phosphor-icon
                *ngIf="ctx.showMap"
                class="stroke-secondary-900 fill-secondary-100 w-3"
                name="circle-fill"
              ></phosphor-icon>
            </div>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-900 fill-neutral-500 w-4"
                name="map"
              ></phosphor-icon>
              <div class="absolute right-1 top-1">
                <phosphor-icon
                  *ngIf="ctx.showMap"
                  class="stroke-secondary-900 fill-secondary-100 w-3"
                  name="circle-fill"
                ></phosphor-icon>
              </div>
            </div>
          </ng-template>
        </a>
      </ng-template>
      <ng-template #inactiveShowMap>
        <div data-cy="map" class="block unactive-sidelink">
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-middle"
          >
            <phosphor-icon
              class="stroke-neutral-400 fill-neutral-100 w-4 mr-4"
              name="map"
            ></phosphor-icon>
            <span> Map </span>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-400 fill-neutral-100 w-4"
                name="map"
              ></phosphor-icon>
            </div>
          </ng-template>
        </div>
      </ng-template>
      <ng-template [ngIf]="activeShowPackage3D$ | async" [ngIfElse]="inactiveShowPackage">
        <a
          data-cy="packing"
          class="block sidelink relative"
          (click)="onClickPackage()"
          [routerLink]="
            isVisualizationMode
              ? []
              : ['/application', ctx.application.application.path, ctx.application.version]
          "
          queryParamsHandling="merge"
        >
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-middle"
          >
            <phosphor-icon
              class="stroke-neutral-900 fill-neutral-500 w-4 mr-4"
              name="package"
            ></phosphor-icon>
            <span> Packing 3D </span>
            <div class="absolute right-2">
              <phosphor-icon
                *ngIf="ctx.showPackage"
                class="stroke-secondary-900 fill-secondary-100 w-3"
                name="circle-fill"
              ></phosphor-icon>
            </div>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-900 fill-neutral-500 w-4"
                name="package"
              ></phosphor-icon>
              <div class="absolute right-1 top-1">
                <phosphor-icon
                  *ngIf="ctx.showPackage"
                  class="stroke-secondary-900 fill-secondary-100 w-3"
                  name="circle-fill"
                ></phosphor-icon>
              </div>
            </div>
          </ng-template>
        </a>
      </ng-template>
      <ng-template #inactiveShowPackage>
        <div data-cy="packing" class="block unactive-sidelink">
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-middle"
          >
            <phosphor-icon
              class="stroke-neutral-400 fill-neutral-100 w-4 mr-4"
              name="package"
            ></phosphor-icon>
            <span> Packing 3D </span>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-400 fill-neutral-100 w-4"
                name="package"
              ></phosphor-icon>
            </div>
          </ng-template>
        </div>
      </ng-template>
      <ng-template [ngIf]="activeShowWarehouse$ | async" [ngIfElse]="inactiveWarehouse">
        <a
          data-cy="warehouse"
          class="block sidelink relative"
          [routerLink]="
            isVisualizationMode
              ? []
              : ['/application', ctx.application.application.path, ctx.application.version]
          "
          queryParamsHandling="merge"
          (click)="onClickWarehouse()"
        >
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-middle"
          >
            <phosphor-icon
              class="stroke-neutral-900 fill-neutral-500 w-4 mr-4"
              name="warehouse"
            ></phosphor-icon>
            <span> Warehouse </span>
            <div class="absolute right-2">
              <phosphor-icon
                *ngIf="showWarehouse$ | async"
                class="stroke-secondary-900 fill-secondary-100 w-3"
                name="circle-fill"
              ></phosphor-icon>
            </div>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-900 fill-neutral-500 w-4"
                name="warehouse"
              ></phosphor-icon>
              <div class="absolute right-1 top-1">
                <phosphor-icon
                  *ngIf="showList$ | async"
                  class="stroke-secondary-900 fill-secondary-100 w-3"
                  name="circle-fill"
                ></phosphor-icon>
              </div>
            </div>
          </ng-template>
        </a>
      </ng-template>
      <ng-template #inactiveWarehouse>
        <div data-cy="warehouse" class="block unactive-sidelink">
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-middle"
          >
            <phosphor-icon
              class="stroke-neutral-400 fill-neutral-100 w-4 mr-4"
              name="warehouse"
            ></phosphor-icon>
            <span> Warehouse </span>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-400 fill-neutral-100 w-4"
                name="warehouse"
              ></phosphor-icon>
            </div>
          </ng-template>
        </div>
      </ng-template>
      <ng-template [ngIf]="activeShowTimeline$ | async" [ngIfElse]="inactiveShowTimeline">
        <a
          data-cy="timeline"
          class="block sidelink relative"
          [routerLink]="
            isVisualizationMode
              ? []
              : ['/application', ctx.application.application.path, ctx.application.version]
          "
          queryParamsHandling="merge"
          (click)="onClickTimeline()"
        >
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-baseline"
          >
            <phosphor-icon
              class="stroke-neutral-900 fill-neutral-500 w-4 mr-4"
              name="calendar"
            ></phosphor-icon>
            <span> Timeline </span>
            <div class="absolute right-2">
              <phosphor-icon
                *ngIf="showTimeline$ | async"
                class="stroke-secondary-900 fill-secondary-100 w-3"
                name="circle-fill"
              ></phosphor-icon>
            </div>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-900 fill-neutral-500 w-4"
                name="calendar"
              ></phosphor-icon>
              <div class="absolute right-1 top-1">
                <phosphor-icon
                  *ngIf="showTimeline$ | async"
                  class="stroke-secondary-900 fill-secondary-100 w-3"
                  name="circle-fill"
                ></phosphor-icon>
              </div>
            </div>
          </ng-template>
        </a>
      </ng-template>
      <ng-template #inactiveShowTimeline>
        <div data-cy="timeline" class="block unactive-sidelink">
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-baseline"
          >
            <phosphor-icon
              class="stroke-neutral-400 fill-neutral-100 w-4 mr-4"
              name="calendar"
            ></phosphor-icon>
            <span> Timeline </span>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-400 fill-neutral-100 w-4"
                name="calendar"
              ></phosphor-icon>
            </div>
          </ng-template>
        </div>
      </ng-template>
      <ng-template [ngIf]="activeShowList$ | async" [ngIfElse]="inactiveShowList">
        <a
          data-cy="list"
          class="block sidelink relative"
          [routerLink]="
            isVisualizationMode
              ? []
              : ['/application', ctx.application.application.path, ctx.application.version]
          "
          queryParamsHandling="merge"
          (click)="onClickList()"
        >
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-middle"
          >
            <phosphor-icon
              class="stroke-neutral-900 fill-neutral-500 w-4 mr-4"
              name="columns"
            ></phosphor-icon>
            <span> List </span>
            <div class="absolute right-2">
              <phosphor-icon
                *ngIf="showList$ | async"
                class="stroke-secondary-900 fill-secondary-100 w-3"
                name="circle-fill"
              ></phosphor-icon>
            </div>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-900 fill-neutral-500 w-4"
                name="columns"
              ></phosphor-icon>
              <div class="absolute right-1 top-1">
                <phosphor-icon
                  *ngIf="showList$ | async"
                  class="stroke-secondary-900 fill-secondary-100 w-3"
                  name="circle-fill"
                ></phosphor-icon>
              </div>
            </div>
          </ng-template>
        </a>
      </ng-template>
      <ng-template #inactiveShowList>
        <div data-cy="list" class="block unactive-sidelink">
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-middle"
          >
            <phosphor-icon
              class="stroke-neutral-400 fill-neutral-100 w-4 mr-4"
              name="columns"
            ></phosphor-icon>
            <span> List </span>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-400 fill-neutral-100 w-4"
                name="columns"
              ></phosphor-icon>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </div>
    <!-- optimSpace section -->
    <div class="mt-6" *ngIf="!isVisualizationMode && optimSpace$ | async; let optimSpace">
      <div
        *ngIf="!sideBarCollapsed"
        class="px-4 uppercase font-bold text-xs text-neutral-700 leading-snug tracking-wide pb-2"
      >
        instance
      </div>
      <app-optimspace-menu-component
        (click)="resetMainView(ctx)"
        [sideBarCollapsed]="sideBarCollapsed"
        [optimSpaceId]="optimSpace.id"
        [optimSpaceName]="optimSpace.name"
        [optimSpaceCreatedAt]="optimSpace.createdAt"
        [optimSpaceDescription]="optimSpace.description"
        [canOptimize]="!!ctx.canOptimize"
      >
      </app-optimspace-menu-component>
    </div>

    <!-- Solution section -->
    <div class="mt-6" *ngIf="!isVisualizationMode">
      <div
        *ngIf="!sideBarCollapsed"
        class="px-4 uppercase font-semibold text-xs text-neutral-700 tracking-wide pb-2"
      >
        Solution
      </div>
      <app-output-menu-component
        (click)="resetMainView(ctx)"
        [sideBarCollapsed]="sideBarCollapsed"
        [optimSpaceId]="ctx.optimSpace.id"
        [outputId]="ctx.output.id"
        [outputName]=""
        [outputCreatedAt]="ctx.output.createdAt"
      ></app-output-menu-component>
    </div>

    <!-- View components section -->
    <div class="mt-6">
      <div
        *ngIf="!sideBarCollapsed"
        class="px-4 uppercase font-semibold text-xs text-neutral-700 tracking-wide pb-2"
      >
        Views
      </div>

      <ng-template
        [ngIf]="
          (activeShowInput$ | async) &&
          (ctx.showMap || ctx.showList || ctx.showPackage || ctx.showTimeline)
        "
        [ngIfElse]="inactiveShowInput"
      >
        <div class="relative">
          <a data-cy="input_kpis" class="block sidelink" (click)="onClickInputKpis()">
            <div
              *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
              class="inline-flex items-center align-middle"
            >
              <phosphor-icon
                class="stroke-neutral-900 fill-neutral-500 w-4 mr-4"
                name="activity"
              ></phosphor-icon>
              <span> Input KPIs </span>
              <div class="absolute aligne-middle right-2">
                <phosphor-icon
                  *ngIf="showInputKpis$ | async"
                  class="stroke-secondary-900 fill-secondary-100 w-3"
                  name="circle-fill"
                ></phosphor-icon>
              </div>
            </div>
            <ng-template #sidebarIsCollapsed>
              <div class="inline-flex items-center align-middle">
                <phosphor-icon
                  class="stroke-neutral-900 fill-neutral-500 w-4"
                  name="activity"
                ></phosphor-icon>
                <div class="absolute top-1 right-1">
                  <phosphor-icon
                    *ngIf="showInputKpis$ | async"
                    class="stroke-secondary-900 fill-secondary-100 w-3"
                    name="circle-fill"
                  ></phosphor-icon>
                </div>
              </div>
            </ng-template>
          </a>
        </div>
      </ng-template>
      <ng-template #inactiveShowInput>
        <a data-cy="input_kpis" class="block unactive-sidelink">
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-middle"
          >
            <phosphor-icon
              class="stroke-neutral-400 fill-neutral-100 w-4 mr-4"
              name="activity"
            ></phosphor-icon>
            <span> Input KPIs </span>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-400 fill-neutral-100 w-5"
                name="activity"
              ></phosphor-icon>
            </div>
          </ng-template>
        </a>
      </ng-template>

      <ng-template
        [ngIf]="
          (activeShowOutput$ | async) &&
          (ctx.showMap || ctx.showList || ctx.showPackage || ctx.showTimeline)
        "
        [ngIfElse]="inactiveShowOutput"
      >
        <div class="relative">
          <a data-cy="output_kpis" class="block sidelink" (click)="onClickOutputKpis()">
            <div
              *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
              class="inline-flex items-center align-baseline"
            >
              <phosphor-icon
                class="stroke-neutral-900 fill-neutral-500 w-4 mr-4"
                name="chartbar"
              ></phosphor-icon>
              <span> Output KPIs </span>
              <div class="absolute right-2">
                <phosphor-icon
                  *ngIf="showOutputKpis$ | async"
                  class="stroke-secondary-900 fill-secondary-100 w-3"
                  name="circle-fill"
                ></phosphor-icon>
              </div>
            </div>
            <ng-template #sidebarIsCollapsed>
              <div class="inline-flex items-center align-middle">
                <phosphor-icon
                  class="stroke-neutral-900 fill-neutral-500 w-4"
                  name="chartbar"
                ></phosphor-icon>
                <div class="absolute top-1 right-1">
                  <phosphor-icon
                    *ngIf="showOutputKpis$ | async"
                    class="stroke-secondary-900 fill-secondary-100 w-3"
                    name="circle-fill"
                  ></phosphor-icon>
                </div>
              </div>
            </ng-template>
          </a>
        </div>
      </ng-template>
      <ng-template #inactiveShowOutput>
        <a data-cy="output_kpis" class="block unactive-sidelink">
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-baseline"
          >
            <phosphor-icon
              class="stroke-neutral-400 fill-neutral-100 w-4 mr-4"
              name="chartbar"
            ></phosphor-icon>
            <span> Output KPIs </span>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-400 fill-neutral-100 w-4"
                name="chartbar"
              ></phosphor-icon>
            </div>
          </ng-template>
        </a>
      </ng-template>

      <!-- Warehouse has a fixed caption, so it's always shown when active -->
      <ng-template
        [ngIf]="
          ctx.showWarehouse ||
          (ctx.activeShowCaption &&
            (ctx.showMap || ctx.showList || ctx.showPackage || ctx.showTimeline))
        "
        [ngIfElse]="inactiveShowCaption"
      >
        <div class="relative">
          <a data-cy="captions" class="block sidelink" (click)="onClickCaption()">
            <div
              *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
              class="inline-flex items-center align-middle"
            >
              <phosphor-icon
                class="stroke-neutral-900 fill-neutral-500 w-4 mr-4"
                name="question-mark"
              ></phosphor-icon>
              <span> Captions </span>
              <div class="absolute aligne-middle right-2">
                <phosphor-icon
                  *ngIf="showCaption$ | async"
                  class="stroke-secondary-900 fill-secondary-100 w-3"
                  name="circle-fill"
                ></phosphor-icon>
              </div>
            </div>
            <ng-template #sidebarIsCollapsed>
              <div class="inline-flex items-center align-middle">
                <phosphor-icon
                  class="stroke-neutral-900 fill-neutral-500 w-4"
                  name="question-mark"
                ></phosphor-icon>
                <div class="absolute top-1 right-1">
                  <phosphor-icon
                    *ngIf="showCaption$ | async"
                    class="stroke-secondary-900 fill-secondary-100 w-3"
                    name="circle-fill"
                  ></phosphor-icon>
                </div>
              </div>
            </ng-template>
          </a>
        </div>
      </ng-template>
      <ng-template #inactiveShowCaption>
        <a data-cy="captions" class="block unactive-sidelink">
          <div
            *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
            class="inline-flex items-center align-middle"
          >
            <phosphor-icon
              class="stroke-neutral-400 fill-neutral-300 w-4 mr-4"
              name="question-mark"
            ></phosphor-icon>
            <span> Captions </span>
          </div>
          <ng-template #sidebarIsCollapsed>
            <div class="inline-flex items-center align-middle">
              <phosphor-icon
                class="stroke-neutral-400 fill-neutral-300 w-5"
                name="question-mark"
              ></phosphor-icon>
            </div>
          </ng-template>
        </a>
      </ng-template>
    </div>

    <div *ngIf="!sideBarCollapsed && !isVisualizationMode" class="mt-6">
      <div class="px-4 flex uppercase font-semibold text-xs text-neutral-700 tracking-wide">
        Controls
      </div>
      <div class="flex items-center justify-between mt-4">
        <span class="px-4 font-thin text-xs text-neutral-900 tracking-wide"
          >Instance / Solution</span
        >
        <toggle-button
          data-cy="toggle_sidebar"
          [disabled]="!(activeShowOutputBtn$ | async)"
          [checked]="(controlVisuOnScreen$ | async) === 'output'"
          (toggled)="onToggle($event, ctx.showTimeline)"
        ></toggle-button>
      </div>
    </div>
  </nav>
</ng-container>
