<div
  class="bg-neutral-50 bg-opacity-30 z-1000 py-2 pointer-events-auto flex items-center justify-start"
  placement="top"
>
  <div class="inline-flex items-start justify-between w-full">
    <div class="inline-flex justify-between items-center w-1/5">
      <div
        *ngIf="caption?.color"
        class="w-6 h-6 rounded-full flex items-center justify-center"
        [style.background-color]="caption.color"
      >
        <div class="px-1 py-1">
          <phosphor-icon
            *ngIf="icon"
            class="w-4 h-4 fill-neutral-500 stroke-neutral-900"
            [name]="icon"
          ></phosphor-icon>
        </div>
      </div>
      <div class="px-1 py-1">
        <phosphor-icon
          *ngIf="icon && !caption.color"
          class="w-5 h-5 fill-neutral-500 stroke-neutral-900"
          [name]="icon"
        ></phosphor-icon>
      </div>
    </div>
    <div class="flex justify-center items-center w-4/5 ml-2">
      <p class="text-center">{{ caption?.caption }}</p>
    </div>
  </div>
</div>
