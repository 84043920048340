import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostBinding, Inject, Input, Optional } from '@angular/core';
import { phosphorIcon } from './phosphor-icons';
import { PhosphorIconsService } from '../../services/phosphor-icons.service';

@Component({
  selector: 'phosphor-icon',
  template: ``
})
export class PhosphorIconComponent {
  private svgIcon!: SVGElement;
  @HostBinding('class') class: string;
  @Input()
  set name(iconName: phosphorIcon) {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    //apply default colors & size if not specified in the html component
    if (this.element.nativeElement.classList[0] === undefined) {
      this.class = 'fill-primary-500 stroke-primary-900 w-5';
    } else {
      this.class = this.element.nativeElement.classList;
    }
    const svgData = this.phosphorIconsService.getIcon(iconName);
    this.svgIcon = this.svgElementFromString(svgData);
    this.element.nativeElement.appendChild(this.svgIcon);
    this.element.nativeElement.children[0].classList = this.class;
  }

  constructor(
    readonly element: ElementRef,
    private readonly phosphorIconsService: PhosphorIconsService,
    @Optional() @Inject(DOCUMENT) private readonly document: any
  ) {}

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('div');
    div.innerHTML = svgContent;
    return (
      div.querySelector('svg') ||
      this.document.createElementNS('http://www.w3.org/2000/svg', 'path')
    );
  }
}
