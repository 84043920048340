import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsListComponent } from './notifications-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';

@NgModule({
  declarations: [NotificationsListComponent],
  imports: [CommonModule, SharedModule, IntersectionObserverModule],
  exports: [NotificationsListComponent]
})
export class NotificationsListModule {}
