<div class="flex flex-wrap px-2 py-1">
  <div class="py-2">
    <input
      class="text-xs w-48 appearance-none relative block px-3 py-2 border border-neutral-300 placeholder-neutral-500 text-neutral-900 rounded-md focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10"
      type="text"
      id=""
      name=""
      placeholder="Search"
      [(ngModel)]="userSearch"
      (ngModelChange)="onInput()"
    />
    <ul class="my-2 h-80 overflow-y-auto" #selectList>
      <li
        *ngFor="let item of itemsByWeight; let i = index"
        class="flex justify-between mb-1 text-xs py-2 px-2 hover:bg-neutral-50 rounded-md cursor-pointer"
        [ngClass]="{
          'bg-neutral-50 font-medium text-primary-200': item.selected && !item.validated,
          'bg-neutral-50 font-medium text-primary-600': item.validated,
          'text-neutral-800': !item.selected && !item.validated
        }"
        [attr.data-testid]="item.name"
        (click)="onClickSelectItem($event, i)"
        (mouseenter)="onMouseEnterOption(item.id)"
        (mouseleave)="onMouseLeaveOption(item.id)"
      >
        <span data-testid="item-name">{{ item.name }}</span>
        <span *ngIf="item.validated">
          <phosphor-icon
            class="w-5 stroke-primary-900 fill-primary-400"
            name="check-circle"
          >
          </phosphor-icon>
        </span>
      </li>
    </ul>
    <div *ngIf="unselectAllButton" class="pt-2 flex justify-between">
      <button
        class="cursor-pointer text-primary-600 hover:text-primary-900 text-xs font-medium"
        (click)="onClickSelectAllItems()"
      >
        Select all
      </button>
      <button
        class="cursor-pointer text-red-600 hover:text-red-900 text-xs font-medium"
        (click)="onClickUnselectAllItems()"
      >
        Unselect all
      </button>
    </div>
  </div>
</div>
