import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppViewComponent } from './app-view.component';
import { RouterModule } from '@angular/router';
import { FooterModule } from 'src/app/blocks/footer/footer.module';
import { NavbarModule } from 'src/app/blocks/navbar/navbar.module';
import { SidebarModule } from 'src/app/blocks/sidebar/sidebar.module';

@NgModule({
  declarations: [AppViewComponent],
  imports: [CommonModule, FooterModule, NavbarModule, RouterModule, SidebarModule]
})
export class AppViewModule {}
