import { WarehousePointBase } from './warehouse-point-base';
import { WarehouseStoragePoint } from './warehouse-storage-point';
import { WarehousePointType } from 'src/app/core/models/WarehouseModel';
import { HeatMapUtils } from '../utils/heatmap-utils';
import { WarehouseWallPoint } from './warehouse-wall-point';
import { WarehouseDropOffPoint } from './warehouse-dropoff-point';
import { WarehouseDAO } from '../dao/warehouse.dao';
import { WarehouseHeatValues } from './warehouse-heat-values';

export class WarehousePointFactory {
  static createWarehousePoints(warehouseDAO: WarehouseDAO): WarehousePointBase[] {
    const warehouseHeatValues = new WarehouseHeatValues(warehouseDAO);
    const warehousePoints: WarehousePointBase[] = [];
    const heatMapValues = [];
    const numWarehousePoints = warehouseDAO.getNumWarehousePoints();
    for (let i = 0; i < numWarehousePoints; i++) {
      const warehousePoint = warehouseDAO.getRawWarehousePoint(i);
      switch (warehousePoint.type) {
        case WarehousePointType.Storage:
          warehousePoints.push(
            new WarehouseStoragePoint(i, warehouseDAO, warehouseHeatValues)
          );
          break;
        case WarehousePointType.RouteStart:
          // TODO
          break;
        case WarehousePointType.DropOff:
          warehousePoints.push(new WarehouseDropOffPoint(i, warehouseDAO));
          break;
        case WarehousePointType.Parking:
          // TODO
          break;
        case WarehousePointType.Receive:
          // TODO
          break;
        case WarehousePointType.Wall:
          warehousePoints.push(new WarehouseWallPoint(i, warehouseDAO));
          break;
      }

      heatMapValues.push(warehousePoint.heat_value);
    }
    HeatMapUtils.initReferenceValues(heatMapValues);
    return warehousePoints;
  }
}
