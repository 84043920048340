import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectRoute } from 'src/app/core/store/navigation/navigation.selector';
import { RootState } from 'src/app/core/store';
import {
  selectCurApp,
  selectCurInput,
  selectCurOptimSpace,
  selectCurOutput
} from 'src/app/core/store/application/application.selectors';
import { resetApplication } from 'src/app/core/store/application/application.actions';

@Component({
  selector: 'app-app-view',
  templateUrl: './app-view.component.html',
  styleUrls: ['./app-view.component.scss']
})
export class AppViewComponent implements OnInit, OnDestroy {
  auth$ = this.store.pipe(select((state) => state.auth));
  application$ = this.store.select(selectCurApp);

  currentInput$ = this.store.select(selectCurInput);
  currentOutput$ = this.store.select(selectCurOutput);
  currentOptimSpace$ = this.store.select(selectCurOptimSpace);

  routerState$: Observable<any>;
  sidebarCollapsed = false;

  // Methods :
  constructor(private readonly store: Store<RootState>) {}

  ngOnInit() {
    this.routerState$ = this.store.select(selectRoute);
  }

  ngOnDestroy() {
    this.store.dispatch(resetApplication());
  }

  onCollapsedSideBar(status: boolean) {
    this.sidebarCollapsed = status;
  }
}
