import { Kpi } from './Kpi';
import { NewVisuData } from './Visu';

export type VisuAndKpisCtor = {
  visu_data?: NewVisuData;
  input_kpis: Kpi[];
  output_kpis: Kpi[];
};

/**
 * Contains visualisation data to display an optimSpace or a solution and the kpis.
 */
export class VisuAndKpis {
  visuData?: NewVisuData;
  inputKpis: Kpi[] = new Array<Kpi>();
  outputKpis: Kpi[] = new Array<Kpi>();

  constructor(data: VisuAndKpisCtor) {
    this.visuData = data.visu_data;
    this.inputKpis = data.input_kpis;
    this.outputKpis = data.output_kpis;
  }
}
