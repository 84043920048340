import { BusinessUnitPermission } from './BusinessUnit';

export class DataSpaceModel {
  id: number;
  name: string;
  description: string;
}

export class DataSpacePermissions {
  userGroupId: number = 0;
  appPath: string = '';
  canOptimize: boolean = false;
  canRecompute: boolean = false;
  canUploadInput: boolean = false;
  canUploadOutput: boolean = false;
  canExportOutput: boolean = false;
  canDeleteInput: boolean = false;
  canUploadParameters: boolean = false;
  canEditParameters: boolean = false;
  canDeleteOutput: boolean = false;
  canSeeJobLogs: boolean = false;
}

export enum DataSpaceActions {
  RUN_APPLICATION = 'run_application',
  UPLOAD_INPUT = 'upload_input',
  UPLOAD_OUTPUT = 'upload_output',
  UPLOAD_PARAMETERS = 'upload_parameters',
  EXPORT_OUTPUT = 'export_output',
  DELETE_INPUT = 'delete_input',
  DELETE_OUTPUT = 'delete_output',
  EDIT_PARAMETERS = 'edit_parameters',
  READ = 'read',
  COPY_OPTIMSPACE = 'copy_optimspace',
  EDIT_OUTPUT = 'edit_output',
  EDIT_OPTIMSPACE = 'edit_optimspace',
  EXPORT_ROUTE_LIST = 'export_route_list',
  EXPORT_VISUALIZATION = 'export_visualization'
}

class DataSpaceActionsMapper {
  private mapper = {};
  constructor() {
    this.mapper[DataSpaceActions.RUN_APPLICATION] = (model: DataSpacePermissions) =>
      this.setPropertyAsTrue(model, 'canOptimize');
    this.mapper[DataSpaceActions.UPLOAD_INPUT] = (model: DataSpacePermissions) =>
      this.setPropertyAsTrue(model, 'canUploadInput');
    this.mapper[DataSpaceActions.UPLOAD_OUTPUT] = (model: DataSpacePermissions) =>
      this.setPropertyAsTrue(model, 'canUploadOutput');
    this.mapper[DataSpaceActions.EXPORT_OUTPUT] = (model: DataSpacePermissions) =>
      this.setPropertyAsTrue(model, 'canExportOutput');
    this.mapper[DataSpaceActions.DELETE_INPUT] = (model: DataSpacePermissions) =>
      this.setPropertyAsTrue(model, 'canDeleteInput');
    this.mapper[DataSpaceActions.UPLOAD_PARAMETERS] = (model: DataSpacePermissions) =>
      this.setPropertyAsTrue(model, 'canUploadParameters');
    this.mapper[DataSpaceActions.EDIT_PARAMETERS] = (model: DataSpacePermissions) =>
      this.setPropertyAsTrue(model, 'canEditParameters');
    this.mapper[DataSpaceActions.DELETE_OUTPUT] = (model: DataSpacePermissions) =>
      this.setPropertyAsTrue(model, 'canDeleteOutput');
  }

  private setPropertyAsTrue(
    model: DataSpacePermissions,
    field: keyof DataSpacePermissions
  ): void {
    model[field as string] = true;
  }

  setValues(actions: DataSpaceActions[], model: DataSpacePermissions): void {
    actions.filter((a) => this.mapper[a] != null).forEach((a) => this.mapper[a](model));
  }
}

export type GetDataSpaceActionsResponseV4 = {
  actions: DataSpaceActions[];
};

export class DataSpaceMapper {
  static mapActionsToDataSpacePermissions(
    actions: DataSpaceActions[],
    dataSpaceId: number,
    businessUnitPermissions: BusinessUnitPermission[]
  ): DataSpacePermissions[] {
    const model = new DataSpacePermissions();
    new DataSpaceActionsMapper().setValues(actions, model);
    return businessUnitPermissions.map((bu) => {
      const dataSpacePermission: DataSpacePermissions = {
        ...model,
        userGroupId: dataSpaceId,
        appPath: bu.app_path,
        canRecompute: bu.can_recompute
      };
      return dataSpacePermission;
    });
  }
}
