import { MarkerType } from 'src/app/core/enum/markerType.enum';
import { Geocodable } from 'src/app/core/models/GeocodableModel';
import {
  AppElement,
  StructuralData,
  Visibility,
  DisplayLabel
} from 'src/app/core/models/Visu';

export function isGeocodable(obj: Object) {
  let foundAddress = false;
  let foundLat = false;
  let foundLng = false;
  Object.keys(obj).forEach((key) => {
    if (key == 'address') {
      foundAddress = true;
    }
    if (key == 'latitude') {
      foundLat = true;
    }
    if (key == 'longitude') {
      foundLng = true;
    }
  });
  return foundAddress && foundLat && foundLng;
}

export function setLatLng(geocodableObject: Geocodable, lat: number, lng: number) {
  geocodableObject['latitude'] = lat;
  geocodableObject['longitude'] = lng;
  return;
}

export function getInnerObject(path, object) {
  path.forEach((segment) => {
    // path is an array, loop through it
    object = object[segment];
  });
  return object;
}

export function createAppElementFromUserLocation(userLocation) {
  let newAppElement = new AppElement();
  newAppElement.id = 1;
  newAppElement.short_name = userLocation.name;
  newAppElement.long_name = userLocation.name;
  let nameLabel = new DisplayLabel();
  nameLabel.id = 1;
  nameLabel.short_name = 'Name';
  nameLabel.long_name = 'Name';
  nameLabel.value = userLocation.name;
  let latLabel = new DisplayLabel();
  latLabel.id = 2;
  latLabel.short_name = 'Latitude';
  latLabel.long_name = 'Latitude';
  latLabel.value = userLocation.latitude;
  let longLabel = new DisplayLabel();
  longLabel.id = 3;
  longLabel.short_name = 'Longitude';
  longLabel.long_name = 'Longitude';
  longLabel.value = userLocation.longitude;
  newAppElement.display_labels = [nameLabel, latLabel, longLabel];
  newAppElement.hover_label_ids = [1, 2, 3];
  newAppElement.structural_data = new StructuralData();
  newAppElement.structural_data.element_type = MarkerType.DEFAULT;
  newAppElement.structural_data.lat = userLocation.latitude;
  newAppElement.structural_data.long = userLocation.longitude;
  newAppElement.structural_data.visibility = new Visibility();
  newAppElement.structural_data.visibility.map = true;
  newAppElement.structural_data.visibility.timeline = false;
  return newAppElement;
}
