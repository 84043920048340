import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BASE_API_URL } from '../utils/injectors';
import { EndPointV4 } from '../enum/endpoint.enum';
import {
  BusinessUnitMapper,
  BusinessUnitPermission,
  GetBusinessUnitResponseV4
} from '../models/BusinessUnit';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitService {
  private readonly urlPermission: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(BASE_API_URL) private readonly apiUrl: string
  ) {
    this.urlPermission = `${this.apiUrl}/${EndPointV4.BUSINESS_UNITS}`;
  }

  public getAllPermissions$(
    businessUnitId?: number
  ): Observable<{ permissions: BusinessUnitPermission[] }> {
    const param = businessUnitId ? String(businessUnitId) : 'me';
    return this.http.get<GetBusinessUnitResponseV4>(`${this.urlPermission}/${param}`).pipe(
      map((result) => {
        return {
          permissions: BusinessUnitMapper.toBusinessUnitPermissions(result)
        };
      })
    );
  }
}
