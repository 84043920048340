import { Group, LineSegments, Material, Mesh, Object3D, PlaneGeometry } from 'three';
import { LineSegmentsShape } from './line-segments-shape';
import { Object3DUtils } from '../../../feature/applications/warehouse/components/warehouse3d/module/core/utils/object3d-utils';

const PLANE_GEOMETRY_CACHE = new Map<string, PlaneGeometry>();

export class PlaneShape {
  static create(container: Object3D, data: PlaneProperties): PlaneData {
    const group = new Group();
    const planeGeometry = PlaneShape.createBoxGeometry(data.width, data.height);
    const plane = new Mesh(planeGeometry, data.material);

    const { x, y, z } = data.position;
    plane.position.x = x;
    plane.position.y = y;
    plane.position.z = z;

    if (data.opts?.matrixAutoUpdate != undefined) {
      plane.matrixAutoUpdate = data.opts.matrixAutoUpdate;
    }
    group.add(plane);

    Object3DUtils.rotate(plane, data.rotation);
    plane.updateMatrix();

    let lineSegments: LineSegments;
    if (data.opts?.createLineSegments == undefined || data.opts.createLineSegments) {
      lineSegments = LineSegmentsShape.create(planeGeometry, plane);
      group.add(lineSegments);
    }

    container.add(group);
    return {
      plane,
      lineSegments,
      group
    };
  }

  static clearCache() {
    PLANE_GEOMETRY_CACHE.clear();
  }

  private static createBoxGeometry(width: number, height: number): PlaneGeometry {
    const key = `${width},${height}`;
    if (PLANE_GEOMETRY_CACHE.has(key)) {
      return PLANE_GEOMETRY_CACHE.get(key);
    }
    const boxGeometry = new PlaneGeometry(width, height);
    PLANE_GEOMETRY_CACHE.set(key, boxGeometry);
    return boxGeometry;
  }
}

export interface PlaneProperties {
  position: { x: number; y: number; z: number };
  width: number;
  height: number;
  material: Material;
  rotation?: {
    rx?: number;
    ry?: number;
    rz?: number;
  };
  opts: {
    createLineSegments?: boolean;
    matrixAutoUpdate?: boolean;
  };
}

export interface PlaneData {
  plane: Mesh<PlaneGeometry, Material>;
  lineSegments: LineSegments;
  group: Group;
}
