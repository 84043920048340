import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from '../store';
import { selectAccessToken, selectRefreshToken } from '../store/auth/auth.selectors';
import { catchError, switchMap, take } from 'rxjs/operators';
import { UserService } from '../services/auth.service';
import { updateTokens } from '../store/auth/auth.actions';
import { Actions, ofType } from '@ngrx/effects';
import { ApiError } from '../enum/api-error.enum';
import { EndPointV4 } from '../enum/endpoint.enum';
import { DateTimeUtils } from '../utils/date-time.utils';

const IGNORE_PATHS = [EndPointV4.AUTH, EndPointV4.REFRESH_TOKEN, EndPointV4.HEALTH];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly accessToken$ = this.store.select(selectAccessToken);
  private readonly refreshToken$ = this.store.select(selectRefreshToken);

  private isRefreshing = false;

  constructor(
    private readonly store: Store<RootState>,
    private readonly userService: UserService,
    private readonly actions$: Actions
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldIgnoreRequest(req.url)) {
      return next.handle(req);
    }

    let token: string;
    return this.accessToken$.pipe(
      take(1),
      switchMap((accessToken) => {
        token = accessToken;
        return next.handle(this.addAuthHeader(req, accessToken));
      }),
      catchError((error) => {
        if (this.isApiTokenExpired(error)) {
          return this.tryRefreshToken(req, next, token);
        }
        return throwError(() => error);
      })
    );
  }

  private shouldIgnoreRequest(url: string) {
    return IGNORE_PATHS.some((path) => url.includes(path));
  }

  private isApiTokenExpired(error: any) {
    return (
      error.status === HttpStatusCode.Unauthorized &&
      error?.error?.errorCode === ApiError.API_TOKEN_EXPIRED
    );
  }

  private tryRefreshToken(
    req: HttpRequest<any>,
    next: HttpHandler,
    accessToken: string
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return this.refreshToken$.pipe(
        take(1),
        switchMap((refreshToken) => {
          if (refreshToken) {
            return this.requestRefreshToken(req, next, refreshToken);
          }
          // the error will make back to login
          return next.handle(this.addAuthHeader(req, accessToken));
        }),
        catchError((error) => {
          this.isRefreshing = false;
          return throwError(() => error);
        })
      );
    }
    // if it is already refreshing, subscribe to the observable to get the new token
    return this.actions$.pipe(
      ofType(updateTokens),
      take(1),
      switchMap((result) => {
        return next.handle(this.addAuthHeader(req, result.token));
      })
    );
  }

  private requestRefreshToken(
    req: HttpRequest<any>,
    next: HttpHandler,
    refreshToken: string
  ): Observable<HttpEvent<any>> {
    return this.userService.refreshToken(refreshToken).pipe(
      switchMap((newTokens) => {
        this.store.dispatch(
          updateTokens({
            token: newTokens.access_token,
            refresh_token: newTokens.refresh_token,
            expires_timestamp: DateTimeUtils.addSecondsToDate(
              new Date(),
              newTokens.expires_in_seconds
            ).getTime()
          })
        );

        this.isRefreshing = false;
        return next.handle(this.addAuthHeader(req, newTokens.access_token));
      }),
      catchError((error) => {
        this.isRefreshing = false;
        return throwError(() => error);
      })
    );
  }

  private addAuthHeader(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
