import { Component, OnDestroy, OnInit } from '@angular/core';
import { WarehouseCategory } from '../warehouse3d/module/core/models/warehouse-category';
import { WarehouseCaptionsUtils } from '../warehouse3d/module/core/utils/warehouse-captions-utils';
import { WarehouseEventBus } from '../warehouse3d/module/core/models/warehouse-event-bus';

@Component({
  selector: 'app-warehouse-captions',
  templateUrl: './warehouse-captions.component.html'
})
export class WarehouseCaptionsComponent implements OnInit, OnDestroy {
  categories: WarehouseCategory[];
  showHeatMapCaptions: boolean = false;

  private showHeatMapListener: (show: boolean) => void;

  ngOnInit(): void {
    this.showHeatMapListener = (show) => {
      this.showHeatMapCaptions = show;
      if (!this.showHeatMapCaptions && !this.categories) {
        this.categories = WarehouseCaptionsUtils.getInstance().getCategories();
      }
    };
    WarehouseEventBus.getEventBus().on('showHeatmap', this.showHeatMapListener);
    this.showHeatMapCaptions =
      WarehouseCaptionsUtils.getInstance().shouldShowHeatMapCaptions();
    this.showHeatMapListener(this.showHeatMapCaptions);
  }

  ngOnDestroy(): void {
    WarehouseEventBus.getEventBus().remove('showHeatmap', this.showHeatMapListener);
  }
}
