import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-output-menu-component',
  templateUrl: './output-menu-component.component.html'
})
export class OutputMenuComponentComponent {
  @Input() optimSpaceId: number;
  @Input() outputId: number;
  @Input() outputCreatedAt: Date;
  @Input() outputName: string;
  @Input() sideBarCollapsed: boolean = false;

  constructor() {
    // This is intentional.
  }
}
