import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { EndPointV4 } from '../enum/endpoint.enum';
import { BASE_API_URL } from '../utils/injectors';
import { PageDto } from '../models/Application';
import { OptimSpaceDetailModel, OptimSpaceModel } from '../models/OptimSpaceModel';
import { UploadOptimSpaceModel } from '../models/UploadOptimSpaceModel';
import { ApiTranslatorService } from './apitranslator.service';
import { InputKpiV4 } from '../models/Kpi';
import { UpdateOptimSpace } from '../models/UpdateOptimSpace';
import { CreateInputResponseV4, InputMapper, InputModel } from '../models/InputModel';

@Injectable({
  providedIn: 'root'
})
export class OptimSpacesService {
  private readonly urlOptimSpaceV4: string;
  private readonly urlInputV4: string;

  constructor(
    private readonly http: HttpClient,
    private readonly apiTranslatorService: ApiTranslatorService,
    @Inject(BASE_API_URL) private readonly apiUrl: string
  ) {
    this.urlOptimSpaceV4 = `${apiUrl}/${EndPointV4.OPTIMSPACES}`;

    this.urlInputV4 = `${apiUrl}/${EndPointV4.INPUTS}`;
  }

  /**
   * * HTTP request to Galia API to get a single OptimSpaces
   * @param OptimSpaceId a number that represents the ID of the OptimSpace to be retrieved
   * @returns  an observable that emits the selected optimSpace
   */
  getOptimSpace$(optimSpaceId: number): Observable<OptimSpaceDetailModel> {
    return this.http
      .get<OptimSpaceModel>(`${this.urlOptimSpaceV4}/${optimSpaceId}`)
      .pipe(map((r) => this.apiTranslatorService.modelToClass(OptimSpaceDetailModel, r)));
  }

  /**
   * HTTP request to Galia API to get the list of the optimSpace of the application referenced by `appPath`
   * @param appPath a string that represents the name of the application
   * @returns an observable that emits the list of optimSpace of the application
   */
  getApplicationOptimSpace$(
    appPath: string,
    version: string,
    cursor: number,
    descendingOrder: string,
    startDate: string,
    endDate: string,
    searchQuery: string,
    dataSpaceIds: number[]
  ): Observable<{ optimSpaces: OptimSpaceModel[]; cursor: number }> {
    const params = {
      take: 50,
      app_path: appPath,
      ...(cursor && { cursor }),
      ...(descendingOrder && { order_by: descendingOrder }),
      ...(searchQuery && { search: searchQuery }),
      ...(startDate && { from_date: startDate }),
      ...(endDate && { to_date: endDate }),
      ...(dataSpaceIds?.length > 0 && { data_space_ids: dataSpaceIds }),
      version
    };

    const httpParams = new HttpParams({ fromObject: params });
    return this.http
      .get<PageDto<OptimSpaceModel, number>>(this.urlOptimSpaceV4, {
        params: httpParams
      })
      .pipe(
        map((result) => {
          return {
            optimSpaces: result.result.map((r) =>
              this.apiTranslatorService.modelToClass(OptimSpaceModel, r)
            ),
            cursor: result.cursor_next_page
          };
        })
      );
  }

  /**
   * HTTP request to save a new optimSpace along with the new input for the application referenced by `appPath`
   * @param appPath a string that represents the application path
   * @param newOptimSpace an UploadOptimSpaceModel object that represents the new optimSpace to be saved that contains the input to be created
   * @returns an observable that emits optimSpaceId and the initialInputId of the new optimSpace
   */
  postOptimSpace$(newOptimSpace: UploadOptimSpaceModel): Observable<InputModel> {
    return this.http
      .post<CreateInputResponseV4>(this.urlInputV4, newOptimSpace)
      .pipe(map((res) => InputMapper.toIputModel(res)));
  }

  objToCSVString(data: Object) {
    const headers = Object.keys(data);
    const vectors = Object.values(data);
    const csvRows = [];
    // This line will cause problems according to how the client formats their float numbers
    const delimiter = ',';

    // Construct header row
    const headerRow = headers.join(delimiter);
    csvRows.push(headerRow);

    // Fill the rest of the rows
    const maxLength = Math.max(...vectors.map((vector) => vector.length));
    for (let rowIdx = 0; rowIdx < maxLength; rowIdx++) {
      let row = [];
      for (let colIdx = 0; colIdx < vectors.length; colIdx++) {
        if (vectors[colIdx].length < rowIdx || vectors[colIdx][rowIdx] == null) {
          row.push(''); // null, missing, "", undefined
        } else if (
          typeof vectors[colIdx][rowIdx] == 'string' &&
          vectors[colIdx][rowIdx].includes(',')
        ) {
          row.push(`"${vectors[colIdx][rowIdx]}"`); // wrap strings containing comma in double quotes
        } else {
          row.push(vectors[colIdx][rowIdx]); // 0, 0.0, 1, -1, "non empty string"
        }
      }
      row.join(delimiter);
      csvRows.push(row);
    }
    return csvRows.join('\n');
  }

  /**
   * HTTP request to save a new optimSpace along with the new input for the application referenced by `appPath`
   * @param appPath a string that represents the application path
   * @param newOptimSpace an UploadOptimSpaceModel object that represents the new optimSpace to be saved that contains the input to be created
   * @returns an observable that emits optimSpaceId and the initialInputId of the new optimSpace
   */
  postOptimSpaceCsv$(newOptimSpace: UploadOptimSpaceModel): Observable<InputModel> {
    const requestData = {
      ...newOptimSpace,
      data: this.objToCSVString(newOptimSpace.data)
    };
    return this.http
      .post<CreateInputResponseV4>(`${this.urlInputV4}/csv`, requestData)
      .pipe(map((response) => InputMapper.toIputModel(response)));
  }

  /**
   * HTTP request to delete an optimSpace
   * @param optimSpaceId a number that represents the  optimSpace ID
   * @returns
   */
  deleteOptimSpace$(optimSpaceId: number): Observable<any> {
    return this.http.delete(`${this.urlOptimSpaceV4}/${optimSpaceId}`);
  }

  /**
   * HTTP request that the retrieves kpis of an input.
   * We add an output id to know when the visu data changes.
   * @param inputId
   * @returns an observable kpis
   */
  getOptimSpaceKpi(inputId: number): Observable<InputKpiV4> {
    return this.http.get<InputKpiV4>(`${this.urlInputV4}/${inputId}/kpis`).pipe(
      map((res: InputKpiV4) => {
        return res;
      })
    );
  }

  updateOptimSpace(dto: UpdateOptimSpace): Observable<UpdateOptimSpace> {
    return this.http.put<UpdateOptimSpace>(`${this.urlOptimSpaceV4}/${dto.id}`, {
      name: dto.name,
      description: dto.description?.length > 0 ? dto.description : null
    });
  }
}
