import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('src/app/blocks/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'authwall',
    loadChildren: () =>
      import('src/app/blocks/authwall/authwall.module').then((m) => m.AuthwallModule)
  },
  {
    path: 'error-500',
    loadChildren: () =>
      import('src/app/blocks/page500/page500.module').then((m) => m.Page500Module)
  },
  {
    path: 'error-404',
    loadChildren: () =>
      import('src/app/blocks/page404/page404.module').then((m) => m.Page404Module)
  },
  {
    path: 'health',
    loadChildren: () =>
      import('src/app/blocks/health/health.module').then((m) => m.HealthModule)
  },

  {
    path: '**',
    loadChildren: () =>
      import('src/app/blocks/page404/page404.module').then((m) => m.Page404Module)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlocksRoutingModule {}
