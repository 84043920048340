/**
 * A BusinessUnit is the entity representing the consumption that leads to a regular bill.
 * This data structure contains businessUnit metadata returned by the API
 */
export class BusinessUnit {
  id: number;
  name: string;
  description: string;
  active: boolean;
  created_at: Date;
}

export class BusinessUnitPermission {
  business_unit_id: number;
  app_path: string;
  can_optimize: boolean;
  can_recompute: boolean;
}

type ApplicationPermission = {
  name: string;
  app_path: string;
  features: string[];
};

export type GetBusinessUnitResponseV4 = {
  id: number;
  name: string;
  description: string;
  num_data_spaces: number;
  applications: ApplicationPermission[];
  created_at: Date;
};

export class BusinessUnitMapper {
  static toBusinessUnitPermissions(
    businessUnit: GetBusinessUnitResponseV4
  ): BusinessUnitPermission[] {
    return businessUnit.applications.map((app) => {
      const isReadOnly = app.features.includes('READ_ONLY');
      const canOptimize = app.features.includes('DEFAULT') && !isReadOnly;
      const canRecompute = app.features.includes('RECOMPUTE') && !isReadOnly;
      return {
        business_unit_id: businessUnit.id,
        app_path: app.app_path,
        can_optimize: canOptimize,
        can_recompute: canRecompute
      };
    });
  }
}
