import { InteractionManagerSingleton } from 'src/app/feature/applications/warehouse/components/warehouse3d/module/core/interactive-manager-singleton';
import { Material } from 'three';

export class SceneDispose {
  static dispose(obj: THREE.Object3D): void {
    SceneDispose.disposeObject(obj);
    SceneDispose.clearObjects(obj);
  }

  private static clearObjects(obj: THREE.Object3D) {
    const children = obj.children;

    for (let i = children.length - 1; i >= 0; i--) {
      const child = children[i];
      SceneDispose.clearObjects(child);
      obj.remove(child);
    }
  }

  private static disposeObject(obj: THREE.Object3D): void {
    if (obj !== null) {
      for (var i = 0; i < obj.children.length; i++) {
        SceneDispose.disposeObject(obj.children[i]);
      }

      const mesh = obj as THREE.Mesh;
      if (mesh.geometry) {
        InteractionManagerSingleton.getInstance().remove(mesh);
        mesh.geometry.dispose();
        mesh.geometry = undefined;
      }
      if (mesh.material instanceof Material) {
        (mesh.material as Material).dispose();
      } else if (mesh.material instanceof Array) {
        (mesh.material as Material[]).forEach((material) => material.dispose());
      }
      mesh.material = undefined;

      if ((obj as any).texture) {
        (obj as any).texture.dispose();
        (obj as any).texture = undefined;
      }
      obj = undefined;
    }
  }
}
