import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/authInterceptor.service';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './core/store/auth/auth.effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { localStorageSyncReducer, reducers, RootState } from './core/store';
import { ApplicationEffects } from './core/store/application/application.effects';

import { ErrorInterceptor } from './core/interceptors/error.interceptor.service';
import { SharedModule } from './shared/shared.module';
import { BlocksModule } from './blocks/blocks.module';
import { FeatureModule } from './feature/feature.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OptimSpacePickerEffect } from './feature/applications/optim-space-picker/store/optim-space-picker.effects';
import { optimSpacePickerReducer } from './feature/applications/optim-space-picker/store/optim-space-picker.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { paramPickerReducer } from './feature/applications/param-picker/store/param-picker.reducers';
import { ParamPickerEffect } from './feature/applications/param-picker/store/param-picker.effects';
import { outputPickerReducer } from './feature/applications/output-picker/store/output-picker.reducers';
import { OutputPickerEffect } from './feature/applications/output-picker/store/output-picker.effects';

const metaReducers: Array<MetaReducer<RootState, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    BlocksModule,
    FeatureModule,
    CommonModule,
    // setup the store
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: true // Restrict extension to log-only mode
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal
    }),
    StoreModule.forFeature('optimSpacePickerComponent', optimSpacePickerReducer),
    StoreModule.forFeature('paramPickerComponent', paramPickerReducer),
    StoreModule.forFeature('outputPickerComponent', outputPickerReducer),
    EffectsModule.forRoot([AuthEffects, ApplicationEffects]),
    EffectsModule.forFeature([
      OptimSpacePickerEffect,
      ParamPickerEffect,
      OutputPickerEffect
    ])
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
