import { Pipe, PipeTransform } from '@angular/core';
import { MessageCategory } from 'src/app/core/models/MessageCategory';

@Pipe({ name: 'alertColor' })
export class AlertColor implements PipeTransform {
  transform(category: MessageCategory): string {
    switch (category) {
      case 'info':
        return 'primary';
      case 'warning':
        return 'yellow';
      case 'error':
        return 'red';
      case 'success':
        return 'green';
    }
    return 'green';
  }
}
