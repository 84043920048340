import * as THREE from 'three';
export class SceneSingleton {
  private static scene: THREE.Scene;

  public static getScene(): THREE.Scene {
    if (!SceneSingleton.scene) {
      SceneSingleton.scene = new THREE.Scene();
    }
    return SceneSingleton.scene;
  }
}
