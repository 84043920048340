import { Color, Object3D } from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';

export class LineShape {
  static create(container: Object3D, lineProps: LineProperties): LineData {
    const geometry = new LineGeometry();
    geometry.setPositions(lineProps.points);

    const color = new Color(lineProps.color);
    geometry.setColors([color.r, color.g, color.b]);

    const material = LineShape.createLineMaterial(lineProps);

    const line = new Line2(geometry, material);
    line.computeLineDistances();
    line.scale.set(1, 1, 1);
    line.matrixAutoUpdate = false;
    line.updateMatrix();

    container.add(line);
    return {
      line,
      material
    };
  }

  static createLineMaterial(lineProps: LineProperties): LineMaterial {
    return new LineMaterial({
      color: lineProps.color,
      linewidth: lineProps.lineWidth,
      vertexColors: false,
      dashed: false
    });
  }
}

export interface LineProperties {
  color: string;
  lineWidth: number;
  points: number[];
}

export interface LineData {
  line: Line2;
  material: LineMaterial;
}
