import { WarehouseAisle } from 'src/app/core/models/WarehouseModel';
import { Direction, Line } from './line';
import {
  BoxData,
  BoxShape
} from '../../../../../../../../shared/visualization3d/shapes/box-shape';
import { Object3D, MeshStandardMaterial } from 'three';
import { StandardMaterial } from '../../../../../../../../shared/visualization3d/shapes/standard-material';
import {
  ArrowData,
  ArrowShape
} from '../../../../../../../../shared/visualization3d/shapes/arrow-shape';
import { WarehouseEventBus } from './warehouse-event-bus';
import { WarehouseDAO } from '../dao/warehouse.dao';

export class WarehousePath {
  private static HEAT_VALUE_LIMITS: HeatValueLimits;
  private static MATERIAL_BOX: MeshStandardMaterial;
  private static MATERIAL_ARROW: MeshStandardMaterial;
  private static Y_BOX_SIZE: number;
  private static Y_ARROW_SIZE: number;
  private static readonly Y_OFFSET: number = 5;

  private line: Line;
  private boxData: BoxData;
  private boxHeatValue: BoxData;
  private triangleData: ArrowData;
  private showHeatMap: boolean = false;
  private showWarehousePath: boolean = true;

  constructor(private readonly aisle: WarehouseAisle, warehouseDAO: WarehouseDAO) {
    if (!WarehousePath.MATERIAL_BOX) {
      WarehousePath.MATERIAL_BOX = StandardMaterial.create({
        color: '#000000'
      });
    }
    if (!WarehousePath.MATERIAL_ARROW) {
      WarehousePath.MATERIAL_ARROW = StandardMaterial.create({
        color: '#ffffff'
      });
    }

    if (!WarehousePath.Y_BOX_SIZE) {
      WarehousePath.Y_BOX_SIZE = warehouseDAO.getContainerCoords().max.y / 12;
      WarehousePath.Y_ARROW_SIZE = WarehousePath.Y_BOX_SIZE + 10;
    }

    WarehouseEventBus.getEventBus().on('dispose', () => {
      WarehousePath.MATERIAL_BOX = undefined;
      WarehousePath.MATERIAL_ARROW = undefined;
      WarehousePath.HEAT_VALUE_LIMITS = undefined;
      WarehousePath.Y_BOX_SIZE = undefined;
      WarehousePath.Y_ARROW_SIZE = undefined;
    });
  }

  draw(warehouse: Object3D): void {
    this.line = new Line(this.aisle.start, this.aisle.finish);
    if (this.line.getXZDirection() === Direction.STOPED) {
      return;
    }
    const midPoint = this.line.getMiddlePoint();
    this.boxData = BoxShape.create(warehouse, {
      position: {
        x: midPoint.x,
        y: midPoint.y + WarehousePath.Y_OFFSET + WarehousePath.Y_BOX_SIZE / 2,
        z: midPoint.z
      },
      dimensions: {
        xSize: this.line.getDistance(),
        ySize: WarehousePath.Y_BOX_SIZE,
        zSize: this.aisle.breadth / 6
      },
      rotation: {
        ry: this.line.calculateXZAngle()
      },
      material: WarehousePath.MATERIAL_BOX,
      opts: {
        createLineSegments: false,
        matrixAutoUpdate: false
      }
    });

    this.boxHeatValue = BoxShape.create(warehouse, {
      position: {
        x: midPoint.x,
        y: midPoint.y + WarehousePath.Y_OFFSET + WarehousePath.Y_BOX_SIZE / 2,
        z: midPoint.z
      },
      dimensions: {
        xSize: this.line.getDistance(),
        ySize: WarehousePath.Y_BOX_SIZE,
        zSize: Math.max(
          this.aisle.breadth / 20,
          this.aisle.breadth * this.getHeatValueNormalized()
        ) // avoid invisible lines
      },
      rotation: {
        ry: this.line.calculateXZAngle()
      },
      material: WarehousePath.MATERIAL_BOX,
      opts: {
        createLineSegments: false,
        matrixAutoUpdate: false
      }
    });
    this.boxHeatValue.group.visible = false;

    // bidirectional paths don't have arrows
    if (this.aisle.directed) {
      this.triangleData = ArrowShape.create(warehouse, {
        position: { x: midPoint.x, y: midPoint.y, z: midPoint.z },
        dimensions: {
          xSize: this.aisle.breadth / 2,
          ySize: WarehousePath.Y_ARROW_SIZE,
          zSize: this.aisle.breadth / 2
        },
        rotation: {
          ry: this.line.calculateXZAngle()
        },
        material: WarehousePath.MATERIAL_ARROW,
        opts: {
          createLineSegments: false,
          matrixAutoUpdate: false
        }
      });
    }

    this.addEvents();
  }

  static setHeatValueLimits(aisles: WarehouseAisle[]): void {
    let min = 0;
    let max = 0.1;

    for (let i = 0; i < aisles.length; i++) {
      const heatValue = aisles[i].heat_value;
      if (heatValue < min) {
        min = heatValue;
      }
      if (heatValue > max) {
        max = heatValue;
      }
    }
    WarehousePath.HEAT_VALUE_LIMITS = { min, max };
  }

  private addEvents(): void {
    WarehouseEventBus.getEventBus().on('showHeatmap', (show: boolean) => {
      this.showHeatMap = show;
      if (!this.showWarehousePath) {
        return;
      }
      this.boxData.group.visible = !this.showHeatMap;
      this.boxHeatValue.group.visible = this.showHeatMap;
    });

    WarehouseEventBus.getEventBus().on('showWarehousePath', (show: boolean) => {
      this.showWarehousePath = show;
      if (this.triangleData) {
        this.triangleData.arrow.visible = show;
      }
      if (show) {
        this.boxData.group.visible = !this.showHeatMap;
        this.boxHeatValue.group.visible = this.showHeatMap;
      } else {
        this.boxData.group.visible = false;
        this.boxHeatValue.group.visible = false;
      }
    });
  }

  private getHeatValueNormalized(): number {
    return (
      (this.aisle.heat_value - WarehousePath.HEAT_VALUE_LIMITS.min) /
      (WarehousePath.HEAT_VALUE_LIMITS.max - WarehousePath.HEAT_VALUE_LIMITS.min)
    );
  }
}

type HeatValueLimits = {
  min: number;
  max: number;
};
