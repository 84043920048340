export type WarehouseEventType =
  | 'showTooltip'
  | 'dispose'
  | 'showHeatmap'
  | 'updateTime'
  | 'selectPickers'
  | 'showPickersPath'
  | 'updateMaxTime'
  | 'pickersList'
  | 'service'
  | 'showWarehousePath'
  | 'changeCamera'
  | 'sceneCreated'
  | 'clickWarehousePoint'
  | 'showStock'
  | 'notifyStock';

export class WarehouseEventBus {
  private static eventBus: WarehouseEventBus;
  private listeners: Map<string, ((data: any) => void)[]> = new Map();
  private permanentListener: Map<string, ((data: any) => void)[]> = new Map();

  public static getEventBus(): WarehouseEventBus {
    if (!this.eventBus) {
      this.eventBus = new WarehouseEventBus();
    }
    return WarehouseEventBus.eventBus;
  }

  emit(eventName: WarehouseEventType, data: any): void {
    if (this.listeners.has(eventName)) {
      this.listeners.get(eventName).forEach((listener) => listener(data));
    }
    if (this.permanentListener.has(eventName)) {
      this.permanentListener.get(eventName).forEach((listener) => listener(data));
    }
  }

  on(
    eventName: WarehouseEventType,
    listener: (data: any) => void,
    permanent: boolean = false
  ): void {
    if (permanent) {
      if (!this.permanentListener.has(eventName)) {
        this.permanentListener.set(eventName, []);
      }
      this.permanentListener.get(eventName).push(listener);
      return;
    }

    if (!this.listeners.has(eventName)) {
      this.listeners.set(eventName, []);
    }
    this.listeners.get(eventName).push(listener);
  }

  remove(eventName: WarehouseEventType, listener: (data: any) => void): void {
    if (this.listeners.has(eventName)) {
      this.listeners
        .get(eventName)
        .splice(this.listeners.get(eventName).indexOf(listener), 1);
    }
  }

  removePermanent(eventName: WarehouseEventType, listener: (data: any) => void): void {
    if (this.permanentListener.has(eventName)) {
      this.permanentListener
        .get(eventName)
        .splice(this.permanentListener.get(eventName).indexOf(listener), 1);
    }
  }

  clear(): void {
    this.listeners.clear();
  }
}
