import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlocksRoutingModule } from './blocks-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, BlocksRoutingModule],
  exports: []
})
export class BlocksModule {}
