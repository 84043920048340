import * as THREE from 'three';
import { NewVisuData } from 'src/app/core/models/Visu';
import { WarehousePointBase } from './warehouse-point-base';
import { WarehousePointFactory } from './warehouse-point-factory';
import { SceneSingleton } from '../scene-singleton';
import { StandardMaterial } from '../../../../../../../../shared/visualization3d/shapes/standard-material';
import { BoxShape } from '../../../../../../../../shared/visualization3d/shapes/box-shape';
import { Picker } from './picker';
import { WarehouseTimeline } from '../controls/warehouse-timeline';
import { WarehouseEventBus } from './warehouse-event-bus';
import { WarehousePath } from './warehouse-path';
import { WarehouseDAO } from '../dao/warehouse.dao';
import { WarehouseWalls } from './warehouse-walls';
import { WarehouseStockControl } from './warehouse-stock-control';
import { WarehouseCaptionsUtils } from '../utils/warehouse-captions-utils';
import { SceneDispose } from 'src/app/shared/visualization3d/scene-dispose';

export class Warehouse {
  private readonly warehousePoints: WarehousePointBase[];
  private readonly pickers: Picker[];
  private readonly aisles: WarehousePath[];
  private readonly walls: WarehouseWalls;
  private warehouseGroup: THREE.Group;

  constructor(private readonly visuData: NewVisuData) {
    const warehouseDAO = new WarehouseDAO(visuData);
    WarehouseStockControl.init(warehouseDAO);
    WarehouseCaptionsUtils.init(warehouseDAO);
    this.warehousePoints = WarehousePointFactory.createWarehousePoints(warehouseDAO);

    const maxTime = warehouseDAO.getMaxTime();
    WarehouseTimeline.getInstance().setLastFrame(maxTime);

    WarehousePath.setHeatValueLimits(this.visuData.warehouse_model?.aisles ?? []);

    this.aisles = warehouseDAO
      .getAisles()
      .map((aisle) => new WarehousePath(aisle, warehouseDAO));
    const pickersList = this.visuData.warehouse_model.routes.map((route) => route.id);

    WarehouseEventBus.getEventBus().emit('pickersList', pickersList);
    WarehouseEventBus.getEventBus().emit('updateMaxTime', maxTime);

    this.walls = new WarehouseWalls(warehouseDAO);

    this.pickers = this.visuData.warehouse_model.routes.map(
      (_route, index) => new Picker(index, warehouseDAO, maxTime)
    );
  }

  draw(): void {
    this.warehouseGroup = new THREE.Group();
    this.drawBase();
    this.walls.draw(this.warehouseGroup);
    this.pickers.forEach((p) => {
      p.setup();
      p.draw(this.warehouseGroup);
    });
    this.aisles?.forEach((a) => a.draw(this.warehouseGroup));

    this.warehousePoints.forEach((point) => point.draw(this.warehouseGroup));
    SceneSingleton.getScene().add(this.warehouseGroup);
  }

  dispose(): void {
    SceneDispose.dispose(this.warehouseGroup);
  }

  private drawBase(): void {
    const { x, z, xSize, zSize } = this.findeMidPointForBase();
    const height = 0.05;
    const base = StandardMaterial.create({
      color: '#d3d3d3'
    });
    BoxShape.create(this.warehouseGroup, {
      position: { x, y: -height / 2, z },
      dimensions: { xSize: xSize, ySize: height, zSize: zSize },
      material: base,
      opts: {
        matrixAutoUpdate: false
      }
    });
  }

  private findeMidPointForBase(): { x: number; z: number; xSize: number; zSize: number } {
    const { x: x1, y: z1 } = this.visuData.warehouse_model.min_coordinates;
    const { x: x2, y: z2 } = this.visuData.warehouse_model.max_coordinates;

    return {
      x: (x1 + x2) / 2,
      z: (z1 + z2) / 2,
      xSize: x2 - x1,
      zSize: z2 - z1
    };
  }
}
