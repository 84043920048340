import { MessageCategory } from 'src/app/core/models/MessageCategory';

export interface OutputPickerTableState {
  colNames: string[];
  rows: Array<{ id: number; rowData: any[] }>;
  orderBy: string;
  startDate: string;
  endDate: string;
}

export const initialDataPickerState: OutputPickerState = {
  selectedIds: [],
  data: { colNames: [], rows: [], orderBy: 'desc', startDate: '', endDate: '' },
  currentLoadedId: 0,
  spinnerSelect: false,
  cursor: -1
};

export interface MessageState {
  category: MessageCategory;
  message: string;
  persistentMessage: boolean;
  details?: { action: string; title: string; htmlMessage: string };
}

export interface OutputPickerState {
  /**
   * Data of the table (head + array of rows).
   */
  data: OutputPickerTableState;

  /**
   * current optimSpace loaded
   */
  currentLoadedId: number;

  /**
   * Ids of optimSpace/input/output currently selected by the user in the table.
   */
  selectedIds: number[];

  spinnerSelect: boolean;

  message?: MessageState;

  cursor?: number;
}
