import {
  phosphorIconCaretRight,
  phosphorIconMapPin,
  phosphorIconMapPinCube,
  phosphorIconMapPinEmpty,
  phosphorIconMapPinMinus,
  phosphorIconMapPinPlus,
  phosphorIconMapPinRelay,
  phosphorIconMapPinSquare,
  phosphorIconMapRoadDirection
} from 'src/app/shared/components/phosphor-icons/phosphor-icons';

export enum MapFilterType {
  RESET = 0,
  UNSELECTED_ALL_GROUP_ID = -1
}

export type HighlightAggregatorMarker = {
  aggregatorIds: number[];
  markerSelectedId: number;
};

export enum ProfileType {
  Car = 0,
  Truck = 1
}

export class StructuralAggregatorMapbox {
  id: number;
  name: string;
  routeVisibility: boolean;
  solutionType: string;
  orderedMarkerIds: Array<number>;
  color: string;
  weight: number;
  profile: ProfileType;
  areaDataIds: string[];
}

export type MarkerGaliaType =
  | 'basic'
  | 'cube'
  | 'relay'
  | 'minus'
  | 'plus'
  | 'square'
  | 'base'
  | 'none';

export type LngLat = [number, number];

export type MarkerDescription = {
  name: string;
  startDate: string;
  endDate: string;
  content: {};
};

export class MarkerGalia {
  id: number;
  coordinates: LngLat;
  type: MarkerGaliaType;
  color: string;
  description: MarkerDescription;
  transportMode: string;
  structuralAggregatorIds?: number[];
  highlightAggregatorIds?: number[];
  draggable?: boolean;
}

export class IconMapGalia {
  iconInUse = ['basic', 'base', 'square', 'minus', 'plus', 'relay', 'cube', 'arrow-road'];

  createIcon(combineIconName?: string) {
    const parser = new DOMParser();
    switch (combineIconName) {
      case 'basic':
        return parser
          .parseFromString(phosphorIconMapPinEmpty.data, 'text/html')
          .getElementsByTagName('svg')[0];
      case 'plus':
        return parser
          .parseFromString(phosphorIconMapPinPlus.data, 'text/html')
          .getElementsByTagName('svg')[0];
      case 'minus':
        return parser
          .parseFromString(phosphorIconMapPinMinus.data, 'text/html')
          .getElementsByTagName('svg')[0];
      case 'square':
        return parser
          .parseFromString(phosphorIconMapPinSquare.data, 'text/html')
          .getElementsByTagName('svg')[0];
      case 'cube':
        return parser
          .parseFromString(phosphorIconMapPinCube.data, 'text/html')
          .getElementsByTagName('svg')[0];
      case 'relay':
        return parser
          .parseFromString(phosphorIconMapPinRelay.data, 'text/html')
          .getElementsByTagName('svg')[0];
      case 'base':
        return parser
          .parseFromString(phosphorIconMapPin.data, 'text/html')
          .getElementsByTagName('svg')[0];
      case 'arrow-road':
        return parser
          .parseFromString(phosphorIconMapRoadDirection.data, 'text/html')
          .getElementsByTagName('svg')[0];
      case 'carret-right':
        return parser
          .parseFromString(phosphorIconCaretRight.data, 'text/html')
          .getElementsByTagName('svg')[0];
      default:
        return parser
          .parseFromString(phosphorIconMapPinEmpty.data, 'text/html')
          .getElementsByTagName('svg')[0];
    }
  }
}
