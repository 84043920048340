import { Kpi } from './Kpi';
import { NewVisuData } from './Visu';

export type VisualizationType = 'input' | 'ouput';

class ApplicationShortInfo {
  name: string;
  path: string;
}

export class DataVisualization {
  application: ApplicationShortInfo;
  dataId: number;
  dataType: VisualizationType;
  visu?: NewVisuData;
  kpis: Kpi[];
}

export class ExportSolution {
  token: string;
  expiresInSeconds: number;
}

export type DataVisualizationV4 = {
  application: {
    name: string;
    app_path: string;
  };
  data_id: number;
  data_type: VisualizationType;
  visu: NewVisuData;
  kpis: {
    name: string;
    description: string;
    value: string;
    unit: string;
  }[];
};

export class DataVisualitionMapper {
  static toDataVisualization(data: DataVisualizationV4): DataVisualization {
    return {
      application: {
        name: data.application.name,
        path: data.application.app_path
      },
      dataId: data.data_id,
      dataType: data.data_type,
      visu: data.visu,
      kpis: data.kpis as any
    };
  }
}
