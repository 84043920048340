import * as THREE from 'three';

export class Compass {
  private element: HTMLElement;
  private direction = new THREE.Vector3();
  private spherical = new THREE.Spherical();

  constructor(element: HTMLElement) {
    this.element = element;
  }

  update(camera: THREE.Camera): void {
    camera.getWorldDirection(this.direction);
    this.spherical.setFromVector3(this.direction);
    this.element.style.transform = `rotate(${
      THREE.MathUtils.radToDeg(this.spherical.theta) - 180
    }deg)`;
  }
}
