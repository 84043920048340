import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2
} from '@angular/core';
import {
  applyStyles,
  computeStyles,
  Instance,
  offset,
  Options,
  Placement,
  popperOffsets
} from '@popperjs/core';
import { createPopper } from '@popperjs/core/lib/createPopper';
import { Subject, takeUntil } from 'rxjs';
import { DropdownMenuDirective } from './dropdown-menu.directive';
import { DropdownOriginDirective } from './dropdown-origin.directive';

// Comes from https://netbasal.com/create-powerful-action-menu-with-angular-and-popper-eb047f789f2c
// Upgrade to Popper v2 by Guillaume.

@Component({
  selector: 'dropdown-menu',
  template: ` <ng-content></ng-content> `
  //styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements AfterViewInit, OnDestroy {
  @ContentChild(DropdownOriginDirective) origin: DropdownOriginDirective;
  @ContentChild(DropdownMenuDirective) dropdown: DropdownMenuDirective;

  @Input() placement: Placement = 'bottom-start';
  @Input() closeWhenClickOnDropdown: boolean = true;

  private _popper: Instance;
  private _open = false;

  private _stop$ = new Subject<void>();

  constructor(private readonly host: ElementRef) {}

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this._popper = createPopper(
      this.origin.element,
      this.dropdown.element,
      this._getPopperOptions()
    );
    this.origin.click.pipe(takeUntil(this._stop$)).subscribe((_) => {
      this._open = !this._open;
      if (this._open) {
        this.open();
      } else {
        this.close();
      }
    });
    this.dropdown.click.pipe(takeUntil(this._stop$)).subscribe(() => {
      if (this.closeWhenClickOnDropdown) {
        this.close();
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this._stop$.next();
    this._stop$.complete();
    this.close();
    this._popper.destroy();
  }

  @HostListener('document:click', ['$event.target'])
  click(target) {
    if (!(this.host.nativeElement as HTMLElement).contains(target)) {
      this.close();
    }
  }

  open() {
    this.dropdown.element.setAttribute('data-show', '');
    this._popper.update();
  }

  close() {
    this._open = false;
    this.dropdown.element.removeAttribute('data-show');
  }

  private _getPopperOptions() {
    const minWidth = {
      name: 'minWidth',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }) => {
        state.styles.popper.minWidth = `${state.rects.reference.width}px`;
      },
      effect: ({ state }) => {
        state.elements.popper.style.minWidth = `${state.elements.reference.offsetWidth}px`;
      }
    };
    return {
      placement: this.placement,
      modifiers: [popperOffsets, offset, computeStyles, applyStyles, minWidth]
    } as Options;
  }
}
