import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { WarehousePoint } from 'src/app/core/models/WarehouseModel';
import { WarehousePointBase } from './warehouse-point-base';
import { WarehouseColorUtils } from '../utils/color-utils';
import { StandardMaterial } from '../../../../../../../../shared/visualization3d/shapes/standard-material';
import {
  BoxData,
  BoxShape
} from '../../../../../../../../shared/visualization3d/shapes/box-shape';
import { InteractionManagerSingleton } from '../interactive-manager-singleton';
import { WarehouseEventBus } from './warehouse-event-bus';
import { HeatMapUtils } from '../utils/heatmap-utils';
import { WarehouseDAO } from '../dao/warehouse.dao';
import { WarehouseShowTooltipEvent } from './warehouse-events';
import { WarehouseHeatValues } from './warehouse-heat-values';

export class WarehouseStoragePoint implements WarehousePointBase {
  // this warehouse points
  private readonly warehousePoint: WarehousePoint;

  private readonly categoryColor: string;

  // 3D object
  private mesh: BoxData;

  // Emissive Color for mouseover
  private emissiveColor: THREE.Color = new THREE.Color(0xaaaaaa);
  private blackColor: THREE.Color = new THREE.Color(0x000000);

  // colors
  private boxColor: THREE.Color;
  private heatColor: THREE.Color;
  private maxHeatColor: THREE.Color; // 2D visualization only

  // service animation
  private serviceAnimation: TWEEN.Tween<any>;

  constructor(
    private readonly index: number,
    private readonly warehouseDAO: WarehouseDAO,
    private readonly warehouseHeatValues: WarehouseHeatValues
  ) {
    this.warehousePoint = warehouseDAO.getWarehousePoint(index);
    this.categoryColor = this.getCategoryColor();
  }

  draw(warehouse: THREE.Object3D): void {
    const { position, dimensions } = this.warehousePoint;
    this.mesh = BoxShape.create(warehouse, {
      position,
      dimensions: {
        xSize: dimensions.x_size,
        ySize: dimensions.y_size,
        zSize: dimensions.z_size
      },
      material: StandardMaterial.create({
        color: this.categoryColor
      }),
      opts: {
        createLineSegments: true,
        matrixAutoUpdate: false,
        useSlack: true
      }
    });

    this.addMouseEvents();
    this.addToggleHeatmapEvent();
    this.addServiceEvent();
  }

  private getCategoryColor(): string {
    const categories = this.warehousePoint.category;
    if (categories.length > 0) {
      const mode = this.findCategoriesMode();
      return WarehouseColorUtils.getCategoryColor(mode - 1);
    }
    // default color since julia uses 1 as first position
    return WarehouseColorUtils.getCategoryColor(-1);
  }

  private findCategoriesMode(): number {
    const categories = this.warehousePoint.category;
    const categoriesCount = categories.reduce((acc, category) => {
      acc[category] = (acc[category] || 0) + 1;
      return acc;
    }, {});
    const mode = Object.keys(categoriesCount).reduce((a, b) =>
      categoriesCount[a] > categoriesCount[b] ? a : b
    );
    return +mode;
  }

  private addMouseEvents(): void {
    const tooltipText = this.buildTooltipText();
    InteractionManagerSingleton.getInstance().add(this.mesh.box);
    const onMouseOver = () => {
      (this.mesh.box.material as THREE.MeshStandardMaterial).emissive = this.emissiveColor;
      WarehouseEventBus.getEventBus().emit('showTooltip', {
        show: true,
        text: tooltipText
      } as WarehouseShowTooltipEvent);
    };
    const onMouseOut = () => {
      (this.mesh.box.material as THREE.MeshStandardMaterial).emissive = this.blackColor;
      WarehouseEventBus.getEventBus().emit('showTooltip', {
        show: false
      } as WarehouseShowTooltipEvent);
    };

    let numClick = 0;
    const onMouseClick = (event: any) => {
      event.stopPropagation();
      numClick++;
      setTimeout(() => (numClick = 0), 500);
      if (numClick == 2) {
        WarehouseEventBus.getEventBus().emit('clickWarehousePoint', this.index);
      }
    };

    this.mesh.box.addEventListener('mouseover', onMouseOver);
    this.mesh.box.addEventListener('mouseout', onMouseOut);
    this.mesh.box.addEventListener('click', onMouseClick);
    WarehouseEventBus.getEventBus().on('dispose', () => {
      this.mesh.box.removeEventListener('mouseover', onMouseOver);
      this.mesh.box.removeEventListener('mouseout', onMouseOut);
      this.mesh.box.removeEventListener('click', onMouseClick);
      if (this.serviceAnimation) {
        this.serviceAnimation.stop();
        this.serviceAnimation = undefined;
      }
    });
  }

  private addToggleHeatmapEvent(): void {
    const material = this.mesh.box.material as THREE.MeshStandardMaterial;
    this.boxColor = material.color;
    WarehouseEventBus.getEventBus().on('showHeatmap', (show) => {
      material.color = show ? this.getHeatColor() : this.boxColor;
    });
  }

  private getHeatColor(): THREE.Color {
    const { x, z } = this.warehousePoint.position;
    const maxValue = this.warehouseHeatValues.get2DMaxValueForPosition(x, z);
    // visualization is 2D and at leat one box is not empty
    if (maxValue != undefined && maxValue >= 0) {
      if (!this.maxHeatColor) {
        this.maxHeatColor = new THREE.Color(HeatMapUtils.getHeatMapColor(maxValue));
      }
      return this.maxHeatColor;
    }

    // 3d visualization with category should show heat map
    if (this.warehousePoint.category?.length > 0) {
      if (!this.heatColor) {
        this.heatColor = new THREE.Color(
          HeatMapUtils.getHeatMapColor(this.warehousePoint.heat_value)
        );
      }
      return this.heatColor;
    }
    // 3d visualization but the storage point doesn't have category (empty box)
    return this.boxColor;
  }

  private addServiceEvent(): void {
    WarehouseEventBus.getEventBus().on('service', (id: number) => {
      if (this.index === id) {
        this.doServiceAnimation();
      }
    });
  }

  private buildTooltipText(): string {
    const appElementId = this.warehousePoint.app_elem_id;
    if (appElementId == undefined) {
      return;
    }

    const elements = this.warehouseDAO.getAppLabesById(appElementId);
    if (!elements) {
      return;
    }
    const res = elements.map(
      (element) => `<strong>${element.name}:</strong> ${element.value}`
    );
    res.push(`<strong>Heat:</strong> ${this.warehousePoint.heat_value}`);
    const categories = this.warehouseDAO.getCategoriesNames(this.warehousePoint.category);
    if (categories.length > 0) {
      res.push(`<strong>Categories:</strong> ${categories.join(', ')}`);
    }

    return res.join('<br/>');
  }

  private doServiceAnimation(): void {
    if (!this.serviceAnimation) {
      // build animation only when necessary
      this.serviceAnimation = this.buildAnimationForService();
    }
    if (!this.serviceAnimation.isPlaying()) {
      this.serviceAnimation.resume();
      this.serviceAnimation.start();
    }
  }

  private buildAnimationForService(): TWEEN.Tween<any> {
    const fromColor = this.blackColor;
    const toColor = this.emissiveColor;

    const from1 = { r: fromColor.r, g: fromColor.g, b: fromColor.b };
    const to1 = { r: toColor.r, g: toColor.g, b: toColor.b };

    const from2 = { r: toColor.r, g: toColor.g, b: toColor.b };
    const to2 = { r: fromColor.r, g: fromColor.g, b: fromColor.b };

    return new TWEEN.Tween(from1)
      .to(to1, 750)
      .onUpdate((color) => {
        (this.mesh.box.material as THREE.MeshStandardMaterial).emissive.setRGB(
          color.r,
          color.g,
          color.b
        );
      })
      .chain(
        new TWEEN.Tween(from2).to(to2, 750).onUpdate((color) => {
          (this.mesh.box.material as THREE.MeshStandardMaterial).emissive.setRGB(
            color.r,
            color.g,
            color.b
          );
        })
      );
  }
}
