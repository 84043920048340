import * as THREE from 'three';

export class LineSegmentsShape {
  static create(
    geometry: THREE.BufferGeometry,
    referenceMesh: THREE.Mesh,
    color: string = '#000000'
  ): THREE.LineSegments {
    const edgesGeometry = new THREE.EdgesGeometry(geometry);

    const lineSegments = new THREE.LineSegments(
      edgesGeometry,
      new THREE.LineBasicMaterial({ color: color })
    );
    lineSegments.position.copy(referenceMesh.position);
    lineSegments.rotation.copy(referenceMesh.rotation);
    return lineSegments;
  }
}
