import * as THREE from 'three';
import { SceneSpace } from 'src/app/shared/visualization3d/scene-space';

export class WarehouseLightsConfig {
  static addLights(scene: THREE.Scene, space: SceneSpace): void {
    const dirLight1 = new THREE.DirectionalLight(0xffffff);
    dirLight1.intensity = 1.5;
    dirLight1.position.set(-space.xSize / 2, space.ySize / 2, -space.zSize);
    scene.add(dirLight1);

    const dirLight2 = new THREE.DirectionalLight(0xffffff);
    dirLight2.intensity = 1.5;
    dirLight2.position.set(space.xSize / 2, space.ySize / 2, space.zSize);
    scene.add(dirLight2);

    // ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff);
    ambientLight.intensity = 3;
    scene.add(ambientLight);
  }
}
