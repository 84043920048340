import { createAction, props } from '@ngrx/store';
import { MessageState, OutputPickerTableState } from './output-picker.state';
import { UrlTree } from '@angular/router';

export const updateOutputData = createAction(
  '[Output picker] update output data.',
  props<{ data: OutputPickerTableState; cursor: number }>()
);

export const updateCurrentOutputInUse = createAction(
  '[Output picker] update current output in use.',
  props<{ currentLoadedId: number }>()
);

export const selectOutput = createAction(
  '[Output picker] select output.',
  props<{ id: number; dest: UrlTree }>()
);

export const preSelectOutput = createAction(
  '[Output picker] select a row in the output table, pre selection.',
  props<{ id: number }>()
);

export const deleteOutput = createAction(
  '[Output picker] delete output.',
  props<{ id: number }>()
);

export const deleteOutputs = createAction(
  '[Output picker] delete outputs.',
  props<{ id: number[] }>()
);

export const deleteRow = createAction(
  '[Output picker] delete row in the table.',
  props<{ id: number | number[] }>()
);

export const loadOutputs = createAction(
  '[Output picker] load output list.',
  props<{
    inputId: number;
    optimSpaceId: number;
    cursor: number;
    orderBy: string;
    startDate: string;
    endDate: string;
  }>()
);

export const updateSpinner = createAction(
  '[Output picker] update spinner.',
  props<{ status: boolean }>()
);

export const triggerAlert = createAction(
  '[Output picker] trigger alert message.',
  props<{ message: MessageState }>()
);

export const triggerDestroy = createAction('[Output picker] destroy store.');

export const updateOutput = createAction(
  '[Output picker] update output.',
  props<{ id: number; name: string }>()
);
