import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { logout } from 'src/app/core/store/auth/auth.actions';
import { RootState } from 'src/app/core/store';
import { selectCurUser } from 'src/app/core/store/auth/auth.selectors';
import {
  ButtonJobStatus,
  selectCurApp,
  selectCurInputId,
  selectCurOptimSpaceId,
  selectCurOutputId,
  selectCurWarningsAndErrors,
  selectCurParameterId,
  selectLongJobButtonStatus,
  selectVisuForRecompute,
  selectMainBarAlertMessage,
  selectCurCanOptimize
} from 'src/app/core/store/application/application.selectors';
import {
  closeStatusBar,
  optimizeRun,
  recomputeRun
} from 'src/app/core/store/application/application.actions';
import { selectNbOfUnreadNotifications } from 'src/app/core/store/notifications/notifications.selectors';
import { map, Observable } from 'rxjs';
import { NewVisuData } from 'src/app/core/models/Visu';
import { phosphorIcon } from 'src/app/shared/components/phosphor-icons/phosphor-icons';
import { Color } from 'src/app/shared/components/button/button.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent {
  application$ = this.store.select(selectCurApp);
  user$ = this.store.select(selectCurUser);

  optimSpaceId$ = this.store.select(selectCurOptimSpaceId);
  inputId$ = this.store.select(selectCurInputId);
  outputId$ = this.store.select(selectCurOutputId);
  parameterId$ = this.store.select(selectCurParameterId);
  alertMessageMainBar$ = this.store.select(selectMainBarAlertMessage);

  nbNotifsUnread$ = this.store.select(selectNbOfUnreadNotifications);

  outputVisuForRecompute$ = this.store.select(selectVisuForRecompute);

  buttonJobStatus$: Observable<ButtonJobStatus> = this.store.select(
    selectLongJobButtonStatus
  );

  curOutputWarnings$ = this.store.select(selectCurWarningsAndErrors);

  canOptimize$ = this.store.select(selectCurCanOptimize);

  @Input() mainLink: string = '/dashboard/user-app-list';
  @Input() isVisualizationMode: boolean = false;

  public title = 'Galia';
  public dynamicMode = false;

  showModal = false;
  details = { action: '', title: '', htmlMessage: '' };

  buttonErrorOrWarning$: Observable<{ type: string; svgIcon: phosphorIcon; color: Color }> =
    this.curOutputWarnings$.pipe(
      map(({ warnings, errors }) => {
        let color: Color = 'secondary'; // green
        if (errors != null && errors.length > 0) {
          color = 'red';
          return { type: 'error', svgIcon: 'bug', color };
        }
        if (warnings != null && warnings.length > 0) {
          color = 'yellow';
          return { type: 'warning', svgIcon: 'warning', color };
        }
        return { type: 'success', svgIcon: 'check-circle', color };
      })
    );

  modalCategory$ = this.curOutputWarnings$.pipe(
    map(({ visuOnScreen, warnings, errors }) => {
      if (errors != null && errors.length > 0) {
        this.details.title = 'This output has errors.';
        this.details.action = 'Output errors';
        this.details.htmlMessage = `The optimizer produced found following errors: <ul class="list-disc list-inside">${errors
          .map((error) => `<li class="py-1">${error}</li>`)
          .join('')}</ul>`;
        return 'error';
      }
      if (warnings != null && warnings.length > 0) {
        const listOfMessages = warnings
          .map((warning) => {
            let item = '';
            if (typeof warning === 'string') {
              item = warning;
            } else if (typeof warning === 'object' && 'message' in warning) {
              item = warning['message'];
            } else {
              item = JSON.stringify(warning);
            }
            return `<li class="py-1">${item}</li>`;
          })
          .join('');
        if (visuOnScreen === 'input') {
          this.details.title = 'This input has warnings.';
          this.details.action = 'Input warnings';
          this.details.htmlMessage = `The application returned some warnings for this input:
            <ul class="list-disc list-inside">${listOfMessages}</ul>`;
        } else if (visuOnScreen === 'output') {
          this.details.title = 'This output has warnings.';
          this.details.action = 'Output warnings';
          this.details.htmlMessage = `The optimizer produced this output but could not satisfy some constraints:
            <ul class="list-disc list-inside">${listOfMessages}</ul>`;
        }
        return 'warning';
      } else {
        this.details.title = 'All good !';
        this.details.action = 'No warnings';
        if (visuOnScreen === 'input') {
          this.details.htmlMessage =
            'This input respects the schema and passes the input check.';
        } else if (visuOnScreen === 'output') {
          this.details.htmlMessage = 'This output respects all constraints.';
        }
        return 'success';
      }
    })
  );

  constructor(private readonly store: Store<RootState>, private router: Router) {}

  onOptimize(
    inputId: number | null,
    parameterId: number | null,
    outputId: number | null,
    outputVisuForRecompute: NewVisuData | null,
    btnJobStatus: ButtonJobStatus,
    version: string
  ) {
    const { status, job } = btnJobStatus;
    if (status === 'FREE' && job === 'OPTIMIZE') {
      this.store.dispatch(optimizeRun({ inputId, parameterId, version }));
    } else if (status === 'FREE' && job === 'RECOMPUTE') {
      this.store.dispatch(recomputeRun({ outputId, outputVisuForRecompute }));
    }
  }

  logout() {
    this.store.dispatch(logout());
  }

  onClickShowWarnings() {
    this.showModal = true;
  }

  onCloseModal(event: boolean) {
    this.showModal = event;
  }

  onCloseAlertMessageMainBar() {
    this.store.dispatch(closeStatusBar({ message: null }));
  }

  isDashboardRoute() {
    return this.router.url.includes('/dashboard/');
  }
}
