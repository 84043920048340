import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ENDPOINT, EndPointV4 } from '../enum/endpoint.enum';
import { BASE_API_URL } from '../utils/injectors';
import {
  OutputDetailV4,
  OutputItemV4,
  OutputMapper,
  OutputModel
} from '../models/OutputModel';
import { VisuAndKpis, VisuAndKpisCtor } from '../models/VisuAndKpis';
import { PageDto } from '../models/Application';
import { Kpi } from '../models/Kpi';
import { UpdateOutput } from '../models/UpdateOutput';

/**
 * The OutputService provides methods to retrieve data of
 * a given output, OptimSpace and application
 */
@Injectable({
  providedIn: 'root'
})
export class OutputService {
  private readonly urlOutputsV4;
  private readonly urlInputsV4;

  constructor(
    private readonly http: HttpClient,
    @Inject(BASE_API_URL) private readonly apiUrl: string
  ) {
    this.urlOutputsV4 = `${apiUrl}/${EndPointV4.OUTPUTS}`;
    this.urlInputsV4 = `${apiUrl}/${EndPointV4.INPUTS}`;
  }

  /**
   * HTTP request that retrieves data of an output from server
   * @param outputId
   * @returns an observable that emits the output data
   */
  getOutput(outputId: number): Observable<OutputModel> {
    return this.http.request('GET', `${this.urlOutputsV4}/${outputId}`).pipe(
      map((output: OutputDetailV4) => {
        return OutputMapper.toOutputModel(output);
      })
    );
  }

  /**
   * HTTP request that retrieves report of an output from server
   * @param outputId
   * @returns an observable that emits the output data
   */
  getReport(outputId: number): Observable<any> {
    return this.http
      .get(`${this.urlOutputsV4}/${outputId}/csv-report`, { responseType: 'text' })
      .pipe(
        map((r: string) => {
          const blob = new Blob([r], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const fileName = `csv-report-${outputId}.csv`;
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(url);
        })
      );
  }

  /**
   * HTTP request that the retrieves the visudata and kpis of an output.
   * We add an output id to know when the visu data changes.
   * @param outputId
   * @returns an observable that emits the visudata, input kpis and output kpis
   */
  getOutputVisuAndKpis(outputId: number): Observable<VisuAndKpis> {
    return this.http.get(`${this.urlOutputsV4}/${outputId}/visu-and-kpis`).pipe(
      map((data: VisuAndKpisCtor) => {
        const visuAndKpis = new VisuAndKpis(data);
        visuAndKpis.visuData._id = `output-${outputId}`;
        return visuAndKpis;
      })
    );
  }

  /**
   * HTTP request that the retrieves the visudata and kpis of an output.
   * We add an output id to know when the visu data changes.
   * @param outputId
   * @returns an observable that emits the visudata, input kpis and output kpis
   */
  getOutputKpi(outputId: number): Observable<Kpi> {
    return this.http.get<Kpi>(`${this.urlOutputsV4}/${outputId}/kpis`).pipe(
      map((kpi: Kpi) => {
        return kpi;
      })
    );
  }

  /**
   * HTTP request to delete an output from the database
   * @param outputId
   * @returns observable that emits the response of the API
   */
  deleteOutput(outputId: number): Observable<any> {
    return this.http.delete(`${this.urlOutputsV4}/${outputId}`);
  }

  /**
   * HTTP request to delete all outputs for a given input id from the database
   * @param inputId
   * @returns observable that emits the response of the API
   */
  getOutputsByInputId(
    inputId: number,
    optimSpaceId: number,
    cursor: number,
    orderBy: string,
    startDate: string,
    endDate: string
  ): Observable<{ outputs: OutputModel[]; cursor: number }> {
    const params = {
      take: 50,
      ...(cursor && { cursor }),
      ...(orderBy && { order_by: orderBy }),
      ...(startDate && { from_date: startDate }),
      ...(endDate && { to_date: endDate })
    };
    const httpParams = new HttpParams({ fromObject: params });
    return this.http
      .get<PageDto<OutputModel, number>>(`${this.urlInputsV4}/${inputId}/outputs`, {
        params: httpParams
      })
      .pipe(
        map((result) => {
          const outputs = result.result.map((r) => {
            const result = r as any as OutputItemV4;
            return {
              id: result.id,
              name: result.name,
              appPath: result.application.app_path,
              optimSpaceId,
              inputId,
              createdAt: result.created_at,
              data: {},
              parameterId: result.parameter.id
            };
          });
          return {
            outputs,
            cursor: result.cursor_next_page
          };
        })
      );
  }

  updateOutput(dto: UpdateOutput): Observable<UpdateOutput> {
    return this.http.put<UpdateOutput>(`${this.urlOutputsV4}/${dto.id}`, {
      name: dto.name
    });
  }
}
