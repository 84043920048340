import { BoxGeometry, Group, Mesh, Material, Object3D, LineSegments } from 'three';
import { LineSegmentsShape } from './line-segments-shape';
import { Object3DUtils } from '../../../feature/applications/warehouse/components/warehouse3d/module/core/utils/object3d-utils';

const BOX_GEOMETRY_CACHE = new Map<string, BoxGeometry>();
export class BoxShape {
  static create(container: Object3D, boxProps: BoxProperties): BoxData {
    const group = new Group();

    const slack: number = boxProps.opts?.useSlack ? 0.01 : 0;
    const ySlack: number = boxProps.opts?.useSlack ? 0.005 : 0;

    const { x, y, z } = boxProps.position;
    const { xSize, ySize, zSize } = boxProps.dimensions;
    const cubeGeometry = this.createBoxGeometry(
      xSize - slack,
      ySize - ySlack,
      zSize - slack
    );

    const boxMesh = new Mesh(cubeGeometry, boxProps.material);
    boxMesh.position.x = x;
    boxMesh.position.y = y;
    boxMesh.position.z = z;

    Object3DUtils.rotate(boxMesh, boxProps.rotation);

    if (boxProps.opts?.matrixAutoUpdate != undefined) {
      boxMesh.matrixAutoUpdate = boxProps.opts.matrixAutoUpdate;
    }
    boxMesh.updateMatrix();

    group.add(boxMesh);

    let lineSegments: LineSegments;
    if (
      boxProps.opts?.createLineSegments == undefined ||
      boxProps?.opts.createLineSegments
    ) {
      lineSegments = LineSegmentsShape.create(
        cubeGeometry,
        boxMesh,
        boxProps.opts.colorLineSegments
      );
      Object3DUtils.rotate(lineSegments, boxProps.rotation);
      group.add(lineSegments);
    }

    container.add(group);
    return {
      box: boxMesh,
      lineSegments,
      group: group
    };
  }

  static clearCache() {
    BOX_GEOMETRY_CACHE.clear();
  }

  private static createBoxGeometry(
    xSize: number,
    ySize: number,
    zSize: number
  ): BoxGeometry {
    const key = `${xSize},${ySize},${zSize}`;
    if (BOX_GEOMETRY_CACHE.has(key)) {
      return BOX_GEOMETRY_CACHE.get(key);
    }
    const boxGeometry = new BoxGeometry(xSize, ySize, zSize);
    BOX_GEOMETRY_CACHE.set(key, boxGeometry);
    return boxGeometry;
  }
}

export interface BoxProperties {
  position: { x: number; y: number; z: number };
  dimensions: { xSize: number; ySize: number; zSize: number };
  rotation?: { rx?: number; ry?: number; rz?: number };
  material: Material;
  opts: {
    createLineSegments?: boolean;
    matrixAutoUpdate?: boolean;
    useSlack?: boolean;
    colorLineSegments?: string;
  };
}

export interface BoxData {
  box: Mesh<BoxGeometry, Material>;
  lineSegments: LineSegments;
  group: Group;
}
