<!--
    Source of the modal : TailwindUI
-->
<div
  *ngIf="showModal"
  class="fixed z-15000 inset-0"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <!--
        Background overlay, show/hide based on modal state.
  
        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
    <div
      (click)="onClickout($event)"
      class="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity"
      data-testid="modal-background"
      aria-hidden="true"
    ></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="inline-block align-middle h-screen" aria-hidden="true">&#8203;</span>

    <!--
        Modal panel, show/hide based on modal state.
  
        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
    <div
      class="inline-block bg-white rounded-xl text-left shadow-xl transform transition-all my-8 align-middle max-w-xl w-full"
    >
      <div class="bg-white block max-h-48 overflow-auto p-6 pb-4">
        <div class="sm:flex sm:items-start">
          <phosphor-icon
            class="{{ category | modalIconClass }}"
            [name]="category | modalIcon"
          ></phosphor-icon>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h1 class="text-lg leading-6 font-medium text-neutral-900" id="modal-title">
              {{ details.title }}
            </h1>
            <p class="mt-2 text-sm text-neutral-500" [innerHTML]="details.htmlMessage"></p>
          </div>
        </div>
      </div>
      <div class="bg-neutral-50 py-3 px-6 flex justify-end">
        <button
          app-button
          color="neutral"
          hierarchy="secondary"
          size="medium"
          (click)="onClose()"
          data-testid="modal-close"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
