import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('src/app/feature/dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: 'application',
    loadChildren: () =>
      import('src/app/feature/applications/applications.module').then(
        (m) => m.ApplicationsModule
      )
  },
  {
    path: 'token-visualization',
    loadChildren: () =>
      import('src/app/feature/token-visualization/token-visualization.module').then(
        (m) => m.TokenVisualizationModule
      )
  },
  {
    path: 'visualization',
    loadChildren: () =>
      import('src/app/feature/visualization/visualization.module').then(
        (m) => m.VisualizationModule
      )
  },
  {
    path: 'reset',
    loadChildren: () =>
      import('src/app/feature/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      )
  },
  {
    path: 'mapping',
    loadChildren: () =>
      import('src/app/feature/test-mapping/test-mapping.module').then(
        (m) => m.TestMappingModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeatureRoutingModule {}
