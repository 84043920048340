import { Component, HostBinding, Input } from '@angular/core';
import { phosphorIcon } from '../phosphor-icons/phosphor-icons';

//https://www.youtube.com/watch?v=Z9U6BLB6TQM&t=1s

export type Size = 'large' | 'small' | 'medium' | 'small-square';
export type Color = 'primary' | 'secondary' | 'neutral' | 'yellow' | 'red' | 'disabled';
export type State = 'running' | 'disabled' | 'enabled';
export type Hierarchy = 'primary' | 'secondary' | 'tertiary';

@Component({
  selector: '[app-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() size: Size = 'large';

  svgIconStyle: string;
  colorValue: Color = 'primary';
  @Input() set color(color: Color) {
    this.colorValue = color;
    if (this.hierarchyValue === 'primary') {
      this.svgIconStyle = `fill-white stroke-white w-5`;
    } else {
      this.svgIconStyle = `fill-${this.colorValue}-500 stroke-${this.colorValue}-900 w-5`;
    }
  }
  hierarchyValue: Hierarchy = 'secondary';
  @Input() set hierarchy(hierarchy: Hierarchy) {
    this.hierarchyValue = hierarchy;
    if (this.hierarchyValue === 'primary') {
      this.svgIconStyle = `fill-white stroke-white w-5`;
    }
  }
  @Input() state: State = 'enabled';
  @Input() svgIcon: phosphorIcon;

  @HostBinding('class') get className() {
    return `color-${this.colorValue}-${this.hierarchyValue} size-${this.size} state-${this.state}`;
  }
}
