import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { Warehouse3DComponent } from './components/warehouse3d/warehouse3d.component';
import { WarehouseControlsComponent } from './components/warehouse-controls/warehouse-controls.component';
import { WarehouseCaptionsComponent } from './components/warehouse-captions/warehouse-captions.component';
import { WarehouseStockComponent } from './components/warehouse-stock/warehouse-stock.component';

@NgModule({
  declarations: [
    Warehouse3DComponent,
    WarehouseControlsComponent,
    WarehouseCaptionsComponent,
    WarehouseStockComponent
  ],
  imports: [CommonModule, SharedModule],
  exports: [Warehouse3DComponent, WarehouseCaptionsComponent, WarehouseStockComponent]
})
export class WarehouseModule {}
