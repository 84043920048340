import { createSelector } from '@ngrx/store';
import { TimelineRow } from 'src/app/core/models/Timeline';
import {
  MarkerGalia,
  MarkerGaliaType,
  ProfileType,
  StructuralAggregatorMapbox
} from 'src/app/core/models/mapbox.model';
import {
  StructuralAggregator,
  VisuContainer,
  NewAppElement,
  NewVisuData,
  NewDisplayLabel,
  VisuDataStateUtils,
  AreaCornerData
} from 'src/app/core/models/Visu';
import { RootState } from '..';
import { selectRoute } from '../navigation/navigation.selector';
import { AlertDisplayLocation, VisuFilter, VisuScreenState } from './application.reducers';
import { Packing3dData, PackingItem } from 'src/app/core/models/PackingModel';
import { ColorOfIndex } from 'src/app/shared/pipes/color.pipe';
import { AreaDataDto } from '../dto/area-data.dto';

// Reminder about ngrx selectors :
// This implementation may look very verbose but selectors created with
// `createSelector()` have memoization. This means the projector function won't
// be called as long as the selectors in input return the same values (based on
// strict equality check).

export const selectAppState = (state: RootState) => state.application;

// Very basic selectors on attributes of the Application state.
export const selectCurParameterState = createSelector(
  selectAppState,
  ({ optimSpace }) =>
    optimSpace?.parameter ?? { parameterId: undefined, favoriteParameterId: undefined }
);

export const selectCurApp = createSelector(
  selectAppState,
  ({ application }) => application
);

export const selectCurOptimSpace = createSelector(
  selectAppState,
  ({ optimSpace }) => optimSpace
);
export const selectCurCanOptimize = createSelector(selectCurOptimSpace, (optimSpace) => {
  return !!optimSpace?.dataSpace?.permissions?.canOptimize;
});

export const selectCurInput = createSelector(selectAppState, ({ input }) => input);
export const selectCurOutput = createSelector(selectAppState, ({ output }) => output);
export const selectCurAppPathAndAppVersion = createSelector(
  selectCurApp,
  (application) => ({
    appPath: application.application.path,
    version: application.version
  })
);

export const selectCurOptimSpaceId = createSelector(selectCurOptimSpace, ({ id }) => id);
export const selectCurInputId = createSelector(selectCurInput, ({ id }) => id);
export const selectCurOutputId = createSelector(selectCurOutput, ({ id }) => id);
export const selectCurParameterId = createSelector(
  selectCurParameterState,
  ({ parameterId }) => parameterId
);
export const selectCurFavoriteParameterId = createSelector(
  selectCurParameterState,
  ({ favoriteParameterId }) => favoriteParameterId
);

export const selectCurControls = createSelector(selectAppState, ({ controls }) => controls);
export const selectCurControlsVisuOnScreen = createSelector(
  selectCurControls,
  ({ visuOnScreen }) => visuOnScreen
);

export const selectCurWarningsAndErrors = createSelector(
  selectCurControlsVisuOnScreen,
  selectCurInput,
  selectCurOutput,
  (visuOnScreen, input, output) => {
    if (visuOnScreen == 'input') {
      return { visuOnScreen, warnings: input.warnings, errors: [] };
    } else if (visuOnScreen == 'output') {
      return { visuOnScreen, warnings: output?.warnings, errors: output?.errors };
    }
    return { visuOnScreen, warnings: [], errors: [] };
  }
);

export const selectCurInputVisu = createSelector(
  selectAppState,
  ({ inputVisu }) => inputVisu
);
export const selectCurInputVisuFilter = createSelector(
  selectAppState,
  ({ inputVisuFilter }) => inputVisuFilter
);
export const selectCurInputKpis = createSelector(
  selectAppState,
  ({ inputKpis }) => inputKpis
);

export const selectCurOutputVisu = createSelector(
  selectAppState,
  ({ outputVisu }) => outputVisu
);
export const selectCurOutputVisuFilter = createSelector(
  selectAppState,
  ({ outputVisuFilter }) => outputVisuFilter
);
export const selectCurOutputKpis = createSelector(
  selectAppState,
  ({ outputKpis }) => outputKpis
);

export const selectCurEventListeners = createSelector(
  selectAppState,
  ({ eventListeners }) => eventListeners
);

export const selectCurOutputVisuAndOutputId = createSelector(
  selectCurOutputVisu,
  selectCurOutputId,
  (outputVisuData, outputId) => ({
    outputVisuData,
    outputId
  })
);

/**
 * Merges application path & all currently selected ids (optimSpace, input, output, & parameter).
 */
export const selectCurIds = createSelector(
  selectCurAppPathAndAppVersion,
  selectCurOptimSpaceId,
  selectCurInputId,
  selectCurOutputId,
  selectCurParameterId,
  (application, optimSpaceId, inputId, outputId, parameterId) => {
    return {
      appPath: application.appPath,
      version: application.version,
      optimSpaceId,
      inputId,
      outputId,
      parameterId
    };
  }
);

// Select only for the fetchParameter effect
export const selectForParametersEffect = createSelector(
  selectRoute,
  selectCurParameterState,
  (navigation, parameter) => {
    return { navigation, parameter };
  }
);

export function filterVisu(visu: NewVisuData, filter?: VisuFilter) {
  if (filter == null) {
    return visu;
  }

  const primaryAgg = new Set<number>(filter.primaryAggregators);
  const structural_aggregators = new VisuContainer<StructuralAggregator>();
  structural_aggregators.all_ids = { ...visu.structural_aggregators.all_ids };
  structural_aggregators.by_id = { ...visu.structural_aggregators.by_id };

  const app_elements = new VisuContainer<NewAppElement>();
  app_elements.all_ids = { ...visu.app_elements.all_ids };
  app_elements.by_id = { ...visu.app_elements.by_id };

  const selected_app_element_ids = [];

  if (primaryAgg.size > 0) {
    const filterStructAgg = Object.keys(visu.structural_aggregators.by_id)
      .filter((id) => primaryAgg.has(+id))
      .reduce((cur, id) => {
        return Object.assign(cur, { [id]: visu.structural_aggregators.by_id[id] });
      }, {});

    structural_aggregators.by_id = filterStructAgg;

    Object.keys(structural_aggregators.by_id).forEach((id) => {
      structural_aggregators.by_id[id].app_elem_ids.forEach((appElemId) => {
        if (selected_app_element_ids.indexOf(appElemId) === -1) {
          selected_app_element_ids.push(appElemId);
        }
      });
    });

    const filterAppElem = Object.keys(visu.app_elements.by_id)
      .filter((id) => selected_app_element_ids.includes(id))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: visu.app_elements.by_id[key] as NewAppElement });
      }, {});
    app_elements.by_id = filterAppElem;
  }

  // About the filter on the date range: we keep only events that start in the
  // range.
  // Examples for a time range from 15:00 to 16:00
  // Event 1 (14:45 -> 15:30) : false becase 14:45 < range start (15:00)
  // Event 2 (14:45 -> 17:00) : false for the same reason
  // Event 3 (15:15 -> 17:00) : true because 15:15 > range start

  // I think it can be a good idea to give options to the user to filter on the
  // ending date of the event, or keeping all events that intersect the range...

  const startDate = filter.startDate ?? new Date(0);
  const endDate = filter.endDate ?? new Date('2099-12-31');
  if (VisuDataStateUtils.hasTimeline(visu)) {
    const filterAppElem = Object.keys(visu.app_elements.by_id)
      .filter((id) => {
        if (visu.timeline_datas.by_id[id]) {
          const elemStartDate = new Date(visu.timeline_datas.by_id[id].start_date);
          if (startDate <= elemStartDate && elemStartDate <= endDate) {
            return true;
          }
        } else {
          return true;
        }
      })
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: visu.app_elements.by_id[key] as NewAppElement });
      }, {});
    app_elements.by_id = filterAppElem;

    Object.entries(structural_aggregators.by_id).forEach(([key, structAgg]) => {
      if (structAgg.visibility.timeline) {
        const app_elem_ids = structAgg.app_elem_ids.filter((id) => {
          if (Object.keys(app_elements.by_id).includes(id)) {
            return true;
          }
        });
        structural_aggregators.by_id[key] = {
          ...structural_aggregators.by_id[key],
          app_elem_ids
        };
      }
    });
  }
  return {
    ...visu,
    app_elements,
    structural_aggregators
  };
}

export const selectCurUnfilteredVisuOnScreen = createSelector(
  selectCurControlsVisuOnScreen,
  selectCurInputVisu,
  selectCurOutputVisu,
  (visuOnScreen, inputVisu, outputVisu) => {
    if (visuOnScreen === 'input') {
      return inputVisu;
    } else if (visuOnScreen === 'output') {
      return outputVisu;
    } else {
      return new NewVisuData();
    }
  }
);

export const selectCurAreaCornerData = createSelector(
  selectCurControlsVisuOnScreen,
  selectCurInputVisu,
  selectCurOutputVisu,
  (visuOnScreen, inputVisu, outputVisu): AreaDataDto[] => {
    let visuData: NewVisuData;
    if (visuOnScreen === 'input') {
      visuData = inputVisu;
    } else if (visuOnScreen === 'output') {
      visuData = outputVisu;
    } else {
      visuData = new NewVisuData();
    }
    if (
      visuData.area_datas === undefined ||
      Object.keys(visuData.area_datas).length === 0
    ) {
      return [];
    }

    const allPoints = Object.values(visuData.area_datas.by_id);
    const groupByArreaUUID = allPoints.reduce((acc, cur) => {
      if (acc[cur.area_uid]) {
        acc[cur.area_uid].push(cur);
      } else {
        acc[cur.area_uid] = [cur];
      }
      return acc;
    }, {} as { [key: string]: AreaCornerData[] });

    return Object.keys(groupByArreaUUID).map((key) => {
      return {
        id: key,
        type: 'polygon',
        coordinates: groupByArreaUUID[key]
          .sort((a1, a2) => a1.seq_nb - a2.seq_nb)
          .map((r) => [r.longitude, r.latitude])
      };
    });
  }
);

export const selectCurFilterOnScreen = createSelector(
  selectCurControlsVisuOnScreen,
  selectCurInputVisuFilter,
  selectCurOutputVisuFilter,
  (visuOnScreen, inputFilter, outputFilter) => {
    if (visuOnScreen === 'input') {
      return inputFilter;
    } else if (visuOnScreen === 'output') {
      return outputFilter;
    } else {
      return null;
    }
  }
);

export const selectCurVisuOnScreen = createSelector(
  selectCurUnfilteredVisuOnScreen,
  selectCurFilterOnScreen,
  (visu, filter) => {
    if (filter == null) {
      return visu;
    }
    const filteredVisu = filterVisu(visu, filter);
    return filteredVisu;
  }
);

export const selectStructuralAggregators = createSelector(
  selectCurVisuOnScreen,
  selectCurControlsVisuOnScreen,

  (visu, visuState) => {
    return visuToMapStructuralAggregators(visu, visuState);
  }
);

function visuToMapStructuralAggregators(visu: NewVisuData, solutionType: VisuScreenState) {
  const colorPipe = new ColorOfIndex();
  const structuralAggregators: StructuralAggregatorMapbox[] = [];
  if (!VisuDataStateUtils.hasMap(visu)) return structuralAggregators;
  Object.keys(visu.structural_aggregators.by_id).forEach((idAgg) => {
    if (!visu.structural_aggregators.by_id[idAgg].visibility.map) return;
    const structuralAggregator = new StructuralAggregatorMapbox();
    const { id, name, visibility, weight, color, vehicle_profile, area_data_ids } =
      visu.structural_aggregators.by_id[idAgg];
    structuralAggregator.solutionType = solutionType;
    structuralAggregator.id = +id;
    structuralAggregator.name = name;
    structuralAggregator.areaDataIds = area_data_ids ?? [];

    if (weight === undefined || weight === null) {
      structuralAggregator.weight = 1;
    } else {
      structuralAggregator.weight = weight;
    }

    structuralAggregator.color = color;

    if (visibility.path) {
      structuralAggregator.routeVisibility = visibility.path;
    } else {
      structuralAggregator.routeVisibility = false;
    }

    structuralAggregator.orderedMarkerIds = visu.structural_aggregators.by_id[
      idAgg
    ].app_elem_ids
      .filter((id) => visu.map_datas.by_id[id])
      .map((id) => +id);

    structuralAggregator.color = colorPipe.transform(
      structuralAggregator.id,
      structuralAggregator.color
    );

    if (vehicle_profile === 'Truck') {
      structuralAggregator.profile = ProfileType.Truck;
    } else {
      structuralAggregator.profile = ProfileType.Car;
    }
    structuralAggregators.push(structuralAggregator);
  });

  return structuralAggregators;
}

export const selectMapData = createSelector(selectCurVisuOnScreen, (visu) => {
  return visuToMapData(visu);
});

function visuToMapData(visu: NewVisuData) {
  if (!VisuDataStateUtils.hasMap(visu)) return null;
  const mapData: MarkerGalia[] = [];
  const appElements = visu.app_elements.by_id;
  Object.keys(appElements).forEach((id) => {
    if (visu.map_datas.by_id[id]) {
      const mapMarker = new MarkerGalia();
      mapMarker.id = +id;
      mapMarker.structuralAggregatorIds = [];
      Object.keys(visu.structural_aggregators.by_id).forEach((structAggId) => {
        if (!visu.structural_aggregators.by_id[structAggId].visibility.map) return;
        if (visu.structural_aggregators.by_id[structAggId].app_elem_ids.includes(id)) {
          mapMarker.structuralAggregatorIds.push(
            +visu.structural_aggregators.by_id[structAggId].id
          );
        }
      });
      mapMarker.highlightAggregatorIds = [];
      Object.keys(visu.highlight_aggregators.by_id).forEach((structAggId) => {
        if (visu.highlight_aggregators.by_id[structAggId].app_elem_ids.includes(id)) {
          mapMarker.highlightAggregatorIds.push(
            +visu.highlight_aggregators.by_id[structAggId].id
          );
        }
      });
      mapMarker.coordinates = [visu.map_datas.by_id[id].lon, visu.map_datas.by_id[id].lat];
      mapMarker.transportMode = 'land';
      if (visu.map_datas.by_id[id].transport_mode || visu.options?.transportMode) {
        mapMarker.transportMode =
          visu.options?.transportMode ?? visu.map_datas.by_id[id].transport_mode;
      }
      mapMarker.type = visu.map_datas.by_id[id].marker as MarkerGaliaType;
      mapMarker.draggable = false;
      const content = {};
      visu.app_elem_labels.by_id[id]?.forEach((displayLabel: NewDisplayLabel) => {
        const short_name = displayLabel.name;
        const value = displayLabel.value;
        content[`${short_name}`] = value;
      });
      let startDate = '';
      let endDate = '';
      if (VisuDataStateUtils.hasTimeline(visu) && visu.timeline_datas.by_id[id]) {
        startDate = visu.timeline_datas.by_id[id].start_date;
        endDate = visu.timeline_datas.by_id[id].finish_date;
      }
      mapMarker.description = {
        name: visu.app_elements.by_id[id].name,
        startDate: startDate,
        endDate: endDate,
        content: content
      };
      const colorPipe = new ColorOfIndex();
      let color = '#78716c';
      if (mapMarker.structuralAggregatorIds[0]) {
        color = colorPipe.transform(
          mapMarker.structuralAggregatorIds[0],
          visu.structural_aggregators.by_id[mapMarker.structuralAggregatorIds[0]].color
        );
      }
      mapMarker.color = color;
      mapData.push(mapMarker);
    }
  });
  //https://stackoverflow.com/questions/66177740/producing-points-in-a-circle-with-specific-radius-from-central-lat-long
  const duplicatedMarkersDeltas = {};
  const radiusDuplicateMarkersKms = 0.01;
  mapData.forEach((markerMapData) => {
    const id =
      markerMapData.coordinates[0].toString() +
      '_' +
      markerMapData.coordinates[1].toString();
    let markerGroup = [];
    const radiusLon =
      (1 / (111.319 * Math.cos(markerMapData.coordinates[1] * (Math.PI / 180)))) *
      radiusDuplicateMarkersKms;
    const radiusLat = (1 / 110.574) * radiusDuplicateMarkersKms;
    if (!duplicatedMarkersDeltas.hasOwnProperty(id)) {
      markerGroup = mapData.filter(
        (markerDuplicated) =>
          markerDuplicated.coordinates[0] === markerMapData.coordinates[0] &&
          markerDuplicated.coordinates[1] === markerMapData.coordinates[1]
      );
      if (markerGroup.length > 1) {
        duplicatedMarkersDeltas[id] = { count: markerGroup.length, delta: 0 };
        markerMapData.coordinates[1] =
          markerMapData.coordinates[1] +
          radiusLat * Math.sin(duplicatedMarkersDeltas[id].delta);
        markerMapData.coordinates[0] =
          markerMapData.coordinates[0] +
          radiusLon * Math.cos(duplicatedMarkersDeltas[id].delta);
      }
    } else {
      duplicatedMarkersDeltas[id].delta +=
        (2 * Math.PI) / duplicatedMarkersDeltas[id].count;
      markerMapData.coordinates[1] =
        markerMapData.coordinates[1] +
        radiusLat * Math.sin(duplicatedMarkersDeltas[id].delta);
      markerMapData.coordinates[0] =
        markerMapData.coordinates[0] +
        radiusLon * Math.cos(duplicatedMarkersDeltas[id].delta);
    }
  });
  return mapData;
}

export const selectPackingData = createSelector(selectCurVisuOnScreen, (visu) => {
  return visuToPackingData(visu);
});

function visuToPackingData(visu: NewVisuData) {
  const packingDatas = {};
  if (VisuDataStateUtils.hasPackage(visu)) {
    Object.entries(visu.packing_spaces.by_id).forEach(([structAggId, space]) => {
      if (
        visu.structural_aggregators.by_id[structAggId] &&
        visu.structural_aggregators.by_id[structAggId].visibility.packing
      ) {
        const app_elem_ids = visu.structural_aggregators.by_id[structAggId].app_elem_ids;
        const items = app_elem_ids.map((appElemId) => {
          const packingData = visu.packing_datas.by_id[appElemId];
          let description: string = '';
          if (visu.app_elem_labels.by_id[appElemId]) {
            visu.app_elem_labels.by_id[appElemId].forEach((label: NewDisplayLabel) => {
              description += label.name + ': ' + label.value + ', ';
            });
            description = description.substring(0, description.length - 2);
          }
          return {
            x: packingData.x,
            y: packingData.y,
            z: packingData.z,
            x_size: packingData.x_size,
            y_size: packingData.y_size,
            z_size: packingData.z_size,
            layer: packingData.layer,
            parent_ae_id: packingData.parent_ae_id,
            name: visu.app_elements.by_id[appElemId]
              ? visu.app_elements.by_id[appElemId].name
              : appElemId,
            description: description ? description : '',
            color: packingData.material,
            shape: packingData.shape,
            pattern_texture: packingData.pattern_texture
          } as PackingItem;
        });
        const packing: Packing3dData = { items: items, space: space };
        packingDatas[structAggId] = packing;
      }
    });
    return packingDatas;
  }
}

export const selectPackingNbLayers = createSelector(selectPackingData, (packingDatas) => {
  if (packingDatas) {
    return (Object.values(packingDatas)[0] as Packing3dData).items.reduce(
      (acc, item) => (acc = acc > item.layer ? acc : item.layer),
      1
    );
  }
});

export const selectCurVisuOnScreenForTimeline = createSelector(
  selectCurVisuOnScreen,
  (visu) => {
    const rows = new Array<TimelineRow>();
    if (VisuDataStateUtils.hasTimeline(visu)) {
      Object.entries(visu.structural_aggregators.by_id).forEach(
        ([structAggId, structAgg]) => {
          if (structAgg.visibility.timeline) {
            //create rows
            const tasks = [];
            let rowStartDate = new Date(1e14);
            let rowEndDate = new Date(0);
            (structAgg as StructuralAggregator).app_elem_ids.forEach((appElemId) => {
              //create tasks
              const elem = visu.app_elements.by_id[appElemId];
              if (elem) {
                const timelineDatas = visu.timeline_datas.by_id[appElemId];
                if (timelineDatas) {
                  let kind = 'work';
                  if (timelineDatas.kind && timelineDatas.kind !== '') {
                    kind = timelineDatas.kind;
                  }
                  const startDate = new Date(timelineDatas.start_date);
                  if (startDate < rowStartDate) {
                    rowStartDate = startDate;
                  }
                  const endDate = new Date(timelineDatas.finish_date);
                  if (endDate > rowEndDate) {
                    rowEndDate = endDate;
                  }
                  const name = elem.name;
                  const _dragAndDropped = elem._dragAndDropped;
                  const hoverLabels = {};
                  visu.app_elem_labels.by_id[appElemId]?.forEach(
                    (displayLabel: NewDisplayLabel) => {
                      const short_name = displayLabel.name;
                      const value = displayLabel.value;
                      hoverLabels[`${short_name}`] = value;
                    }
                  );
                  const id = +elem.id;
                  const primaryAggregatorIds = [];
                  Object.keys(visu.structural_aggregators.by_id).forEach((key) => {
                    if (
                      visu.structural_aggregators.by_id[key].app_elem_ids.includes(elem.id)
                    ) {
                      primaryAggregatorIds.push(+key);
                    }
                  });
                  const secondaryAggregatorIds = [];
                  Object.keys(visu.highlight_aggregators.by_id).forEach((key) => {
                    if (
                      visu.highlight_aggregators.by_id[key].app_elem_ids.includes(elem.id)
                    ) {
                      secondaryAggregatorIds.push(+key);
                    }
                  });
                  tasks.push({
                    id,
                    name,
                    hoverLabels,
                    _dragAndDropped,
                    startDate: startDate,
                    kind: kind,
                    endDate: endDate,
                    secondaryAggregatorIds,
                    primaryAggregatorIds
                  });
                }
              }
            }); // end of tasks
            const rowName = structAgg.name;
            const rowId = +structAgg.id;
            const color = structAgg.color;
            rows.push({
              id: rowId,
              color: color,
              name: rowName,
              startDate: rowStartDate,
              endDate: rowEndDate,
              tasks: tasks
            });
          }
        }
      );
      return rows;
    } else {
      return rows;
    }
  }
);

export const selectVisuForRecompute = createSelector(
  selectAppState,
  ({ outputVisuForRecompute }) => outputVisuForRecompute
);

export const selectCurFilteredPrimaryAggregators = createSelector(
  selectCurFilterOnScreen,
  (filter) => {
    if (filter) {
      return filter.primaryAggregators;
    }
  }
);

export const selectMainBarAlertMessage = createSelector(
  selectAppState,
  ({ alertMessage }) => {
    const everywhere: AlertDisplayLocation = 'everywhere';
    const mainbar: AlertDisplayLocation = 'mainbar';
    if (alertMessage?.code === everywhere || alertMessage?.code === mainbar) {
      let color = 'primary';
      switch (
        alertMessage.category // TODO: delete color attribute
      ) {
        case 'success':
          color = 'green';
          break;
        case 'warning':
          color = 'yellow';
          break;
        case 'error':
          color = 'red';
          break;
      }
      return { ...alertMessage, color };
    }
    return null;
  }
);

export const selectCurOptimRunningJobs = createSelector(
  selectAppState,
  ({ runningOptimJobs }) => runningOptimJobs
);
export const selectCurOptimRunningJob = createSelector(
  selectCurAppPathAndAppVersion,
  selectCurInputId,
  selectCurOptimRunningJobs,
  (app, inputId, runningOptimJobs) => runningOptimJobs[app.appPath]?.[inputId]
);

// Method for filtering
export const selectSelectedPrimaryAggregator = createSelector(
  selectCurVisuOnScreen,
  selectCurFilterOnScreen,
  (visuOnScreen, filter) => {
    if (filter == null || filter.primaryAggregators.length === 0) {
      return [{ id: -1, name: 'All aggregators', color: '' }];
    }

    return Object.entries(visuOnScreen.structural_aggregators.by_id)
      .filter(([key, { visibility }]) => !visibility.packing)
      .map(([key, { name, color }]) => {
        const id = +key;
        return { id, name, color };
      })
      .sort((a, b) => a.id - b.id);
  }
);

export const selectSelectedStructAggPackage = createSelector(
  selectCurVisuOnScreen,
  selectCurFilterOnScreen,
  (visuOnScreen, filter) => {
    if (!VisuDataStateUtils.hasPackage(visuOnScreen)) return [];
    if (filter == null || filter.primaryAggregators.length === 0) {
      //show by default the first packing struct Agg
      const id = Object.keys(visuOnScreen.packing_spaces.by_id)[0];
      const name = visuOnScreen.structural_aggregators.by_id[id].name;
      const color = visuOnScreen.structural_aggregators.by_id[id].color;
      return [{ id: +id, name: name, color: color }];
    }

    return Object.entries(visuOnScreen.structural_aggregators.by_id)
      .filter(([key, { visibility }]) => visibility.packing)
      .map(([key, { name, color }]) => {
        const id = +key;
        return { id, name, color };
      })
      .sort((a, b) => a.id - b.id);
  }
);

export const selectPrimaryAggregatorsForPackageVisuSelector = createSelector(
  selectCurUnfilteredVisuOnScreen,
  selectCurFilterOnScreen,
  (visuOnScreen, filter) => {
    const filterStructAgg = Object.entries(
      visuOnScreen.structural_aggregators.by_id
    ).reduce(function (result, [key, { name, visibility }]) {
      if (visibility.packing) {
        const id = +key;
        result.push({
          id,
          name,
          inFilter: false
        });
      }
      return result;
    }, []);

    if (filter != null && filter.primaryAggregators.length > 0) {
      filter.primaryAggregators.forEach((paId) => {
        const pos = filterStructAgg.findIndex((elem) => elem.id == paId);
        filterStructAgg[pos].inFilter = true;
      });
    }
    return filterStructAgg;
  }
);

// View controls
export const selectActiveOutputBtn = createSelector(selectCurOutputVisu, (outputVisu) => {
  return outputVisu != undefined;
});

export const selectActiveInputKpisBtn = createSelector(selectCurInputKpis, (inputKpis) => {
  return inputKpis != null && inputKpis.length > 0;
});

export const selectActiveCaptionBtn = createSelector(
  selectCurControlsVisuOnScreen,
  selectCurInputVisu,
  selectCurOutputVisu,
  (visuOnScreen, inputVisu, outputVisu) => {
    const inputIsActiveAndHasCaption =
      visuOnScreen === 'input' && VisuDataStateUtils.hasCaption(inputVisu);
    const outputIsActiveAndHasCaption =
      visuOnScreen === 'output' && VisuDataStateUtils.hasCaption(outputVisu);
    return inputIsActiveAndHasCaption || outputIsActiveAndHasCaption;
  }
);

export const selectActiveOutputKpisBtn = createSelector(
  selectCurControlsVisuOnScreen,
  selectCurOutputKpis,
  (visuOnScreen, outputKpis) => {
    return visuOnScreen === 'output' && outputKpis != null && outputKpis.length > 0;
  }
);

export const selectActiveTimelineBtn = createSelector(
  selectCurControlsVisuOnScreen,
  selectCurInputVisu,
  selectCurOutputVisu,
  (visuOnScreen, inputVisu, outputVisu) => {
    const inputIsActiveAndHasTimeline =
      visuOnScreen === 'input' && VisuDataStateUtils.hasTimeline(inputVisu);
    const outputIsActiveAndHasTimeline =
      visuOnScreen === 'output' && VisuDataStateUtils.hasTimeline(outputVisu);
    return inputIsActiveAndHasTimeline || outputIsActiveAndHasTimeline;
  }
);

export const selectActiveListBtn = createSelector(
  selectCurControlsVisuOnScreen,
  selectCurInputVisu,
  selectCurOutputVisu,
  (visuOnScreen, inputVisu, outputVisu) => {
    const inputIsActiveAndHasList =
      visuOnScreen === 'input' && VisuDataStateUtils.hasList(inputVisu);
    const outputIsActiveAndHasList =
      visuOnScreen === 'output' && VisuDataStateUtils.hasList(outputVisu);
    return inputIsActiveAndHasList || outputIsActiveAndHasList;
  }
);

export const selectActivePackage3DBtn = createSelector(
  selectCurControlsVisuOnScreen,
  selectCurInputVisu,
  selectCurOutputVisu,
  (visuOnScreen, inputVisu, outputVisu) => {
    const inputIsActiveAndHasPackage =
      visuOnScreen === 'input' && VisuDataStateUtils.hasPackage(inputVisu);
    const outputIsActiveAndHasPackage =
      visuOnScreen === 'output' && VisuDataStateUtils.hasPackage(outputVisu);
    return inputIsActiveAndHasPackage || outputIsActiveAndHasPackage;
  }
);

export const selectActiveWarehouseBtn = createSelector(
  selectCurControlsVisuOnScreen,
  selectCurInputVisu,
  selectCurOutputVisu,
  (visuOnScreen, inputVisu, outputVisu) => {
    const inputIsActiveAndHasWarehouse =
      visuOnScreen === 'input' && VisuDataStateUtils.hasWarehouse(inputVisu);
    const outputIsActiveAndHasWarehouse =
      visuOnScreen === 'output' && VisuDataStateUtils.hasWarehouse(outputVisu);
    return inputIsActiveAndHasWarehouse || outputIsActiveAndHasWarehouse;
  }
);

export const selectActiveMap = createSelector(
  selectCurControlsVisuOnScreen,
  selectCurInputVisu,
  selectCurOutputVisu,
  (visuOnScreen, inputVisu, outputVisu) => {
    const inputIsActiveAndHasMap =
      visuOnScreen === 'input' && VisuDataStateUtils.hasMap(inputVisu);
    const outputIsActiveAndHasMap =
      visuOnScreen === 'output' && VisuDataStateUtils.hasMap(outputVisu);
    return inputIsActiveAndHasMap || outputIsActiveAndHasMap;
  }
);

export const selectControls = createSelector(selectAppState, ({ controls }) => controls);
export const selectShowInputKpis = createSelector(
  selectControls,
  ({ showInputKpis }) => showInputKpis
);
export const selectShowOutputKpis = createSelector(
  selectControls,
  ({ showOutputKpis }) => showOutputKpis
);
export const selectShowTimeline = createSelector(
  selectControls,
  ({ showTimeline }) => showTimeline
);
export const selectShowList = createSelector(selectControls, ({ showList }) => showList);

export const selectShowPackage = createSelector(
  selectControls,
  ({ showPackage }) => showPackage
);

export const selectShowWarehouse = createSelector(
  selectControls,
  ({ showWarehouse }) => showWarehouse
);

export const selectShowMap = createSelector(selectControls, ({ showMap }) => showMap);

export const selectShowCaption = createSelector(
  selectControls,
  ({ showCaption }) => showCaption
);

// Method for filtering
export const selectCurCaption = createSelector(
  selectCurControlsVisuOnScreen,
  selectCurInputVisu,
  selectCurOutputVisu,
  (visuOnScreen, inputVisu, outputVisu) => {
    if (visuOnScreen === 'input') {
      const caption = Object.values(inputVisu.caption_datas.by_id).map(
        ({ icon, color, caption }) => {
          return { caption, color, icon };
        }
      );
      return caption;
    }
    if (visuOnScreen === 'output') {
      const caption = Object.values(outputVisu.caption_datas.by_id).map(
        ({ icon, color, caption }) => {
          return { caption, color, icon };
        }
      );
      return caption;
    }
  }
);

type ButtonStatus = 'DISABLED' | 'RUNNING' | 'FREE';
type ButtonJob = 'OPTIMIZE' | 'RECOMPUTE';
export interface ButtonJobStatus {
  job: ButtonJob;
  status: ButtonStatus;
}

function _buttonStatusWhenOutput(visuForRecompute, curOptim, curParamId): ButtonJobStatus {
  if (visuForRecompute == null || curParamId === null || curParamId === undefined) {
    return { job: 'RECOMPUTE', status: 'DISABLED' };
  } else if (curOptim == null || curOptim.recompute == null) {
    return { job: 'RECOMPUTE', status: 'FREE' };
  } else {
    return { job: 'RECOMPUTE', status: 'RUNNING' };
  }
}

function _buttonStatusWhenInput(curOptim, curParamId): ButtonJobStatus {
  if (curParamId == null) {
    return { job: 'OPTIMIZE', status: 'DISABLED' };
  } else if (curOptim == null || curOptim.optimize == null) {
    return { job: 'OPTIMIZE', status: 'FREE' };
  } else {
    return { job: 'OPTIMIZE', status: 'RUNNING' };
  }
}

export const selectLongJobButtonStatus = createSelector(
  selectCurControlsVisuOnScreen,
  selectVisuForRecompute,
  selectCurOptimRunningJob,
  selectCurParameterId,
  (visuOnScreen, visuForRecompute, curOptim, curParamId): ButtonJobStatus => {
    if (visuOnScreen === 'input') {
      return _buttonStatusWhenInput(curOptim, curParamId);
    } else if (visuOnScreen === 'output') {
      return _buttonStatusWhenOutput(visuForRecompute, curOptim, curParamId);
    }
    return { job: 'OPTIMIZE', status: 'DISABLED' };
  }
);

export const selectPrimaryAggregatorsForVisuSelector = createSelector(
  selectCurUnfilteredVisuOnScreen,
  selectCurFilterOnScreen,
  selectShowMap,
  selectShowTimeline,
  selectShowList,
  (visuOnScreen, filter, activeMap, activeTimeline, activeList) => {
    const filterStructAgg = Object.entries(
      visuOnScreen.structural_aggregators.by_id
    ).reduce(function (result, [key, { name, visibility }]) {
      const obj = { id: +key, name, inFilter: false };
      if (activeMap && visibility.map) {
        if (!result.some((o) => o.id === +key && o.name === name)) {
          result.push(obj);
        }
      }
      if (activeTimeline && visibility.timeline) {
        if (!result.some((o) => o.id === +key && o.name === name)) {
          result.push(obj);
        }
      }
      if (activeList && visibility.list) {
        if (!result.some((o) => o.id === +key && o.name === name)) {
          result.push(obj);
        }
      }
      return result;
    }, []);

    if (filter != null && filter.primaryAggregators.length > 0) {
      filter.primaryAggregators.forEach((paId) => {
        const pos = filterStructAgg.findIndex((elem) => elem.id == paId);
        if (pos >= 0) filterStructAgg[pos].inFilter = true;
      });
    }
    return filterStructAgg;
  }
);
