import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { NotificationsListModule } from '../notifications-list/notifications-list.module';
import { ParameterMenuComponentComponent } from './parameter-menu-component/parameter-menu-component.component';
import { StatusBarModule } from '../status-bar/status-bar.module';

@NgModule({
  declarations: [NavbarComponent, ParameterMenuComponentComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NotificationsListModule,
    StatusBarModule
  ],
  exports: [NavbarComponent]
})
export class NavbarModule {}
