import { ApplicationControlState } from '../store/application/application.reducers';
import { WarehouseModel } from './WarehouseModel';

/**
 * @ignore
 */
export class Visibility {
  map: boolean;
  timeline: boolean;
}

/**
 * @ignore
 */
export class DisplayLabel {
  id: number;
  short_name: string; // i.e.: "Start t."
  long_name: string; // i.e.: "Start time"
  value: string; // i.e.: "10:25:00"
  complement: string; // i.e.: "m", "meters", "s"
}

/**
 * @ignore
 */
export class SecondaryAggregator {
  id: number;
  name: string; // i.e.: "Route 1", "Vehicles", "Requests"
  masks?: Array<string>; // For now we only use "_mask_none" "_mask_big" and "mask_bright"
  app_element_ids: Array<number>; // Vector of ids of AppElements
}

/**
 * @ignore
 */
export class PrimaryAggregator {
  id: number;
  name: string; // i.e.: "Route 1", "Vehicles", "Requests"
  app_element_ids: Array<number>; // Vector of ids of AppElements
  visibility: Visibility;
  travel_mode: string | null;
}

/**
 * @ignore
 */
export class StructuralData {
  element_type: number; //Used for color-coding and map icons
  primary_aggregator_id?: number;
  start_date?: string;
  end_date?: string;
  lat?: number;
  long?: number;
  visibility: Visibility;
}

/**
 * @ignore
 */
export class AppElement {
  id: number = null; // Unique accross all app_elements
  short_name: string = null;
  long_name: string = null;
  structural_data: StructuralData = null;
  secondary_aggregator_ids: Array<number> = [];
  display_labels: Array<DisplayLabel> = [];
  default_label_ids: Array<number> = []; // Shown without hover nor click. Use long_name
  hover_label_ids: Array<number> = []; // Use short_name
  click_label_ids: Array<number> = []; // Use long_name
  _dragAndDropped?: boolean;
  _taskDone?: boolean;
}

/**
 * Data structure that contains all information to show an optimSpace or a solution in Galia.
 */
export class VisuData {
  id: string;
  app_elements: AppElement[];

  /**
   * Defines the sequence in lists, map and timeline. A partition of a subset of `all_elements`.
   */
  primary_aggregators: PrimaryAggregator[];

  /**
   * Defines highlight behaviours
   */
  secondary_aggregators: SecondaryAggregator[];
  has_map: boolean;
  has_timeline: boolean;
  has_list: boolean;
  constructor() {
    this.app_elements = new Array<AppElement>();
    this.primary_aggregators = new Array<PrimaryAggregator>();
    this.secondary_aggregators = new Array<SecondaryAggregator>();
    this.has_map = false;
    this.has_timeline = false;
    this.has_list = false;
  }
}

//********************************************** NEW VISU */

export interface NewAppElement {
  id: string;
  name: string;
  _taskDone: boolean;
  _dragAndDropped: boolean;
}

export interface NewDisplayLabel {
  name: string;
  value: string;
  level: number;
}

export interface MapData {
  marker: string;
  lat: number;
  lon: number;
  transport_mode: string;
}

export interface AreaCornerData {
  area_uid: string;
  seq_nb: number;
  latitude: number;
  longitude: number;
}

export interface TimelineData {
  start_date: string;
  finish_date: string;
  kind: string;
}

export interface ListData {}

export interface PackingData {
  parent_ae_id: string;
  layer: number;
  packing_order: number;
  material: string;
  x: number;
  y: number;
  z: number;
  x_size: number;
  y_size: number;
  z_size: number;
  shape?: string;
  pattern_texture?: string;
}

export interface PackingSpace {
  x_size: number;
  y_size: number;
  z_size: number;
}

export interface StructuralAggregator {
  id: string;
  name: string;
  category: string;
  color: string | null;
  weight: number;
  items_category: string;
  visibility: StandardVisibility;
  app_elem_ids: string[];
  vehicle_profile: string | null;
  area_data_ids: string[];
}

export interface StandardVisibility {
  list: boolean;
  timeline: boolean;
  map: boolean;
  path: boolean;
  packing: boolean;
}

export interface HighlightAggregator {
  id: string;
  app_elem_ids: string[];
}

export interface LinkData {
  id: string;
  from_ae_id: string;
  to_ae_id: string;
}

export interface CaptionData {
  color: string;
  icon: string;
  caption: string;
}

export class VisuContainer<T> {
  by_id: { [id: string]: T };
  all_ids: string[];
}

export class NewVisuData {
  _id: string;
  view_type: string;
  version: string;
  app_elements: VisuContainer<NewAppElement>;
  app_elem_labels: VisuContainer<NewDisplayLabel[]>;
  map_datas: VisuContainer<MapData>;
  area_datas: VisuContainer<AreaCornerData>;
  timeline_datas: VisuContainer<TimelineData>;
  packing_datas: VisuContainer<PackingData>;
  packing_spaces: VisuContainer<PackingSpace>;
  list_datas: VisuContainer<ListData>;
  caption_datas: VisuContainer<CaptionData>;
  custom_app_elem_datas: VisuContainer<any>;
  warehouse_model: WarehouseModel;

  structural_aggregators: VisuContainer<StructuralAggregator>;
  struct_agg_labels: VisuContainer<NewDisplayLabel[]>;
  custom_struct_agg_datas: VisuContainer<any>;

  highlight_aggregators: VisuContainer<HighlightAggregator>;
  link_datas: VisuContainer<LinkData>;
  link_data_labels: VisuContainer<NewDisplayLabel[]>;
  options?: { [key: string]: any };

  constructor() {
    this.app_elements = { all_ids: new Array<string>(), by_id: {} };
    this.app_elem_labels = { all_ids: new Array<string>(), by_id: {} };
    this.map_datas = { all_ids: new Array<string>(), by_id: {} };
    this.area_datas = { all_ids: new Array<string>(), by_id: {} };
    this.timeline_datas = { all_ids: new Array<string>(), by_id: {} };
    this.packing_datas = { all_ids: new Array<string>(), by_id: {} };
    this.list_datas = { all_ids: new Array<string>(), by_id: {} };
    this.custom_app_elem_datas = { all_ids: new Array<string>(), by_id: {} };
    this.structural_aggregators = { all_ids: new Array<string>(), by_id: {} };
    this.struct_agg_labels = { all_ids: new Array<string>(), by_id: {} };
    this.custom_struct_agg_datas = { all_ids: new Array<string>(), by_id: {} };

    this.highlight_aggregators = { all_ids: new Array<string>(), by_id: {} };
    this.link_datas = { all_ids: new Array<string>(), by_id: {} };
    this.link_data_labels = { all_ids: new Array<string>(), by_id: {} };
    this.warehouse_model = {} as WarehouseModel;
    this.options = {};
  }
}

export type VisuType = 'map' | 'timeline' | 'list' | 'packing' | 'caption' | 'warehouse';
export class VisuDataStateUtils {
  static hasTimeline(visu: NewVisuData): boolean {
    return Object.keys(visu.timeline_datas.by_id).length > 0;
  }

  static hasList(visu: NewVisuData): boolean {
    return Object.keys(visu.list_datas.by_id).length > 0;
  }

  static hasPackage(visu: NewVisuData): boolean {
    return (
      Object.keys(visu.packing_datas.by_id).length > 0 &&
      Object.keys(visu.packing_spaces.by_id).length > 0
    );
  }

  static hasMap(visu: NewVisuData): boolean {
    return Object.keys(visu.map_datas.by_id).length > 0;
  }

  static hasCaption(visu: NewVisuData): boolean {
    return Object.keys(visu.caption_datas.by_id).length > 0;
  }

  static hasWarehouse(visu: NewVisuData): boolean {
    return (
      visu?.warehouse_model != null && visu.warehouse_model.warehouse_points.length > 0
    );
  }

  static updateApplicationState(
    visu: NewVisuData,
    controlState: ApplicationControlState,
    visuTypes: VisuType[] = ['map', 'timeline', 'list', 'packing', 'warehouse']
  ): void {
    for (const visuType of visuTypes) {
      switch (visuType) {
        case 'map':
          controlState.showMap = VisuDataStateUtils.hasMap(visu);
          return;
        case 'timeline':
          controlState.showTimeline = VisuDataStateUtils.hasTimeline(visu);
          return;
        case 'list':
          controlState.showList = VisuDataStateUtils.hasList(visu);
          return;
        case 'packing':
          controlState.showPackage = VisuDataStateUtils.hasPackage(visu);
          return;
        case 'warehouse':
          controlState.showWarehouse = VisuDataStateUtils.hasWarehouse(visu);
          return;
        case 'caption':
          controlState.showCaption = VisuDataStateUtils.hasCaption(visu);
          return;
      }
    }
  }
}
