import * as THREE from 'three';
import { Dimensions, Point3D, RoutePoint } from 'src/app/core/models/WarehouseModel';
import { WarehouseColorUtils } from '../utils/color-utils';
import { WarehouseEventBus } from './warehouse-event-bus';
import {
  LineData,
  LineShape
} from '../../../../../../../../shared/visualization3d/shapes/line-shape';
import { PickerObject3D } from './picker-object3d';
import { WarehouseDAO } from '../dao/warehouse.dao';
import { WarehouseShowPickerEvent, WarehouseTimelineEvent } from './warehouse-events';

export class Picker {
  // For each time (0..maxtime) this array points corresponding position in route
  // This helps to find the positon in route for a given time
  private readonly routeReference: number[];

  // warehouse group
  private warehouse: THREE.Object3D;

  // 3D representation of the picker
  private picker: PickerObject3D;

  // 3D representation of the route
  private routePathData: LineData;

  // Offset of the path to avoid overlapping
  private pathOffset: number;

  // if the picker should be shown
  private showPicker: boolean = true;

  // if the route should be shown
  private showRoute: boolean = false;

  private readonly pickerDimensions: Dimensions;
  private lastEvent: WarehouseTimelineEvent;

  private readonly routeId: string;
  private readonly routeSize: number;

  constructor(
    private readonly index: number,
    private readonly warehouseDAO: WarehouseDAO,
    readonly maxTime: number
  ) {
    // we need one position more becase of the time 0. (ex: 0..10, 11 positions)
    this.routeReference = new Array(maxTime + 1).fill(-1);

    this.pickerDimensions = this.warehouseDAO.getPickerDimensions();

    // calculate the offset for this picker
    const pickerMinSize = Math.min(
      this.pickerDimensions.x_size,
      this.pickerDimensions.z_size
    );
    const numRoutes = this.warehouseDAO.getNumRoutes();
    const step = pickerMinSize / numRoutes;
    this.pathOffset = pickerMinSize / 2 - step * this.index;
    const route = this.warehouseDAO.getRawRoute(this.index);
    this.routeId = route.id;
    this.routeSize = route.route_points.length;
  }

  /**
   * This method will create an array (routeReference) that refers to the routePoint index.
   * It will be used to find the position in the route at a given time.
   * Each position in the array will point to the corresponding position in the route array.
   * -1 valus means that at the given time this route did not start any action.
   * ex: [-1, 0, 0, 1, 1, 1, 2]. Time 0 -> -1 (no action), time 1 -> 0 (action at position 0 in the route position),
   * time 2 -> 0 (action at position 0 in the route position), time 3 -> 1 (action at position 1 in the route position) and so on.
   */
  setup(): void {
    const routeSize = this.warehouseDAO.getRouteSize(this.index);

    for (let i = 1; i < routeSize; i++) {
      const prevTime = this.warehouseDAO.getRoutePoint(this.index, i - 1).time_s;
      const currTime = this.warehouseDAO.getRoutePoint(this.index, i).time_s;

      for (let j = prevTime; j < currTime; j++) {
        // fill the position j (time j) with the position in the route
        this.routeReference[j] = i - 1;
      }
    }
    // fill the rest with the last position
    const lastTime = this.warehouseDAO.getRoutePoint(this.index, routeSize - 1).time_s;
    for (let i = lastTime; i < this.routeReference.length; i++) {
      this.routeReference[i] = routeSize - 1;
    }
  }

  draw(warehouse: THREE.Object3D): void {
    this.warehouse = warehouse;
    this.drawPicker();
    this.addEvents();
  }

  private drawPicker(): void {
    const firstPosition = this.warehouseDAO.getRoutePoint(this.index, 0).point;
    this.picker = new PickerObject3D(
      this.index,
      this.warehouse,
      firstPosition,
      this.pickerDimensions
    );
  }

  private processTimelineEvent(event: WarehouseTimelineEvent): void {
    let reference = this.routeReference[event.frame];
    // Nothing to do (first action is in time greater than zero), draw at the first positon to show the picker
    if (reference === -1) {
      this.clearPath();
      const firstPosition = this.warehouseDAO.getRoutePoint(this.index, 0).point;
      this.picker.moveTo(firstPosition);
      return;
    }

    this.doMove(reference, event);
    const routePosition = this.warehouseDAO.getRoutePoint(this.index, reference);
    switch (routePosition.action) {
      case 'Service':
        this.doService(routePosition);
    }
    this.drawPath(event);
  }

  private selectPickersEvent(event: WarehouseShowPickerEvent): void {
    if (event.ids.includes(this.routeId)) {
      this.showPicker = event.show;
      this.picker.show(this.showPicker);
    }
  }

  private doMove(referenceIndex: number, event: WarehouseTimelineEvent): void {
    const source = this.warehouseDAO.getRoutePoint(this.index, referenceIndex);
    if (referenceIndex + 1 >= this.routeSize) {
      this.picker.moveTo(source.point);
      return;
    }
    const target = this.warehouseDAO.getRoutePoint(this.index, referenceIndex + 1);

    const deltaSpaceX = target.point.x - source.point.x;
    const deltaSpaceY = target.point.y - source.point.y;
    const deltaSpaceZ = target.point.z - source.point.z;

    const movementTime = target.time_s - source.time_s;
    const speedX = deltaSpaceX / movementTime;
    const speedY = deltaSpaceY / movementTime;
    const speedZ = deltaSpaceZ / movementTime;

    // This gives the delta sice the beginning of the current movent
    const currentTime = event.timeSeconds - source.time_s;

    const x = source.point.x + speedX * currentTime;
    const y = source.point.y + speedY * currentTime;
    const z = source.point.z + speedZ * currentTime;

    this.picker.moveTo({ x, y, z });
  }

  private doService(routePosition: RoutePoint): void {
    const service = this.warehouseDAO.getService(routePosition.service_id);
    WarehouseEventBus.getEventBus().emit('service', service.warehouse_point_id);
  }

  private addEvents(): void {
    WarehouseEventBus.getEventBus().on('updateTime', (event: WarehouseTimelineEvent) => {
      this.lastEvent = event;
      this.processTimelineEvent(event);
    });
    WarehouseEventBus.getEventBus().on(
      'selectPickers',
      (event: WarehouseShowPickerEvent) => {
        this.selectPickersEvent(event);
        if (this.lastEvent) {
          this.drawPath(this.lastEvent);
        }
      }
    );
    WarehouseEventBus.getEventBus().on('showPickersPath', (show: boolean) => {
      this.showRoute = show;
      if (this.lastEvent) {
        this.drawPath(this.lastEvent);
      }
    });
  }

  private drawPath(event: WarehouseTimelineEvent): void {
    // clear previous path
    this.clearPath();
    if (!this.showPicker || !this.showRoute) {
      return;
    }

    // build the path
    const routePath = [];
    this.applyOffset(event.frame).forEach((point) =>
      routePath.push(point.x, point.y, point.z)
    );

    // add start point
    if (routePath.length === 0) {
      const firstPosition = this.warehouseDAO.getRoutePoint(this.index, 0);
      routePath.push(
        firstPosition.point.x,
        firstPosition.point.z + this.pathOffset / 2,
        firstPosition.point.y
      );
    }

    // draw the path
    const color = WarehouseColorUtils.getPickerColor(+this.index);
    // const material = new THREE.LineBasicMaterial({ color, linewidth: 50 });
    this.routePathData = LineShape.create(this.warehouse, {
      color,
      lineWidth: 0.002,
      points: routePath
    });
  }

  private clearPath() {
    if (this.routePathData) {
      this.warehouse.remove(this.routePathData.line);
      this.routePathData.line.geometry.dispose();
      this.routePathData.material.dispose();
      this.routePathData = undefined;
    }
  }

  private applyOffset(frame: number): Point3D[] {
    const pathPositions: MovementInfo[] = [];
    for (let i = 0; i < this.routeSize; i++) {
      const routePoint = this.warehouseDAO.getRoutePoint(this.index, i);
      if (routePoint.time_s > frame) {
        break;
      }

      let direction =
        i === 0
          ? this.findFirstDirection()
          : this.discoverDirection(
              this.warehouseDAO.getRoutePoint(this.index, i - 1).point,
              routePoint.point
            );

      if (i > 0 && direction == undefined) {
        direction = pathPositions[pathPositions.length - 1].direction;
      }
      pathPositions.push({
        point: {
          x: routePoint.point.x,
          y: routePoint.point.y + this.picker.getYOffset(),
          z: routePoint.point.z
        },
        direction
      });
    }
    if (pathPositions.length === 0) {
      return [];
    }
    // add the current position
    const currentPosition = this.picker.getCurrentPosition();
    const lastPathPoint = pathPositions[pathPositions.length - 1].point;
    let direction = this.discoverDirection(lastPathPoint, currentPosition);

    direction = direction ?? pathPositions[pathPositions.length - 1].direction;
    pathPositions.push({
      point: currentPosition,
      direction
    });

    const points = [];
    for (let i = 0; i < pathPositions.length; i++) {
      const prev = i > 0 ? pathPositions[i - 1] : pathPositions[i];
      const pos = pathPositions[i];

      if (this.isVerticalMovement(prev) && this.isVerticalMovement(pos)) {
        pos.point.x += this.pathOffset; // add the offset of this route
      } else if (this.isVerticalMovement(prev) && this.isHorizontalMovement(pos)) {
        pos.point.z += this.pathOffset; // add the offset of this route
        if (i > 0) {
          prev.point.z += this.pathOffset; // fix the previous point to avoid irregular path
        }
        // if the previous position is horizontal and the current position is horizontal
      } else if (this.isHorizontalMovement(prev) && this.isVerticalMovement(pos)) {
        pos.point.x += this.pathOffset; // add the offset of this route
        if (i > 0) {
          prev.point.x += this.pathOffset; // fix the previous point to avoid irregular path
        }
      } else if (this.isHorizontalMovement(prev) && this.isHorizontalMovement(pos)) {
        pos.point.z += this.pathOffset; // add the offset of this route
      } else {
        console.error('no direction changement');
      }
      points.push(pos.point);
    }
    return points;
  }

  private findFirstDirection(): Direction {
    if (this.routeSize < 2) {
      return Direction.UP;
    }
    let direction: Direction;
    for (let i = 1; i < this.routeSize; i++) {
      direction = this.discoverDirection(
        this.warehouseDAO.getRoutePoint(this.index, i - 1).point,
        this.warehouseDAO.getRoutePoint(this.index, i).point
      );
      if (direction != undefined) {
        return direction;
      }
    }
    return Direction.UP;
  }

  private discoverDirection(start: Point3D, end: Point3D): Direction {
    const deltaX = end.x - start.x;
    const deltaZ = end.z - start.z;

    if (Math.abs(deltaX) > Math.abs(deltaZ)) {
      if (deltaX > 0) {
        return Direction.RIGHT;
      } else if (deltaX < 0) {
        return Direction.LEFT;
      }
    } else {
      if (deltaZ > 0) {
        return Direction.UP;
      } else if (deltaZ < 0) {
        return Direction.DOWN;
      }
    }
    return undefined;
  }

  private isHorizontalMovement(moveInfo: MovementInfo): boolean {
    return [Direction.LEFT, Direction.RIGHT].includes(moveInfo.direction);
  }

  private isVerticalMovement(moveInfo: MovementInfo): boolean {
    return [Direction.UP, Direction.DOWN].includes(moveInfo.direction);
  }
}

enum Direction {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  UP = 'UP',
  DOWN = 'DOWN'
}

interface MovementInfo {
  point: Point3D;
  direction: Direction;
}
