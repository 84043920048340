import { createSelector } from '@ngrx/store';
import { RootState } from 'src/app/core/store';

const selectAppState = (state: RootState) => state.application;
const selectRootState = (state: RootState) => state;
// Very basic selectors on attributes of the Application state.
export const selectMapProvider = createSelector(
  selectAppState,
  ({ mapProvider }) => mapProvider
);

export const selectNavigation = createSelector(
  selectRootState,
  ({ navigation }) => navigation
);
