<h5 class="pt-4 px-4 w-96 text-neutral-700 text-lg font-semibold">Notifications</h5>

<div *ngIf="notifications$ | async as notifications">
  <ng-template
    [ngIf]="(notifications | keyvalue).length > 0"
    [ngIfElse]="emptyNotifications"
  >
    <div class="px-2 w-96">
      <ul
        waIntersectionObserver
        waIntersectionThreshold="1"
        class="text-neutral-700 text-sm h-96 overflow-y-auto"
      >
        <li
          *ngFor="let notification of notifications | keyvalue: timeDescOrder"
          class="flex py-4 px-2 hover:bg-neutral-50 cursor-default border-b border-neutral-100 last:border-b-0"
        >
          <div class="w-12 flex-none px-1">
            <phosphor-icon
              class="fill-{{ notification.value.color }}-500 stroke-{{
                notification.value.color
              }}-900 w-10"
              [name]="notification.value.icon"
            ></phosphor-icon>
          </div>
          <div class="flex-auto">
            <p>{{ notification.value.message }}</p>

            <p
              *ngFor="let action of notification.value.actions"
              class="cursor-pointer pt-1 font-semibold text-{{
                notification.value.color
              }}-700"
              (click)="onClickAction(action.action)"
            >
              {{ action.message }}
              <phosphor-icon
                class="fill-{{ notification.value.color }}-500 stroke-{{
                  notification.value.color
                }}-900 w-5 inline-block align-middle"
                name="arrow-right"
              ></phosphor-icon>
            </p>
          </div>
          <div class="text-xs text-neutral-500 w-16 flex-none text-right">
            <div>{{ notification.value.date | timeSince }}</div>
            <div
              *ngIf="!notification.value.read"
              class="bg-red-500 h-3 w-3 rounded-full ml-8 mt-2"
              (waIntersectionObservee)="onIntersection($event)"
              [attr.notifKey]="notification.key"
            ></div>
          </div>
        </li>
      </ul>
      <div class="text-sm text-neutral-500 font-medium py-2 px-2 text-center"></div>
    </div>
  </ng-template>

  <ng-template #emptyNotifications>
    <div class="px-2 w-96 pt-6">
      <img
        class="mx-auto w-auto h-40"
        src="/assets/images/svgs/empty-notifications.svg"
        alt=""
      />
      <p class="text-neutral-400 py-6 text-center">No notification.</p>
    </div>
  </ng-template>
</div>
