import { Object3D, Color } from 'three';
import { WarehousePointBase } from './warehouse-point-base';
import { WarehousePoint } from 'src/app/core/models/WarehouseModel';
import {
  BoxData,
  BoxShape
} from '../../../../../../../../shared/visualization3d/shapes/box-shape';
import { StandardMaterial } from '../../../../../../../../shared/visualization3d/shapes/standard-material';
import { WarehouseDAO } from '../dao/warehouse.dao';
import { WarehouseEventBus } from './warehouse-event-bus';

export class WarehouseWallPoint implements WarehousePointBase {
  private static MATERIAL: THREE.MeshStandardMaterial;
  private readonly warehousePoint: WarehousePoint;

  constructor(readonly index: number, readonly warehouseDAO: WarehouseDAO) {
    if (!WarehouseWallPoint.MATERIAL) {
      WarehouseWallPoint.MATERIAL = StandardMaterial.create({
        color: '#78828b'
      });
    }
    this.warehousePoint = warehouseDAO.getWarehousePoint(index);

    WarehouseEventBus.getEventBus().on('dispose', () => {
      WarehouseWallPoint.MATERIAL = undefined;
    });
  }
  draw(warehouse: Object3D): void {
    const position = this.warehousePoint.position;
    const { x_size, y_size, z_size } = this.warehousePoint.dimensions;
    BoxShape.create(warehouse, {
      position,
      dimensions: { xSize: x_size, ySize: y_size, zSize: z_size },
      material: WarehouseWallPoint.MATERIAL,
      opts: {
        createLineSegments: false,
        matrixAutoUpdate: false,
        useSlack: false
      }
    });
  }
}
