import { WarehousePoint } from 'src/app/core/models/WarehouseModel';
import { WarehousePointBase } from './warehouse-point-base';
import { MeshStandardMaterial, Object3D } from 'three';
import { BoxShape } from '../../../../../../../../shared/visualization3d/shapes/box-shape';
import { StandardMaterial } from '../../../../../../../../shared/visualization3d/shapes/standard-material';
import { WarehouseDAO } from '../dao/warehouse.dao';
import { WarehouseEventBus } from './warehouse-event-bus';

export class WarehouseDropOffPoint implements WarehousePointBase {
  private static MATERIAL: MeshStandardMaterial;
  private readonly warehousePoint: WarehousePoint;

  constructor(index: number, private readonly warehouseDAO: WarehouseDAO) {
    if (!WarehouseDropOffPoint.MATERIAL) {
      WarehouseDropOffPoint.MATERIAL = StandardMaterial.create({
        color: '#ffffff'
      });
    }
    this.warehousePoint = warehouseDAO.getWarehousePoint(index);

    WarehouseEventBus.getEventBus().on('dispose', () => {
      WarehouseDropOffPoint.MATERIAL = undefined;
    });
  }
  draw(container: Object3D): void {
    const { x, z } = this.warehousePoint.position;
    const { x_size, z_size } = this.warehousePoint.dimensions;
    // the size will be based on the max height of the warehouse
    const ySize = this.warehouseDAO.getContainerCoords().max.y;
    BoxShape.create(container, {
      position: { x, y: ySize / 2, z },
      dimensions: { xSize: x_size, ySize, zSize: z_size },
      material: WarehouseDropOffPoint.MATERIAL,
      opts: {
        createLineSegments: true,
        matrixAutoUpdate: false,
        useSlack: false
      }
    });
  }
}
