import { createSelector } from '@ngrx/store';
import { RootState } from '..';

export const selectCurAuth = (state: RootState) => state.auth;

export const selectCurUser = createSelector(selectCurAuth, (auth) => {
  return auth.user;
});

export const selectCurUserBusinessUnit = createSelector(
  selectCurUser,
  (user) => user.business_unit
);
export const selectIsAuth = createSelector(selectCurAuth, (auth) => auth.isAuthenticated);
export const selectAccessToken = createSelector(selectCurAuth, (auth) => auth.access_token);
export const selectRefreshToken = createSelector(
  selectCurAuth,
  (auth) => auth.refresh_token
);
export const selectExpiresTimestamp = createSelector(
  selectCurAuth,
  (auth) => auth.expires_timestamp
);

export const selectCurUserBusinessUnitId = createSelector(
  selectCurUserBusinessUnit,
  (businessUnit) => businessUnit.id
);
export const selectCurUserCurrentGroup = createSelector(
  selectCurUser,
  (user) => user.data_space
);

export const selectCurUserGroupId = createSelector(
  selectCurUserCurrentGroup,
  (userGroup) => userGroup.id
);
export const selectAuthAlertMessage = createSelector(
  selectCurAuth,
  (auth) => auth.alertMessage
);
