import { Inject, Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { BASE_APP_URL } from './injectors';
import { Geocodable } from '../models/GeocodableModel';
@Injectable({
  providedIn: 'root'
})
export class Swals {
  constructor(@Inject(BASE_APP_URL) private readonly appUrl: string) {}

  private getTitle(method: string, url: string) {
    if (method === 'POST' && url.includes('/run/')) {
      return 'Problem during optimization.';
    } else if (method === 'GET' && url.includes('/visualization/input/')) {
      return 'Could not retrieve input visualization.';
    } else if (method === 'GET' && url.includes('/visualization/input/')) {
      return 'Could not retrieve output visualization.';
    } else if (method === 'POST' && url.includes('/recompute/')) {
      return 'Problems when recomputing output :';
    } else if (method === 'POST' && url.includes('/instances/')) {
      return 'Could not upload instance :';
    } else {
      return 'Problem during API request.';
    }
  }

  public fireInfo(title: string, message: string): Promise<any> {
    return Swal.fire({
      icon: 'info',
      title: title,
      text: message,
      showCloseButton: true
    });
  }

  public fire500(errorMessage: string) {
    return Swal.fire({
      icon: 'error',
      title: 'Internal error.',
      text: errorMessage,
      showCloseButton: true
    });
  }

  public fire400(
    method: string,
    url: string,
    errors: Array<string>,
    warnings: Array<string> = []
  ) {
    let messages = '';
    errors.forEach((prob: string) => {
      messages += prob + '<br />';
    });
    warnings.forEach((prob: string) => {
      messages += prob + '<br />';
    });
    let title: string = this.getTitle(method, url);
    const icon = errors.length >= 1 ? 'error' : 'warning';
    return Swal.fire({
      icon: icon,
      title: title,
      html: messages,
      showCloseButton: true,
      showConfirmButton: false
    });
  }

  public fireError({
    errorTitle = 'Internal Error',
    errorMessage = '',
    buttonText = 'OK'
  }: {
    errorTitle?: string;
    errorMessage?: string;
    buttonText?: string;
  }) {
    return Swal.fire({
      icon: 'error',
      title: errorTitle,
      text: errorMessage,
      confirmButtonText: buttonText,
      inputAttributes: {
        id: 'errorAlert'
      }
    });
  }

  public fireOptimizationFinished(id: number) {
    return Swal.fire({
      title: 'Optimization has finished',
      icon: 'success',
      text: 'New output id : ' + id,
      showConfirmButton: true,
      confirmButtonText: 'Visualize it now',
      showCloseButton: true
    });
  }

  public fireCSVTransformError() {
    return Swal.fire({
      icon: 'error',
      title: 'Cannot export to CSV',
      text: 'Failed to produce CSV out of json object',
      showConfirmButton: true
    });
  }

  public fireExportError() {
    return Swal.fire({
      icon: 'warning',
      title: 'Cannot export output',
      text: 'The computed output contains errors and the data is not available',
      showConfirmButton: true
    });
  }

  public fireNoCSVMapping() {
    return Swal.fire({
      icon: 'error',
      title: 'Cannot export to CSV',
      text: 'No CSV output mapping was found for this application',
      showConfirmButton: true
    });
  }

  public fireOptimizationStarted() {
    return Swal.fire({
      position: 'top-end',
      title: 'Optimization procedure started',
      icon: 'info',
      toast: true,
      text: 'You will be notified upon termination',
      timer: 3000,
      showConfirmButton: false,
      timerProgressBar: true
    });
  }

  public fireRecomputeFinished() {
    return Swal.fire({
      position: 'top-end',
      title: 'Output was recomputed',
      text: 'Displaying new output',
      icon: 'success',
      toast: true,
      timer: 2000,
      showConfirmButton: false,
      timerProgressBar: false
    });
  }

  public fireUploadSuccessful(id: number, objectName: string) {
    return Swal.fire({
      position: 'top-end',
      title: objectName + ' Uploaded successfully',
      text: 'New id : ' + id,
      icon: 'success',
      toast: true,
      timer: 3000,
      showConfirmButton: true
    });
  }

  public fireDeleteSuccessful(id: number, objectType: string) {
    return Swal.fire({
      position: 'top-end',
      title: objectType + ' deleted successfully',
      text: objectType + ' id was : ' + id,
      icon: 'success',
      toast: true,
      timer: 3000,
      showConfirmButton: true
    });
  }

  public fireLock(isLocked: boolean) {
    let title = 'Task ';
    if (!isLocked) {
      title += 'un';
    }
    title += 'locked in solution';
    return Swal.fire({
      position: 'top-end',
      title: title,
      icon: 'success',
      toast: true,
      timer: 3000,
      showConfirmButton: true
    });
  }

  public fireTerminateSuccessful(id: number, objectType: string) {
    return Swal.fire({
      position: 'top-end',
      title: objectType + ' terminated successfully',
      text: objectType + ' id was : ' + id,
      icon: 'success',
      toast: true,
      timer: 3000,
      showConfirmButton: true
    });
  }

  public async fireAreYouSure(verb: string) {
    const result = await Swal.fire({
      title: 'Are you sure to ' + verb + '?',
      text: 'This action cannot be undone',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete',
      cancelButtonText: 'No, keep it safe',
      focusCancel: true
    });
    if (result.value) {
      return true;
    }
    return false;
  }

  public fireInvalidJson() {
    return Swal.fire({
      position: 'top-end',
      title: 'Invalid JSON',
      icon: 'error',
      toast: true,
      showConfirmButton: true
    });
  }

  public fireExportSuccessful(token: string) {
    return Swal.fire({
      title: 'Output successfully exported',
      text: 'Generated token ' + token,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Copy URL',
      showCloseButton: true
    }).then((clicked: any) => {
      if (clicked.value) {
        const textToBeCopied = `${this.appUrl}/token-visualization/${token}`;
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = textToBeCopied;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.fireCopied(textToBeCopied);
      }
    });
  }

  async fireExportVisualizationSuccessful(
    token: string,
    title: string,
    text: string
  ): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonText: 'Copy URL',
      showCloseButton: true
    }).then((clicked: any) => {
      if (clicked.value) {
        const textToBeCopied = `${this.appUrl}/visualization/${token}`;
        navigator.clipboard.writeText(textToBeCopied);
        this.fireCopied(textToBeCopied);
      }
    });
  }

  public fireCopied(text: string) {
    return Swal.fire({
      position: 'top-end',
      title: 'Copied!',
      text: text,
      icon: 'success',
      toast: true,
      timer: 3000,
      showConfirmButton: false
    });
  }

  public fireGeocodeSuccessful(geocodableObject: Geocodable) {
    return Swal.fire({
      position: 'top-end',
      title: geocodableObject.address,
      text: '(' + geocodableObject.latitude + ', ' + geocodableObject.longitude + ')',
      icon: 'success',
      toast: true,
      timer: 5000,
      showConfirmButton: false
    });
  }

  public fireResetPasswordSuccessful() {
    return Swal.fire({
      title: 'Password was reset',
      icon: 'success',
      showConfirmButton: true,
      confirmButtonText: 'OK',
      focusConfirm: true
    });
  }

  public fireRequestPasswordSuccessful() {
    return Swal.fire({
      title: 'Password reset request sent',
      text: 'If your email address exists in our database, you will receive a password recovery link at your email address.',
      icon: 'success',
      showConfirmButton: true,
      confirmButtonText: 'OK',
      focusConfirm: true
    });
  }

  public fireResetPasswordUnsuccessful() {
    return Swal.fire({
      title: 'Problem resetting password',
      icon: 'error',
      showConfirmButton: true,
      confirmButtonText: 'OK',
      focusConfirm: true
    });
  }
}
