import { createReducer, on } from '@ngrx/store';
import { OutputPickerState, initialDataPickerState } from './output-picker.state';
import {
  deleteRow,
  preSelectOutput,
  triggerAlert,
  triggerDestroy,
  updateCurrentOutputInUse,
  updateOutput,
  updateOutputData,
  updateSpinner
} from './output-picker.actions';
import {
  outputSelectionComplete,
  outputSelectionFailure
} from 'src/app/core/store/application/application.actions';

export const outputPickerReducer = createReducer<OutputPickerState>(
  initialDataPickerState,
  on(preSelectOutput, (state, { id }) => {
    const selectedIds = [...state.selectedIds];
    const index = selectedIds.findIndex((rowId) => rowId === id);
    if (index === -1) {
      selectedIds.push(id);
    } else {
      selectedIds.splice(index, 1);
    }
    return {
      ...state,
      selectedIds
    };
  }),

  on(deleteRow, (state, { id }) => {
    const ids = Array.isArray(id) ? id : [id];

    const rows = [...state.data.rows];
    const selectedIds = [...state.selectedIds];

    ids.forEach((deleteId) => {
      const rowPos = rows.findIndex((row) => row.id === deleteId);
      if (rowPos > -1) {
        rows.splice(rowPos, 1);
        const selectIdPos = selectedIds.findIndex((sid) => sid === deleteId);
        if (selectIdPos > -1) {
          selectedIds.splice(selectIdPos, 1);
        }
      }
    });

    if (
      rows.length < state.data.rows.length ||
      selectedIds.length < state.selectedIds.length
    ) {
      return {
        ...state,
        data: {
          ...state.data,
          rows
        },
        selectedIds,
        message: null
      };
    } else {
      return {
        ...state,
        message: {
          category: 'error',
          message: `Cannot remove row(s) #${ids.join(', ')}.`,
          persistentMessage: false,
          details: null
        }
      };
    }
  }),

  on(updateOutputData, (state, { data, cursor }) => {
    //put the selected optim space at the first place
    let rows = [];
    if (
      state.data &&
      state.data.orderBy === data.orderBy &&
      state.data.startDate === data.startDate &&
      state.data.endDate === data.endDate
    ) {
      rows = state.data.rows.concat(data.rows);
    } else {
      rows = [...data.rows];
    }
    return {
      ...state,
      data: {
        ...data,
        rows
      },
      cursor,
      selectedIds: [],
      message: null
    };
  }),

  on(updateCurrentOutputInUse, (state, { currentLoadedId }) => {
    return {
      ...state,
      currentLoadedId
    };
  }),

  on(updateSpinner, (state, { status }) => {
    return {
      ...state,
      spinnerSelect: status
    };
  }),

  on(outputSelectionComplete, (state, { output }) => {
    return {
      ...state,
      spinnerSelect: false,
      message: {
        category: 'success',
        message: 'Output ' + output.id + ' has been loaded.',
        persistentMessage: false,
        details: null
      }
    };
  }),

  on(outputSelectionFailure, (state, { message, details }) => {
    return {
      ...state,
      spinnerSelect: false,
      message: {
        category: 'error',
        message: message,
        persistentMessage: false,
        details: details
      }
    };
  }),

  on(triggerAlert, (state, { message }) => {
    return {
      ...state,
      spinnerSelect: false,
      message: message
    };
  }),

  on(triggerDestroy, (state) => {
    return { ...initialDataPickerState };
  }),

  on(updateOutput, (state, { id, name }) => {
    const index = state.data.rows.findIndex((row) => row.id === id);
    const rows = [...state.data.rows];
    rows[index] = { ...rows[index], name } as any;
    return {
      ...state,
      data: {
        ...state.data,
        rows
      }
    };
  })
);
