import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customDate' })
export class CustomDatePipe implements PipeTransform {
  transform(value: string): string {
    const date = new Date(value);
    const options = {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    } as Intl.DateTimeFormatOptions;
    if (date.getFullYear() !== new Date(Date.now()).getFullYear()) {
      options['year'] = 'numeric';
    }
    return date.toLocaleString('en-US', options);
  }
}
