import * as THREE from 'three';
import { Object3DUtils } from '../../../feature/applications/warehouse/components/warehouse3d/module/core/utils/object3d-utils';

export class ArrowShape {
  static create(container: THREE.Object3D, triangleProps: ArrowProperties): ArrowData {
    const { x, y, z } = triangleProps.position;
    const { xSize, ySize, zSize } = triangleProps.dimensions;

    const halfxSize = xSize / 2;
    const shape = new THREE.Shape();
    shape.moveTo(x, z); // top
    shape.lineTo(x - halfxSize, z - zSize); // left-down
    shape.lineTo(x, z - (2 / 3) * zSize); // mid down
    shape.lineTo(x + halfxSize, z - zSize); // right-down
    shape.lineTo(x, z); // top (close the shape)

    const triangleGeometry = new THREE.ExtrudeGeometry(shape, {
      steps: 1,
      depth: ySize,
      bevelEnabled: false
    });
    triangleGeometry.center();

    const triangleMesh = new THREE.Mesh(triangleGeometry, triangleProps.material);
    triangleMesh.position.set(x, y + ySize / 2, z);
    triangleMesh.rotateX(Math.PI / 2);
    triangleMesh.rotateZ(-Math.PI / 2);

    // after the rotation the axes are rotated too, so ry and rz need to be inverted
    const { rx, ry, rz } = triangleProps.rotation || {};
    Object3DUtils.rotate(triangleMesh, { rx: rx, ry: rz, rz: ry });

    if (triangleProps.opts?.matrixAutoUpdate != undefined) {
      triangleMesh.matrixAutoUpdate = triangleProps.opts.matrixAutoUpdate;
    }

    triangleMesh.updateMatrix();
    container.add(triangleMesh);
    return { arrow: triangleMesh };
  }
}

export interface ArrowProperties {
  position: {
    x: number;
    y: number;
    z: number;
  };
  dimensions: {
    xSize: number;
    ySize: number;
    zSize: number;
  };
  rotation?: {
    rx?: number;
    ry?: number;
    rz?: number;
  };
  material: THREE.Material;
  opts: {
    createLineSegments?: boolean;
    matrixAutoUpdate?: boolean;
  };
}

export interface ArrowData {
  arrow: THREE.Mesh;
}
