import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import JSONEditor, { JSONEditorOptions } from 'jsoneditor';
import { RootState } from 'src/app/core/store';
import { Swals } from 'src/app/core/utils/swals';
import { LngLat, MarkerGalia } from 'src/app/core/models/mapbox.model';
import {
  getInnerObject,
  createAppElementFromUserLocation
} from 'src/app/core/utils/jsonEditorUtils';
import { VisuData } from 'src/app/core/models/Visu';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss']
})
export class JsonEditorComponent implements OnInit, OnDestroy {
  private _example: Object = {};
  private _schema: any = {};

  @ViewChild('jsonEditor', { static: true }) editorContainer: ElementRef;
  @Output() updateLocalisation: EventEmitter<MarkerGalia[]> = new EventEmitter<
    MarkerGalia[]
  >();

  public _obs_currentVisuData: BehaviorSubject<VisuData>;

  @Input() rootName: string;

  @Input() set schema(schema: any) {
    this._schema = schema;
    if (this.editor) {
      this.setSchema(schema);
    }
  }

  get schema() {
    return this._schema;
  }

  @Input() set example(example: Object) {
    this._example = example;
    if (this.editor) {
      this.setExample(example);
    }
  }

  get example() {
    return this._example;
  }

  @Input() updatedCoordinates: LngLat;

  private currentFocusedPath: Array<string | number>;
  private currentNode: any;

  public helpBoxText: string = null;
  public helpBoxTitle: string = null;
  public helperBoxDragged: boolean = false;

  private editor: JSONEditor;
  private schemaRefs: Object;

  constructor(private readonly swals: Swals, private readonly store: Store<RootState>) {}

  public myOnDrag() {
    this.helperBoxDragged = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.updatedCoordinates && this.editor) {
      const path = this.currentFocusedPath;
      const parentObject = getInnerObject(path.slice(0, path.length - 1), this.getJSON());
      if (parentObject) {
        parentObject.latitude = this.updatedCoordinates[1];
        parentObject.longitude = this.updatedCoordinates[0];
      }
      this.updateVisuData(parentObject).then(() => {
        const updatedJson = this.getJSON();
        let target = updatedJson;
        let i = 0;
        while (i < path.length - 2) {
          target = target[path[i]];
          i = i + 1;
        }
        target[path[path.length - 2]] = parentObject;
        this.editor.update(updatedJson);
      });
    }
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.helperBoxDragged = false;
    this.schemaRefs = {};
    this._obs_currentVisuData = new BehaviorSubject<VisuData>(new VisuData());
    if (this.schema && this.schema.definitions) {
      Object.keys(this.schema.definitions).forEach((key) => {
        this.schemaRefs['#/definitions/' + key] = this.schema.definitions[key];
      });
    }

    const options = {
      schema: this.schema,
      schemaRefs: this.schemaRefs,
      enableTransform: false,
      mode: 'tree',
      modes: ['code', 'tree'],
      onEvent: (node, event) => {
        return this.myOnEvent(node, event, this.editor.get(), node.path);
      },
      onError: function (err) {
        console.log(err);
      },
      name: this.rootName,
      onEditable: (node) => this.nodeIsEditable(node)
    } as JSONEditorOptions;

    this.editor = new JSONEditor(this.editorContainer.nativeElement, options, this.example);
    this.resetHelperBox();
  }

  ngOnDestroy(): void {
    //Called once, before the optimSpace is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.editor.destroy();
  }

  private async updateVisuData(userLocation: any) {
    let newVisu = new VisuData();
    let newAppElement = createAppElementFromUserLocation(userLocation);
    newVisu.app_elements.push(newAppElement);
    this._obs_currentVisuData.next(newVisu);
    return;
  }

  public getJSON() {
    return this.editor.get();
  }

  public setExample(example: any) {
    this.editor.update(example);
    this.resetHelperBox();
  }

  public setSchema(schema: any) {
    this.editor.setSchema(schema);
  }

  public setRootName(rootName: string) {
    this.rootName = rootName;
    this.editor.setName(rootName);
  }

  private resetHelperBox() {
    this.helpBoxText = null;
    this.helpBoxTitle = null;
    this.helperBoxDragged = false;
  }

  public updateJSON(json: Object) {
    return this.editor.set(json);
  }

  public nodeIsEditable(node) {
    if (node.field === 'updatetype') {
      return false;
    }
    if (node.field === 'objecttype') {
      return false;
    }
    return true;
  }

  private async handleHelpText(node, event) {
    if (
      (event.type == 'mouseover' || event.type == 'focus') &&
      !node.value &&
      event.target.title
    ) {
      this.helpBoxTitle = null;
      this.helpBoxText = null;
      if (node.field) {
        this.helpBoxTitle = node.field;
      }
      if (event.target.title) {
        this.helpBoxText = event.target.title;
      }
    }
    return;
  }

  public myOnEvent(node, event, curJson, path) {
    this.currentFocusedPath = path;
    this.currentNode = node;
    const parentObject = getInnerObject(path.slice(0, path.length - 1), curJson);

    if ('latitude' in parentObject && 'longitude' in parentObject) {
      const marker: MarkerGalia = {
        id: 1,
        coordinates: [parentObject.longitude, parentObject.latitude],
        type: 'basic',
        color: '#ff0000',
        transportMode: 'land',
        draggable: true,
        description: {
          name: parentObject.id,
          startDate: null,
          endDate: null,
          content: {}
        }
      };
      this.updateLocalisation.emit([marker]);
    } else {
      this.updateLocalisation.emit([]);
    }
    this.handleHelpText(node, event);
  }
}
