<ng-container
  *ngIf="{
    application: application$ | async,
    user: user$ | async,
    optimSpaceId: optimSpaceId$ | async,
    inputId: inputId$ | async,
    outputId: outputId$ | async,
    outputVisuForRecompute: outputVisuForRecompute$ | async,
    parameterId: parameterId$ | async,
    buttonJobStatus: buttonJobStatus$ | async,
    buttonErrorOrWarning: buttonErrorOrWarning$ | async,
    canOptimize: canOptimize$ | async,
  } as ctx"
>
  <nav data-cy="navbar" class="w-full h-full bg-neutral-50">
    <!-- Center : Main menu only for medium and larger screens; empty for small screens -->
    <div class="inline-flex w-full items-center justify-between py-2 px-4">
      <!-- Atoptimal logo -->
      <div class="block h-12 max-w-52">
        <a [routerLink]="mainLink">
          <img class="h-10" src="assets/images/atoptima/atoptima_color_v2.png" alt="logo" />
        </a>
      </div>

      <ng-container *ngIf="alertMessageMainBar$ | async as alertMessage">
        <app-status-bar
          data-cy="status_bar"
          (closed)="onCloseAlertMessageMainBar()"
          [message]="alertMessage.message"
          [category]="alertMessage.category"
        ></app-status-bar>
      </ng-container>

      <div class="relative inline-block text-left">
        <!-- Right : Controls & Profil  -->
        <ul class="pl-2 flex flex-1 justify-right items-center">
          <li
            *ngIf="
              (!isDashboardRoute() || isVisualizationMode) &&
              ctx.application.application.path &&
              (ctx.inputId || ctx.outputId)
            "
            class="mr-1 relative"
          >
            <button
              data-cy="button_state"
              app-button
              [color]="ctx.buttonErrorOrWarning.color"
              hierarchy="tertiary"
              size="small-square"
              [svgIcon]="ctx.buttonErrorOrWarning.svgIcon"
              (click)="onClickShowWarnings()"
            ></button>
          </li>
          <li
            *ngIf="
              ctx.application.application.path &&
              !isDashboardRoute() &&
              !isVisualizationMode
            "
            class="mr-1 relative"
          >
            <ng-template
              [ngIf]="ctx.buttonJobStatus.status == 'DISABLED' || !ctx.canOptimize"
            >
              <button
                data-cy="optimize_button"
                app-button
                hierarchy="primary"
                state="disabled"
                color="neutral"
                svgIcon="play"
                title="Optimization disabled. You must select an input and a parameter file."
              >
                <ng-template [ngIf]="ctx.buttonJobStatus.job == 'OPTIMIZE'">
                  Optimize
                </ng-template>
                <ng-template [ngIf]="ctx.buttonJobStatus.job == 'RECOMPUTE'">
                  Recompute
                </ng-template>
              </button>
            </ng-template>
            <ng-template [ngIf]="ctx.buttonJobStatus.status == 'FREE' && ctx.canOptimize">
              <button
                app-button
                state="enabled"
                color="primary"
                hierarchy="primary"
                svgIcon="play"
                (click)="
                  onOptimize(
                    ctx.inputId,
                    ctx.parameterId,
                    ctx.outputId,
                    ctx.outputVisuForRecompute,
                    ctx.buttonJobStatus,
                    ctx.application.version
                  )
                "
              >
                <ng-template
                  [ngIf]="ctx.buttonJobStatus.job == 'OPTIMIZE' && ctx.canOptimize"
                >
                  Optimize
                </ng-template>
                <ng-template
                  [ngIf]="ctx.buttonJobStatus.job == 'RECOMPUTE' && ctx.canOptimize"
                >
                  Recompute
                </ng-template>
              </button>
            </ng-template>
            <ng-template
              [ngIf]="ctx.buttonJobStatus.status == 'RUNNING' && ctx.canOptimize"
            >
              <button app-button color="neutral" hierarchy="primary" state="running">
                <ng-template
                  [ngIf]="ctx.buttonJobStatus.job == 'OPTIMIZE' && ctx.canOptimize"
                >
                  Optimizing
                </ng-template>
                <ng-template
                  [ngIf]="ctx.buttonJobStatus.job == 'RECOMPUTE' && ctx.canOptimize"
                >
                  Recomputing
                </ng-template>
              </button>
            </ng-template>
          </li>
          <li
            *ngIf="
              ctx.application.application.path &&
              !isDashboardRoute() &&
              !isVisualizationMode
            "
            class="mr-8 relative"
          >
            <dropdown-menu [placement]="'bottom-end'">
              <button
                data-cy="param_button"
                app-button
                state="enabled"
                color="neutral"
                hierarchy="secondary"
                size="small-square"
                svgIcon="gear"
                dropdownOrigin
              ></button>
              <div class="dropdown-panel" dropdownMenu>
                <app-parameter-menu-component class="bg-white" [dynamicMode]="dynamicMode">
                </app-parameter-menu-component>
              </div>
            </dropdown-menu>
          </li>
          <li class="mr-5 relative" *ngIf="!isVisualizationMode">
            <dropdown-menu [placement]="'bottom-end'">
              <button
                data-cy="notifications_button"
                state="enabled"
                app-button
                color="primary"
                hierarchy="tertiary"
                size="small-square"
                svgIcon="bell"
                dropdownOrigin
              >
                <div
                  *ngIf="nbNotifsUnread$ | async as nbNotifsUnread"
                  class="bg-red-500 text-white rounded-full absolute px-2 left-6 text-sm"
                >
                  {{ nbNotifsUnread }}
                </div>
              </button>
              <div class="dropdown-panel" dropdownMenu>
                <app-notifications-list></app-notifications-list>
              </div>
            </dropdown-menu>
          </li>
          <li class="relative">
            <dropdown-menu *ngIf="user$ | async; let user" [placement]="'bottom-end'">
              <button
                app-button
                data-cy="user_button"
                state="enabled"
                size="small-square"
                color="primary"
                hierarchy="tertiary"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
                svgIcon="user-circle"
                dropdownOrigin
              ></button>
              <div class="dropdown-panel" dropdownMenu>
                <div class="py-1">
                  <a
                    routerLink="/dashboard/user-profile"
                    class="block px-4 py-2 text-sm leading-5 text-neutral-700 hover:bg-neutral-100 hover:text-neutral-900 focus:outline-none focus:bg-neutral-100 focus:text-neutral-900 cursor-pointer whitespace-nowrap"
                    role="menuitem"
                    >{{ user.user_name }}</a
                  >
                </div>

                <div class="py-1">
                  <a
                    (click)="logout()"
                    class="block px-4 py-2 text-sm leading-5 text-neutral-700 hover:bg-neutral-100 hover:text-neutral-900 focus:outline-none focus:bg-neutral-100 focus:text-neutral-900 cursor-pointer whitespace-nowrap"
                    role="menuitem"
                    >Sign out</a
                  >
                </div>
              </div>
            </dropdown-menu>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <app-modal
    data-cy="modal"
    (closeModal)="onCloseModal($event)"
    [showModal]="showModal"
    [details]="details"
    [category]="modalCategory$ | async"
  ></app-modal>
</ng-container>
