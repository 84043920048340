import { Pipe, PipeTransform } from '@angular/core';
import { MessageCategory } from 'src/app/core/models/MessageCategory';
import { phosphorIcon } from 'src/app/shared/components/phosphor-icons/phosphor-icons';

@Pipe({ name: 'modalIcon' })
export class ModalIcon implements PipeTransform {
  transform(category: MessageCategory): phosphorIcon {
    switch (category) {
      case 'info':
        return 'info';
      case 'warning':
        return 'warning';
      case 'error':
        return 'warning-circle';
      case 'success':
        return 'check-circle';
    }
    return 'check-circle';
  }
}
