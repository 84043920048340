import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { Observable, of, pipe } from 'rxjs';
import { concatMap, withLatestFrom } from 'rxjs/operators';
import {
  applicationReducer,
  ApplicationState,
  initialApplicationState
} from './application/application.reducers';
import { authReducer, AuthState, initialAuthState } from './auth/auth.reducers';
import { componentStateReducer } from './componentstate';
import {
  initialNavigationState,
  navigationReducer,
  NavigationState
} from './navigation/navigation.reducers';
import {
  initialNotificationState,
  notificationsReducer,
  NotificationsState
} from './notifications/notifications.reducers';
import { localStorageSync } from 'ngrx-store-localstorage';

/**
 * Global state of the application.
 * This is the single source of truth after the rehydration has been completed.
 */
export interface RootState {
  auth: AuthState; // Partially rehydrated from the local storage (auth token only).
  application: ApplicationState; // Partially rehydrated from loc storage (async job status only).
  router?: RouterReducerState;
  navigation: NavigationState;
  notifications: NotificationsState; // Rehydrated from the local storage.
  componentState?;
}

export const initRootState: RootState = {
  auth: initialAuthState,
  application: initialApplicationState,
  navigation: initialNavigationState,
  notifications: initialNotificationState
};

export const reducers: ActionReducerMap<RootState> = {
  auth: authReducer,
  application: applicationReducer,
  router: routerReducer,
  navigation: navigationReducer,
  notifications: notificationsReducer,
  componentState: componentStateReducer
};

// Operator to use the state in effects.
// While withLatestFrom() is definitely a neat way to incorporate the state into effects,
// it also has a catch: applying it directly on the stream will run the selector upon effect
// registration - so, basically upon app startup. This might degrade performance when your
// application grows and you’re registering a lot of effects like this. As a workaround, we
// can combine our stream lazily with the state selection by using the concatMap() operator.
// It maps stream values to observables which are unwrapped in order once they complete.
// Therefore it’s similar to , but while mergeMap() just merges the values of resulting
// observables, also maintains their order.
// of() is a function that creates an observable which will emit the function argument(s)
// and complete rightafterthat.Bycreatingtheunderlyingobservablethiswayandapplying
// withLatestFrom() we basically get the same stream as before, but the selector will only
// be run once the first action passes through the effect.
// -- from ngrx book by Nils Mehlhorn
export const withLatestFromDeferred = <A, B>(other: Observable<B>) =>
  pipe(concatMap((value: A) => of(value).pipe(withLatestFrom(other))));

// See https://github.com/btroncone/ngrx-store-localstorage for documentation
// TODO: expire date.
export function localStorageSyncReducer(
  reducer: ActionReducer<RootState>
): ActionReducer<RootState> {
  return localStorageSync({
    keys: [
      { auth: ['access_token', 'refresh_token', 'expires_timestamp'] },
      { notifications: ['lastNotificationId', 'notifications'] }
      // { application: ['runningOptimJobs'] } // what about eventListeners ?
    ],
    rehydrate: true
  })(reducer);
}
