import { Component, Inject } from '@angular/core';
import { APP_VERSION } from 'src/app/core/utils/injectors';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  constructor(@Inject(APP_VERSION) readonly appVersion: string) {}
}
