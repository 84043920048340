import { WarehouseDAO } from '../dao/warehouse.dao';
import { WarehouseColorUtils } from './color-utils';
import { WarehouseCategory } from '../models/warehouse-category';
import { WarehouseEventBus } from '../models/warehouse-event-bus';

export class WarehouseCaptionsUtils {
  private static instance: WarehouseCaptionsUtils;

  private categories: WarehouseCategory[];
  private showHeatMap: boolean = false;

  static init(warehouseDAO: WarehouseDAO): void {
    WarehouseCaptionsUtils.instance = new WarehouseCaptionsUtils(warehouseDAO);
  }

  static getInstance(): WarehouseCaptionsUtils {
    return WarehouseCaptionsUtils.instance;
  }

  private constructor(private readonly warehouseDAO: WarehouseDAO) {
    WarehouseEventBus.getEventBus().on(
      'showHeatmap',
      (show: boolean) => (this.showHeatMap = show)
    );
  }

  getCategories(): WarehouseCategory[] {
    if (!this.categories) {
      this.categories = this.warehouseDAO.getAllCategories().map((name, index) => {
        return { name, color: WarehouseColorUtils.getCategoryColor(index) };
      });
    }
    return this.categories;
  }

  shouldShowHeatMapCaptions() {
    return this.showHeatMap;
  }
}
