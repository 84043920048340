<div *ngIf="state === 'running'" class="h-5">
  <div class="lds-ring">
    <div class="{{ colorValue }}-{{ hierarchyValue }}"></div>
    <div class="{{ colorValue }}-{{ hierarchyValue }}"></div>
    <div class="{{ colorValue }}-{{ hierarchyValue }}"></div>
    <div class="{{ colorValue }}-{{ hierarchyValue }}"></div>
  </div>
</div>
<phosphor-icon
  *ngIf="svgIcon && state !== 'running'"
  [class]="svgIconStyle"
  [name]="svgIcon"
></phosphor-icon>
<ng-content></ng-content>
