import { Injectable } from '@angular/core';
import { iconInUse, PhosphorIcon } from '../components/phosphor-icons/phosphor-icons';

@Injectable({
  providedIn: 'root'
})
export class PhosphorIconsService {
  public iconInUse = iconInUse;
  private readonly registry = new Map<string, string>();

  public registerIcons(icons: PhosphorIcon[]): void {
    icons.forEach((icon: PhosphorIcon) => this.registry.set(icon.name, icon.data));
  }

  public getIcon(iconName: string): string | undefined {
    if (!this.registry.has(iconName)) {
      console.warn(
        `We could not find the phosphor Icon with the name ${iconName}, did you add it to the Icon registry?`
      );
    }
    return this.registry.get(iconName);
  }
}
