export enum ENDPOINT {
  MAPPING = 'v3/mapping'
}

export enum EndPointV4 {
  AUTH = 'v4-beta/auth/token',
  PASSWORDS = 'v4-beta/passwords',
  USERS = 'v4-beta/users',
  REFRESH_TOKEN = 'v4-beta/auth/refresh-token',
  OPTIMSPACES = 'v4-beta/optimspaces',
  DATASPACES = 'v4-beta/dataspaces',
  PARAMETERS = 'v4-beta/parameters',
  INPUTS = 'v4-beta/inputs',
  OUTPUTS = 'v4-beta/outputs',
  APPLICATIONS = 'v4-beta/applications',
  JOBS = 'v4-beta/jobs',
  SCHEMAS = 'v4-beta/schemas',
  HEALTH = 'v4-beta/health',
  PROFILE = 'v4-beta/auth/profile',
  BUSINESS_UNITS = 'v4-beta/businessunits',
  VISUALIZATIONS = 'v4-beta/visualizations'
}
