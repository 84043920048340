import { KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootState } from 'src/app/core/store';
import {
  clickShowSolution,
  markNotificationAsRead
} from 'src/app/core/store/notifications/notifications.actions';
import { Notification } from 'src/app/core/store/notifications/notifications.reducers';
import { selectNotificationsList } from 'src/app/core/store/notifications/notifications.selectors';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListComponent implements OnDestroy {
  private readonly _stop$ = new Subject<void>();

  notifications$ = this.store.select(selectNotificationsList);

  constructor(
    private readonly store: Store<RootState>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router
  ) {
    // This how we force the view refresh each minute because the timeSince
    // pipe display the relative creation date of the notification.
    // If we don't reforce the refresh, relative time does not change and the user has to
    // refresh the page to see the real relative time.
    interval(60000)
      .pipe(takeUntil(this._stop$))
      .subscribe((_) => {
        this.changeDetectorRef.detach();
        this.changeDetectorRef.detectChanges();
        this.changeDetectorRef.reattach();
      });
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  onIntersection($event: Array<IntersectionObserverEntry>) {
    $event.forEach((event) => {
      if (event.isIntersecting) {
        const id = event.target.attributes['notifKey'].value;
        this.store.dispatch(markNotificationAsRead({ id }));
      }
    });
  }

  onClickAction(action) {
    if (action.kind === 'showSolution') {
      const { appPath, appVersion, optimSpaceId, inputId, outputId, parameterId } = action;
      const path = `/application/${appPath}/${appVersion ?? 'latest'}`;
      const queryParams = { optimSpaceId, inputId, outputId, parameterId };
      const dest = this.router.createUrlTree([path], { queryParams });
      this.store.dispatch(clickShowSolution({ dest }));
    }
    // TODO: show error not implemented because no test case
  }

  // Order by descending property key
  timeDescOrder(
    a: KeyValue<string, Notification>,
    b: KeyValue<string, Notification>
  ): number {
    return new Date(b.value.date).getTime() - new Date(a.value.date).getTime();
  }
}
