import { createReducer, on } from '@ngrx/store';
import { MessageCategory } from 'src/app/core/models/MessageCategory';
import { User } from '../../models/User';
import {
  eraseAuthAlertMessage,
  loginComplete,
  loginDetails,
  loginFailure,
  logoutComplete,
  updateTokens,
  updateUserInfoComplete,
  updateUserInfoFailure,
  updateUserInfoSuccess
} from './auth.actions';

export interface AuthState {
  // is a user authenticated?
  isAuthenticated: boolean;
  // if authenticated, there should be a user object
  user?: User;
  // acces token that authentifies the user in the API
  access_token?: string;
  // refresh token
  refresh_token?: string;
  // expiration date for the access_token
  expires_timestamp?: number;
  // message for the authentication
  alertMessage?: { category: MessageCategory; message: string };
  //Flag that Indicates if the user update is complete
  updateComplete: boolean;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  updateComplete: false
};

export const authReducer = createReducer(
  initialAuthState,
  on(loginDetails, (state, { token, refresh_token, expires_timestamp }) => {
    return { ...state, access_token: token, refresh_token, expires_timestamp };
  }),

  on(loginComplete, (state, { user, token }) => {
    return {
      ...state,
      isAuthenticated: true,
      alertMessage: null,
      user,
      access_token: token
    };
  }),

  on(updateTokens, (state, { token, refresh_token, expires_timestamp }) => {
    return {
      ...state,
      access_token: token,
      refresh_token,
      expires_timestamp
    };
  }),

  on(updateUserInfoSuccess, (state, { email, full_name }) => {
    const updatedUser = {
      ...state.user,
      fullname: full_name,
      email: email
    };
    return {
      ...state,
      isAuthenticated: true,
      alertMessage: {
        category: 'success',
        message: 'user has been updated successfully'
      },
      user: updatedUser
    };
  }),

  on(updateUserInfoComplete, (state) => {
    return {
      ...state,
      isAuthenticated: true,
      alertMessage: null
    };
  }),

  on(updateUserInfoFailure, (state, { category, message }) => {
    return {
      ...state,
      isAuthenticated: true,
      alertMessage: {
        message: message,
        category: category
      }
    };
  }),

  on(loginFailure, (state, { category, message }) => {
    return {
      ...state,
      isAuthenticated: false,
      alertMessage: { category, message }
    };
  }),

  on(logoutComplete, (state, _) => {
    return {
      isAuthenticated: false,
      updateComplete: false,
      user: null,
      alertMessage: {
        category: 'success',
        message: 'You have successfully been logged out.'
      },
      access_token: null,
      refresh_token: null,
      expires_timestamp: null
    };
  }),

  on(eraseAuthAlertMessage, (state, _) => {
    return {
      ...state,
      alertMessage: null
    };
  })
);
