import { createReducer, on } from '@ngrx/store';
import { PhosphorIconComponent } from 'src/app/shared/components/phosphor-icons/phosphor-icon.component';
import { markNotificationAsRead, newNotification } from './notifications.actions';

export const MAX_NOTIFICATIONS = 10;

export type NotificationAction =
  | {
      kind: 'showSolution';
      appPath: string;
      appVersion: string;
      optimSpaceId: number;
      inputId: number;
      outputId: number;
      parameterId: number;
    }
  | {
      kind: 'showError';
      appPath: string;
      appVersion: string;
      optimSpaceId: number;
      inputId: number;
      parameterId: number;
    };

export interface Notification {
  color: string;
  icon: PhosphorIconComponent['name'];
  message: string;
  date: string;
  read: boolean;
  actions?: Array<{ message: string; action: NotificationAction }>;
}

export interface Notifications {
  [id: number]: Notification;
}

export interface NotificationsState {
  lastNotificationId: number;
  notifications: Notifications;
}

export const initialNotificationState = {
  lastNotificationId: 0,
  notifications: {}
};

export const notificationsReducer = createReducer(
  initialNotificationState,
  on(newNotification, (state, { color, icon, message, read, actions }) => {
    const notifications = { ...state.notifications };
    const id = state.lastNotificationId + 1;
    const date = new Date();
    if (actions != null) {
      notifications[id] = { color, icon, message, read, date, actions };
    } else {
      notifications[id] = { color, icon, message, read, date };
    }
    // If the number of notifications exceeds the limit, remove the oldest one
    if (Object.keys(notifications).length > MAX_NOTIFICATIONS) {
      const oldestId = Object.keys(notifications)[0];
      delete notifications[oldestId];
    }
    return {
      lastNotificationId: id,
      notifications
    };
  }),

  on(markNotificationAsRead, (state, { id }) => {
    if (id in state.notifications) {
      const notifications = {
        ...state.notifications
      };
      notifications[id] = { ...state.notifications[id], read: true };
      return {
        ...state,
        notifications
      };
    }
    return state;
  })
);
