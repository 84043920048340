import { Component, Input, OnInit } from '@angular/core';
import { CaptionData } from 'src/app/core/models/Visu';
import { phosphorIcon } from '../phosphor-icons/phosphor-icons';

@Component({
  selector: 'app-caption',
  templateUrl: './caption.component.html'
})
export class CaptionComponent implements OnInit {
  @Input() caption: CaptionData;

  icon: phosphorIcon;

  constructor() {}

  ngOnInit() {
    if (this.caption?.icon) {
      //will be empty if not found
      this.icon = this.caption.icon as phosphorIcon;
    }
  }
}
