import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  OptimSpacePickerState,
  OptimSpacePickerTableState,
  initialDataPickerState
} from './optim-space-picker.state';
import { OptimSpacesService } from 'src/app/core/services/optimSpaces.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mapTo, merge, of, switchMap, throwError, toArray } from 'rxjs';
import {
  deleteOptimSpace,
  deleteOptimSpaces,
  deleteRow,
  loadOptimSpaces,
  selectOptimSpace,
  triggerAlert,
  updateOptimSpaceData,
  updateSpinner
} from './optim-space-picker.actions';
import { optimSpaceSelection } from 'src/app/core/store/application/application.actions';
import { OptimSpaceModel } from 'src/app/core/models/OptimSpaceModel';

@Injectable()
export class OptimSpacePickerEffect extends ComponentStore<OptimSpacePickerState> {
  constructor(
    private readonly optimSpaceSvc: OptimSpacesService,
    private readonly actions$: Actions
  ) {
    super(initialDataPickerState);
  }

  fireSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectOptimSpace),
      switchMap(({ id, dest }) =>
        of(updateSpinner({ status: true }), optimSpaceSelection({ optimSpaceId: id, dest }))
      )
    )
  );

  fireDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteOptimSpace, deleteOptimSpaces),
      switchMap(({ id }) => {
        const ids = Array.isArray(id) ? id : [id];
        return merge(
          ...ids.map((id) =>
            this.optimSpaceSvc.deleteOptimSpace$(id).pipe(
              mapTo(id),
              catchError((error) => throwError(error))
            )
          )
        ).pipe(
          toArray(),
          switchMap((deletedIds) => {
            const successMsg = `Removed instance(s) #${deletedIds.join(', ')}.`;
            return of(
              deleteRow({ id }),
              triggerAlert({
                message: {
                  category: 'success',
                  message: successMsg,
                  persistentMessage: false,
                  details: null
                }
              })
            );
          }),
          catchError((err) =>
            of(
              triggerAlert({
                message: {
                  category: 'error',
                  message: `Error deleting instance(s): ${err.message}`,
                  persistentMessage: false,
                  details: null
                }
              })
            )
          )
        );
      })
    )
  );

  fetchOptimSpaces$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOptimSpaces),
      switchMap(
        ({
          appPath,
          version,
          cursor,
          orderBy,
          startDate,
          endDate,
          searchQuery,
          dataSpaceIds
        }) => {
          return this.optimSpaceSvc
            .getApplicationOptimSpace$(
              appPath ?? localStorage.getItem('app_path'),
              version ?? localStorage.getItem('app_version'),
              cursor,
              orderBy,
              startDate,
              endDate,
              searchQuery,
              dataSpaceIds
            )
            .pipe(
              map((result) => {
                const colNames = ['#ID', 'Instance', 'Data Space', 'Created'];
                const rows = [];
                result.optimSpaces.map((optimSpace: OptimSpaceModel) => {
                  rows.push({
                    id: optimSpace.id,
                    name: optimSpace.name,
                    outputs: optimSpace.numOutputs,
                    description: optimSpace.description,
                    createdAt: optimSpace.createdAt,
                    dataSpace: optimSpace.dataSpace.name,
                    initialInputId: optimSpace.initialInputId
                  });
                });
                const newCursor = result.cursor;
                const data: OptimSpacePickerTableState = {
                  colNames: colNames,
                  rows: rows,
                  orderBy,
                  startDate,
                  endDate,
                  searchQuery,
                  dataSpaceIds
                };
                return updateOptimSpaceData({ data, cursor: newCursor });
              }),
              catchError(() =>
                of(
                  triggerAlert({
                    message: {
                      category: 'error',
                      message: 'Cannot load instances.',
                      persistentMessage: false,
                      details: null
                    }
                  })
                )
              )
            );
        }
      )
    );
  });
}
