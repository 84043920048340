import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureRoutingModule } from './feature/feature-routing.module';

const APP_ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/dashboard/user-app-list' }
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, {}), FeatureRoutingModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
