import { Object3D } from 'three';

export class Object3DUtils {
  static rotate(obj3D: Object3D, rotation: Rotation): void {
    const { rx, ry, rz } = rotation || {};
    if (rx != undefined) {
      obj3D.rotateX(rx);
    }
    if (ry != undefined) {
      obj3D.rotateY(ry);
    }
    if (rz != undefined) {
      obj3D.rotateZ(rz);
    }
  }
}

type Rotation = {
  rx?: number;
  ry?: number;
  rz?: number;
};
