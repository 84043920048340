import { createSelector } from '@ngrx/store';
import { RootState } from '..';

export const selectNotifications = (state: RootState) => state.notifications;
export const selectNotificationsList = createSelector(
  selectNotifications,
  ({ notifications }) => notifications
);
export const selectNbOfUnreadNotifications = createSelector(
  selectNotificationsList,
  (dict) => {
    let nbUnread = 0;
    for (const id in dict) {
      if (!dict[id].read) {
        nbUnread += 1;
      }
    }
    return nbUnread;
  }
);
