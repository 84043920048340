import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as SocketIo from 'socket.io-client';
import { BASE_WEBSOCKET_URL } from './core/utils/injectors';
import { Store } from '@ngrx/store';
import { RootState } from './core/store';
import { selectAccessToken } from './core/store/auth/auth.selectors';

// See example from https://github.com/avatsaev/angular-ngrx-socket-frontend.
// doc of socket.io: https://socket.io/docs/v4/client-socket-instance/

// The user has a private room (https://socket.io/docs/v4/rooms/) to communicate
// with the backend.

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  socket: SocketIo.Socket;
  connected$ = new BehaviorSubject<boolean>(false);

  private shouldBeConnected = false;

  constructor(
    @Inject(BASE_WEBSOCKET_URL) private readonly apiWebSocketUrl: string,
    private readonly store: Store<RootState>
  ) {
    this.store.select(selectAccessToken).subscribe((accessToken) => {
      if (this.shouldBeConnected && accessToken) {
        this.connect(accessToken);
      }
    });
  }

  connect(accessToken: string) {
    this.shouldBeConnected = true;
    if (this.socket?.connected) {
      return;
    }

    this.socket = SocketIo.io(this.apiWebSocketUrl, {
      auth: {
        token: accessToken
      },
      // - Transport socket must be set to 'websocket' to avoid
      // multiple http-polling connections when multiple GBE replicas
      // are running behind the load-balancer.
      // - The http-polling causes the socket client to be disconnected every-time
      // until it figures-out that the connection can be upgraded to websocket.
      // - Sometimes the http-polling is required because the server-side
      // does not support websocket, however, it is not the case for GBE
      transports: ['websocket']
    });
    this.socket.on('connect', () => this.connected$.next(true));
    this.socket.on('disconnect', () => this.connected$.next(false));
    return;
  }

  disconnect() {
    this.shouldBeConnected = false;
    this.socket.disconnect();
    this.connected$.next(false);
  }

  emit(event: string, data?: any) {
    this.socket.emit(event, data);
  }

  listen(event: string): Observable<any> {
    return new Observable((observer) => {
      this.socket.on(event, (data) => {
        observer.next(data);
      });
      return () => this.socket.off(event);
    });
  }

  ignore(event: string) {
    this.socket.off(event);
  }

  ignoreAll(event: string) {
    this.socket.removeAllListeners();
  }
}
