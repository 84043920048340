import { Params } from '@angular/router';
import { getSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { RootState } from '..';

export const selectFeature = (state: RootState) => state.router;

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl // select the current url
} = getSelectors(selectFeature);

export const selectRouteNestedParams = createSelector(selectFeature, (router) => {
  let currentRoute = router?.state?.root;
  let params: Params = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params
    };
  }
  return params;
});
