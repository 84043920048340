import { Component, HostListener, OnInit } from '@angular/core';
import { DefaultUrlSerializer } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootState } from './core/store';
import { navigationPopState } from './core/store/application/application.actions';
import { HeartbeatService } from './core/services/heartbeat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private readonly store: Store<RootState>,
    private heartbeatService: HeartbeatService
  ) {}

  ngOnInit(): void {
    this.heartbeatService.startHeartbeat();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState($event) {
    const { pathname, search } = $event.target.location;
    let url = pathname;
    if (search != null) {
      url = `${url}${search}`;
    }
    const dus = new DefaultUrlSerializer();
    const dest = dus.parse(url);
    this.store.dispatch(navigationPopState({ dest }));
  }
}
