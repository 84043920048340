export class HeatMapUtils {
  private static referenceValues: { min: number; max: number };

  static initReferenceValues(heatValues: number[]): void {
    let minValue = 0;
    let maxValue = 1;

    heatValues.forEach((value) => {
      if (value < minValue) {
        minValue = value;
      }
      if (value > maxValue) {
        maxValue = value;
      }
    });
    HeatMapUtils.referenceValues = { min: minValue, max: maxValue };
  }

  static getHeatMapColor(heatValue: number): string {
    const value = HeatMapUtils.getNormalizedValue(heatValue);

    const darkGreen = [0, 128, 0];
    const yellow = [255, 200, 0];
    const red = [166, 16, 0];
    let color: number[];
    if (value < 0.5) {
      color = [
        Math.round((0.5 - value) * 2 * darkGreen[0] + value * 2 * yellow[0]),
        Math.round((0.5 - value) * 2 * darkGreen[1] + value * 2 * yellow[1]),
        Math.round((0.5 - value) * 2 * darkGreen[2] + value * 2 * yellow[2])
      ];
    } else {
      color = [
        Math.round((1 - value) * 2 * yellow[0] + (value - 0.5) * 2 * red[0]),
        Math.round((1 - value) * 2 * yellow[1] + (value - 0.5) * 2 * red[1]),
        Math.round((1 - value) * 2 * yellow[2] + (value - 0.5) * 2 * red[2])
      ];
    }
    const hex =
      '#' +
      ((1 << 24) + (color[0] << 16) + (color[1] << 8) + color[2]).toString(16).slice(1);
    return hex;
  }

  private static getNormalizedValue(value: number): number {
    // Calculate the percentage between minValue and maxValue
    const range = HeatMapUtils.referenceValues.max - HeatMapUtils.referenceValues.min;
    return (value - HeatMapUtils.referenceValues.min) / range;
  }
}
