import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { ButtonComponent } from './components/button/button.component';
import { PhosphorIconComponent } from './components/phosphor-icons/phosphor-icon.component';
import { PhosphorIconsService } from './services/phosphor-icons.service'; // to remove
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { DropdownMenuDirective } from './components/dropdown-menu/dropdown-menu.directive';
import { DropdownOriginDirective } from './components/dropdown-menu/dropdown-origin.directive';
import { ItemSelectorComponent } from './components/item-selector/item-selector.component';
import { JsonEditorComponent } from './components/json-editor/json-editor.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalIcon } from './components/modal/modal-icon.pipe';
import { ModalIconClass } from './components/modal/modal-icon-class.pipe';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { TimeSincePipe } from './pipes/time-since.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { AlertColor } from './components/alert/alert-color.pipe';
import { AlertComponent } from './components/alert/alert.component';
import { AlertIcon } from './components/alert/alert-icon.pipe';
import { ColorOfIndex } from './pipes/color.pipe';
import { TaskTooltipPipe } from './pipes/task-tooltip.pipe';
import { DragAndDropDirective } from './directives/drag-and-drop/drag-and-drop.directive';
import { FormsModule } from '@angular/forms';
import { CaptionComponent } from './components/caption/caption.component';
import { CompassComponent } from './components/compass/compass.component';
import { OutSideClickDirective } from './directives/outside-click/outside-click.directive';

@NgModule({
  declarations: [
    SpinnerComponent,
    ButtonComponent,
    PhosphorIconComponent,
    TooltipDirective,
    DropdownMenuComponent,
    DropdownMenuDirective,
    DropdownOriginDirective,
    ItemSelectorComponent,
    JsonEditorComponent,
    ModalComponent,
    ModalIcon,
    ModalIconClass,
    ToggleButtonComponent,
    TimeSincePipe,
    CustomDatePipe,
    AlertColor,
    AlertIcon,
    AlertComponent,
    ColorOfIndex,
    TaskTooltipPipe,
    DragAndDropDirective,
    OutSideClickDirective,
    CaptionComponent,
    CompassComponent
  ],
  imports: [CommonModule, RouterModule, FormsModule],
  exports: [
    SpinnerComponent,
    ButtonComponent,
    PhosphorIconComponent,
    TooltipDirective,
    DropdownMenuComponent,
    DropdownMenuDirective,
    DropdownOriginDirective,
    ItemSelectorComponent,
    JsonEditorComponent,
    ModalComponent,
    ModalIcon,
    ModalIconClass,
    ToggleButtonComponent,
    TimeSincePipe,
    CustomDatePipe,
    AlertColor,
    AlertIcon,
    AlertComponent,
    ColorOfIndex,
    TaskTooltipPipe,
    DragAndDropDirective,
    OutSideClickDirective,
    CaptionComponent,
    CompassComponent
  ]
})
export class SharedModule {
  constructor(private readonly phosphorIconsService: PhosphorIconsService) {
    this.phosphorIconsService.registerIcons(this.phosphorIconsService.iconInUse);
  }
}
