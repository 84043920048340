import { Dimensions } from 'src/app/core/models/WarehouseModel';

export class DimensionsUtils {
  static changeYZ(dimensions: Dimensions): Dimensions {
    return {
      x_size: dimensions.x_size,
      y_size: dimensions.z_size,
      z_size: dimensions.y_size
    };
  }
}
