import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent {
  @Input() disabled: boolean;
  @Input() checked: boolean;
  @Input() label: string;
  @Output() toggled: EventEmitter<boolean> = new EventEmitter();

  onChange() {
    this.checked = !this.checked;
    this.toggled.emit(this.checked);
  }
}
