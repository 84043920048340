import { Pipe, PipeTransform } from '@angular/core';
import { MessageCategory } from 'src/app/core/models/MessageCategory';

@Pipe({ name: 'modalIconClass' })
export class ModalIconClass implements PipeTransform {
  transform(category: MessageCategory): string {
    switch (category) {
      case 'info':
        return 'fill-primary-500 stroke-primary-900 w-12';
      case 'warning':
        return 'fill-yellow-500 stroke-yellow-900 w-12';
      case 'error':
        return 'fill-red-500 stroke-red-900 w-12';
      case 'success':
        return 'fill-secondary-500 stroke-secondary-900 w-12';
    }
    return 'secondary';
  }
}
