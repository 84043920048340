import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  DataPickerState,
  DataPickerTableState,
  initialDataPickerState
} from './param-picker.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mapTo, merge, of, switchMap, throwError, toArray } from 'rxjs';
import {
  deleteParam,
  deleteParams,
  deleteRow,
  loadParams,
  selectParam,
  triggerAlert,
  updateParamData,
  updateSpinner
} from './param-picker.actions';
import { parameterSelection } from 'src/app/core/store/application/application.actions';
import { ApplicationsService } from 'src/app/core/services/applications.service';
import { ParameterItemModel } from 'src/app/core/models/ParametersModel';
import { ParamsService } from 'src/app/core/services/params.service';

@Injectable()
export class ParamPickerEffect extends ComponentStore<DataPickerState> {
  constructor(
    private readonly applicationSvc: ApplicationsService,
    private readonly paramsService: ParamsService,
    private readonly actions$: Actions
  ) {
    super(initialDataPickerState);
  }

  fireSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectParam),
      switchMap(({ id, dest }) =>
        of(updateSpinner({ status: true }), parameterSelection({ parameterId: id, dest }))
      )
    )
  );

  fireDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteParam, deleteParams),
      switchMap(({ id }) => {
        const ids = Array.isArray(id) ? id : [id];
        return merge(
          ...ids.map((id) =>
            this.paramsService.deleteParameter$(id).pipe(
              mapTo(id),
              catchError((error) => throwError(error))
            )
          )
        ).pipe(
          toArray(),
          switchMap((deletedIds) => {
            const successMsg = `Removed instance(s) #${deletedIds.join(', ')}.`;
            return of(
              deleteRow({ id }),
              triggerAlert({
                message: {
                  category: 'success',
                  message: successMsg,
                  persistentMessage: false,
                  details: null
                }
              })
            );
          }),
          catchError((err) =>
            of(
              triggerAlert({
                message: {
                  category: 'error',
                  message: `Error deleting instance(s): ${err.message}`,
                  persistentMessage: false,
                  details: null
                }
              })
            )
          )
        );
      })
    )
  );

  fetchParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadParams),
      switchMap(({ appPath, version, optimSpaceId, cursor }) => {
        return this.paramsService
          .getForOptimSpace$(appPath, version, optimSpaceId, cursor)
          .pipe(
            map((response) => {
              const colNames = ['#ID', 'Name'];
              const rows: ParameterItemModel[] = response.parameters ?? [];
              const data: DataPickerTableState = { colNames: colNames, rows: rows };
              return updateParamData({ data, cursor: response.cursor });
            }),
            catchError(() =>
              of(
                triggerAlert({
                  message: {
                    category: 'error',
                    message: 'Cannot load instances.',
                    persistentMessage: false,
                    details: null
                  }
                })
              )
            )
          );
      })
    );
  });
}
