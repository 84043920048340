import { createAction, props } from '@ngrx/store';
import { MessageCategory } from 'src/app/core/models/MessageCategory';
import { User } from 'src/app/core/models/User';

export const login = createAction(
  '[Login Page] Login',
  props<{ mail: string; password: string; routeDest: string | null }>()
);

export const updateUserInfo = createAction(
  '[User Profile] Update user info',
  props<{ userId: number; email: string; full_name: string; routeDest: string | null }>()
);
export const updateUserInfoSuccess = createAction(
  '[User Profile] Update user info success',
  props<{ email: string; full_name: string }>()
);

export const updateUserInfoFailure = createAction(
  '[User Profile] Update user info failure',
  props<{ category: MessageCategory; message: string }>()
);

export const updateUserInfoComplete = createAction(
  '[update user] Update User Info Complete'
);

export const loginAtInit = createAction('[Init Galia] Login');

export const loginDetails = createAction(
  '[Auth] Authenticate token',
  props<{
    token: string;
    refresh_token?: string;
    expires_timestamp?: number;
    routeDest?: string;
  }>()
);

export const updateTokens = createAction(
  '[Auth] Update Tokens',
  props<{ token: string; refresh_token: string; expires_timestamp: number }>()
);

export const loginComplete = createAction(
  '[Auth API] Login Complete',
  props<{ user: User; token: string }>()
);
export const loginFailure = createAction(
  '[Auth API] Login Failure',
  props<{ category: MessageCategory; message: string }>()
);

export const eraseAuthAlertMessage = createAction('[Login Page] Erase alert message.');

export const logout = createAction('[* Page] Logout');
export const logoutComplete = createAction('[* Page] Logout Complete');
