<!-- <ng-container *ngIf="{ stock: stock$ | async } as ctx"> -->
<ng-container>
  <ng-container *ngIf="stock">
    <div (click)="close()" class="cursor-pointer flex flex-row-reverse pt-2 pb-2">
      <phosphor-icon
        class="stroke-red-900 fill-red-500 w-4 mr-4"
        name="x-circle"
      ></phosphor-icon>
    </div>
    <div class="rouded-md bg-white pl-5 pr-5 pt-1 pb-5 flex- flex-auto overflow-auto">
      <table class="w-full">
        <thead
          class="text-xs text-neutral-700 uppercase bg-neutral-50 border-l-3 border-l-neutral-50 flex items-center h-10"
        >
          <tr class="grid grid-cols-8 ml-1 w-full">
            <th class="col-span-1 text-center">#</th>
            <th class="col-span-3 text-left">Article</th>
            <th class="col-span-2 text-right">Quantity</th>
            <th class="col-span-2 text-right pr-4">Volume</th>
          </tr>
        </thead>
        <tbody class="text-xs">
          <tr
            *ngFor="let data of stock; index as i"
            class="bg-white border-b hover:shadow-inner hover:bg-neutral-50 text-neutral-800 flex w-full items-center border-l-white border-l-4 hover:border-l-neutral-100 grid grid-cols-8"
          >
            <td class="col-span-1 text-center">{{ i + 1 }}</td>
            <td class="col-span-3 text-left">{{ data.article }}</td>
            <td class="col-span-2 text-right">{{ data.quantity }}</td>
            <td class="col-span-2 text-right pr-4">{{ data.volume }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
  <ng-container *ngIf="!stock">
    <p data-testid="list-not-available">Empty stock</p>
  </ng-container>
</ng-container>
