<!-- Toggle B -->
<div class="items-center justify-center">
  <label class="flex items-center cursor-pointer">
    <!-- toggle -->
    <div class="relative">
      <!-- input -->
      <input
        type="checkbox"
        class="sr-only"
        [checked]="checked"
        [disabled]="disabled"
        (change)="onChange()"
      />
      <!-- line -->
      <div class="line block bg-neutral-500 shadow-inner w-8 h-5 rounded-full"></div>
      <!-- dot -->
      <div
        class="dot absolute left-1 top-1 bg-neutral-50 w-3 h-3 rounded-full transition"
      ></div>
    </div>
    <!-- label -->
    <div class="ml-3 text-neutral-600 font-normal">
      {{ label }}
    </div>
  </label>
</div>
