import { NewDisplayLabel, NewVisuData } from 'src/app/core/models/Visu';
import {
  Dimensions,
  Point3D,
  Route,
  RoutePoint,
  WarehouseAisle,
  WarehousePoint,
  WarehousePointType,
  WarehouseService
} from 'src/app/core/models/WarehouseModel';
import { PointUtils } from '../utils/point-utils';
import { YZTranslator } from '../utils/yz-translator';
import { DimensionsUtils } from '../utils/dimensions-utils';

export class WarehouseDAO {
  constructor(private readonly visu: NewVisuData) {}

  getWarehousePoint(warehousePointIndex: number): WarehousePoint {
    const warehousePoint = this.visu.warehouse_model.warehouse_points[warehousePointIndex];
    const objProps = YZTranslator.createChangedYZ(
      warehousePoint.position,
      warehousePoint.dimensions
    );
    objProps.centerYPosition();
    const position = objProps.getPoint();
    const dimensions = objProps.getDimensions();
    return {
      ...warehousePoint,
      position,
      dimensions
    };
  }

  getContainerCoords(): { min: Point3D; max: Point3D } {
    const min = PointUtils.changeYZ(this.visu.warehouse_model.min_coordinates);
    const max = PointUtils.changeYZ(this.visu.warehouse_model.max_coordinates);
    return {
      min,
      max
    };
  }

  getCopyOfInitialStock(warehousePointIndex: number): { [key: number]: [number, number] } {
    return JSON.parse(
      JSON.stringify(
        this.visu.warehouse_model.warehouse_points[warehousePointIndex].initial_stock
      )
    );
  }

  getAppLabesById(appElementId: string): NewDisplayLabel[] {
    return this.visu.app_elem_labels.by_id[appElementId];
  }

  getNumWarehousePoints(): number {
    return this.visu.warehouse_model.warehouse_points.length;
  }

  getRawWarehousePointsByType(type: WarehousePointType): WarehousePoint[] {
    return this.visu.warehouse_model.warehouse_points.filter(
      (point) => point.type === type
    );
  }

  getRawWarehousePoint(warehousePointIndex: number): WarehousePoint {
    return this.visu.warehouse_model.warehouse_points[warehousePointIndex];
  }

  getPickerDimensions(): Dimensions {
    const dimensions = this.visu.warehouse_model.picker_dimensions ?? {
      x_size: 50,
      y_size: 50,
      z_size: 50
    };
    return DimensionsUtils.changeYZ(dimensions);
  }

  getNumRoutes(): number {
    return this.visu.warehouse_model.routes.length;
  }

  getRawRoute(routeIndex: number): Route {
    return this.visu.warehouse_model.routes[routeIndex];
  }

  getRouteSize(routeIndex: number): number {
    return this.visu.warehouse_model.routes[routeIndex].route_points.length;
  }

  getRoutePoint(routeIndex: number, pointIndex: number): RoutePoint {
    const routePoint =
      this.visu.warehouse_model.routes[routeIndex].route_points[pointIndex];

    return {
      time_s: routePoint.time_s,
      point: PointUtils.changeYZ(routePoint.point),
      action: routePoint.action,
      service_id: routePoint.service_id
    };
  }

  getService(warehouseServiceIndex: number): WarehouseService {
    // -1 because services_id and warehouse_point_id start at 1
    const service = this.visu.warehouse_model.services[warehouseServiceIndex - 1];
    return {
      ...service,
      warehouse_point_id: service.warehouse_point_id - 1
    };
  }

  getAisles(): WarehouseAisle[] {
    return (this.visu.warehouse_model?.aisles ?? []).map((aisle) => {
      return {
        ...aisle,
        start: PointUtils.changeYZ(aisle.start),
        finish: PointUtils.changeYZ(aisle.finish)
      };
    });
  }

  getMaxTime(): number {
    return this.visu.warehouse_model.routes
      .flatMap((route) => route.route_points)
      .map((routePoint) => routePoint.time_s)
      .reduce((time1, time2) => Math.max(time1, time2), 0);
  }

  getArticleName(articleId: number): string {
    // -1 because julia is 1 based
    return this.visu.warehouse_model.articles[articleId - 1];
  }

  getCategoriesNames(categoriesId: number[]): string[] {
    return categoriesId.map((categoryId) => this.getCategoryName(categoryId));
  }

  getCategoryName(categoryId: number): string {
    // -1 because julia is 1 based
    return this.visu.warehouse_model.categories[categoryId - 1];
  }

  getAllCategories(): string[] {
    return this.visu.warehouse_model.categories;
  }
}
