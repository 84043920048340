import { WarehouseEventBus } from '../models/warehouse-event-bus';
import { WarehouseTimelineEvent } from '../models/warehouse-events';

export class WarehouseTimeline {
  private static instance: WarehouseTimeline;

  // tracks the current frame
  private frame: number;

  // It's used to avoid notify frames that not exist
  private lastFrame: number;

  // tracks the current time
  private currentTime: number;

  // Always points to the last time the timeline was updated, used to calculate the delta
  private lastTime: number;

  private isPlaying: boolean;

  // Controls the animation speed
  private speed: number;

  private constructor() {
    this.speed = 1;
    this.reset();
  }

  static getInstance(): WarehouseTimeline {
    if (!WarehouseTimeline.instance) {
      WarehouseTimeline.instance = new WarehouseTimeline();
    }
    return WarehouseTimeline.instance;
  }

  update(time: number): void {
    const lastTimeBackup = this.lastTime;
    this.lastTime = time;

    if (!this.isPlaying) {
      return;
    }

    if (this.currentTime === undefined) {
      this.currentTime = 0;
      this.frame = 0;
      this.notify();
      return;
    }

    let deltaTime = (time - lastTimeBackup) * this.speed;

    this.currentTime += deltaTime;
    this.frame = parseInt(String(this.currentTime / 1000), 10);

    if (this.frame > this.lastFrame) {
      this.stop();
      return;
    }
    this.notify();
  }

  goToFrame(frame: number): void {
    this.frame = frame > this.lastFrame ? this.lastFrame : frame;
    this.currentTime = this.frame * 1000;
    this.notify();
  }

  play(): void {
    this.isPlaying = true;
  }

  stop(): void {
    this.isPlaying = false;
  }

  reset(): void {
    this.goToFrame(0);
    this.isPlaying = false;
  }

  setLastFrame(frame: number): void {
    this.lastFrame = frame;
  }

  getLastFrame(): number {
    return this.lastFrame;
  }

  setSpeed(speed: number): void {
    this.speed = speed;
  }

  private notify(): void {
    WarehouseEventBus.getEventBus().emit('updateTime', {
      timeSeconds: this.currentTime / 1000,
      frame: this.frame
    } as WarehouseTimelineEvent);
  }
}
