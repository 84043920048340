const { green } = require('tailwindcss/colors');
const colors = require('tailwindcss/colors');

module.exports = {
  future: {
    // removeDeprecatedGapUtilities: true,
    // purgeLayersByDefault: true,
  },
  content: ['./src/**/*.{html,ts}'],
  theme: {
    colors: {
      primary: {
        50: '#EBF8FF',
        100: '#D1EEFC',
        200: '#A7D8F0',
        300: '#7CC1E4',
        400: '#55AAD4',
        500: '#3994C1',
        600: '#2D83AE',
        700: '#1D6F98',
        800: '#166086',
        900: '#0B4F71'
      },
      secondary: {
        50: '#E3F9E5',
        100: '#C1EAC5',
        200: '#A3D9A5',
        300: '#7BC47F',
        400: '#57AE5B',
        500: '#3F9142',
        600: '#2F8132',
        700: '#207227',
        800: '#0E5814',
        900: '#05400A'
      },
      neutral: colors.gray,
      black: colors.black,
      white: colors.white,
      transparent: 'transparent',
      red: colors.red,
      orange: colors.orange,
      amber: colors.amber,
      lime: colors.lime,
      green: colors.green,
      teal: colors.teal,
      sky: colors.sky,
      indigo: colors.indigo,
      violet: colors.violet,
      fuchsia: colors.fuchsia,
      rose: colors.rose,
      yellow: colors.yellow,
      blue: colors.blue,
      emerald: colors.emerald,
      cyan: colors.cyan,
      slate: colors.slate,
      zinc: colors.zinc
    },
    borderColor: (theme) => ({
      ...theme('colors'),
      DEFAULT: theme('colors.neutral.200', 'currentColor')
    }),
    backgroundColor: (theme) => ({
      ...theme('colors')
    }),
    extend: {
      lineHeight: {
        12: '3rem'
      },
      maxHeight: {
        '3/4': '75%'
      },
      scale: {
        125: '1.25'
      },
      gridColumn: {
        'span-7': 'span 7 / span 7'
      },
      gridRow: {
        'span-7': 'span 7 / span 7'
      },
      gridTemplateColumns: {
        7: 'repeat(7, minmax(0, 1fr))'
      },
      gridTemplateRows: {
        7: 'repeat(7, minmax(0, 1fr))'
      }
    },
    zIndex: {
      0: 0,
      10: 10,
      900: 900,
      1000: 1000,
      2000: 2000,
      3000: 3000,
      5000: 5000,
      15000: 15000
    },
    minHeight: {
      0: '0',
      '1/4': '25%',
      '1/2': '50%',
      '3/4': '75%',
      full: '100%'
    }
  },
  plugins: [require('@tailwindcss/forms')]
};
