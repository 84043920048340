<div
  class="absolute top-0 right-0 bg-neutral-50 bg-opacity-90 h-full w-60 overflow-y-scroll z-1000 px-4 py-4"
>
  <div>
    <h1 class="font-extrabold tracking-wide text-neutral-700 text-xl mb-4">Captions</h1>

    <!-- Directional Path -->
    <div
      class="bg-neutral-50 bg-opacity-30 z-1000 py-2 pointer-events-auto flex justify-start"
      placement="top"
    >
      <div class="inline-flex items-start justify-between w-full">
        <div class="inline-flex justify-between items-center w-1/5">
          <img
            style="width: 28px"
            src="/assets/images/warehouse/directed-path.png"
            alt="Directional path"
          />
        </div>
        <div class="flex w-4/5 ml-2">
          <p class="text-sm">Directed path</p>
        </div>
      </div>
    </div>
    <!-- Bidirected Path -->
    <div
      class="bg-neutral-50 bg-opacity-30 z-1000 py-2 pointer-events-auto flex justify-start"
      placement="top"
    >
      <div class="inline-flex items-start justify-between w-full">
        <div class="inline-flex justify-between items-center w-1/5">
          <img
            style="width: 28px"
            src="/assets/images/warehouse/bidirected-path.png"
            alt="Directional path"
          />
        </div>
        <div class="flex w-4/5 ml-2">
          <p class="text-sm">Bidirected path</p>
        </div>
      </div>
    </div>

    <!-- Walls -->
    <div
      class="bg-neutral-50 bg-opacity-30 z-1000 py-2 pointer-events-auto flex justify-start"
      placement="top"
    >
      <div class="inline-flex items-start justify-between w-full">
        <div class="inline-flex justify-between items-center w-1/5">
          <svg
            height="22px"
            width="22px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 58 58"
            xml:space="preserve"
            fill="#000000"
            stroke="#000000"
            stroke-width="0.23199999999999998"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke="#CCCCCC"
              stroke-width="0.348"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <polygon style="fill: #768089" points="29,58 3,45 3,13 29,26 "></polygon>
                <polygon style="fill: #768089" points="29,58 55,45 55,13 29,26 "></polygon>
                <polygon style="fill: #768089" points="3,13 28,0 55,13 29,26 "></polygon>
              </g>
            </g>
          </svg>
        </div>
        <div class="flex w-4/5 ml-2">
          <p class="text-sm">Walls</p>
        </div>
      </div>
    </div>

    <!-- Drop off point -->
    <div
      class="bg-neutral-50 bg-opacity-30 z-1000 py-2 pointer-events-auto flex justify-start"
      placement="top"
    >
      <div class="inline-flex items-start justify-between w-full">
        <div class="inline-flex justify-between items-center w-1/5">
          <svg
            height="22px"
            width="22px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 58 58"
            xml:space="preserve"
            fill="#000000"
            stroke="#000000"
            stroke-width="0.23199999999999998"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke="#CCCCCC"
              stroke-width="0.348"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <polygon style="fill: #ffffff" points="29,58 3,45 3,13 29,26 "></polygon>
                <polygon style="fill: #ffffff" points="29,58 55,45 55,13 29,26 "></polygon>
                <polygon style="fill: #ffffff" points="3,13 28,0 55,13 29,26 "></polygon>
              </g>
            </g>
          </svg>
        </div>
        <div class="flex w-4/5 ml-2">
          <p class="text-sm">Drop off point</p>
        </div>
      </div>
    </div>

    <!-- Pickers -->
    <div
      class="bg-neutral-50 bg-opacity-30 z-1000 py-2 pointer-events-auto flex items-center justify-start"
      placement="top"
    >
      <div class="inline-flex items-start justify-between w-full">
        <div class="inline-flex justify-between items-center w-1/5">
          <svg
            height="22px"
            width="22px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 58 58"
            xml:space="preserve"
            fill="#000000"
            stroke="#000000"
            stroke-width="0.29000000000000004"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <path
                  style="fill: #0097b2"
                  d="M29,8C13.536,8,1,6.209,1,4v50c0,2.209,12.536,4,28,4s28-1.791,28-4V4C57,6.209,44.464,8,29,8z"
                ></path>
                <ellipse style="fill: #3b8ba5" cx="29" cy="4" rx="28" ry="4"></ellipse>
                <ellipse style="fill: #0097b2" cx="29" cy="54" rx="28" ry="4"></ellipse>
              </g>
            </g>
          </svg>
        </div>
        <div class="flex w-4/5 ml-2">
          <p class="text-sm">Pickers</p>
        </div>
      </div>
    </div>

    <ng-container *ngIf="showHeatMapCaptions">
      <h2 class="font-extrabold tracking-wide text-neutral-700 mt-4 mb-4">Heat map</h2>

      <!-- Heat map - low frequency -->
      <div
        class="bg-neutral-50 bg-opacity-30 z-1000 py-2 pointer-events-auto flex justify-start"
        placement="top"
      >
        <div class="inline-flex items-start justify-between w-full">
          <div class="inline-flex justify-between items-center w-1/5">
            <svg
              width="22px"
              height="22px"
              viewBox="0 0 5.8208332 5.8208333"
              version="1.1"
              id="svg1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:svg="http://www.w3.org/2000/svg"
            >
              <defs id="defs1" />
              <g id="layer1">
                <g
                  id="g1"
                  style="
                    fill: #008000;
                    stroke: #1a1a1a;
                    stroke-width: 0.3;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    paint-order: markers stroke fill;
                  "
                  transform="matrix(0.50526249,0,0,0.50526249,-25.409521,-54.301486)"
                >
                  <path
                    d="m 56.953336,110.85263 -6.616671,3.35724 v -1.76224 l 6.616671,-2.92335 z"
                    style="
                      fill: #008000;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path48"
                  />
                  <path
                    d="m 61.763236,112.33087 v -1.5194 l -4.8099,-1.28719 v 1.32835 z"
                    style="
                      fill: #008000;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path49"
                  />
                  <path
                    d="m 61.763236,112.33087 -6.055721,4.60893 -5.37085,-2.72993 6.616671,-3.35724 z"
                    style="
                      fill: #008000;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path50"
                  />
                  <path
                    d="m 61.763236,110.81147 -6.055721,4.01326 -5.37085,-2.3771 6.616671,-2.92335 z"
                    style="
                      fill: #008000;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path51"
                  />
                  <path
                    d="m 55.707515,116.9398 v -2.11507 l -5.37085,-2.3771 v 1.76224 z"
                    style="
                      fill: #008000;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path52"
                  />
                  <path
                    d="m 61.763236,112.33087 -6.055721,4.60893 v -2.11507 l 6.055721,-4.01326 z"
                    style="
                      fill: #008000;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path53"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="flex w-4/5 ml-2">
            <p class="text-sm">Low frequency</p>
          </div>
        </div>
      </div>

      <!-- Heat map - intermediate frequency -->
      <div
        class="bg-neutral-50 bg-opacity-30 z-1000 py-2 pointer-events-auto flex justify-start"
        placement="top"
      >
        <div class="inline-flex items-start justify-between w-full">
          <div class="inline-flex justify-between items-center w-1/5">
            <svg
              width="22px"
              height="22px"
              viewBox="0 0 5.8208332 5.8208333"
              version="1.1"
              id="svg1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:svg="http://www.w3.org/2000/svg"
            >
              <defs id="defs1" />
              <g id="layer1">
                <g
                  id="g1"
                  style="
                    fill: #ffc800;
                    stroke: #1a1a1a;
                    stroke-width: 0.3;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    paint-order: markers stroke fill;
                  "
                  transform="matrix(0.50526249,0,0,0.50526249,-25.409521,-54.301486)"
                >
                  <path
                    d="m 56.953336,110.85263 -6.616671,3.35724 v -1.76224 l 6.616671,-2.92335 z"
                    style="
                      fill: #ffc800;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path48"
                  />
                  <path
                    d="m 61.763236,112.33087 v -1.5194 l -4.8099,-1.28719 v 1.32835 z"
                    style="
                      fill: #ffc800;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path49"
                  />
                  <path
                    d="m 61.763236,112.33087 -6.055721,4.60893 -5.37085,-2.72993 6.616671,-3.35724 z"
                    style="
                      fill: #ffc800;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path50"
                  />
                  <path
                    d="m 61.763236,110.81147 -6.055721,4.01326 -5.37085,-2.3771 6.616671,-2.92335 z"
                    style="
                      fill: #ffc800;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path51"
                  />
                  <path
                    d="m 55.707515,116.9398 v -2.11507 l -5.37085,-2.3771 v 1.76224 z"
                    style="
                      fill: #ffc800;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path52"
                  />
                  <path
                    d="m 61.763236,112.33087 -6.055721,4.60893 v -2.11507 l 6.055721,-4.01326 z"
                    style="
                      fill: #ffc800;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path53"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="flex w-4/5 ml-2">
            <p class="text-sm">Medium frequency</p>
          </div>
        </div>
      </div>

      <!-- Heat map - high frequency -->
      <div
        class="bg-neutral-50 bg-opacity-30 z-1000 py-2 pointer-events-auto flex justify-start"
        placement="top"
      >
        <div class="inline-flex items-start justify-between w-full">
          <div class="inline-flex justify-between items-center w-1/5">
            <svg
              width="22px"
              height="22px"
              viewBox="0 0 5.8208332 5.8208333"
              version="1.1"
              id="svg1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:svg="http://www.w3.org/2000/svg"
            >
              <defs id="defs1" />
              <g id="layer1">
                <g
                  id="g1"
                  style="
                    fill: #a61000;
                    stroke: #1a1a1a;
                    stroke-width: 0.3;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    paint-order: markers stroke fill;
                  "
                  transform="matrix(0.50526249,0,0,0.50526249,-25.409521,-54.301486)"
                >
                  <path
                    d="m 56.953336,110.85263 -6.616671,3.35724 v -1.76224 l 6.616671,-2.92335 z"
                    style="
                      fill: #a61000;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path48"
                  />
                  <path
                    d="m 61.763236,112.33087 v -1.5194 l -4.8099,-1.28719 v 1.32835 z"
                    style="
                      fill: #a61000;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path49"
                  />
                  <path
                    d="m 61.763236,112.33087 -6.055721,4.60893 -5.37085,-2.72993 6.616671,-3.35724 z"
                    style="
                      fill: #a61000;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path50"
                  />
                  <path
                    d="m 61.763236,110.81147 -6.055721,4.01326 -5.37085,-2.3771 6.616671,-2.92335 z"
                    style="
                      fill: #a61000;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path51"
                  />
                  <path
                    d="m 55.707515,116.9398 v -2.11507 l -5.37085,-2.3771 v 1.76224 z"
                    style="
                      fill: #a61000;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path52"
                  />
                  <path
                    d="m 61.763236,112.33087 -6.055721,4.60893 v -2.11507 l 6.055721,-4.01326 z"
                    style="
                      fill: #a61000;
                      fill-rule: evenodd;
                      stroke: #1a1a1a;
                      stroke-width: 0.0938435;
                      stroke-linejoin: round;
                    "
                    id="path53"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="flex w-4/5 ml-2">
            <p class="text-sm">High frequency</p>
          </div>
        </div>
      </div>

      <!-- Heat map - path low frequency -->
      <div
        class="bg-neutral-50 bg-opacity-30 z-1000 py-2 pointer-events-auto flex justify-start"
        placement="top"
      >
        <div class="inline-flex items-start justify-between w-full">
          <div class="inline-flex justify-between items-center w-1/5">
            <span
              class="mt-2"
              style="height: 3px; width: 22px; background-color: black"
            ></span>
          </div>
          <div class="flex w-4/5 ml-2">
            <p class="text-sm">Path low frequency</p>
          </div>
        </div>
      </div>

      <!-- Heat map - path high frequency -->
      <div
        class="bg-neutral-50 bg-opacity-30 z-1000 py-2 pointer-events-auto flex justify-start"
        placement="top"
      >
        <div class="inline-flex items-start justify-between w-full">
          <div class="inline-flex justify-between items-center w-1/5">
            <span style="height: 18px; width: 22px; background-color: black"></span>
          </div>
          <div class="flex w-4/5 ml-2">
            <p class="text-sm">Path high frequency</p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!showHeatMapCaptions">
      <h2 class="font-extrabold tracking-wide text-neutral-700 mt-4 mb-4">Categories</h2>

      <!-- Heat map - low frequency -->
      <div
        *ngFor="let category of categories"
        class="bg-neutral-50 bg-opacity-30 z-1000 py-2 pointer-events-auto flex justify-start"
        placement="top"
      >
        <div class="inline-flex items-start justify-between w-full">
          <div class="inline-flex justify-between items-center w-1/5">
            <span
              style="height: 18px; width: 22px"
              [style.background-color]="category.color"
            ></span>
          </div>
          <div class="flex w-4/5 ml-2">
            <p class="text-sm">{{ category.name }}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
