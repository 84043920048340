import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, UserMapper, UserProfileV4 } from '../models/User';
import { EndPointV4 } from '../enum/endpoint.enum';
import { map, Observable } from 'rxjs';
import { BASE_API_URL } from '../utils/injectors';
import { ApiTranslatorService } from './apitranslator.service';
import { AuthTokens } from '../models/Tokens';

/**
 * Manage user authentification requests to the API and current user information.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly urlAuth: string;
  private readonly urlRefreshToken: string;
  private readonly urlProfile: string;
  private readonly urlResetPassword: string;
  private readonly urlRequestResetPassword: string;

  private readonly currentRegionName: string;
  private readonly urlUpdateProfile: string;

  /**
   * Information about the authentified user.
   */
  public currentUser$: Observable<User>;

  constructor(
    private readonly http: HttpClient,
    private readonly apiTranslatorService: ApiTranslatorService,

    @Inject(BASE_API_URL) private readonly apiUrl: string
  ) {
    this.urlAuth = `${apiUrl}/${EndPointV4.AUTH}`;
    this.urlRefreshToken = `${apiUrl}/${EndPointV4.REFRESH_TOKEN}`;
    this.urlProfile = `${apiUrl}/${EndPointV4.PROFILE}`;
    this.urlResetPassword = `${apiUrl}/${EndPointV4.PASSWORDS}/reset`;
    this.urlRequestResetPassword = `${apiUrl}/${EndPointV4.PASSWORDS}/request-mail`;

    // TODO: Remove the hardcoded region
    this.currentRegionName = 'France';
    this.urlUpdateProfile = `${apiUrl}/${EndPointV4.USERS}`;
  }

  /**
   * Authentify an user from his e-mail address and his password.
   * @param username e-mail address
   * @param password
   * @returns an access token
   */
  public login(username: string, password: string): Observable<AuthTokens> {
    return this.http.post<AuthTokens>(this.urlAuth, { username, password });
  }

  /**
   * Refreshes the access token and returns a new access and refresh token.
   *
   * @param {string} refreshToken - The refresh token used to generate a new access token.
   * @return {Observable<AuthTokens>} - An observable that emits the new access and refresh token.
   */
  public refreshToken(refreshToken: string): Observable<AuthTokens> {
    return this.http.post<AuthTokens>(this.urlRefreshToken, {
      refresh_token: refreshToken
    });
  }

  /**
   * @returns user profile
   */
  public getUserProfile$(): Observable<User> {
    return this.http
      .get(this.urlProfile)
      .pipe(map((user: UserProfileV4) => UserMapper.toUser(user)));
  }

  /**
   * Update user email and/or fullname.
   * @param email e-mail address
   * @param fullname
   * @returns updated user info
   */
  public updateUserProfile(
    userid: number,
    email: string,
    fullname: string
  ): Observable<User> {
    return this.http
      .put(this.urlUpdateProfile + '/' + userid, { email, fullname })
      .pipe(map((r) => this.apiTranslatorService.modelToClass(User, r)));
  }

  /**
   * Reset the password of an user from a reset token
   * @param token
   * @param password
   * @returns
   */
  public resetUserPassword(token: string, password: string): Observable<any> {
    return this.http.post(this.urlResetPassword, {
      token: token,
      password: password
    });
  }
  /**
   * Reset the password of an user from a reset token
   * @param token
   * @param password
   * @returns
   */
  public requestResetPassword(email: string): Observable<any> {
    return this.http.post(this.urlRequestResetPassword, {
      email: email
    });
  }

  /**
   * Reset the password of a client from a reset token
   * @param token
   * @param password
   * @returns
   */
  resetClientPassword(token: string, password: string): Observable<any> {
    return this.http.post(this.urlResetPassword + 'client/', {
      token: token,
      password: password
    });
  }

  /**
   * TODO : move into the store
   * @returns region name
   */
  public getCurrentRegionName() {
    return this.currentRegionName;
  }
}
