import { createReducer, on } from '@ngrx/store';
import { OptimSpacePickerState, initialDataPickerState } from './optim-space-picker.state';
import {
  clearDataSpaceDropdownSelection,
  deleteRow,
  preSelectOptimSpace,
  triggerAlert,
  triggerDestroy,
  updateCurrentOptimSpaceInUse,
  updateOptimSpace,
  updateOptimSpaceData,
  updateSpinner
} from './optim-space-picker.actions';
import {
  optimSpaceSelectionComplete,
  optimSpaceSelectionFailure
} from 'src/app/core/store/application/application.actions';
import { updateOutputData } from '../../output-picker/store/output-picker.actions';
import { state } from '@angular/animations';

export const optimSpacePickerReducer = createReducer<OptimSpacePickerState>(
  initialDataPickerState,
  on(preSelectOptimSpace, (state, { id }) => {
    const selectedIds = [...state.selectedIds];
    const index = selectedIds.findIndex((rowId) => rowId === id);
    if (index === -1) {
      selectedIds.push(id);
    } else {
      selectedIds.splice(index, 1);
    }
    return {
      ...state,
      selectedIds
    };
  }),

  on(deleteRow, (state, { id }) => {
    const ids = Array.isArray(id) ? id : [id];

    const rows = [...state.data.rows];
    const selectedIds = [...state.selectedIds];

    ids.forEach((deleteId) => {
      const rowPos = rows.findIndex((row) => row.id === deleteId);
      if (rowPos > -1) {
        rows.splice(rowPos, 1);
        const selectIdPos = selectedIds.findIndex((sid) => sid === deleteId);
        if (selectIdPos > -1) {
          selectedIds.splice(selectIdPos, 1);
        }
      }
    });

    if (
      rows.length < state.data.rows.length ||
      selectedIds.length < state.selectedIds.length
    ) {
      return {
        ...state,
        data: {
          ...state.data,
          rows
        },
        selectedIds,
        message: null
      };
    } else {
      return {
        ...state,
        message: {
          category: 'error',
          message: `Cannot remove row(s) #${ids.join(', ')}.`,
          persistentMessage: false,
          details: null
        }
      };
    }
  }),

  on(updateOptimSpaceData, (state, { data, cursor }) => {
    //put the selected optim space at the first place
    let rows = [];
    if (
      state.data &&
      state.data.orderBy === data.orderBy &&
      state.data.startDate === data.startDate &&
      state.data.endDate === data.endDate &&
      state.data.searchQuery === data.searchQuery &&
      (state.data.dataSpaceIds ?? []).join(',') === (data.dataSpaceIds ?? []).join(',')
    ) {
      rows = state.data.rows.concat(data.rows);
    } else {
      rows = [...data.rows];
    }
    return {
      ...state,
      data: {
        ...data,
        rows
      },
      cursor,
      message: null
    };
  }),

  on(clearDataSpaceDropdownSelection, (state) => {
    return {
      ...state,
      data: {
        ...state.data,
        dataSpaceIds: [],
        rows: []
      },
      cursor: null
    };
  }),

  on(updateCurrentOptimSpaceInUse, (state, { currentLoadedOptimSpaceId }) => {
    return {
      ...state,
      currentLoadedOptimSpaceId
    };
  }),

  on(updateSpinner, (state, { status }) => {
    return {
      ...state,
      spinnerSelect: status
    };
  }),

  on(optimSpaceSelectionComplete, (state, { optimSpace }) => {
    return {
      ...state,
      spinnerSelect: false,
      message: {
        category: 'success',
        message: 'Optim Space ' + optimSpace.id + ' has been loaded.',
        persistentMessage: false,
        details: null
      }
    };
  }),

  on(optimSpaceSelectionFailure, (state, { message, details }) => {
    return {
      ...state,
      spinnerSelect: false,
      message: {
        category: 'error',
        message: message,
        persistentMessage: false,
        details: details
      }
    };
  }),

  on(triggerAlert, (state, { message }) => {
    return {
      ...state,
      spinnerSelect: false,
      message: message
    };
  }),

  on(triggerDestroy, (state) => {
    return { ...initialDataPickerState };
  }),

  on(updateOptimSpace, (state, { optimSpace }) => {
    const index = state.data.rows.findIndex((row) => row.id === optimSpace.id);
    const rows = [...state.data.rows];
    rows[index] = { ...rows[index], ...optimSpace };
    return {
      ...state,
      data: {
        ...state.data,
        rows
      },
      message: null
    };
  })
);
