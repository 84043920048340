import { Pipe, PipeTransform } from '@angular/core';
import tailwindConfig from 'tailwind.config.js';
//https://stackoverflow.com/questions/31173738/typescript-getting-error-ts2304-cannot-find-name-require
//require is not defined and as we only use it in one file it is better to use this ugly declare.
declare var require: any;
const resolveConfig = require('tailwindcss/resolveConfig');
// See https://tailwindcss.com/docs/configuration#referencing-in-java-script
const fullConfig = resolveConfig(tailwindConfig);
@Pipe({ name: 'colorOfIndex' })
export class ColorOfIndex implements PipeTransform {
  private _tailwindPalette = [
    //color, shade
    fullConfig.theme.colors.red,
    fullConfig.theme.colors.blue,
    fullConfig.theme.colors.amber,
    fullConfig.theme.colors.violet,
    fullConfig.theme.colors.lime,
    fullConfig.theme.colors.fuchsia,
    fullConfig.theme.colors.emerald,
    fullConfig.theme.colors.slate,
    fullConfig.theme.colors.cyan
  ];

  transform(index: number, color?: string, shade?: string): string {
    if (color != undefined && color !== '') {
      return color;
    }
    if (index < 0) {
      return '#000';
    }
    index = index % this._tailwindPalette.length;
    const shadesOfColor = this._tailwindPalette[index];
    return shadesOfColor[shade] === undefined ? shadesOfColor['500'] : shadesOfColor[shade];
  }
}
