import { WarehouseService } from 'src/app/core/models/WarehouseModel';
import { WarehouseDAO } from '../dao/warehouse.dao';
import { WarehouseEventBus, WarehouseEventType } from './warehouse-event-bus';
import { Observable, Subscriber, Subscription, sampleTime } from 'rxjs';
import {
  WarehouseNotifyStockEvent,
  WarehouseShowStockEvent,
  WarehouseTimelineEvent
} from './warehouse-events';

export class WarehouseStockControl {
  private warehouseServiceMap: Map<number, ServiceInTime[]>;
  private selectedIndex: number = -1;
  private lastSelectIndex: number = -1;
  private lastFrame: number = 0;

  private subscription: Subscription;

  private static instance: WarehouseStockControl;

  static init(warehouseDAO: WarehouseDAO): void {
    WarehouseStockControl.instance = new WarehouseStockControl(warehouseDAO);
  }

  static getInstance(): WarehouseStockControl {
    return WarehouseStockControl.instance;
  }

  private constructor(private readonly warehouseDAO: WarehouseDAO) {
    const numRoutes = this.warehouseDAO.getNumRoutes();
    this.warehouseServiceMap = new Map();
    for (let i = 0; i < numRoutes; i++) {
      const route = this.warehouseDAO.getRawRoute(i);
      for (let j = 0; j < route.route_points.length; j++) {
        const serviceId = route.route_points[j].service_id;
        if (serviceId == 0) {
          continue;
        }

        const warehouseService = this.warehouseDAO.getService(serviceId);
        const time = route.route_points[j].time_s;

        if (this.warehouseServiceMap.has(warehouseService.warehouse_point_id)) {
          const services = this.warehouseServiceMap.get(
            warehouseService.warehouse_point_id
          );
          services.push({ time, service: warehouseService });
        } else {
          this.warehouseServiceMap.set(warehouseService.warehouse_point_id, [
            { time, service: warehouseService }
          ]);
        }
      }
    }

    this.warehouseServiceMap.forEach((services) => {
      services.sort((a, b) => a.time - b.time);
    });
    this.addEvents();
  }

  getStockAtTime(): { notify: boolean; stock: WarehouseStockResult[] } {
    const warehouseId = this.selectedIndex;
    const time = this.lastFrame;

    const initialStock = this.warehouseDAO.getCopyOfInitialStock(warehouseId);
    const services = this.warehouseServiceMap.get(warehouseId) ?? [];
    const stockResult = services
      .filter((service) => service.time <= time)
      .map((service) => service.service.stock_change)
      .reduce((stock1, stock2) => {
        for (const key in stock2) {
          if (stock1.hasOwnProperty(key)) {
            stock1[key][0] += stock2[key][0];
            stock1[key][1] += stock2[key][1];
          } else {
            stock1[key] = [stock2[key][0], stock2[key][1]];
          }
        }
        return stock1;
      }, initialStock);

    const stock = Object.keys(stockResult).map((key) => {
      const articleId = +key;
      return {
        article: this.warehouseDAO.getArticleName(+articleId),
        quantity: stockResult[articleId][0],
        volume: stockResult[articleId][1]
      };
    });
    return { notify: true, stock };
  }

  private addEvents(): void {
    WarehouseEventBus.getEventBus().on('clickWarehousePoint', (index: number) => {
      this.selectedIndex = index;
      const result = this.getStockAtTime();
      WarehouseEventBus.getEventBus().emit('showStock', {
        show: true
      } as WarehouseShowStockEvent);
      this.notifyStock(result.stock);
    });

    let notifier: Subscriber<WarehouseTimelineEvent>;

    this.subscription = new Observable<WarehouseTimelineEvent>((subscriber) => {
      notifier = subscriber;
    })
      .pipe(sampleTime(500))
      .subscribe((data) => {
        // should notify only when the frame changes
        if (
          this.selectedIndex < 0 ||
          (data.frame === this.lastFrame && this.selectedIndex === this.lastSelectIndex)
        ) {
          return;
        }
        this.lastSelectIndex = this.selectedIndex;
        this.lastFrame = data.frame;
        const result = this.getStockAtTime();
        if (result.notify) {
          this.notifyStock(result.stock);
        }
      });

    WarehouseEventBus.getEventBus().on('updateTime', (data: WarehouseTimelineEvent) => {
      notifier.next(data);
    });

    WarehouseEventBus.getEventBus().on('dispose', () => {
      this.subscription.unsubscribe();
    });
  }

  private notifyStock(
    stock: WarehouseStockResult[],
    eventName: WarehouseEventType = 'notifyStock'
  ): void {
    if (this.selectedIndex >= 0) {
      WarehouseEventBus.getEventBus().emit(eventName, {
        id: this.selectedIndex,
        stock
      } as WarehouseNotifyStockEvent);
    }
  }
}

export interface ServiceInTime {
  time: number;
  service: WarehouseService;
}

export interface WarehouseStockResult {
  article: string;
  quantity: number;
  volume: number;
}

export interface ShelfStockResult {
  id: number;
  quantity: number;
  volume: number;
  capacity: number;
  category: string;
}
