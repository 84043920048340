import { createAction, props } from '@ngrx/store';
import { MessageState, OptimSpacePickerTableState } from './optim-space-picker.state';
import { UrlTree } from '@angular/router';
import { UpdateOptimSpace } from 'src/app/core/models/UpdateOptimSpace';

export const updateOptimSpaceData = createAction(
  '[OptimSpace picker] update optim space data.',
  props<{ data: OptimSpacePickerTableState; cursor: number }>()
);

export const updateCurrentOptimSpaceInUse = createAction(
  '[OptimSpace picker] update current optim space in use.',
  props<{ currentLoadedOptimSpaceId: number }>()
);

export const selectOptimSpace = createAction(
  '[OptimSpace picker] select optim space.',
  props<{ id: number; dest: UrlTree }>()
);

export const preSelectOptimSpace = createAction(
  '[OptimSpace picker] select a row in the optim space table, pre selection.',
  props<{ id: number }>()
);

export const deleteOptimSpace = createAction(
  '[OptimSpace picker] delete optim space.',
  props<{ id: number }>()
);

export const deleteOptimSpaces = createAction(
  '[OptimSpace picker] delete optim spaces.',
  props<{ id: number[] }>()
);

export const deleteRow = createAction(
  '[OptimSpace picker] delete row in the table.',
  props<{ id: number | number[] }>()
);

export const loadOptimSpaces = createAction(
  '[OptimSpace picker] load optim space list.',
  props<{
    appPath: string;
    version: string;
    cursor: number;
    orderBy: string;
    startDate: string;
    endDate: string;
    searchQuery: string;
    dataSpaceIds?: number[];
  }>()
);

export const updateSpinner = createAction(
  '[OptimSpace picker] update spinner.',
  props<{ status: boolean }>()
);

export const triggerAlert = createAction(
  '[OptimSpace picker] trigger alert message.',
  props<{ message: MessageState }>()
);

export const triggerDestroy = createAction('[OptimSpace picker] destroy store.');

export const clearDataSpaceDropdownSelection = createAction(
  '[OptimSpace picker] clear data space dropdown selection.'
);

export const updateOptimSpace = createAction(
  '[OptimSpace picker] update optim space.',
  props<{ optimSpace: UpdateOptimSpace }>()
);
