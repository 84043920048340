import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MessageCategory } from 'src/app/core/models/MessageCategory';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent {
  @Input() category: MessageCategory;
  @Input() details: { action: string; title: string; htmlMessage: string } | null = null;
  @Input() showModal = false;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges): void {
    //hide body scroll bar to only show the modal scroll bar
    if (changes.showModel !== undefined) {
      document.documentElement.style.overflow = changes.showModal.currentValue
        ? 'hidden'
        : 'auto';
    }
  }

  onClickout(event) {
    this.showModal = false;
    this.closeModal.emit(false);
  }

  onClose() {
    this.showModal = false;
    this.closeModal.emit(false);
  }
}
