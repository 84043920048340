import { TemplateResult, html } from 'lit';

export class HtmlRenderTemplate {
  static getTemplate(data: {
    autoRotateCss: string;
    transparentCss: string;
    toggleTransparent: () => void;
    toggleAutoRotate: () => void;
    positionCameraTop: () => void;
    positionCameraFront: () => void;
    positionCameraSide: () => void;
  }): TemplateResult<1> {
    return html`<div id="packviz-menu">
      <a
        class="has_tooltip ${data.transparentCss}"
        data-text="Toggle item transparency"
        @click=${data.toggleTransparent}
        @touchend=${data.toggleTransparent}
      >
        <svg class="cube_icon cube_icon_transparent" viewBox="0 0 60 60">
          <!-- back side -->
          <polyline
            points="10 10, 40 10, 40 40, 10 40, 10 10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <!-- bottom side -->
          <polyline
            points="40 40, 50 50, 20 50, 10 40, 40 40"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <!-- left side -->
          <polyline
            points="20 20, 10 10, 10 40, 20 50, 20, 20"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <!-- top side -->
          <polyline
            points="20 20, 50 20, 40 10, 10 10, 20 20"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <!-- front side -->
          <polyline
            points="20 20, 50 20, 50 50, 20 50, 20 20"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
      <a
        class="has_tooltip ${data.autoRotateCss}"
        data-text="Toggle auto rotate"
        @click=${data.toggleAutoRotate}
        @touchend=${data.toggleAutoRotate}
      >
        <svg class="cube_icon" viewBox="0 0 60 60">
          <!-- left side -->
          <polyline
            points="20 20, 10 10, 10 40, 20 50, 20, 20"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="cube_side_inactive"
          />
          <!-- top side -->
          <polyline
            points="20 20, 50 20, 40 10, 10 10, 20 20"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="cube_side_inactive"
          />
          <!-- front side -->
          <polyline
            points="20 20, 50 20, 50 50, 20 50, 20 20"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="cube_side_inactive"
          />
          <path
            d="M 5 30
                        A 7 4 0 0 0 55 30"
            stroke="#444"
            stroke-width="3"
            stroke-linecap="round"
            fill="none"
          />
        </svg>
      </a>
      <a
        class="has_tooltip"
        data-text="go to top view"
        @click=${data.positionCameraTop}
        @touchend=${data.positionCameraTop}
      >
        <svg class="cube_icon" viewBox="0 0 60 60">
          <!-- left side -->
          <polyline
            points="20 20, 10 10, 10 40, 20 50, 20, 20"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="cube_side_inactive"
          />
          <!-- top side -->
          <polyline
            points="20 20, 50 20, 40 10, 10 10, 20 20"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="cube_side_active"
          />
          <!-- front side -->
          <polyline
            points="20 20, 50 20, 50 50, 20 50, 20 20"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="cube_side_inactive"
          />
        </svg>
      </a>
      <a
        class="has_tooltip"
        data-text="go to front view"
        @click=${data.positionCameraFront}
        @touchend=${data.positionCameraFront}
      >
        <svg class="cube_icon" viewBox="0 0 60 60">
          <!-- left side -->
          <polyline
            points="20 20, 10 10, 10 40, 20 50, 20, 20"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="cube_side_inactive"
          />
          <!-- top side -->
          <polyline
            points="20 20, 50 20, 40 10, 10 10, 20 20"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="cube_side_inactive"
          />
          <!-- front side -->
          <polyline
            points="20 20, 50 20, 50 50, 20 50, 20 20"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="cube_side_active"
          />
        </svg>
      </a>
      <a
        class="has_tooltip"
        data-text="go to side view"
        @click=${data.positionCameraSide}
        @touchend=${data.positionCameraSide}
      >
        <svg class="cube_icon" viewBox="0 0 60 60">
          <!-- left side -->
          <polyline
            points="20 20, 10 10, 10 40, 20 50, 20, 20"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="cube_side_active"
          />
          <!-- top side -->
          <polyline
            points="20 20, 50 20, 40 10, 10 10, 20 20"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="cube_side_inactive"
          />
          <!-- front side -->
          <polyline
            points="20 20, 50 20, 50 50, 20 50, 20 20"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="cube_side_inactive"
          />
        </svg>
      </a>
    </div>`;
  }
}
