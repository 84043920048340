import { Vector3 } from 'three';
import { Point3D } from 'src/app/core/models/WarehouseModel';

export class Line {
  constructor(readonly point1: Point3D, readonly point2: Point3D) {}

  getXZDirection(): Direction {
    if (this.point2.x > this.point1.x) {
      return Direction.RIGHT;
    } else if (this.point2.x < this.point1.x) {
      return Direction.LEFT;
    } else if (this.point2.z > this.point1.z) {
      return Direction.UP;
    } else if (this.point2.z < this.point1.z) {
      return Direction.DOWN;
    } else {
      return Direction.STOPED;
    }
  }

  getDistance(): number {
    return new Vector3(this.point1.x, this.point1.y, this.point1.z).distanceTo(
      new Vector3(this.point2.x, this.point2.y, this.point2.z)
    );
  }

  getMiddlePoint(): Point3D {
    return {
      x: (this.point1.x + this.point2.x) / 2,
      y: (this.point1.y + this.point2.y) / 2,
      z: (this.point1.z + this.point2.z) / 2
    };
  }

  calculateXZAngle(): number {
    const dx = this.point2.x - this.point1.x;
    const dz = this.point2.z - this.point1.z;
    return Math.atan2(dz, dx);
  }
}

export enum Direction {
  UP = 'UP',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  STOPED = 'STOPED'
}
