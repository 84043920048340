<ng-template
  [ngIf]="!dynamicMode && (optimSpaceId$ | async) && (canOptimize$ | async)"
  [ngIfElse]="parameterPickerUnactive"
>
  <a
    class="block px-4 py-2 text-sm leading-5 text-neutral-700 hover:bg-neutral-100 hover:text-neutral-900 focus:outline-none focus:bg-neutral-100 focus:text-neutral-900 cursor-pointer whitespace-nowrap"
    routerLink="param-picker"
    routerLinkActive="active"
    queryParamsHandling="merge"
  >
    Select parameter set
  </a>
</ng-template>
<ng-template #parameterPickerUnactive>
  <a
    class="block px-4 py-2 text-sm leading-5 text-neutral-500 whitespace-nowrap"
    title="Select parameter set (Select an instance first)"
  >
    Select parameter set
  </a>
</ng-template>

<ng-template
  [ngIf]="!dynamicMode && (optimSpaceId$ | async) && (canOptimize$ | async)"
  [ngIfElse]="editParameterDisabled"
>
  <a
    [routerLink]="['parameters']"
    queryParamsHandling="merge"
    class="block px-4 py-2 text-sm leading-5 text-neutral-700 hover:bg-neutral-100 hover:text-neutral-900 focus:outline-none focus:bg-neutral-100 focus:text-neutral-900 cursor-pointer whitespace-nowrap"
    >View current config. / Upload new
  </a>
</ng-template>
<ng-template #editParameterDisabled>
  <a class="block px-4 py-2 text-sm leading-5 text-neutral-500 whitespace-nowrap"
    >View current config. / Upload new
  </a>
</ng-template>

<a
  *ngIf="(user$ | async)?.can_manage_applications"
  [routerLink]="['map-provider']"
  queryParamsHandling="merge"
  class="block px-4 py-2 text-sm leading-5 text-neutral-700 hover:bg-neutral-100 hover:text-neutral-900 focus:outline-none focus:bg-neutral-100 focus:text-neutral-900 cursor-pointer whitespace-nowrap"
>
  Select map provider
</a>
