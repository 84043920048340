import * as THREE from 'three';
import { InteractionManager } from 'three.interactive';

export class InteractionManagerSingleton {
  static interactiveManager: InteractionManager;

  static init(renderer: THREE.WebGLRenderer, camera: THREE.Camera): void {
    InteractionManagerSingleton.interactiveManager = new InteractionManager(
      renderer,
      camera,
      renderer.domElement,
      undefined
    );
  }
  static getInstance(): InteractionManager {
    return InteractionManagerSingleton.interactiveManager;
  }
}
