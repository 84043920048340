import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { BASE_API_URL } from '../utils/injectors';
import { ENDPOINT, EndPointV4 } from '../enum/endpoint.enum';
import { PageDto } from '../models/Application';
import { UserForList, UserForListV4, UserMapper } from '../models/User';
import {
  DataSpaceMapper,
  DataSpaceModel,
  DataSpacePermissions,
  GetDataSpaceActionsResponseV4
} from '../models/DataSpaceModel';
import { ApiTranslatorService } from './apitranslator.service';
import { BusinessUnitService } from './businessunit.service';

@Injectable({
  providedIn: 'root'
})
export class DataSpaceService {
  private readonly urlUsers: string;
  private readonly urlDataSpaces: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(BASE_API_URL) private readonly apiUrl: string,
    private readonly apiTranslatorService: ApiTranslatorService,
    private readonly businessUnitService: BusinessUnitService
  ) {
    this.urlUsers = `${apiUrl}/${EndPointV4.USERS}`;
    this.urlDataSpaces = `${apiUrl}/${EndPointV4.DATASPACES}`;
  }

  public getAllPermissions$(
    dataSpaceId: number,
    businessUnitId?: number
  ): Observable<DataSpacePermissions[]> {
    return forkJoin([
      this.http.get<GetDataSpaceActionsResponseV4>(
        `${this.urlDataSpaces}/${dataSpaceId}/actions`
      ),
      this.businessUnitService.getAllPermissions$(businessUnitId)
    ]).pipe(
      map(([dataSpaceActions, businessUnitPermissions]) => {
        return DataSpaceMapper.mapActionsToDataSpacePermissions(
          dataSpaceActions.actions,
          dataSpaceId,
          businessUnitPermissions.permissions
        );
      })
    );
  }

  public getPermissionsById(
    dataSpaceId: number,
    applicationPath: string
  ): Observable<DataSpacePermissions> {
    return this.getAllPermissions$(dataSpaceId).pipe(
      map((dataSpacePermissions) => {
        return dataSpacePermissions.find(
          (dataSpacePermission) => dataSpacePermission.appPath === applicationPath
        );
      })
    );
  }

  //Retrieve all users of the user's userGroup
  public getUsersOfDataSpace$(): Observable<{ users: UserForList[]; cursor: number }> {
    return this.http.get<PageDto<UserForListV4, number>>(this.urlUsers).pipe(
      map((result) => {
        return {
          users: result.result.map((u) => UserMapper.toUserForList(u)),
          cursor: result.cursor_next_page
        };
      })
    );
  }

  public getDataSpaces$(
    search: string,
    cursor?: number
  ): Observable<{ dataSpaces: DataSpaceModel[]; cursor: number }> {
    const params = {
      take: 25,
      ...(search && { search }),
      ...(cursor && { cursor })
    };
    const httpParams = new HttpParams({ fromObject: params });
    return this.http
      .get<PageDto<DataSpaceModel, number>>(this.urlDataSpaces, { params: httpParams })
      .pipe(
        map((result) => {
          return {
            dataSpaces: result.result.map((r) =>
              this.apiTranslatorService.modelToClass(DataSpaceModel, r)
            ),
            cursor: result.cursor_next_page
          };
        })
      );
  }
}
