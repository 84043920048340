export enum MarkerType {
  BASE = 0,
  SQUARE = 1,
  CUBE = 2,
  DEFAULT = 3,
  TRAVEL = 4,
  PLUS = 5,
  MINUS = 6,
  RELAY = 7
}
