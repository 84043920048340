import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subject, takeUntil, timer } from 'rxjs';
import { MessageCategory } from 'src/app/core/models/MessageCategory';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  animations: [
    trigger('fadeInOut', [
      // Animation for the alert block.
      state('open', style({ opacity: 1 })),
      state('closed', style({ opacity: 0, height: '0px' })),
      transition('open <=> closed', animate('0.1s linear'))
    ]),
    trigger('progress', [
      state('full', style({ width: '100%' })),
      state('empty', style({ width: '0%' })),
      transition('full => empty', animate('5s linear')),
      transition('empty => full', animate('0.00005s linear'))
    ])
  ]
})
export class AlertComponent implements OnChanges {
  private readonly destroy$ = new Subject<void>();

  private _isOpen = true;
  isProgressBarFull = true;

  @Input() autoHide = true;
  @Input() cancel?: string = null;
  @Input() category?: MessageCategory;
  @Input() details?: { action: string; title: string; htmlMessage: string };
  @Input() message = '';

  @Output() closed = new EventEmitter<void>();

  @HostBinding('@fadeInOut') get fadeInOut() {
    return this._isOpen ? 'open' : 'closed';
  }

  @Input() set isOpen(value: boolean) {
    this._isOpen = value;
  }

  get isOpen() {
    return this._isOpen;
  }

  showModal = false;

  ngOnChanges(changes: SimpleChanges) {
    const messageChanges = changes.message;
    if (messageChanges.previousValue !== messageChanges.currentValue) {
      this.isProgressBarFull = true;
      timer(10)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.autoHide) {
            this.isProgressBarFull = false;
            setTimeout(() => (this._isOpen = false), 5000);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClose() {
    this._isOpen = false;
  }

  close(event) {
    if (event.toState === 'closed') {
      this.closed.emit();
    }
  }

  onClickShowDetails() {
    this.showModal = true;
  }

  onCloseModal(event: boolean) {
    this.showModal = event;
  }
}
