import { UrlTree } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { PhosphorIconComponent } from 'src/app/shared/components/phosphor-icons/phosphor-icon.component';
import { NotificationAction } from './notifications.reducers';

export const newNotification = createAction(
  '[Notification] new',
  props<{
    color: string;
    icon: PhosphorIconComponent['name'];
    message: string;
    read: boolean;
    actions?: Array<{ message: string; action: NotificationAction }>;
  }>()
);
export const markNotificationAsRead = createAction(
  '[Notification] mark as read',
  props<{ id: number }>()
);
export const clickShowSolution = createAction(
  '[Notification menu] Click show solution',
  props<{ dest: UrlTree }>()
);
