import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { Task } from 'src/app/core/models/Timeline';

@Pipe({ name: 'taskTooltip' })
export class TaskTooltipPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private _locale: string) {}

  transform(task: Task): string {
    let description = `<p class="text-white p-0 text-xs font-light">We cannot display accurate information for this item because it has been
    moved. Please recompute the solution.</p>`;

    if (!task._dragAndDropped) {
      const list = new Array<string>();
      for (const name in task.hoverLabels) {
        const label = `<span class="text-white text-xs font-semibold">${name}</span>`;
        const value = `<span class="text-neutral-100 text-xs font-normal">${task.hoverLabels[name]}</span>`;
        list.push(`<li class="leading-4 pb-[3px]">${label}: ${value}</li>`);
      }
      const datePipe = new DatePipe(this._locale);
      description = `<p class="text-white p-0 text-xs whitespace-nowrap overflow-x-hidden">
        ${datePipe.transform(task.startDate, 'H:mm')} -
        ${datePipe.transform(task.endDate, 'H:mm')}
        </p>
        <ul class="pt-2">${list.join('')}</ul>`;
    }

    return `<div class="px-2 py-1">
        <p class="font-semibold text-white p-0 text-sm whitespace-norap overflow-x-hidden">
            ${task.name}
        </p>
        ${description}
        </div>`;
  }
}
