/**
 * Set of parameters that can be used by the application to optimize the problem.
 * This data structure contains metadata returned by the API and the description
 * of the parameters.
 */
export class ParametersModel {
  id: number = null;
  createdAt: Date = null;

  /**
   * Name of the set of parameters given by the user.
   */
  name: string = null;

  /**
   * Short description of the parameters given by the user.
   */
  description: string = null;

  /**
   * The set of parameters in a javascript object.
   */
  data: Object = {};
}

export interface ParameterItemModel {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly appVersion: string;
  readonly createdAt: Date;
  isFavorite: boolean;
  readonly dataSpace: DataSpace;
  readonly application: Application;
}

export interface ParameterDetailModel extends ParameterItemModel {
  data: any;
}

interface DataSpace {
  readonly id: number;
  readonly name: string;
  readonly description: string;
}

interface Application {
  readonly name: string;
  readonly path: string;
}

export type GetParameterResponseV4 = {
  id: number;
  name: string;
  description: string;
  data: any;
  created_at: Date;
  data_space: {
    id: number;
    name: string;
  };
  application: {
    version: string;
    app_path: string;
  };
};

export class ParameterMapper {
  static toParameterDetailModel(parameter: GetParameterResponseV4): ParameterDetailModel {
    return {
      id: parameter.id,
      name: parameter.name,
      description: parameter.description,
      data: parameter.data,
      createdAt: parameter.created_at,
      dataSpace: {
        id: parameter.data_space.id,
        name: parameter.data_space.name,
        description: undefined
      },
      application: {
        name: parameter.application.app_path,
        path: parameter.application.app_path
      },
      appVersion: parameter.application.version,
      isFavorite: undefined
    };
  }
}
