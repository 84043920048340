import { MessageCategory } from 'src/app/core/models/MessageCategory';

export interface OptimSpacePickerTableState {
  colNames: string[];
  rows: Array<{ id: number; rowData: any[] }>;
  orderBy?: string;
  startDate?: string;
  endDate?: string;
  searchQuery?: string;
  dataSpaceIds?: number[];
}

export const initialDataPickerState: OptimSpacePickerState = {
  selectedIds: [],
  data: { colNames: [], rows: [] },
  currentLoadedOptimSpaceId: 0,
  spinnerSelect: false,
  cursor: -1
};

export interface MessageState {
  category: MessageCategory;
  message: string;
  persistentMessage: boolean;
  details?: { action: string; title: string; htmlMessage: string };
}

export interface OptimSpacePickerState {
  /**
   * Data of the table (head + array of rows).
   */
  data: OptimSpacePickerTableState;

  /**
   * current optimSpace loaded
   */
  currentLoadedOptimSpaceId: number;

  /**
   * Ids of optimSpace/input/output currently selected by the user in the table.
   */
  selectedIds: number[];

  spinnerSelect: boolean;

  message?: MessageState;

  cursor?: number;
}
