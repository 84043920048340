import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input
} from '@angular/core';
import {
  applyStyles,
  arrow,
  computeStyles,
  flip,
  Instance,
  offset,
  Options,
  popperOffsets,
  preventOverflow
} from '@popperjs/core';
import { createPopper } from '@popperjs/core/lib/createPopper';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements AfterViewInit {
  @Input('tooltip') content: string;
  @Input('placement') placement: string = 'auto';
  @Input('clamped') clamped: boolean = false;
  @Input('maxWidth') maxWidth: number | 'parent' | 'auto' = 'auto';

  tooltip: HTMLElement | null = null;

  private _showTooltip: boolean;
  private _popper: Instance;

  constructor(
    public host: ElementRef,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    const hostElem = this.host.nativeElement;
    this._showTooltip = !this.clamped || hostElem.scrollHeight > hostElem.clientHeight; // this comparison is performance killer
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.hide();
    if (this.tooltip) {
      this.document.body.removeChild(this.tooltip);
    }
    if (this._popper) {
      this._popper.destroy();
    }
  }

  @HostListener('focusin')
  @HostListener('mouseenter')
  @HostListener('focus')
  onMouseEnter() {
    if (this._showTooltip) {
      this.show();
    }
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  @HostListener('blur')
  onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  show() {
    if (this._showTooltip && !this.tooltip) {
      this.tooltip = document.createElement('div');
      this.tooltip.setAttribute('class', 'tooltip');
      this.tooltip.innerHTML = `<div class="p-1">${this.content}</div> <div class="arrow" data-popper-arrow></div>`;
      this.document.body.appendChild(this.tooltip);
    }

    if (!this._popper) {
      this._popper = createPopper(
        this.host.nativeElement,
        this.tooltip,
        this._getPopperOptions()
      );
    }

    this.tooltip.setAttribute('data-show', '');
    this._popper.update();
  }

  hide() {
    if (this.tooltip) {
      this.tooltip.removeAttribute('data-show');
    }
  }

  private _getPopperOptions() {
    const maxWidth = {
      name: 'maxWidth',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }) => {
        if (this.maxWidth !== 'auto' && this.maxWidth !== 'parent') {
          state.styles.popper.maxWidth = `${this.maxWidth}px`;
        }
        if (this.maxWidth === 'parent') {
          const w = state.rects.reference.width;
          state.styles.popper.maxWidth = `${w}px`;
        }
      },
      effect: ({ state }) => {
        if (this.maxWidth !== 'auto' && this.maxWidth !== 'parent') {
          state.elements.popper.style.maxWidth = `${this.maxWidth}px`;
        }
        if (this.maxWidth === 'parent') {
          const w = state.elements.reference.offsetWidth;
          state.elements.popper.style.maxWidth = `${w}px`;
        }
      }
    };

    const arrowOffset = {
      ...offset,
      options: {
        offset: [0, 8]
      }
    };

    const arrowPadding = {
      ...arrow,
      options: {
        padding: 5
      }
    };

    return {
      placement: this.placement,
      modifiers: [
        popperOffsets,
        arrowOffset,
        preventOverflow,
        arrowPadding,
        flip,
        computeStyles,
        applyStyles,
        maxWidth
      ]
    } as Options;
  }
}
