import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { EndPointV4 } from '../enum/endpoint.enum';
import { NewVisuData } from '../models/Visu';
import { Observable } from 'rxjs';
import { IdResponseModel } from '../models/IdResponseModel';
import { BASE_API_URL } from '../utils/injectors';
import { map, retry } from 'rxjs/operators';
import {
  DataVisualitionMapper,
  DataVisualization,
  DataVisualizationV4,
  ExportSolution
} from '../models/DataVisualization';
import { ApiTranslatorService } from './apitranslator.service';

@Injectable({
  providedIn: 'root'
})
export class TokenVisualizationService {
  private readonly urlVisualizarionV4: string;

  constructor(
    private readonly http: HttpClient,
    private readonly apiTranslatorService: ApiTranslatorService,
    @Inject(BASE_API_URL) private readonly apiUrl: string
  ) {
    this.urlVisualizarionV4 = `${apiUrl}/${EndPointV4.VISUALIZATIONS}`;
  }

  /**
   * @param visuData
   * @param outputId
   * @returns
   */
  public postTokenVisu$(
    visuData: NewVisuData,
    outputId: number
  ): Observable<IdResponseModel> {
    const data = { ...visuData };
    delete data['id']; // we delete the id we added when retrieving the visu data.
    const body = {
      output_id: outputId,
      data
    };
    return this.http.post<IdResponseModel>(`${this.urlVisualizarionV4}/routes`, body);
  }

  /**
   * @param token
   * @returns
   */
  public getTokenVisu$(token: string) {
    return this.http.get(`${this.urlVisualizarionV4}/routes/${token}`);
  }

  public getVisualization$(token: string): Observable<DataVisualization> {
    return this.http.get<DataVisualizationV4>(`${this.urlVisualizarionV4}/${token}`).pipe(
      map((res) => DataVisualitionMapper.toDataVisualization(res)),
      retry({
        count: 3,
        delay: (error) => {
          if (error?.status === HttpStatusCode.NotFound) {
            throw error;
          }
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(null);
            }, 2000);
          });
        }
      })
    );
  }

  public exportVisualization$(
    dataId: number,
    type: 'input' | 'output'
  ): Observable<ExportSolution> {
    return this.http
      .post(`${this.urlVisualizarionV4}`, {
        data_type: type,
        data_id: dataId
      })
      .pipe(map((result) => this.apiTranslatorService.modelToCamelCase(result)));
  }
}
