/**
 * An update is a modification applied to a state in which the optimSpace finds itself.
 * This data structure contains update metadata returned by the API.
 */
export class InputModel {
  id: number;
  appPath: string;
  createdAt: Date;
  data: any;
  optimSpaceId: number;
  warnings: any[];
}

export type CreateInputResponseV4 = {
  id: number;
  created_at: Date;
  data: any;
  warnings: any[];
  application: {
    version: string;
    app_path: string;
  };
  optim_space: {
    id: number;
    name: string;
    description: string;
  };
  data_space: {
    id: number;
    name: string;
  };
};

export class InputMapper {
  static toIputModel(data: CreateInputResponseV4): InputModel {
    const model = new InputModel();
    model.id = data.id;
    model.appPath = data.application.app_path;
    model.data = data.data;
    model.optimSpaceId = data.optim_space.id;
    model.warnings = data.warnings;
    model.createdAt = data.created_at;
    return model;
  }
}
