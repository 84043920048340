import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { OptimSpaceMenuComponentComponent } from './optimspace-menu-component/optimspace-menu-component.component';
import { OutputMenuComponentComponent } from './output-menu-component/output-menu-component.component';
import { WarehouseModule } from 'src/app/feature/applications/warehouse/warehouse.module';

@NgModule({
  declarations: [
    SidebarComponent,
    OptimSpaceMenuComponentComponent,
    OutputMenuComponentComponent
  ],
  imports: [CommonModule, SharedModule, RouterModule, WarehouseModule],
  exports: [SidebarComponent]
})
export class SidebarModule {}
