import { Injectable } from '@angular/core';
import { camelCase, snakeCase } from 'lodash';

/**
 * This service provides utils functions to convert json properties of the back-end from
 * snake case to camel case.
 * See https://tech.uqido.com/2020/02/27/snake-case-to-camel-case-bring-peace-between-rails-and-javascript/
 */

@Injectable({
  providedIn: 'root'
})
export class ApiTranslatorService {
  constructor() {}

  modelToSnakeCase(model): any {
    if (!model) {
      return;
    }
    if (Array.isArray(model)) {
      return model.map((e) => this.modelToSnakeCase(e));
    }
    if (typeof model !== 'object') {
      return model;
    }
    const apiModel = {};
    for (const key of Object.keys(model)) {
      if (model[key] === null) {
        apiModel[snakeCase(key)] = null;
        continue;
      }
      if (model[key] === undefined) {
        apiModel[snakeCase(key)] = undefined;
        continue;
      }
      if (key == 'data') {
        apiModel[key] = model[key];
      } else if (Array.isArray(model[key])) {
        const array = [];
        for (const item of model[key]) {
          switch (typeof item) {
            case 'string':
              array.push(item);
              break;
            case 'number':
              array.push(item);
              break;
            default:
              array.push(this.modelToSnakeCase(item));
          }
        }
        apiModel[snakeCase(key)] = array;
      } else {
        apiModel[snakeCase(key)] =
          typeof model[key] === 'object' ? this.modelToSnakeCase(model[key]) : model[key];
      }
    }
    return apiModel;
  }

  modelToCamelCase(apiModel): any {
    if (!apiModel) {
      return;
    }
    if (Array.isArray(apiModel)) {
      return apiModel.map((e) => this.modelToCamelCase(e));
    }
    if (typeof apiModel !== 'object') {
      return apiModel;
    }
    const modelData = {};
    for (const key of Object.keys(apiModel)) {
      if (apiModel[key] === null && apiModel[key] === undefined) {
        return;
      }
      if (key == 'data') {
        modelData[key] = apiModel[key];
      } else if (Array.isArray(apiModel[key])) {
        const array = [];
        for (const item of apiModel[key]) {
          switch (typeof item) {
            case 'string':
              array.push(item);
              break;
            case 'number':
              array.push(item);
              break;
            default:
              array.push(this.modelToCamelCase(item));
          }
        }
        modelData[camelCase(key)] = array;
      } else {
        modelData[camelCase(key)] =
          typeof apiModel[key] === 'object'
            ? this.modelToCamelCase(apiModel[key])
            : apiModel[key];
      }
    }
    return modelData;
  }

  modelToClass<T>(type: new (data) => T, apiModel: any): T {
    return this.modelToCamelCase(apiModel) as T;
  }
}
