import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { InteractionManagerSingleton } from '../../feature/applications/warehouse/components/warehouse3d/module/core/interactive-manager-singleton';
import { SceneSpace } from 'src/app/shared/visualization3d/scene-space';

export class CameraControls {
  private renderer: THREE.WebGLRenderer;
  private perspectiveCamera: THREE.PerspectiveCamera;
  private orthoCamera: THREE.OrthographicCamera;
  private activeCamera: THREE.Camera;
  private cameraType: CameraType = 'perspective';
  private controls: OrbitControls;
  private space: SceneSpace;
  private centerPosition: THREE.Vector3;
  private scene: THREE.Object3D;
  private objectToFitConfig: {
    object: THREE.Object3D;
    fitOffset?: number;
  };

  // position camera functions
  positionCameraTop: () => Promise<void>; // position camera to the top
  positionCameraFront: () => Promise<void>; // position camera to the front
  positionCameraSide: () => Promise<void>; // position camera to the side
  resetControls: () => void;

  constructor(scene: THREE.Object3D, renderer: THREE.WebGLRenderer, space: SceneSpace) {
    this.scene = scene;
    this.renderer = renderer;
    this.space = space;
  }

  initCameras(width: number, height: number, camerasConfig: CamerasConfiguration): void {
    const aspectRatio = width / height;
    const maxDistance = Math.max(this.space.xSize, this.space.ySize, this.space.zSize);
    const far = maxDistance * 3;
    this.perspectiveCamera = this.createPerspectiveCamera(
      camerasConfig?.perspective,
      aspectRatio,
      far
    );
    this.activeCamera = this.perspectiveCamera;

    this.orthoCamera = this.createOrthographicCamerta(
      camerasConfig?.orthographic,
      width,
      height,
      maxDistance * 1.5
    );

    // OrbitControl
    this.createOrtbitControls(this.activeCamera, camerasConfig);

    // Camera key coordinates
    this.initPerspeciteveCameraPositions(this.perspectiveCamera, camerasConfig);

    // Configure ortho camera position
    this.orthoCamera.position.set(
      this.centerPosition.x,
      maxDistance * 1.5,
      this.centerPosition.z
    );
  }

  changeCameras(cameraType: CameraType): void {
    this.cameraType = cameraType;
    if (this.cameraType === 'perspective') {
      this.activePerspectiveCamera();
    } else {
      this.activateOrthographicCamera();
    }
    this.controls.update();
  }

  resize(entries: ResizeObserverEntry[]): void {
    for (let entry of entries) {
      this.resizeCamera(entry);

      const { width, height } = entry.contentRect;
      this.renderer.setSize(width, height);
    }
  }

  setObjectToFitOnScene(objectToFit: THREE.Object3D, fitOffset = 1.2): void {
    this.objectToFitConfig = {
      object: objectToFit,
      fitOffset
    };
  }

  private resizeCamera(entry: ResizeObserverEntry): void {
    const { width, height } = entry.contentRect;
    // update the scene and canvas size
    this.perspectiveCamera.aspect = width / height;
    this.perspectiveCamera.updateProjectionMatrix();

    this.orthoCamera.left = width / -2;
    this.orthoCamera.right = width / 2;
    this.orthoCamera.top = height / 2;
    this.orthoCamera.bottom = height / -2;
    this.orthoCamera.updateProjectionMatrix();
  }

  getActiveCamera(): THREE.Camera {
    return this.activeCamera;
  }

  getOrbitControls(): OrbitControls {
    return this.controls;
  }

  update(): void {
    this.controls.update();
  }

  private createPerspectiveCamera(
    config: PerspectiveCameraConfiguration,
    aspectRatio: number,
    defaultFar: number
  ): THREE.PerspectiveCamera {
    return new THREE.PerspectiveCamera(
      config?.fov ?? 75,
      aspectRatio,
      config?.near ?? 0.1,
      config?.far ?? defaultFar
    );
  }

  private createOrthographicCamerta(
    config: OrthographicCameraConfiguration,
    width: number,
    height: number,
    defaultFar: number
  ): THREE.OrthographicCamera {
    return new THREE.OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      config?.near ?? 1,
      config?.far ?? defaultFar
    );
  }

  private createOrtbitControls(
    camera: THREE.Camera,
    camerasConfig: CamerasConfiguration
  ): void {
    this.controls?.dispose();
    this.controls = new OrbitControls(camera, this.renderer.domElement);
    if (camerasConfig?.configureOrbitControls) {
      camerasConfig.configureOrbitControls(this.controls);
    } else {
      this.controls.listenToKeyEvents(<HTMLElement>(<unknown>window));
      this.controls.enablePan = true;
      this.controls.panSpeed = 1;
      this.controls.zoomSpeed = 1.5;
      this.controls.screenSpacePanning = false;
      this.controls.maxPolarAngle = 90 * (Math.PI / 180);
      this.controls.zoomToCursor = true;
      this.controls.minDistance = 1;
      const maxDistance = Math.max(this.space.xSize, this.space.ySize, this.space.zSize);
      this.controls.maxDistance = maxDistance * 1.5;
      this.controls.keyPanSpeed = 70.0;
    }
    this.resetControls = () => {
      this.controls.target = new THREE.Vector3(
        this.space.xSize / 2,
        this.space.ySize / 2,
        this.space.zSize / 2
      );
    };
    this.resetControls();
  }

  private initPerspeciteveCameraPositions(
    camera: THREE.PerspectiveCamera,
    camerasConfig: CamerasConfiguration
  ): void {
    const cameraPositions = camerasConfig.cameraPositions;

    camera.position.set(
      cameraPositions.initial.x,
      cameraPositions.initial.y,
      cameraPositions.initial.z
    );
    camera.rotation.set(
      cameraPositions.initial?.rx ?? 0,
      cameraPositions.initial?.ry ?? 0,
      cameraPositions.initial?.rz ?? 0
    );

    this.scene.add(camera);

    this.centerPosition = new THREE.Vector3(this.space.xSize / 2, 0, this.space.zSize / 2);
    camera.lookAt(this.centerPosition);

    // camera callbacks
    this.positionCameraFront = this.cameraCallbackFactory(
      cameraPositions.front,
      camerasConfig
    );
    this.positionCameraTop = this.cameraCallbackFactory(cameraPositions.top, camerasConfig);
    this.positionCameraSide = this.cameraCallbackFactory(
      cameraPositions.side,
      camerasConfig
    );
  }
  fitCameraToSelection(): void {
    if (!this.objectToFitConfig) {
      return;
    }
    const size = new THREE.Vector3();
    const center = new THREE.Vector3();
    const box = new THREE.Box3();

    box.makeEmpty();
    box.expandByObject(this.objectToFitConfig.object);

    box.getSize(size);
    box.getCenter(center);

    const maxSize = Math.max(size.x, size.y, size.z);
    const fitHeightDistance =
      maxSize / (2 * Math.atan((Math.PI * this.perspectiveCamera.fov) / 360));
    const fitWidthDistance = fitHeightDistance / this.perspectiveCamera.aspect;
    const distance =
      this.objectToFitConfig.fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

    const direction = this.controls.target
      .clone()
      .sub(this.perspectiveCamera.position)
      .normalize()
      .multiplyScalar(distance);

    this.controls.maxDistance = distance * 10;
    this.controls.target.copy(center);

    this.perspectiveCamera.near = distance / 100;
    this.perspectiveCamera.far = distance * 100;
    this.perspectiveCamera.updateProjectionMatrix();
    this.perspectiveCamera.position.copy(this.controls.target).sub(direction);
    this.controls.update();
  }

  private cameraCallbackFactory(
    finalPosition: CameraPosition,
    camerasConfig: CamerasConfiguration
  ): () => Promise<void> {
    return () => {
      return new Promise((resolve) => {
        const currentCam = this.activeCamera;

        const { rx, ry, rz } = finalPosition;
        const hasRotation = [rx, ry, rz].some((val) => val != undefined);
        const lastPosition = {
          ...finalPosition,
          rx: rx ?? 0,
          ry: ry ?? 0,
          rz: rz ?? 0
        };
        const coordsInitial = {
          x: currentCam.position.x,
          y: currentCam.position.y,
          z: currentCam.position.z,
          rx: currentCam.rotation.x,
          ry: currentCam.rotation.y,
          rz: currentCam.rotation.z
        };
        this.resetControls();
        new TWEEN.Tween(coordsInitial)
          .to(lastPosition)
          .easing(camerasConfig?.cameraChangeAnimation ?? TWEEN.Easing.Sinusoidal.Out)
          .onUpdate((coords) => {
            currentCam.position.set(coords.x, coords.y, coords.z);
            if (hasRotation) {
              currentCam.rotation.set(coords.rx, coords.ry, coords.rz);
            }
          })
          .onStart(() => {
            this.controls.enabled = false;
          })
          .onComplete(() => {
            this.controls.enabled = true;
            this.fitCameraToSelection();
            resolve();
          })
          .start();
      });
    };
  }

  private activePerspectiveCamera(): void {
    const oldY = this.activeCamera.position.y;
    this.perspectiveCamera.position.copy(this.orthoCamera.position);
    this.perspectiveCamera.position.y = oldY / this.orthoCamera.zoom;
    this.perspectiveCamera.updateProjectionMatrix();
    this.activeCamera = this.perspectiveCamera;
    this.controls.object = this.perspectiveCamera;
    this.controls.enableRotate = true;
    InteractionManagerSingleton.getInstance().camera = this.perspectiveCamera;
  }

  private activateOrthographicCamera() {
    this.positionCameraTop().then(() => {
      this.orthoCamera.position.copy(this.perspectiveCamera.position);
      const distance = this.perspectiveCamera.position.distanceTo(this.controls.target);
      const halfWidth = this.frustumWidthAtDistance(this.perspectiveCamera, distance) / 2;
      const halfHeight = this.frustumHeightAtDistance(this.perspectiveCamera, distance) / 2;
      this.orthoCamera.top = halfHeight;
      this.orthoCamera.bottom = -halfHeight;
      this.orthoCamera.left = -halfWidth;
      this.orthoCamera.right = halfWidth;
      this.orthoCamera.zoom = 1;
      this.orthoCamera.lookAt(this.controls.target);
      this.orthoCamera.updateProjectionMatrix();
      this.activeCamera = this.orthoCamera;
      this.controls.object = this.orthoCamera;
      this.controls.enableRotate = false;
      InteractionManagerSingleton.getInstance().camera = this.orthoCamera;
    });
  }

  private frustumHeightAtDistance(camera: THREE.PerspectiveCamera, distance: number) {
    const vFov = (camera.fov * Math.PI) / 180;
    return Math.tan(vFov / 2) * distance * 2;
  }

  private frustumWidthAtDistance(camera: THREE.PerspectiveCamera, distance: number) {
    return this.frustumHeightAtDistance(camera, distance) * camera.aspect;
  }
}

type CameraType = 'perspective' | 'orthographic';

export interface CameraPositions {
  front: CameraPosition;
  top: CameraPosition;
  side: CameraPosition;
  initial: CameraPosition;
}

interface CameraPosition {
  x: number;
  y: number;
  z: number;
  rx?: number;
  ry?: number;
  rz?: number;
}

export interface CamerasConfiguration {
  perspective?: PerspectiveCameraConfiguration;
  orthographic?: OrthographicCameraConfiguration;
  cameraPositions: CameraPositions;
  cameraChangeAnimation?: (amount: number) => number;
  configureOrbitControls?: (OrbitControls: OrbitControls) => void;
}

export interface PerspectiveCameraConfiguration {
  fov?: number;
  near?: number;
  far?: number;
}

export interface OrthographicCameraConfiguration {
  near?: number;
  far?: number;
}
