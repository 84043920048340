import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-optimspace-menu-component',
  templateUrl: './optimspace-menu-component.component.html'
})
export class OptimSpaceMenuComponentComponent {
  @Input() optimSpaceId: number;
  @Input() optimSpaceName: string;
  @Input() optimSpaceCreatedAt: Date;
  @Input() optimSpaceDescription: string;
  @Input() sideBarCollapsed: boolean = false;
  @Input() canOptimize: boolean = false;
}
