import { createReducer, on } from '@ngrx/store';
import { openGaliaAppView } from '../application/application.actions';

export interface NavigationState {
  rehydrationDone: boolean;
}

export const initialNavigationState = {
  rehydrationDone: false
};

export const navigationReducer = createReducer(
  initialNavigationState,
  on(openGaliaAppView, (state, _) => {
    return {
      ...state,
      rehydrationDone: true
    };
  })
);
