<ng-template [ngIf]="optimSpaceId" [ngIfElse]="outputPickerUnactive" class="block sidelink">
  <a
    data-cy="output_table"
    class="block sidelink"
    routerLink="output-picker"
    routerLinkActive="active-sidelink"
    queryParamsHandling="merge"
  >
    <div
      *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
      class="inline-flex items-center align-middle"
    >
      <phosphor-icon
        class="stroke-neutral-900 fill-neutral-500 w-4 mr-4"
        name="list"
      ></phosphor-icon>
      <span> Output table </span>
    </div>
    <ng-template #sidebarIsCollapsed>
      <div class="inline-flex items-center align-middle">
        <phosphor-icon
          class="stroke-neutral-900 fill-neutral-500 w-4"
          name="list"
        ></phosphor-icon>
      </div>
    </ng-template>
  </a>
</ng-template>
<ng-template #outputPickerUnactive>
  <a data-cy="output_table" class="block unactive-sidelink">
    <div
      *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
      class="inline-flex items-center align-middle"
    >
      <phosphor-icon
        class="stroke-neutral-400 fill-neutral-100 w-4 mr-4"
        name="list"
      ></phosphor-icon>
      <span> Output table </span>
    </div>
    <ng-template #sidebarIsCollapsed>
      <div class="inline-flex items-center align-middle">
        <phosphor-icon
          class="stroke-neutral-400 fill-neutral-100 w-4"
          name="list"
        ></phosphor-icon>
      </div>
    </ng-template>
  </a>
</ng-template>

<ng-template [ngIf]="optimSpaceId" [ngIfElse]="exportUnactive" class="block sidelink">
  <a
    data-cy="export"
    class="block sidelink"
    [routerLink]="'export'"
    routerLinkActive="active"
    queryParamsHandling="merge"
  >
    <div
      *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
      class="inline-flex items-center align-baseline"
    >
      <phosphor-icon
        class="stroke-neutral-900 fill-neutral-500 w-4 mr-4"
        name="export"
      ></phosphor-icon>
      <span> Export </span>
    </div>
    <ng-template #sidebarIsCollapsed>
      <div class="inline-flex items-center align-middle">
        <phosphor-icon
          class="stroke-neutral-900 fill-neutral-500 w-4"
          name="export"
        ></phosphor-icon>
      </div>
    </ng-template>
  </a>
</ng-template>
<ng-template #exportUnactive>
  <a data-cy="export" class="block unactive-sidelink">
    <div
      *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
      class="inline-flex items-center align-middle"
    >
      <phosphor-icon
        class="stroke-neutral-400 fill-neutral-100 w-4 mr-4"
        name="export"
      ></phosphor-icon>
      <span> Export </span>
    </div>
    <ng-template #sidebarIsCollapsed>
      <div class="inline-flex items-center align-middle">
        <phosphor-icon
          class="stroke-neutral-400 fill-neutral-100 w-4"
          name="export"
        ></phosphor-icon>
      </div>
    </ng-template>
  </a>
</ng-template>
