/**
 * An output is the solution returned by an application for an update of an optimSpace optimized
 * with a given set of parameters.
 * This data structure contains output data returned by the API.
 */
export class OutputModel {
  id: number;
  name: string | null;
  appPath: string;
  optimSpaceId: number;
  inputId: number;
  createdAt: Date;

  /**
   * Application-specific data
   */
  data: any;

  parameterId: number;
  errors?: string[];
  warnings?: string[];
}

export class OutputModelV4 {
  id: number;
  data: any;
  createdAt: Date;
  outputWarnings: any[];
}

export type OutputDetailV4 = {
  id: number;
  name: string | null;
  data: any;
  created_at: Date;
  application: {
    version: string;
    app_path: string;
  };
  data_space: {
    id: number;
    name: string;
  };
  optim_space: {
    id: number;
    name: string;
    description: string;
  };
  parameter: {
    id: 1;
    name: string;
  };
  input: {
    id: number;
  };
  warnings: any[];
  errors: any[];
  author: {
    id: number;
    username: string;
  };
};

export type OutputItemV4 = {
  id: number;
  name: string;
  application: {
    version: string;
    app_path: string;
  };
  created_at: Date;
  author: {
    id: number;
    username: string;
  };
  parameter: {
    id: number;
    name: string;
  };
};

export class OutputMapper {
  static toOutputModel(output: OutputDetailV4): OutputModel {
    const outputModel = new OutputModel();
    outputModel.id = output.id;
    outputModel.name = output.name;
    outputModel.data = output.data;
    outputModel.appPath = output.application.app_path;
    outputModel.inputId = output.input.id;
    outputModel.createdAt = output.created_at;
    outputModel.optimSpaceId = output.optim_space.id;
    outputModel.parameterId = output.parameter.id;
    outputModel.warnings = output.warnings;
    outputModel.errors = output.errors;
    return outputModel;
  }
}
