import * as THREE from 'three';
import { Point3D } from 'src/app/core/models/WarehouseModel';
import { WarehouseDAO } from '../dao/warehouse.dao';
import {
  PlaneProperties,
  PlaneShape
} from '../../../../../../../../shared/visualization3d/shapes/plane-shape';
import { StandardMaterial } from '../../../../../../../../shared/visualization3d/shapes/standard-material';

export class WarehouseWalls {
  constructor(private readonly warehouseDAO: WarehouseDAO) {}

  draw(warehouseGroup: THREE.Group): void {
    const { min, max } = this.warehouseDAO.getContainerCoords();

    const xSize = max.x - min.x;
    const ySize = max.y - min.y;
    const zSize = max.z - min.z;

    const facesPoints = this.calculateCubeFaceMidpoints();
    const material = StandardMaterial.create({
      color: '#d3d3d3',
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.5
    });
    const walls: Partial<PlaneProperties>[] = [
      { position: facesPoints.front, width: xSize, height: ySize },
      { position: facesPoints.back, width: xSize, height: ySize },
      {
        position: facesPoints.left,
        width: zSize,
        height: ySize,
        rotation: { ry: Math.PI / 2 }
      },
      {
        position: facesPoints.right,
        width: zSize,
        height: ySize,
        rotation: { ry: Math.PI / 2 }
      }
    ];
    walls.forEach((wall) => {
      PlaneShape.create(warehouseGroup, {
        ...(wall as PlaneProperties),
        material: material,
        opts: {
          matrixAutoUpdate: false
        }
      });
    });
  }

  private calculateCubeFaceMidpoints(): {
    front: Point3D;
    back: Point3D;
    left: Point3D;
    right: Point3D;
  } {
    const { min, max } = this.warehouseDAO.getContainerCoords();

    const { x: x1, y: y1, z: z1 } = min;
    const { x: x2, y: y2, z: z2 } = max;

    // Calculate midpoint of each face
    const xMid = (x1 + x2) / 2;
    const yMid = (y1 + y2) / 2;
    const zMid = (z1 + z2) / 2;

    // Return the midpoints as an object
    return {
      front: { x: xMid, y: yMid, z: z1 },
      back: { x: xMid, y: yMid, z: z2 },
      left: { x: x1, y: yMid, z: zMid },
      right: { x: x2, y: yMid, z: zMid }
    };
  }
}
