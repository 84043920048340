<div class="w-full h-[60vh]" id="jsoneditor" #jsonEditor>
  <!-- <div
    *ngIf="helpBoxText || helpBoxTitle"
    class="field-helper"
    cdkDrag
    cdkDragBoundary=".w-full"
  >
    <h6 *ngIf="helpBoxTitle" class="uppercase">{{ helpBoxTitle }}</h6>
    <p *ngIf="helpBoxText">{{ helpBoxText }}</p>
  </div> -->
</div>
