import { LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { visuStyle } from './styles';
import { WarehouseScene } from './core/warehouse-scene';
import { NewVisuData } from 'src/app/core/models/Visu';

@customElement('warehouse-visu')
export default class WarehouseVisu extends LitElement {
  // Define scoped styles right with your component, in plain CSS
  static styles = [visuStyle];

  // warehouse to visualize
  @property({ type: Object })
  visuData: NewVisuData;

  scene: WarehouseScene;

  init(): void {
    this.scene = new WarehouseScene(this.visuData);
  }

  // Render the UI as a function of component state
  render(): TemplateResult<1> {
    return this.scene.render();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.scene.createScene(this.renderRoot as ShadowRoot);
  }

  disconnectedCallback() {
    this.scene.disposeTreejsComponents();
  }

  updateScene(visuData: NewVisuData) {
    this.scene.loadWarehouseVisualization(visuData);
  }
}
