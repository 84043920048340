import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlTree } from '@angular/router';
import { tapResponse } from '@ngrx/component-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, forkJoin, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  finalize,
  map,
  mergeMap,
  switchMap,
  tap,
  timeout
} from 'rxjs/operators';
import { ApplicationVersion } from 'src/app/core/models/Application';
import { InputModel } from 'src/app/core/models/InputModel';
import { OptimSpaceModel } from 'src/app/core/models/OptimSpaceModel';
import { OutputModel } from 'src/app/core/models/OutputModel';
import { ParameterItemModel } from 'src/app/core/models/ParametersModel';
import {
  WebSocketResponseMapper,
  WebSocketResponseDto,
  WebSocketResponseV4Dto
} from 'src/app/core/models/WebSocketResponse.dto';
import { ApplicationsService } from 'src/app/core/services/applications.service';
import { InputService } from 'src/app/core/services/input.service';
import { OptimSpacesService } from 'src/app/core/services/optimSpaces.service';
import { OptimizationService } from 'src/app/core/services/optimization.service';
import { OutputService } from 'src/app/core/services/output.service';
import { selectRoute } from 'src/app/core/store/navigation/navigation.selector';
import { Swals } from 'src/app/core/utils/swals';
import { RootState, withLatestFromDeferred } from '..';
import { clickShowSolution, newNotification } from '../notifications/notifications.actions';
import { NotificationAction } from '../notifications/notifications.reducers';
import {
  applicationSelection,
  applicationSelectionComplete,
  applicationSelectionFailure,
  navigationPopState,
  openGaliaAppView,
  optimizeResponseError,
  optimizeResponseNotify,
  optimizeResponseShow,
  optimizeResponseShowError,
  optimizeResponseSuccess,
  optimizeResponseTreatment,
  optimizeResponseWarning,
  optimizeRun,
  optimizeRunFailure,
  optimizeRunSuccess,
  optimSpaceSelection,
  optimSpaceSelectionComplete,
  optimSpaceSelectionFailure,
  optimSpaceUploadComplete,
  optimSpaceUploadCompletion,
  optimSpaceUploadFailure,
  outputSelection,
  outputSelectionComplete,
  outputSelectionFailure,
  parameterSelection,
  parameterSelectionComplete,
  parameterUpdateComplete,
  parameterUpdateFailure,
  parameterUploadComplete,
  primaryAggregatorExport,
  recomputeRun,
  rehydrateGalia,
  rehydrationComplete,
  rehydrationFailure
} from './application.actions';
import {
  filterVisu,
  selectCurEventListeners,
  selectCurOptimSpace,
  selectCurOutputVisuAndOutputId,
  selectForParametersEffect
} from './application.selectors';
import { ApiTranslatorService } from '../../services/apitranslator.service';
import { ParamsService } from '../../services/params.service';
import { TokenVisualizationService } from '../../services/token-visualization.service';
import { DataSpaceService } from '../../services/dataspace.service';
import { DataSpacePermissions } from '../../models/DataSpaceModel';

function extractAppPath(tree: UrlTree) {
  if (
    tree.root.children[PRIMARY_OUTLET] != null &&
    tree.root.children[PRIMARY_OUTLET].segments.length >= 2 &&
    tree.root.children[PRIMARY_OUTLET].segments[0].path === 'application'
  ) {
    return tree.root.children[PRIMARY_OUTLET].segments[1].path;
  }
  return undefined;
}

function extractAppVersion(tree: UrlTree) {
  if (
    tree.root.children[PRIMARY_OUTLET] != null &&
    tree.root.children[PRIMARY_OUTLET].segments.length >= 3 &&
    tree.root.children[PRIMARY_OUTLET].segments[0].path === 'application'
  ) {
    return tree.root.children[PRIMARY_OUTLET].segments[2].path;
  }
  return undefined;
}

/**
 * TODO:
 * - see if we can remove the AggregatorUtil.loadData from the application effects.
 *   It needs a deep understanding of what this object does.
 */
@Injectable()
export class ApplicationEffects {
  private readonly _errorMessageSuffix = {
    TimeoutError: `Looks like the server is taking too long to respond, please try again.
      This can be caused either by poor connectivity or an error with our servers. 
      If the error persists, take a look at our status page (http://status.atoptima.com)
      or contact the support team (support@atoptima.com).`
  };

  constructor(
    private readonly applicationsSvc: ApplicationsService,
    private readonly optimSpaceSvc: OptimSpacesService,
    private readonly inputSvc: InputService,
    private readonly outputSvc: OutputService,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly store: Store<RootState>,
    private readonly optimizationSvc: OptimizationService,
    private readonly apiTranslatorSvc: ApiTranslatorService,
    private readonly swals: Swals,
    private readonly paramsService: ParamsService,
    private readonly tokenVisualizationService: TokenVisualizationService,
    private readonly dataSpaceService: DataSpaceService
  ) {}

  initApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rehydrateGalia),
      switchMap(({ dest }) => of(openGaliaAppView({ dest })))
    )
  );

  rehydrateFromUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openGaliaAppView, navigationPopState, clickShowSolution),
      withLatestFromDeferred(this.store.select(selectCurEventListeners)),
      switchMap(([{ dest }, eventListeners]) => {
        const appPath = extractAppPath(dest);
        const { optimSpaceId, inputId, outputId, parameterId } = dest.queryParams;

        // if there the value [of appPath, OptimSpaceId...] is null, the observable
        // emits the empty object. Otherwise, we request the object to the API.
        const appVersion = extractAppVersion(dest) ?? 'latest';
        const app$ = appPath
          ? this.applicationsSvc.getLatestApplicationVersion$(appPath, appVersion)
          : of(new ApplicationVersion());
        const optimSpace$ =
          optimSpaceId != null
            ? this.optimSpaceSvc.getOptimSpace$(optimSpaceId)
            : of(new OptimSpaceModel());
        const input$ =
          inputId != null ? this.inputSvc.getInput(inputId) : of(new InputModel());
        const inputVisuKpis$ =
          inputId != null ? this.inputSvc.getInputVisuAndKpis(inputId) : of(undefined);
        const output$ =
          outputId != null ? this.outputSvc.getOutput(outputId) : of(new OutputModel());
        const outputVisuKpis$ =
          outputId != null ? this.outputSvc.getOutputVisuAndKpis(outputId) : of(undefined);
        const parameters$ =
          appPath && appVersion && optimSpaceId
            ? this.paramsService.getForOptimSpace$(appPath, appVersion, optimSpaceId)
            : of({ currsor: undefined, parameters: new Array<ParameterItemModel>() });
        return forkJoin({
          application: app$,
          optimSpace: optimSpace$,
          input: input$,
          inputVisuKpis: inputVisuKpis$,
          output: output$,
          outputVisuKpis: outputVisuKpis$,
          parameters: parameters$,
          parameterId: of(parameterId)
        }).pipe(
          timeout(10000),
          switchMap(
            ({
              application,
              optimSpace,
              input,
              inputVisuKpis,
              output,
              outputVisuKpis,
              parameterId
            }) => {
              let favoriteParameterId: Observable<number | undefined> = of(undefined);
              let permissions: Observable<DataSpacePermissions> = of(
                new DataSpacePermissions()
              );
              if (optimSpace.id != null) {
                favoriteParameterId = this.paramsService.getFavoriteParameterId$(
                  optimSpace.appVersion.appPath,
                  optimSpace.appVersion.version,
                  optimSpace.dataSpace.id
                );
                permissions = this.dataSpaceService.getPermissionsById(
                  optimSpace.dataSpace.id,
                  optimSpace.appVersion.appPath
                );
              }
              return forkJoin({
                favoriteParameterId,
                parameterId: of(parameterId),
                application: of(application),
                optimSpace: of(optimSpace),
                input: of(input),
                inputVisuKpis: of(inputVisuKpis),
                output: of(output),
                outputVisuKpis: of(outputVisuKpis),
                permissions
              });
            }
          ),
          map(
            ({
              favoriteParameterId,
              parameterId,
              application,
              optimSpace,
              input,
              inputVisuKpis,
              output,
              outputVisuKpis,
              permissions
            }) => {
              const hasNoOptimSpace = optimSpace.id == null;
              const hasNoInput = input.id == null;
              const hasNoOutput = output.id == null;

              if (optimSpace.id != null) {
                optimSpace.parameter = {
                  favoriteParameterId,
                  parameterId: parameterId ?? favoriteParameterId
                };
                if (optimSpace?.dataSpace != null) {
                  optimSpace.dataSpace.permissions = permissions;
                }
              }

              // make sure objects share the same appPath, OptimSpacesId, and inputId
              // if not empty (and relevant).
              let appPathOk = hasNoOutput || output.appPath === input.appPath;
              appPathOk =
                appPathOk &&
                (hasNoInput || input.appPath === optimSpace.appVersion.appPath);
              appPathOk =
                appPathOk &&
                (hasNoOptimSpace ||
                  optimSpace.appVersion.appPath === application.application.path);

              // no OptimSpacesId in the output
              const optimSpaceIdOk = hasNoInput || input.optimSpaceId === optimSpace.id;

              const inputIdOk = hasNoOutput || output.inputId === input.id;

              if (appPathOk && optimSpaceIdOk && inputIdOk) {
                const inputVisu = inputVisuKpis?.visuData;
                const inputKpis = inputVisuKpis?.inputKpis;
                const outputVisu = outputVisuKpis?.visuData;
                const outputKpis = outputVisuKpis?.outputKpis;

                // We need to avoid multiple listening when the action navigationPopState
                // is triggered.
                const eventListenersSet = new Set<string>(eventListeners);
                if (appPath != null && !eventListenersSet.has(appPath)) {
                  this.optimizationSvc.optimizationListen(appPath);
                  this.optimizationSvc.recomputeListen(appPath);
                }
                return rehydrationComplete({
                  application,
                  optimSpace,
                  input,
                  inputVisu,
                  inputKpis,
                  output,
                  outputVisu,
                  outputKpis
                });
              }
              const message = `Cannot load Galia, the URL may be incorrect.`;
              return rehydrationFailure({ message });
            }
          ),
          tap(() => {
            this.router.navigateByUrl(dest.toString());
          }),
          catchError((error) => {
            const message = `Cannot load Galia, an error occured while fetching data: ${error.message}`;
            return of(rehydrationFailure({ message }));
          })
        );
      })
    )
  );

  redirectToHomepage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rehydrationFailure),
        exhaustMap(() => {
          this.router.navigateByUrl('/');
          return of();
        })
      ),
    { dispatch: false }
  );

  fetchApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(applicationSelection),
      withLatestFromDeferred(this.store.select(selectCurEventListeners)), // events we are listening to
      exhaustMap(([{ appPath, version }, curEventListeners]) => {
        if (appPath == null) {
          const app = new ApplicationVersion();
          return of(applicationSelectionComplete({ app }));
        }

        return forkJoin({
          app: this.applicationsSvc.getLatestApplicationVersion$(appPath, version)
        }).pipe(
          timeout(10000),
          map(({ app }) => {
            if (!curEventListeners.includes(appPath)) {
              this.optimizationSvc.optimizationListen(appPath);
              this.optimizationSvc.recomputeListen(appPath);
            }
            return applicationSelectionComplete({ app });
          }),
          tap((res) =>
            this.router.navigateByUrl(
              `application/${appPath}/${res.app.version}/optimspace-picker`
            )
          ),
          catchError((error) => {
            const message = `Cannot load the chosen application: ${error.message}`;
            return of(applicationSelectionFailure({ message }));
          })
        );
      })
    )
  );

  fetchOptimSpace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optimSpaceSelection),
      withLatestFromDeferred(this.store.select(selectCurOptimSpace)),
      mergeMap(([{ optimSpaceId, dest }, oldOptimSpace]) => {
        if (optimSpaceId == null) {
          const optimSpace = new OptimSpaceModel();
          const input = new InputModel();
          return of(optimSpaceSelectionComplete({ optimSpace, input }));
        }
        return this.optimSpaceSvc.getOptimSpace$(optimSpaceId).pipe(
          timeout(10000),

          switchMap((optimSpace) =>
            forkJoin({
              optimSpace: of(optimSpace),
              input: this.inputSvc.getInput(optimSpace.initialInputId),
              visuAndKpis: this.inputSvc.getInputVisuAndKpis(optimSpace.initialInputId),
              favoriteParameterId: this.paramsService.getFavoriteParameterId$(
                optimSpace.appVersion.appPath,
                optimSpace.appVersion.version,
                optimSpace.dataSpace.id
              ),
              permissions: this.dataSpaceService.getPermissionsById(
                optimSpace.dataSpace.id,
                optimSpace.appVersion.appPath
              )
            })
          ),
          map(({ optimSpace, input, visuAndKpis, favoriteParameterId, permissions }) => {
            const inputVisu = visuAndKpis.visuData;
            const inputKpis = visuAndKpis.inputKpis;
            optimSpace.parameter = {
              favoriteParameterId: favoriteParameterId,
              parameterId: favoriteParameterId
            };
            optimSpace.dataSpace.permissions = permissions;
            return optimSpaceSelectionComplete({ optimSpace, input, inputVisu, inputKpis });
          }),
          tap(({ input }) => {
            const queryParams = {
              optimSpaceId: optimSpaceId,
              inputId: input.id,
              ...(oldOptimSpace?.parameter?.parameterId && {
                parameterId: oldOptimSpace.parameter.parameterId
              })
            };
            const newDest = this.router.createUrlTree([dest.toString()], { queryParams });
            this.router.navigateByUrl(newDest);
          }),
          catchError((error) => {
            console.error(error);
            const message = `Cannot load the chosen instance: ${error.message}`;
            let details = null;
            if (error.error?.errorCode == 'solver_data_is_invalid') {
              details = {
                action: 'Read More',
                title: 'Cannot load the input.',
                htmlMessage: `<p class="py-1">${
                  error.error.errorMessage
                }:</p><ul>${error.error.solverErrors
                  .map((e) => `<li>${e}</li>`)
                  .join('')}</ul>`
              };
            }
            return of(optimSpaceSelectionFailure({ message, details }));
          })
        );
      })
    )
  );

  fetchOutput$ = createEffect(() =>
    this.actions$.pipe(
      ofType(outputSelection),
      withLatestFromDeferred(this.store.select(selectRoute)),
      exhaustMap(([{ outputId, dest }, { parameterId, optimSpaceId, inputId }]) => {
        if (outputId == null) {
          return of(outputSelectionComplete({ output: new OutputModel() }));
        }
        return forkJoin({
          output: this.outputSvc.getOutput(outputId),
          visuAndKpis: this.outputSvc.getOutputVisuAndKpis(outputId)
        }).pipe(
          timeout(60000),
          map(({ output, visuAndKpis }) => {
            const outputVisu = visuAndKpis.visuData;
            const outputKpis = visuAndKpis.outputKpis;
            return outputSelectionComplete({ output, outputVisu, outputKpis });
          }),
          tap(() => {
            const queryParams = {
              optimSpaceId: optimSpaceId,
              inputId: inputId,
              outputId: outputId,
              parameterId: parameterId
            };
            const newDest = this.router.createUrlTree([dest.toString()], { queryParams });
            this.router.navigateByUrl(newDest);
          }),
          catchError((error) => {
            let details = null;
            if (error.error?.errorCode == 'solver_data_is_invalid') {
              details = {
                action: 'Read More',
                title: 'Cannot load the output.',
                htmlMessage: `<p class="py-1">${
                  error.error.errorMessage
                }:</p><ul>${error.error.solverErrors
                  .map((e) => `<li>${e}</li>`)
                  .join('')}</ul>`
              };
            }
            const message =
              'Cannot load the output. ' + (this._errorMessageSuffix[error.name] ?? '');
            return of(outputSelectionFailure({ message, details }));
          })
        );
      })
    )
  );

  fetchParameter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parameterSelection),
      withLatestFromDeferred(this.store.select(selectForParametersEffect)),
      exhaustMap(([{ parameterId, dest }, state]) => {
        return of(parameterSelectionComplete({ parameterId })).pipe(
          tap(() => {
            const { optimSpaceId, inputId, outputId } = state.navigation;
            const queryParams = {
              optimSpaceId,
              inputId,
              outputId,
              parameterId
            };
            const newDest = this.router.createUrlTree([dest.toString()], { queryParams });
            this.router.navigateByUrl(newDest);
          })
        );
      })
    )
  );

  runOptimization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optimizeRun),
      exhaustMap(({ inputId, parameterId, version }) =>
        this.applicationsSvc.postAsyncOptimize$(inputId, parameterId, version).pipe(
          timeout(10000),
          map((response) => {
            const { appPath, jobId, inputId, optimSpaceId } = response;
            const category = 'optimize';
            return optimizeRunSuccess({ appPath, jobId, inputId, optimSpaceId, category });
          }),
          catchError((error) => {
            console.error(error);

            if (
              error?.status === 400 &&
              error.error?.errorCode === 'invalid_application_version'
            ) {
              const message =
                'This input is no longer compatible with the latest application version. ' +
                'Please update the input data according to the format described in the latest schema or contact support in case of difficulty.';
              return of(optimizeRunFailure({ message }));
            }

            if (error.error.message[0] == 'parameterId should not be empty') {
              const message =
                'Cannot launch the optimization: please select a parameter or upload a new one';
              return of(optimizeRunFailure({ message }));
            }
            const message =
              'Cannot launch the optimization: please try again, if the problem persists take a look at our status page (http://status.atoptima.com) or contact the support team (support@atoptima.com).';
            return of(optimizeRunFailure({ message }));
          })
        )
      )
    )
  );

  runRecompute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recomputeRun),
      exhaustMap(({ outputId, outputVisuForRecompute }) =>
        this.applicationsSvc.postAsyncRecompute$(outputId, outputVisuForRecompute).pipe(
          timeout(10000),
          map((response) => {
            const { appPath, jobId, inputId, optimSpaceId } = response;
            const category = 'recompute';
            return optimizeRunSuccess({ appPath, jobId, inputId, optimSpaceId, category });
          })
        )
      )
    )
  );

  listenOptimizeResponse$ = createEffect(() =>
    this.optimizationSvc.optimization$.pipe(
      switchMap((response: Object) => {
        const resV4 = this.apiTranslatorSvc.modelToClass(WebSocketResponseV4Dto, response);
        const resV3 = WebSocketResponseMapper.mapFromV4ToV3(resV4);
        return of(optimizeResponseTreatment({ response: resV3, category: 'optimize' }));
      })
    )
  );

  listenRecomputeResponse$ = createEffect(() =>
    this.optimizationSvc.recompute$.pipe(
      switchMap((response: Object) => {
        const resV4 = this.apiTranslatorSvc.modelToClass(WebSocketResponseV4Dto, response);
        const resV3 = WebSocketResponseMapper.mapFromV4ToV3(resV4);
        return of(optimizeResponseTreatment({ response: resV3, category: 'recompute' }));
      })
    )
  );

  treatOptimizeResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optimizeResponseTreatment),
      withLatestFromDeferred(this.store.select(selectRoute)),
      exhaustMap(([{ response, category }, { appPath, inputId, appVersion }]) => {
        const output = response.output;
        let showResponse = response.appPath === appPath;
        showResponse = showResponse && output.inputId === inputId;
        const res: WebSocketResponseDto = { ...response, appVersion };
        if (showResponse) {
          return of(optimizeResponseShow({ response: res, category }));
        } else {
          return of(optimizeResponseNotify({ response: res, category }));
        }
      })
    )
  );

  showOptimizeResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optimizeResponseShow),
      exhaustMap(({ response, category }) => {
        const output = response.output;
        const errors = output.errors || ([] as string[]);
        const warnings = output.warnings || ([] as string[]);
        if (!response.output.data) {
          return of(optimizeResponseError({ output, errors, category }));
        } else {
          return this.outputSvc.getOutputVisuAndKpis(response.output.id).pipe(
            timeout(5000),
            map((visuAndKpis) => {
              const outputKpis = visuAndKpis.outputKpis;
              const outputVisu = visuAndKpis.visuData;
              if (errors.length > 0) {
                return optimizeResponseError({
                  errors,
                  output,
                  category
                });
              } else if (warnings.length > 0) {
                return optimizeResponseWarning({
                  warnings,
                  output,
                  outputVisu,
                  outputKpis,
                  category
                });
              } else {
                return optimizeResponseSuccess({
                  output,
                  outputVisu,
                  outputKpis,
                  category
                });
              }
            }),
            tapResponse(
              () => {
                const appPath = response.output.appPath;
                const appVersion = response.appVersion;
                const dest = `/application/${appPath}/${appVersion}`;
                const outputId = response.output.id;
                const { optimSpaceId, inputId, parameterId } = response.output;
                const queryParams = { optimSpaceId, inputId, outputId, parameterId };
                const newDest = this.router.createUrlTree([dest.toString()], {
                  queryParams
                });
                this.router.navigateByUrl(newDest);
              },
              () => {
                const { inputId, appPath } = response.output;
                const message =
                  'Cannot load the visualization of the solution. You can retry by selecting the output in the notification list or the output table.';
                this.store.dispatch(
                  optimizeResponseShowError({ appPath, inputId, category, message })
                );
              }
            ),
            finalize(() => {
              const appPath = response.output.appPath;
              const appVersion = response.appVersion;
              const { optimSpaceId, inputId, parameterId } = response.output;
              const outputId = response.output.id;
              const action: NotificationAction = {
                kind: 'showSolution',
                appPath,
                appVersion,
                optimSpaceId,
                inputId,
                outputId,
                parameterId
              };
              const msgSuffix = `${inputId} of application ${output.appPath}.`;
              if (warnings.length > 0) {
                return this.store.dispatch(
                  newNotification({
                    color: 'yellow',
                    icon: 'warning',
                    message: `Optimization finished with warnings for input #${msgSuffix}`,
                    read: false,
                    actions: [{ message: 'See the solution', action }]
                  })
                );
              } else {
                return this.store.dispatch(
                  newNotification({
                    color: 'green',
                    icon: 'check-circle',
                    message: `Optimization successfully finished for input #${msgSuffix}`,
                    read: false,
                    actions: [{ message: 'See the solution', action }]
                  })
                );
              }
            })
          );
        }
      })
    )
  );

  notifyOptimizationResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optimizeResponseNotify),
      exhaustMap(({ response }) => {
        const output = response.output;
        const errors = output.errors || ([] as string[]);
        const warnings = output.warnings || ([] as string[]);
        const appPath = output.appPath;
        const appVersion = response.appVersion;
        const msgSuffix = `${output.inputId} of application ${appPath}.`;
        const { optimSpaceId, inputId, parameterId } = response.output;
        const outputId = response.output.id;
        if (!response.output.data) {
          const action: NotificationAction = {
            kind: 'showError',
            appPath,
            appVersion,
            optimSpaceId,
            inputId,
            parameterId
          };
          return of(
            newNotification({
              color: 'red',
              icon: 'warning-circle',
              message: `An error occured while optimizing input #${msgSuffix}`,
              read: false,
              actions: [{ message: 'See the error', action }]
            })
          );
        } else if (errors.length > 0 || warnings.length > 0) {
          const action: NotificationAction = {
            kind: 'showSolution',
            appPath,
            appVersion,
            optimSpaceId,
            inputId,
            outputId,
            parameterId
          };
          return of(
            newNotification({
              color: 'yellow',
              icon: 'warning',
              message: `Optimization finished with warnings for input #${msgSuffix}`,
              read: false,
              actions: [{ message: 'See the solution', action }]
            })
          );
        } else {
          const action: NotificationAction = {
            kind: 'showSolution',
            appPath,
            appVersion,
            optimSpaceId,
            inputId,
            outputId,
            parameterId
          };
          return of(
            newNotification({
              color: 'green',
              icon: 'check-circle',
              message: `Optimization successfully finished for input #${msgSuffix}`,
              read: false,
              actions: [{ message: 'See the solution', action }]
            })
          );
        }
      })
    )
  );

  showOptimSpaceAndInputAfterSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optimSpaceUploadCompletion),
      withLatestFromDeferred(this.store.select(selectRoute)),
      exhaustMap(([{ dest, optimSpaceId, initialInputId }, { parameterId }]) => {
        const optimSpace$ = this.optimSpaceSvc.getOptimSpace$(optimSpaceId);
        const input$ = this.inputSvc.getInput(initialInputId);
        const inputVisuKpis$ = this.inputSvc.getInputVisuAndKpis(initialInputId);
        return forkJoin({
          optimSpace: optimSpace$,
          input: input$,
          inputVisuKpis: inputVisuKpis$
        }).pipe(
          switchMap(({ optimSpace, input, inputVisuKpis }) => {
            const inputVisu = inputVisuKpis?.visuData;
            const inputKpis = inputVisuKpis?.inputKpis;
            const favoriteParameterId = this.paramsService.getFavoriteParameterId$(
              optimSpace.appVersion.appPath,
              optimSpace.appVersion.version,
              optimSpace.dataSpace.id
            );
            const permissions = this.dataSpaceService.getPermissionsById(
              optimSpace.dataSpace.id,
              optimSpace.appVersion.appPath
            );
            return forkJoin({
              inputVisu: of(inputVisu),
              inputKpis: of(inputKpis),
              input: of(input),
              favoriteParameterId,
              optimSpace: of(optimSpace),
              permissions
            });
          }),
          map(
            ({
              inputVisu,
              inputKpis,
              input,
              favoriteParameterId,
              optimSpace,
              permissions
            }) => {
              optimSpace.parameter = {
                parameterId: null,
                favoriteParameterId: favoriteParameterId
              };
              optimSpace.dataSpace.permissions = permissions;
              return optimSpaceUploadComplete({ optimSpace, input, inputVisu, inputKpis });
            }
          ),
          catchError(() => {
            const message = 'Cannot display the instance and the input selected.';
            return of(optimSpaceUploadFailure({ message }));
          }),
          tap(() => {
            const queryParams = {
              optimSpaceId,
              inputId: initialInputId,
              parameterId
            };
            const newDest = this.router.createUrlTree([dest.toString()], { queryParams });
            this.router.navigateByUrl(newDest);
          })
        );
      })
    )
  );

  updateParameters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(parameterUploadComplete),
      withLatestFromDeferred(this.store.select(selectRoute)),
      exhaustMap(
        ([
          { dest, appPath, parameterId, version },
          { optimSpaceId, inputId, outputId }
        ]) => {
          return this.paramsService.getForOptimSpace$(appPath, version, optimSpaceId).pipe(
            map((parameters) =>
              parameterUpdateComplete({ parameters: parameters.parameters, parameterId })
            ),
            tap(() => {
              const queryParams = {
                optimSpaceId,
                inputId,
                outputId,
                parameterId
              };
              const newDest = this.router.createUrlTree([dest.toString()], {
                queryParams
              });
              this.router.navigateByUrl(newDest);
            }),
            catchError((error) => {
              const message = `Cannot update parameters of application: ${error.name}`;
              return of(parameterUpdateFailure({ message }));
            })
          );
        }
      )
    )
  );

  exportPrimaryAggregator$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(primaryAggregatorExport),
        withLatestFromDeferred(this.store.select(selectCurOutputVisuAndOutputId)),
        exhaustMap(([{ primaryAggregatorId }, { outputVisuData, outputId }]) => {
          const filteredVisu = filterVisu(outputVisuData, {
            primaryAggregators: [primaryAggregatorId]
          });
          return this.tokenVisualizationService
            .postTokenVisu$(filteredVisu, outputId)
            .pipe(
              map((response) => this.swals.fireExportSuccessful(response.id as string))
            );
        })
      ),
    { dispatch: false }
  );
}
