import { WarehousePointType } from 'src/app/core/models/WarehouseModel';
import { WarehouseDAO } from '../dao/warehouse.dao';
import { WarehouseEventBus } from './warehouse-event-bus';

export class WarehouseHeatValues {
  private maxHeatValueByPosition = new Map<String, number>();
  private isVisualization2D: boolean = false;
  private showHeatValues: boolean = false;

  constructor(warehouseDAO: WarehouseDAO) {
    this.groupValues(warehouseDAO);
    WarehouseEventBus.getEventBus().on('showHeatmap', (show: boolean) => {
      this.showHeatValues = show;
    });
    WarehouseEventBus.getEventBus().on('changeCamera', (type: '2D' | '3D') => {
      this.isVisualization2D = type === '2D';
      if (this.showHeatValues) {
        // refresh heat values to use max values in 2D visualization when necessary
        WarehouseEventBus.getEventBus().emit('showHeatmap', this.showHeatValues);
      }
    });
  }

  /* This will return undefined if the current visualization is not 2D */
  get2DMaxValueForPosition(x: number, z: number): number | undefined {
    if (this.isVisualization2D) {
      const positionKey = `${x}-${z}`;
      return this.maxHeatValueByPosition.get(positionKey) ?? 0;
    }
    return undefined;
  }

  private groupValues(warehouseDAO: WarehouseDAO): void {
    const storagePoints = warehouseDAO.getRawWarehousePointsByType(
      WarehousePointType.Storage
    );
    for (let point of storagePoints) {
      const positionKey = `${point.position.x}-${point.position.y}`;
      const heatValue = point.category?.length > 0 ? point.heat_value : -1;
      if (this.maxHeatValueByPosition.has(positionKey)) {
        const currentValue = this.maxHeatValueByPosition.get(positionKey);
        this.maxHeatValueByPosition.set(positionKey, Math.max(heatValue, currentValue));
      } else {
        this.maxHeatValueByPosition.set(positionKey, heatValue);
      }
    }
  }
}
