import { Dimensions, Point3D } from 'src/app/core/models/WarehouseModel';
import { PointUtils } from './point-utils';
import { DimensionsUtils } from './dimensions-utils';

export class YZTranslator {
  private readonly point: Point3D;
  private readonly dimensions: Dimensions;

  constructor(position: Point3D, dimensions: Dimensions) {
    this.point = position;
    this.dimensions = dimensions;
  }

  static createChangedYZ(position: Point3D, dimensions: Dimensions): YZTranslator {
    return new YZTranslator(
      PointUtils.changeYZ(position),
      DimensionsUtils.changeYZ(dimensions)
    );
  }

  centerYPosition(): void {
    this.point.y = this.point.y + this.dimensions.y_size / 2;
  }

  getPoint(): Point3D {
    return this.point;
  }

  getDimensions(): Dimensions {
    return this.dimensions;
  }
}
