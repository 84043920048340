import { createAction, props } from '@ngrx/store';
import { MessageState, DataPickerTableState } from './param-picker.state';
import { UrlTree } from '@angular/router';
import { ParameterItemModel } from 'src/app/core/models/ParametersModel';

export const updateParamData = createAction(
  '[Param picker] update param data.',
  props<{ data: DataPickerTableState; cursor: number }>()
);

export const updateCurrentParamInUse = createAction(
  '[Param picker] update current param in use.',
  props<{ currentLoadedId: number }>()
);

export const selectParam = createAction(
  '[Param picker] select param.',
  props<{ id: number; dest: UrlTree }>()
);

export const preSelectParam = createAction(
  '[Param picker] select a row in the param table, pre selection.',
  props<{ id: number }>()
);

export const deleteParam = createAction(
  '[Param picker] delete param.',
  props<{ id: number }>()
);

export const deleteParams = createAction(
  '[Param picker] delete params.',
  props<{ id: number[] }>()
);

export const deleteRow = createAction(
  '[Param picker] delete row in the table.',
  props<{ id: number | number[] }>()
);

export const loadParams = createAction(
  '[Param picker] load param list.',
  props<{ appPath: string; version: string; cursor: number; optimSpaceId: number }>()
);

export const updateSpinner = createAction(
  '[Param picker] update spinner.',
  props<{ status: boolean }>()
);

export const triggerAlert = createAction(
  '[Param picker] trigger alert message.',
  props<{ message: MessageState }>()
);

export const updateParameterNameCompleted = createAction(
  '[Param picker] update parameter name completed.',
  props<{ parameter: ParameterItemModel }>()
);

export const triggerDestroy = createAction('[Param picker] destroy store.');
