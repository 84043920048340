import { Component, Input } from '@angular/core';
import './module/warehouse-visu';
import { NewVisuData } from 'src/app/core/models/Visu';
import WarehouseVisu from './module/warehouse-visu';
import { WarehouseEventBus } from './module/core/models/warehouse-event-bus';
import { WarehouseShowStockEvent } from './module/core/models/warehouse-events';

@Component({
  selector: 'app-warehouse3d',
  templateUrl: './warehouse3d.component.html',
  styleUrls: ['./warehouse3d.component.scss']
})
export class Warehouse3DComponent {
  private warehouseVisu: HTMLElement = document.createElement('warehouse-visu');
  visuData: NewVisuData;
  private init = false;

  @Input() set warehouseData(data: NewVisuData) {
    if (data) {
      this.build3DVisulization(data);
    }
  }

  constructor() {
    WarehouseEventBus.getEventBus().emit('showStock', {
      show: false
    } as WarehouseShowStockEvent);
  }

  build3DVisulization(data: NewVisuData): void {
    const warehouseVisu = this.warehouseVisu as WarehouseVisu;
    warehouseVisu.visuData = data;
    const div = document.querySelector('#where_warehouse_should_go');
    console.info(
      'num of store points: ' +
        warehouseVisu.visuData.warehouse_model.warehouse_points.length
    );
    if (div === null) {
      return;
    }
    if (!this.init) {
      warehouseVisu.init();
      div.appendChild(this.warehouseVisu);
      this.init = true;
      return;
    }
    warehouseVisu.updateScene(data);
  }
}
