import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpStatusCode
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../services/auth.service';
import { Swals } from '../utils/swals';
import { Router } from '@angular/router';
import { ApiError } from '../enum/api-error.enum';

class SolverCodedCheckError {
  family: number;
  code: string;
  message: string;
}

class SolverCheckErrorModel {
  errorCode: string;
  errorMessage: string;
  solverErrors: Array<string | SolverCodedCheckError>;
  solverWarnings: Array<string | SolverCodedCheckError>;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private userService: UserService,
    private swals: Swals,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (this.isNotTokenExpiredError(err)) {
          this.showError(err);
        } else {
          if (this.isSolverDataInvalidError(err)) {
            this.processCheckError(err.error);
          }
          throw err;
          return of(); // otherwise linter show an error.
        }
      })
    );
  }

  /**
   * This function checks if there is an error of waring in the new coded-error formats
   * and transforms to a string in order to be correctly displayed to the user
   */
  private processCheckError(error: SolverCheckErrorModel) {
    const error_strings = error.solverErrors.map((e) => {
      if (typeof e == 'string') {
        return e;
      } else {
        return e.message;
      }
    });
    const warning_strings = error.solverWarnings.map((e) => {
      if (typeof e == 'string') {
        return e;
      } else {
        return e.message;
      }
    });
    error.solverErrors = error_strings;
    error.solverWarnings = warning_strings;
  }

  private isNotTokenExpiredError(err: any) {
    return (
      err.status === HttpStatusCode.Unauthorized &&
      err?.error?.errorCode !== ApiError.API_TOKEN_EXPIRED
    );
  }

  private isSolverDataInvalidError(err: any) {
    return (
      err.status === HttpStatusCode.BadRequest &&
      err.error &&
      err.error.errorCode == ApiError.SOLVER_DATA_IS_INVALID
    );
  }

  private showError(err: any) {
    const shouldRedirectToLogin = err?.error?.errorCode !== ApiError.UNAUTHORIZED_OPERATION;
    this.swals
      .fireError({
        errorTitle: 'Oops...',
        errorMessage: shouldRedirectToLogin
          ? 'Unauthorized. Please log in.'
          : err.error.errorMessage
      })
      .then(() => {
        // auto logout if 401 response returned from api is not an unauthorized operation
        if (shouldRedirectToLogin) {
          this.router.navigate(['/login']);
        }
      });
  }
}
