import { createSelector } from '@ngrx/store';
import { RootState } from '..';
import {
  selectQueryParams,
  selectRouteNestedParams,
  selectUrl
} from '../router/router.selector';

export interface NavigationSelector {
  rehydrationDone: boolean;
  url: string[];
  appPath?: string;
  appVersion?: string;
  optimSpaceId?: number;
  inputId?: number;
  outputId?: number;
  parameterId?: number;
  messageError?: string;
}

export const selectRehydrationDone = (state: RootState) => state.navigation.rehydrationDone;

export const selectRoute = createSelector(
  selectRehydrationDone,
  selectUrl,
  selectQueryParams,
  selectRouteNestedParams,
  (rehydrationDone, strUrl, queryParams, routeNestedParams) => {
    const appPath =
      'application' in routeNestedParams ? routeNestedParams['application'] : null;
    const appVersion = 'version' in routeNestedParams ? routeNestedParams['version'] : null;
    const optimSpaceId =
      'optimSpaceId' in queryParams ? parseInt(queryParams['optimSpaceId']) : null;
    const inputId = 'inputId' in queryParams ? parseInt(queryParams['inputId']) : null;
    const outputId = 'outputId' in queryParams ? parseInt(queryParams['outputId']) : null;
    const parameterId =
      'parameterId' in queryParams ? parseInt(queryParams['parameterId']) : null;
    const messageError = null;

    const url = strUrl.split('?')[0].split('/');
    url.shift();

    if (parameterId && !appPath) {
      return { rehydrationDone, url, messageError: 'error1' };
    }

    if (optimSpaceId && !appPath) {
      return { rehydrationDone, url, messageError: 'error2' };
    }

    if (inputId && !appPath) {
      return { rehydrationDone, url, messageError: 'error3' };
    }

    if (outputId && (!appPath || !(optimSpaceId || inputId))) {
      return { rehydrationDone, url, messageError: 'error4' };
    }

    return {
      rehydrationDone,
      url,
      appPath,
      optimSpaceId,
      inputId,
      outputId,
      parameterId,
      messageError,
      appVersion
    };
  }
);
