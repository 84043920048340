import { createReducer, on } from '@ngrx/store';
import { DataPickerState, initialDataPickerState } from './param-picker.state';
import {
  deleteRow,
  preSelectParam,
  triggerAlert,
  triggerDestroy,
  updateCurrentParamInUse,
  updateParamData,
  updateParameterNameCompleted,
  updateSpinner
} from './param-picker.actions';
import {
  parameterSelectionComplete,
  parameterSelectionFailure
} from 'src/app/core/store/application/application.actions';

export const paramPickerReducer = createReducer<DataPickerState>(
  initialDataPickerState,
  on(preSelectParam, (state, { id }) => {
    const selectedIds = [...state.selectedIds];
    const index = selectedIds.findIndex((rowId) => rowId === id);
    if (index === -1) {
      selectedIds.push(id);
    } else {
      selectedIds.splice(index, 1);
    }
    return {
      ...state,
      selectedIds
    };
  }),

  on(deleteRow, (state, { id }) => {
    const ids = Array.isArray(id) ? id : [id];

    const rows = [...state.data.rows];
    const selectedIds = [...state.selectedIds];

    ids.forEach((deleteId) => {
      const rowPos = rows.findIndex((row) => row.id === deleteId);
      if (rowPos > -1) {
        rows.splice(rowPos, 1);
        const selectIdPos = selectedIds.findIndex((sid) => sid === deleteId);
        if (selectIdPos > -1) {
          selectedIds.splice(selectIdPos, 1);
        }
      }
    });

    if (
      rows.length < state.data.rows.length ||
      selectedIds.length < state.selectedIds.length
    ) {
      return {
        ...state,
        data: {
          ...state.data,
          rows
        },
        selectedIds,
        message: null
      };
    } else {
      return {
        ...state,
        message: {
          category: 'error',
          message: `Cannot remove row(s) #${ids.join(', ')}.`,
          persistentMessage: false,
          details: null
        }
      };
    }
  }),

  on(updateParamData, (state, { data, cursor }) => {
    let rows = [];
    let selectedIds = [];
    if (state.data) {
      rows = state.data.rows.concat(data.rows);
      selectedIds = state.selectedIds;
    } else {
      rows = [...data.rows];
      selectedIds = [];
    }
    return {
      ...state,
      data: {
        ...data,
        rows
      },
      cursor,
      selectedIds,
      message: null
    };
  }),

  on(updateCurrentParamInUse, (state, { currentLoadedId }) => {
    return {
      ...state,
      currentLoadedId
    };
  }),

  on(updateSpinner, (state, { status }) => {
    return {
      ...state,
      spinnerSelect: status
    };
  }),

  on(parameterSelectionComplete, (state, { parameterId }) => {
    return {
      ...state,
      spinnerSelect: false,
      currentLoadedId: parameterId,
      message: {
        category: 'success',
        message: 'Param ' + parameterId + ' has been loaded.',
        persistentMessage: false,
        details: null
      }
    };
  }),

  on(parameterSelectionFailure, (state, { message, details }) => {
    return {
      ...state,
      spinnerSelect: false,
      message: {
        category: 'error',
        message: message,
        persistentMessage: false,
        details: details
      }
    };
  }),

  on(triggerAlert, (state, { message }) => {
    return {
      ...state,
      spinnerSelect: false,
      message: message
    };
  }),

  on(triggerDestroy, (state) => {
    return { ...initialDataPickerState };
  }),

  on(updateParameterNameCompleted, (state, { parameter }) => {
    const index = state.data.rows.findIndex((r) => r.id === parameter.id);
    const rows = [...state.data.rows];
    rows[index] = parameter;
    return {
      ...state,
      data: {
        ...state.data,
        rows
      }
    };
  })
);
