<div
  class="inline-flex justify-between items-center h-12  overflow-hidden text-sm rounded-md font-medium py-2 px-6 text-center sb-{{
    category
  }}"
>
  {{ message }}
  <phosphor-icon
    data-testid="alert-close"
    (click)="onClose()"
    class="ml-2 fill-{{ category | alertColor }}-500 stroke-{{
      category | alertColor
    }}-900 w-5"
    name="x"
  ></phosphor-icon>
</div>
