import { MessageCategory } from 'src/app/core/models/MessageCategory';
import { ParameterItemModel } from 'src/app/core/models/ParametersModel';

export interface DataPickerTableState {
  colNames: string[];
  rows: Array<ParameterItemModel>;
}

export const initialDataPickerState: DataPickerState = {
  selectedIds: [],
  data: { colNames: [], rows: [] },
  currentLoadedId: 0,
  favoriteParameterId: undefined,
  spinnerSelect: false,
  cursor: -1
};

export interface MessageState {
  category: MessageCategory;
  message: string;
  persistentMessage: boolean;
  details?: { action: string; title: string; htmlMessage: string };
}

export interface DataPickerState {
  /**
   * Data of the table (head + array of rows).
   */
  data: DataPickerTableState;

  /**
   * current optimSpace loaded
   */
  currentLoadedId: number;

  favoriteParameterId: number;

  /**
   * Ids of optimSpace/input/output currently selected by the user in the table.
   */
  selectedIds: number[];

  spinnerSelect: boolean;

  message?: MessageState;

  cursor?: number;
}
