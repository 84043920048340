import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageCategory } from 'src/app/core/models/MessageCategory';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent {
  @Input() message: string;
  @Input() category: MessageCategory;
  @Output() closed = new EventEmitter();

  onClose() {
    this.closed.emit();
  }
}
