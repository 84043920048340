<div [@fadeInOut]="fadeInOut" (@fadeInOut.done)="close($event)">
  <div
    class="bg-{{ category | alertColor }}-50 text-{{
      category | alertColor
    }}-600 my-2 text-sm"
  >
    <div class="flex px-2 py-2">
      <phosphor-icon
        class="fill-{{ category | alertColor }}-500 stroke-{{
          category | alertColor
        }}-900 w-5"
        [name]="category | alertIcon"
      ></phosphor-icon>
      <div class="px-2 flex-grow">
        <p [innerHTML]="message"></p>
        <div
          data-testid="alert-show-details"
          *ngIf="details != null"
          class="flex items-center cursor-pointer"
          (click)="onClickShowDetails()"
        >
          <div data-testid="action-alert" class="font-semibold underline">
            {{ details.action }}
          </div>
          <phosphor-icon
            (click)="onClose()"
            class="fill-{{ category | alertColor }}-500 stroke-{{
              category | alertColor
            }}-900 w-5"
            name="caret-right"
          ></phosphor-icon>
        </div>
      </div>
      <div class="px-2">
        <div *ngIf="!cancel" class="flex items-center cursor-pointer">
          <phosphor-icon
            data-testid="alert-close"
            (click)="onClose()"
            class="fill-{{ category | alertColor }}-500 stroke-{{
              category | alertColor
            }}-900 w-5"
            name="x"
          ></phosphor-icon>
        </div>
      </div>
    </div>
    <div class="bg-{{ category | alertColor }}-50">
      <div
        [@progress]="isProgressBarFull ? 'full' : 'empty'"
        class="h-1 bg-{{ category | alertColor }}-500"
      ></div>
    </div>
  </div>
</div>

<app-modal
  [showModal]="showModal"
  [category]="category"
  [details]="details"
  (closeModal)="onCloseModal($event)"
></app-modal>
