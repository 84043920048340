<div class="p-0.5 mb-5">
  <div class="grid justify-items-center mt-5 warehouse-control">
    <!-- Range Timeline -->
    <input
      id="range-control"
      type="range"
      [value]="valueRangeControl"
      min="0"
      (input)="onRangeChange($event)"
      [max]="maxRangeControl"
      step="1"
      style="width: 700px"
    />
  </div>
  <div class="flex justify-center items-center space-x-1 mt-2 warehouse-control">
    <!-- Show Route -->
    <span class="w-4"></span>
    <input
      (change)="onShowRoute($event)"
      id="show-route"
      class="mr-1"
      type="checkbox"
      class="rounded"
    />
    <label for="show-route" class="text-sm">Show route</label>

    <!-- Warehouse path -->
    <input
      (change)="onShowWarehousePath($event)"
      id="show-warehouse-path"
      class="mr-1"
      type="checkbox"
      class="rounded"
      [checked]="checkWarehousePath"
    />
    <label for="show-warehouse-path" class="text-sm">Warehouse path</label>

    <!-- Show Heat map -->
    <input
      (change)="onShowHeatMap($event)"
      id="show-heat-map"
      class="mr-1"
      type="checkbox"
      class="rounded"
    />
    <label for="show-heat-map" class="text-sm">Heat map</label>

    <!-- Reset -->
    <span class="w-4"></span>
    <button
      (click)="onReset()"
      id="reset"
      title="Reset"
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded flex items-center"
    >
      <svg
        class="h-5 w-5 text-white mr-2 ml-2"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
        />
      </svg>
    </button>

    <!-- Play -->
    <button
      (click)="onPlay()"
      id="play"
      title="Play"
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded flex items-center"
    >
      <svg
        class="h-5 w-5 text-white inline-block mr-2 ml-2"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 3l14 9-14 9V3z"
        />
      </svg>
    </button>

    <!-- Stop -->
    <button
      (click)="onStop()"
      id="stop"
      title="Stop"
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded flex items-center"
    >
      <svg
        class="h-5 w-5 text-white mr-2 ml-2"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 6h12v12H6z"
        />
      </svg>
    </button>

    <!-- Speed -->
    <span class="w-4"></span>
    <label for="speed" class="ml-2 mr-1 text-sm">Speed</label>
    <input
      (input)="onSpeedChange($event)"
      id="speed"
      type="number"
      min="1"
      max="100"
      value="1"
      class="w-16 h-6 px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
    />

    <!-- Show Pickers -->
    <button
      (click)="onShowPickerSelection(!showPickersSelection)"
      id="options"
      title="Reset"
      class="text-sm {{
        showPickersSelection
          ? 'bg-red-500 hover:bg-red-700'
          : 'bg-blue-500 hover:bg-blue-700'
      }} text-white py-1 px-2 rounded flex items-center"
    >
      {{ this.showPickersSelection ? 'Close Selection' : 'Select Pickers' }}
    </button>

    <button
      *ngIf="view3D; else view2D"
      (click)="onClickView()"
      app-button
      size="small"
      hierarchy="secondary"
      color="neutral"
    >
      <phosphor-icon
        class="stroke-neutral-900 fill-neutral-500 w-4"
        name="square"
      ></phosphor-icon>
      <p class="text-xs">2D</p>
    </button>
    <ng-template #view2D>
      <button
        (click)="onClickView()"
        app-button
        size="small"
        hierarchy="secondary"
        color="neutral"
      >
        <phosphor-icon
          class="stroke-neutral-900 fill-neutral-500 w-4"
          name="package"
        ></phosphor-icon>
        <p class="text-xs">3D</p>
      </button>
    </ng-template>
  </div>
  <div
    *ngIf="showPickersSelection"
    class="flex justify-center items-center mt-2 warehouse-control"
  >
    <div id="mark-selectors" class="grid grid-cols-8 gap-2 w-1/2 max-h-40 overflow-auto">
      <div *ngFor="let picker of pickers">
        <input
          (change)="onChangeShowPicker($event, picker)"
          type="checkbox"
          class="rounded mr-1"
          [checked]="picker.show"
        />
        <label class="text-sm">{{ picker.id }}</label>
      </div>
    </div>
  </div>
  <div *ngIf="showPickersSelection" class="flex justify-center items-center space-x-1 mt-2">
    <button
      (click)="onChangeShowAllPickers(true)"
      id="options"
      title="Reset"
      class="text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded flex items-center"
    >
      Select all
    </button>

    <button
      (click)="onChangeShowAllPickers(false)"
      id="options"
      title="Reset"
      class="text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded flex items-center"
    >
      Select none
    </button>
  </div>
</div>
