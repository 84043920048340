import {
  CylinderGeometry,
  Group,
  LineSegments,
  Material,
  MathUtils,
  Mesh,
  Object3D
} from 'three';
import { StandardMaterial } from './standard-material';
import { LineSegmentsShape } from './line-segments-shape';

const NUM_RADIAL_SEGMENTS = 32;

export class CylinderShape {
  static create(container: Object3D, cylinderPros: CylinderProperties): CylinderData {
    const group = new Group();
    const size = cylinderPros?.opts?.useSlack
      ? cylinderPros.size - 0.005
      : cylinderPros.size;
    const cylinderGeometry = new CylinderGeometry(
      cylinderPros.radius,
      cylinderPros.radius,
      size,
      NUM_RADIAL_SEGMENTS
    );

    const material =
      cylinderPros?.material ??
      StandardMaterial.create({
        color: cylinderPros?.color ?? '#00FF00'
      });

    const cylinder = new Mesh(cylinderGeometry, material);
    cylinder.position.x = cylinderPros.position.x;
    cylinder.position.y = cylinderPros.position.y;
    cylinder.position.z = cylinderPros.position.z;

    CylinderShape.applyRotation(cylinder, cylinderPros.faceOrientation);

    group.add(cylinder);

    if (cylinderPros.opts?.matrixAutoUpdate != undefined) {
      cylinder.matrixAutoUpdate = cylinderPros.opts.matrixAutoUpdate;
    }

    let lineSegments: LineSegments;
    if (
      cylinderPros.opts?.createLineSegments == undefined ||
      cylinderPros.opts.createLineSegments
    ) {
      lineSegments = LineSegmentsShape.create(cylinderGeometry, cylinder);
      group.add(lineSegments);
    }

    cylinder.updateMatrix();
    container.add(group);
    return {
      cylinder,
      lineSegments,
      group
    };
  }

  static applyRotation(cylinder: Mesh, faceOrientation: CylinderFaceOrientation): void {
    switch (faceOrientation) {
      case CylinderFaceOrientation.X:
        cylinder.rotation.z = MathUtils.degToRad(90);
        break;
      case CylinderFaceOrientation.Z:
        cylinder.rotation.x = MathUtils.degToRad(90);
      default:
        break;
    }
  }
}

export interface CylinderProperties {
  position: {
    x: number;
    y: number;
    z: number;
  };
  size: number;
  radius: number;
  faceOrientation: CylinderFaceOrientation;
  color?: string;
  material?: Material;
  opts: {
    matrixAutoUpdate?: boolean;
    createLineSegments?: boolean;
    useSlack?: boolean;
  };
}

export enum CylinderFaceOrientation {
  X = 'x',
  Y = 'y',
  Z = 'z'
}

export interface CylinderData {
  cylinder: Mesh;
  lineSegments: LineSegments;
  group: Group;
}
