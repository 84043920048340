import { Component, OnDestroy, OnInit } from '@angular/core';
import { RootState } from 'src/app/core/store';
import { Store } from '@ngrx/store';
import { WarehouseEventBus } from '../warehouse3d/module/core/models/warehouse-event-bus';
import {
  WarehouseStockControl,
  WarehouseStockResult
} from '../warehouse3d/module/core/models/warehouse-stock-control';
import { Observable, Subscriber, Subscription, sampleTime } from 'rxjs';
import {
  WarehouseNotifyStockEvent,
  WarehouseShowStockEvent
} from '../warehouse3d/module/core/models/warehouse-events';

@Component({
  selector: 'app-warehouse-stock',
  templateUrl: './warehouse-stock.component.html'
})
export class WarehouseStockComponent implements OnDestroy {
  stock: WarehouseStockResult[];

  private eventCallback: (event: WarehouseNotifyStockEvent) => void;
  private subscriber: Subscriber<WarehouseNotifyStockEvent>;
  private subscription: Subscription;

  constructor() {
    this.eventCallback = (stock: WarehouseNotifyStockEvent) => this.updateStock(stock);

    this.subscription = new Observable<WarehouseNotifyStockEvent>((subscriber) => {
      this.subscriber = subscriber;
    })
      .pipe(sampleTime(1000))
      .subscribe((event: WarehouseNotifyStockEvent) => {
        this.stock = event.stock;
      });

    // This will receive the updates depending of the current time
    WarehouseEventBus.getEventBus().on('notifyStock', this.eventCallback);
  }

  ngOnInit(): void {
    if (!this.stock) {
      this.stock = WarehouseStockControl.getInstance().getStockAtTime().stock;
    }
  }

  ngOnDestroy(): void {
    WarehouseEventBus.getEventBus().remove('notifyStock', this.eventCallback);
    this.subscription.unsubscribe();
  }

  updateStock(event: WarehouseNotifyStockEvent): void {
    this.subscriber.next(event);
  }

  close(): void {
    WarehouseEventBus.getEventBus().emit('showStock', {
      show: false
    } as WarehouseShowStockEvent);
  }
}
