export enum WarehousePointType {
  RouteStart = 'RouteStart',
  Storage = 'Storage',
  DropOff = 'DropOff',
  Parking = 'Parking',
  Receive = 'Receive',
  Wall = 'Wall'
}

export enum WarehouseActionType {
  Move = 'Move',
  Wait = 'Wait',
  Service = 'Service',
  Park = 'Park'
}

export interface Point3D {
  x: number;
  y: number;
  z: number;
}

export interface Dimensions {
  x_size: number;
  y_size: number;
  z_size: number;
}

export interface RoutePoint {
  time_s: number; // time in seconds - we will interpolate the route based on this
  point: Point3D;
  action: WarehouseActionType;
  service_id: number;
}

export interface Route {
  id: string; // id of the route
  route_points: RoutePoint[];
}

export interface WarehousePoint {
  position: Point3D;
  dimensions: Dimensions;
  app_elem_id: string | null;
  type: WarehousePointType;
  initial_stock: { [key: number]: [number, number] }; // Dict{articleId, Tuple{quantity, volume}}
  capacity: number;
  category: number[]; // Vector, enabling the inclusion of sub-categories.
  heat_value: number;
}

export interface WarehouseService {
  warehouse_point_id: number;
  app_elem_id: string | null;
  // positive values: delivery
  // negative values: pickup
  stock_change: { [key: number]: [number, number] };
}

export interface WarehouseAisle {
  start: Point3D;
  finish: Point3D;
  directed: boolean;
  breadth: number;
  heat_value: number;
}

export interface WarehouseModel {
  min_coordinates: Point3D;
  max_coordinates: Point3D;
  picker_dimensions: Dimensions;
  warehouse_points: WarehousePoint[];
  routes: Route[];
  services: WarehouseService[];
  articles: string[];
  categories: string[];
  aisles: WarehouseAisle[];
}
