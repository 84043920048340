<div class="flex flex-col h-screen">
  <header class="flex-shrink-0">
    <!-- Navigation bar -->

    <app-navbar></app-navbar>
  </header>
  <div class="flex flex-1 overflow-hidden">
    <!-- Side bar -->
    <app-sidebar
      *ngIf="!sidebarCollapsed; else sideBarIsCollapsed"
      class="overflow-y-auto h-full flex-shrink-0 w-52 bg-neutral-50"
      [sideBarCollapsed]="sidebarCollapsed"
      (isSideBarCollapsedEmitter)="onCollapsedSideBar($event)"
    ></app-sidebar>
    <ng-template #sideBarIsCollapsed>
      <app-sidebar
        class="overflow-y-auto h-full flex-shrink-0 w-12 bg-neutral-50"
        [sideBarCollapsed]="sidebarCollapsed"
        (isSideBarCollapsedEmitter)="onCollapsedSideBar($event)"
      ></app-sidebar>
    </ng-template>
    <main class="flex-1 overflow-y-auto bg-white">
      <router-outlet></router-outlet>
    </main>
  </div>
  <app-footer class="flex-shrink-0 bg-neutral-50"></app-footer>
</div>

<!-- Tmp to avoid the purge of some tailwind properties -->
<div
  class="hidden text-green-100 text-yellow-100 text-red-100 text-blue-100 text-green-700 text-yellow-700 text-red-700 text-blue-700 bg-green-600 bg-red-600 bg-yellow-600 bg-blue-600 bg-green-500 bg-red-500 bg-yellow-500 bg-blue-500 bg-green-50 bg-red-50 bg-yellow-50 bg-blue-50 fill-green-500 fill-yellow-500 fill-red-500 stroke-green-900 stroke-yellow-900 stroke-red-900 stroke-primary-900 fill-primary-900 fill-primary-500 stroke-primary-500"
></div>
