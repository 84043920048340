import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeatureRoutingModule } from './feature-routing.module';
import { AppViewModule } from './app-view/app-view.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, FeatureRoutingModule, AppViewModule],
  exports: []
})
export class FeatureModule {}
