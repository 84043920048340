import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';

interface ItemDescription {
  id: number;
  weight: number;
  name: string;
  selected: boolean;
  validated: boolean;
}

@Component({
  selector: 'app-item-selector',
  templateUrl: './item-selector.component.html'
})
export class ItemSelectorComponent {
  // memory of the component.
  userSearch = '';

  private _items: ItemDescription[];
  itemsByWeight: ItemDescription[];

  @ViewChild('selectList') selectList: ElementRef;
  @Output() selectItem = new EventEmitter<{ id: number; select: boolean }>();
  @Output() mouseEnter = new EventEmitter();
  @Output() mouseLeave = new EventEmitter();
  @Output() mouseEnterOption = new EventEmitter<number>();
  @Output() mouseLeaveOption = new EventEmitter<number>();

  @Input() stopClickPropagation = false;
  @Input() unselectAllButton = true;

  @Input() set items(items: Array<{ id: number; name: string; validated?: boolean }>) {
    this._items = items.map((item) => {
      let validated = false;
      if (item.validated) {
        validated = item.validated;
      }
      const weight = item.id;
      return { ...item, selected: false, weight, validated };
    });

    this.itemsByWeight = [...this._items].sort((pa1, pa2) => pa1.weight - pa2.weight);

    if (this.userSearch !== '') {
      this.itemsByWeight = this._itemsSortedByInputSearch(this.userSearch);
    }
  }

  get items(): ItemDescription[] {
    return this._items;
  }

  private _itemsSortedByInputSearch(input: string): ItemDescription[] {
    if (input === '') {
      return this.items;
    }
    return this.items.filter(({ name }) => {
      if (name.toLocaleLowerCase().includes(input.toLocaleLowerCase())) {
        return true;
      }
    });
  }

  public onInput() {
    this.itemsByWeight = this._itemsSortedByInputSearch(this.userSearch);
    this.selectList.nativeElement.scrollTo(0, 0);
  }

  /**
   * input                 output
   * selected  validated   select
   * 0         0           1
   * 0         1           0
   * 1         0           1
   * 1         1           0
   * Relation is select = !validated
   */
  public onClickSelectItem($event, itemPos) {
    const { validated, id } = this.itemsByWeight[itemPos];
    this.itemsByWeight[itemPos].selected = !validated;
    this.selectItem.emit({ id, select: !validated });
    // Hack
    // We can stop the propagation of the event if a dropdown menu embeds the item selector.
    // You should stop propragation when you want the item selector stays open. Indeed,
    // the parent component updates the list just after the click (to mark the item as validated)
    // and when the dropdown component checks that the DOM element on which the user clicked is in
    // the dropdown menu, the check fails because the DOM element does not exist anymore.
    // As a consequence, the dropdown menu is closed.
    if (this.stopClickPropagation) {
      $event.stopPropagation();
    }
  }

  public onClickUnselectAllItems() {
    this.itemsByWeight.forEach((item) => {
      if (item.weight > 0) {
        item.selected = false;
        this.selectItem.emit({ id: item.id, select: false });
      }
    });
  }

  onClickSelectAllItems() {
    //select all items, take into account the search input
    this.itemsByWeight.forEach((item) => {
      if (item.weight > 0) {
        item.selected = true;
        this.selectItem.emit({ id: item.id, select: true });
      }
    });
  }

  @HostListener('focusin')
  @HostListener('mouseenter')
  @HostListener('focus')
  onMouseEnter() {
    this.mouseEnter.emit();
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  @HostListener('blur')
  onMouseLeave() {
    this.mouseLeave.emit();
  }

  onMouseEnterOption($event) {
    this.mouseEnterOption.emit($event);
  }

  onMouseLeaveOption($event) {
    this.mouseLeaveOption.emit($event);
  }
}
