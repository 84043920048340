import { BusinessUnit } from 'src/app/core/models/BusinessUnit';
import { DataSpace } from './DataSpace';

/**
 * A user is someone that has access to the galia API.
 * This data structure contains user data returned by the API.
 */
export class User {
  id?: number;
  user_name: string;
  full_name?: string;
  email: string;
  can_manage_applications?: boolean;
  can_manage_business_units?: boolean;
  business_unit?: BusinessUnit;
  data_space?: DataSpace;
  active?: boolean;
}

export class UserForList {
  id?: number;
  user_name: string;
  full_name?: string;
  email: string;
  can_manage_applications?: boolean;
  can_manage_business_units?: boolean;
  business_unit?: BusinessUnit;
  data_space_id?: number;
  data_space_name?: string;
  active?: boolean;
}

export type UserProfileV4 = {
  id: number;
  username: string;
  fullname: string;
  email: string;
  business_unit: {
    id: number;
    name: string;
    description: string;
    created_at: Date;
  };
  role: string;
  default_data_space: {
    id: number;
    name: string;
    description: string;
  };
};

export type UserForListV4 = {
  id: number;
  email: string;
  username: string;
  fullname: string;
  created_at: Date;
  created_by: {
    id: number;
    username: string;
  };
  default_data_space: {
    id: number;
    name: string;
    description: string;
  };
  role: string;
};

export class UserMapper {
  static toUser(user: UserProfileV4): User {
    const model = new User();
    model.id = user.id;
    model.user_name = user.username;
    model.full_name = user.fullname;
    model.email = user.email;
    model.can_manage_applications = user.role === 'ROOT';
    model.can_manage_business_units = user.role === 'ROOT';

    const businessUnit = new BusinessUnit();
    businessUnit.id = user.business_unit.id;
    businessUnit.name = user.business_unit.name;
    businessUnit.description = user.business_unit.description;
    businessUnit.created_at = user.business_unit.created_at;
    businessUnit.active = true;
    model.business_unit = businessUnit;

    const dataSpace = new DataSpace();
    dataSpace.id = user.default_data_space.id;
    dataSpace.name = user.default_data_space.name;
    dataSpace.description = user.default_data_space.description;
    model.data_space = dataSpace;
    model.active = true;
    return model;
  }

  static toUserForList(user: UserForListV4): UserForList {
    return {
      id: user.id,
      user_name: user.username,
      full_name: user.fullname,
      email: user.email,
      data_space_id: user.default_data_space.id,
      data_space_name: user.default_data_space.name,
      active: true
    };
  }
}
