import { Component, OnDestroy } from '@angular/core';
import { WarehouseTimeline } from '../warehouse3d/module/core/controls/warehouse-timeline';
import { WarehouseEventBus } from '../warehouse3d/module/core/models/warehouse-event-bus';
import { Observable, Subscriber, Subscription, sampleTime } from 'rxjs';
import {
  WarehouseShowPickerEvent,
  WarehouseTimelineEvent
} from '../warehouse3d/module/core/models/warehouse-events';

@Component({
  selector: 'app-warehouse-controls',
  templateUrl: './warehouse-controls.component.html'
})
export class WarehouseControlsComponent implements OnDestroy {
  valueRangeControl: number = 0;
  maxRangeControl: number = 100;
  checkWarehousePath: boolean = true;
  showPickersSelection: boolean = false;
  view3D: boolean = true;

  pickers: PickerData[];

  private subscription: Subscription;

  constructor() {
    WarehouseTimeline.getInstance().reset();
    WarehouseEventBus.getEventBus().on('updateMaxTime', (maxTime: number) => {
      this.maxRangeControl = maxTime;
      this.valueRangeControl = 0;
    });

    let notifier: Subscriber<number>;
    this.subscription = new Observable<number>((subscriber) => {
      notifier = subscriber;
    })
      .pipe(sampleTime(1000))
      .subscribe((time) => {
        this.valueRangeControl = time;
      });
    WarehouseEventBus.getEventBus().on('updateTime', (time: WarehouseTimelineEvent) => {
      notifier.next(time.frame);
    });

    WarehouseEventBus.getEventBus().on('pickersList', (pickersList: string[]) => {
      this.pickers = pickersList.map((pickerId) => ({ id: pickerId, show: true }));
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onPlay(): void {
    WarehouseTimeline.getInstance().play();
  }

  onStop(): void {
    WarehouseTimeline.getInstance().stop();
  }

  onReset(): void {
    WarehouseTimeline.getInstance().reset();
  }

  onRangeChange(event: Event): void {
    WarehouseTimeline.getInstance().goToFrame(+(event.target as HTMLInputElement).value);
  }

  onSpeedChange(event: Event): void {
    let value = +(event.target as HTMLInputElement).value;
    value = Math.max(0, value);
    WarehouseTimeline.getInstance().setSpeed(value);
  }

  onShowRoute(event: Event): void {
    const show = (event.target as HTMLInputElement).checked;
    WarehouseEventBus.getEventBus().emit('showPickersPath', show);
  }

  onShowHeatMap(event: Event): void {
    const show = (event.target as HTMLInputElement).checked;
    WarehouseEventBus.getEventBus().emit('showHeatmap', show);
  }

  onShowWarehousePath(event: Event): void {
    const show = (event.target as HTMLInputElement).checked;
    this.checkWarehousePath = show;
    WarehouseEventBus.getEventBus().emit('showWarehousePath', show);
  }

  onChangeShowPicker(event: Event, picker: PickerData): void {
    const show = (event.target as HTMLInputElement).checked;
    picker.show = show;
    WarehouseEventBus.getEventBus().emit('selectPickers', {
      ids: [picker.id],
      show
    } as WarehouseShowPickerEvent);
  }

  onChangeShowAllPickers(show: boolean): void {
    this.pickers.forEach((picker) => (picker.show = show));
    WarehouseEventBus.getEventBus().emit('selectPickers', {
      ids: this.pickers.map((picker) => picker.id),
      show
    } as WarehouseShowPickerEvent);
  }

  onShowPickerSelection(show: boolean): void {
    this.showPickersSelection = show;
  }
  onClickView(): void {
    this.view3D = !this.view3D;
    WarehouseEventBus.getEventBus().emit('changeCamera', this.view3D ? '3D' : '2D');
  }
}

interface PickerData {
  id: string;
  show: boolean;
}
