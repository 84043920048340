import * as THREE from 'three';
import { HtmlRenderTemplate } from '../../../../../../../shared/visualization3d/html-render-template';
import { InteractionManager } from 'three.interactive';
import * as TWEEN from '@tweenjs/tween.js';
import { TemplateResult } from 'lit';
import { BoxShape } from '../../../../../../../shared/visualization3d/shapes/box-shape';
import { SceneSingleton } from './scene-singleton';
import { InteractionManagerSingleton } from './interactive-manager-singleton';
import { NewVisuData } from 'src/app/core/models/Visu';
import { Warehouse } from './models/warehouse';
import { WarehouseEventBus } from './models/warehouse-event-bus';
import { WarehouseTimeline } from './controls/warehouse-timeline';
import { PlaneShape } from '../../../../../../../shared/visualization3d/shapes/plane-shape';
import { CameraControls } from '../../../../../../../shared/visualization3d/cameras-control';
import { SceneSpace } from 'src/app/shared/visualization3d/scene-space';
import { Compass } from 'src/app/shared/visualization3d/compass';
import { Tooltip } from 'src/app/shared/visualization3d/tooltip';
import { WarehouseCameraPositionsConfig } from './config/warehouse-camera-positions-config';
import { WarehouseLightsConfig } from './config/warehouse-lights-config';
import { SceneDispose } from 'src/app/shared/visualization3d/scene-dispose';

export class WarehouseScene {
  private renderer: THREE.WebGLRenderer;
  private cameraControls: CameraControls;

  // warehouse to visualize
  private space: SceneSpace;
  private visuData: NewVisuData;
  private warehouse: Warehouse;

  // Declare reactive properties
  private autoRotate: boolean = false;
  private scene = SceneSingleton.getScene();
  private interactionManager: InteractionManager;
  private tooltip: Tooltip;
  private renderRoot: ShadowRoot;
  private shouldAnimate: boolean = false;
  private showAxes: boolean = false;

  private compass: Compass;

  // InteractionManager mouse event function
  private mouseEventLister: (event: any) => void;

  constructor(visuData: NewVisuData) {
    this.visuData = visuData;
  }

  /** Create the icon cubes */
  render(): TemplateResult<1> {
    // apply special css classes depending on the internal component state
    const autoRotateCss: string = this.autoRotate ? 'active_menu_icon' : '';
    // view of the component
    return HtmlRenderTemplate.getTemplate({
      autoRotateCss,
      transparentCss: 'hidden',
      toggleTransparent: () => {},
      toggleAutoRotate: this.toggleAutoRotate.bind(this),
      positionCameraTop: () => this.cameraControls.positionCameraTop(),
      positionCameraFront: () => this.cameraControls.positionCameraFront(),
      positionCameraSide: () => this.cameraControls.positionCameraSide()
    });
  }

  /** creates the THREE.js scene for the visualization. */
  createScene(renderRoot: ShadowRoot) {
    this.renderRoot = renderRoot;
    this.compass = new Compass(document.getElementById('compass'));

    // remove all canvas elements if they already exist
    this.renderRoot.querySelectorAll('canvas').forEach((n) => n.remove());

    this.initSpace();

    // get the size of the parent element
    const parentElement = this.renderRoot.host.parentElement;
    let thisWidth = 500; // default sizes if needed
    let thisHeight = 500; // default sizes if needed
    if (parentElement) {
      thisWidth = parentElement.clientWidth;
      thisHeight = parentElement.clientHeight;
    }

    // update the canvas size as we change the parent size
    const parentResizeObserver = new ResizeObserver((entries) =>
      this.cameraControls.resize(entries)
    );
    parentResizeObserver.observe(parentElement);

    // initialize DOM (Scene + Canvas)
    const parentElt = this.renderRoot;

    this.renderer = new THREE.WebGLRenderer({
      antialias: true
    });
    this.renderer.setSize(thisWidth, thisHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    parentElt.appendChild(this.renderer.domElement);

    this.initCameras(thisWidth, thisHeight);
    InteractionManagerSingleton.init(this.renderer, this.cameraControls.getActiveCamera());
    this.interactionManager = InteractionManagerSingleton.getInstance();

    WarehouseLightsConfig.addLights(this.scene, this.space);

    this.scene.background = new THREE.Color(0xffffff);

    this.initTooltip(parentElt);
    this.updateScene(this.visuData);

    const canvas = this.renderRoot.querySelectorAll('canvas')[0];
    this.mouseEventLister = (event: any) => this.tooltip.onMouseMove(canvas, event);
    window.addEventListener('mousemove', this.mouseEventLister);

    this.shouldAnimate = true;
    WarehouseTimeline.getInstance().reset();
    this.animate(null);
    WarehouseEventBus.getEventBus().emit('sceneCreated', {});
  }

  disposeTreejsComponents(): void {
    console.info('Disposing treejs components...');
    if (this.shouldAnimate) {
      this.shouldAnimate = false;
      cancelAnimationFrame(this.animate.bind(this));
    }

    // dispose the scene
    SceneDispose.dispose(this.scene);
    this.scene = undefined;

    // clear cache
    BoxShape.clearCache();
    PlaneShape.clearCache();

    // remove event listener
    window.removeEventListener('mousemove', this.mouseEventLister);

    // clear event bus
    WarehouseEventBus.getEventBus().emit('dispose', {});
    WarehouseEventBus.getEventBus().clear();
  }

  loadWarehouseVisualization(visuData: NewVisuData): void {
    if (!visuData) {
      return;
    }
    if (this.warehouse) {
      this.warehouse.dispose();
    }
    this.warehouse = new Warehouse(visuData);
    this.warehouse.draw();
  }

  updateScene(visuData: NewVisuData) {
    if (this.showAxes) {
      const axesHelper = new THREE.AxesHelper(500);
      this.scene.add(axesHelper);
    }
    this.loadWarehouseVisualization(visuData);
  }

  private renderScene(): void {
    if (this.scene) {
      this.renderer.render(this.scene, this.cameraControls.getActiveCamera());
    }
  }

  private initTooltip(parentElt: ShadowRoot): void {
    this.tooltip = new Tooltip(parentElt);
    WarehouseEventBus.getEventBus().on(
      'showTooltip',
      (data: { show: boolean; text: string }) => {
        if (data.show) {
          this.tooltip.setContent(data.text);
          this.tooltip.show();
        } else {
          this.tooltip.hide();
        }
      }
    );
  }

  private toggleAutoRotate(): void {
    this.autoRotate = !this.autoRotate;
    const controls = this.cameraControls.getOrbitControls();
    if (this.autoRotate) {
      controls.autoRotate = true;
      controls.autoRotateSpeed = 6;
    } else {
      controls.autoRotate = false;
    }
  }

  private initCameras(width: number, height: number): void {
    this.cameraControls = new CameraControls(this.scene, this.renderer, this.space);
    this.cameraControls.initCameras(width, height, {
      cameraPositions: WarehouseCameraPositionsConfig.buildCameraPositions(this.space)
    });
    // Change camera type event
    WarehouseEventBus.getEventBus().on('changeCamera', (type: '2D' | '3D') => {
      const cameraType = type === '3D' ? 'perspective' : 'orthographic';
      this.cameraControls.changeCameras(cameraType);
    });
  }

  // Animation & rendering loop
  private animate(time: any) {
    if (!this.shouldAnimate) {
      return;
    }
    setTimeout(() => {
      requestAnimationFrame(this.animate.bind(this));
      this.cameraControls.update();
      this.interactionManager.update();
      WarehouseTimeline.getInstance().update(time);
      TWEEN.update(time);
      this.renderScene();
      this.compass.update(this.cameraControls.getActiveCamera());
    }, 1000 / 30);
  }

  private initSpace() {
    this.space = {
      xSize:
        this.visuData.warehouse_model.max_coordinates.x -
        this.visuData.warehouse_model.min_coordinates.x,
      ySize:
        this.visuData.warehouse_model.max_coordinates.z -
        this.visuData.warehouse_model.min_coordinates.z,
      zSize:
        this.visuData.warehouse_model.max_coordinates.y -
        this.visuData.warehouse_model.min_coordinates.y
    };
  }
}
