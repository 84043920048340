import { SceneSpace } from 'src/app/shared/visualization3d/scene-space';
import { CameraPositions } from '../../../../../../../../shared/visualization3d/cameras-control';

export class WarehouseCameraPositionsConfig {
  static buildCameraPositions(space: SceneSpace): CameraPositions {
    const ySize = space.zSize * 0.8;
    const top = {
      x: space.xSize / 2,
      y: ySize,
      z: space.zSize / 2 + 1,
      rx: -Math.PI / 2
    };

    return {
      top,
      initial: top,
      front: {
        x: space.xSize / 2,
        y: ySize,
        z: space.zSize / 6
      },
      side: {
        x: -space.xSize / 6,
        y: ySize,
        z: space.zSize / 2
      }
    };
  }
}
