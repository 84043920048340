import { Dimensions, Point3D } from 'src/app/core/models/WarehouseModel';
import {
  CylinderData,
  CylinderFaceOrientation,
  CylinderShape
} from '../../../../../../../../shared/visualization3d/shapes/cylinder-shape';
import { WarehouseColorUtils } from '../utils/color-utils';
import { YZTranslator } from '../utils/yz-translator';

export class PickerObject3D {
  private readonly object3d: CylinderData;
  private readonly color: string;
  private readonly yOffset: number;

  constructor(
    index: number,
    warehouse: THREE.Object3D,
    initialPosition: Point3D,
    pickerDimensions: Dimensions
  ) {
    const pickerProps = new YZTranslator(initialPosition, pickerDimensions);
    pickerProps.centerYPosition();

    const { x_size, y_size, z_size } = pickerProps.getDimensions();

    this.color = WarehouseColorUtils.getPickerColor(index);
    this.yOffset = y_size / 2;

    const baseSize = Math.min(x_size, z_size);
    this.object3d = CylinderShape.create(warehouse, {
      position: pickerProps.getPoint(),
      size: y_size,
      radius: baseSize / 2,
      color: this.color,
      faceOrientation: CylinderFaceOrientation.Y,
      opts: {
        matrixAutoUpdate: true,
        createLineSegments: true
      }
    });
  }

  moveTo(point: Point3D): void {
    this.object3d.cylinder.position.x = point.x;
    this.object3d.cylinder.position.y = point.y + this.yOffset;
    this.object3d.cylinder.position.z = point.z;
    this.object3d.lineSegments.position.copy(this.object3d.cylinder.position);
  }

  show(value: boolean): void {
    this.object3d.group.visible = value;
  }

  getCurrentPosition(): Point3D {
    return {
      x: this.object3d.cylinder.position.x,
      y: this.object3d.cylinder.position.y,
      z: this.object3d.cylinder.position.z
    };
  }

  getYOffset(): number {
    return this.yOffset;
  }
}
