export const phosphorIconUser: PhosphorIcon = {
  name: 'user',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
          <rect width="256" height="256" fill="none" stroke="none"></rect>
          <circle cx="128" cy="96" r="64" opacity="0.2"></circle>
          <circle cx="128" cy="96" r="64" fill="none" stroke-miterlimit="10" stroke-width="16"></circle>
          <path d="M31,216a112,112,0,0,1,194,0" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></path>
      </svg>`
};

export const phosphorIconEnvelope: PhosphorIcon = {
  name: 'envelope',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
          <rect width="256" height="256" fill="none" stroke="none"></rect>
          <polygon points="224 56 128 144 32 56 224 56" opacity="0.2"></polygon>
          <polyline points="224 56 128 144 32 56" fill="none" stroke-linecap="round" stroke-linejoin="round"
              stroke-width="16"></polyline>
          <path d="M32,56H224a0,0,0,0,1,0,0V192a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56A0,0,0,0,1,32,56Z" fill="none"
              stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
          <line x1="110.5" y1="128" x2="34.5" y2="197.7" fill="none" stroke-linecap="round" stroke-linejoin="round"
              stroke-width="16"></line>
          <line x1="221.5" y1="197.7" x2="145.5" y2="128" fill="none" stroke-linecap="round" stroke-linejoin="round"
              stroke-width="16"></line>
      </svg>`
};

export const phosphorIconBuildings: PhosphorIcon = {
  name: 'buildings',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none" stroke="none"></rect>
        <path d="M144,216V40a8,8,0,0,0-8-8H40a8,8,0,0,0-8,8V216" opacity="0.2"></path>
        <line x1="16" y1="216" x2="240" y2="216" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
        <path d="M144,216V40a8,8,0,0,0-8-8H40a8,8,0,0,0-8,8V216" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></path>
        <path d="M224,216V104a8,8,0,0,0-8-8H144" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></path>
        <line x1="64" y1="72" x2="96" y2="72" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16">
        </line>
        <line x1="80" y1="136" x2="112" y2="136" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
        <line x1="64" y1="176" x2="96" y2="176" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
        <line x1="176" y1="176" x2="192" y2="176" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
        <line x1="176" y1="136" x2="192" y2="136" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
    </svg>`
};

export const phosphorIconUsersThree: PhosphorIcon = {
  name: 'users-three',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
  <circle cx="128" cy="140" r="40" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></circle>
  <path d="M196,116a59.8,59.8,0,0,1,48,24" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M12,140a59.8,59.8,0,0,1,48-24" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
  <path d="M70.4,216a64.1,64.1,0,0,1,115.2,0" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
  <path d="M60,116A32,32,0,1,1,91.4,78" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
  <path d="M164.6,78A32,32,0,1,1,196,116" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>
  <line x1="16" y1="216" x2="240" y2="216" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
        <path d="M144,216V40a8,8,0,0,0-8-8H40a8,8,0,0,0-8,8V216" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></path>
        <path d="M224,216V104a8,8,0,0,0-8-8H144" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></path>
        <line x1="64" y1="72" x2="96" y2="72" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16">
        </line>
        <line x1="80" y1="136" x2="112" y2="136" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
        <line x1="64" y1="176" x2="96" y2="176" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
        <line x1="176" y1="176" x2="192" y2="176" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
        <line x1="176" y1="136" x2="192" y2="136" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
  `
};

export const phosphorIconArrowDown: PhosphorIcon = {
  name: 'arrow-down',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none" stroke="none"></rect>
        <line x1="128" y1="40" x2="128" y2="216" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
        <polyline points="56 144 128 216 200 144" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></polyline>
    </svg>`
};

export const phosphorIconArrowCircleRight: PhosphorIcon = {
  name: 'arrow-circle-right',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
  <circle cx="128" cy="128" r="96" opacity="0.2">
  </circle><circle cx="128" cy="128" r="96" fill="none" stroke-miterlimit="10" stroke-width="16"></circle>
  <polyline points="134.1 161.9 168 128 134.1 94.1" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
  <line x1="88" y1="128" x2="168" y2="128" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
  </svg>`
};

export const phosphorIconSquare: PhosphorIcon = {
  name: 'square',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect x="96" y="96" width="64" height="64" opacity="0.2"></rect><rect x="40" y="40" width="176" height="176" rx="8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></rect><rect x="96" y="96" width="64" height="64" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></rect></svg>`
};

export const phosphorIconArrowRight: PhosphorIcon = {
  name: 'arrow-right',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none" stroke="none"></rect>
        <line x1="40" y1="128" x2="216" y2="128" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
        <polyline points="144 56 216 128 144 200" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></polyline>
    </svg>`
};

export const phosphorIconArrowUp: PhosphorIcon = {
  name: 'arrow-up',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none" stroke="none"></rect>
        <line x1="128" y1="216" x2="128" y2="40" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></line>
        <polyline points="56 112 128 40 200 112" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></polyline>
    </svg>`
};

export const phosphorIconBell: PhosphorIcon = {
  name: 'bell',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none" stroke="none"></rect>
        <path
            d="M56.2,104a71.9,71.9,0,0,1,72.3-72c39.6.3,71.3,33.2,71.3,72.9V112c0,35.8,7.5,56.6,14.1,68a8,8,0,0,1-6.9,12H49a8,8,0,0,1-6.9-12c6.6-11.4,14.1-32.2,14.1-68Z"
            opacity="0.2"></path>
        <path
            d="M56.2,104a71.9,71.9,0,0,1,72.3-72c39.6.3,71.3,33.2,71.3,72.9V112c0,35.8,7.5,56.6,14.1,68a8,8,0,0,1-6.9,12H49a8,8,0,0,1-6.9-12c6.6-11.4,14.1-32.2,14.1-68Z"
            fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
        <path d="M96,192v8a32,32,0,0,0,64,0v-8" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="16"></path>
    </svg>`
};

export const phosphorIconBookmark: PhosphorIcon = {
  name: 'bookmark',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <polygon points="192 176 128 136 64 176 64 224 128 184 192 224 192 176" opacity="0.2"></polygon>
      <path d="M192,224l-64-40L64,224V48a8,8,0,0,1,8-8H184a8,8,0,0,1,8,8Z" fill="none"
          stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
      <polyline points="192 176 128 136 64 176" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></polyline>
  </svg>`
};

export const phosphorIconBriefcase: PhosphorIcon = {
  name: 'briefcase',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <path d="M128,152a190.3,190.3,0,0,1-96-25.7V208a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V126.3A191.3,191.3,0,0,1,128,152Z"
          opacity="0.2"></path>
      <rect x="32" y="72" width="192" height="144" rx="8" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></rect>
      <path d="M168,72V56a16,16,0,0,0-16-16H104A16,16,0,0,0,88,56V72" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></path>
      <path d="M224,126.3A191.3,191.3,0,0,1,128,152a190.3,190.3,0,0,1-96-25.7" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></path>
      <line x1="116" y1="120" x2="140" y2="120" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
  </svg>`
};

export const phosphorIconCalendar: PhosphorIcon = {
  name: 'calendar',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <path d="M40,88H216V48a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8Z" opacity="0.2"></path>
      <rect x="40" y="40" width="176" height="176" rx="8" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></rect>
      <line x1="176" y1="24" x2="176" y2="56" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="80" y1="24" x2="80" y2="56" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="40" y1="88" x2="216" y2="88" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
  </svg>`
};

export const phosphorIconCaretDown: PhosphorIcon = {
  name: 'caret-down',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <polygon points="208 96 128 176 48 96 208 96" opacity="0.2"></polygon>
      <polygon points="208 96 128 176 48 96 208 96" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></polygon>
  </svg>`
};

export const phosphorIconCaretUp: PhosphorIcon = {
  name: 'caret-up',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><polygon points="48 160 128 80 208 160 48 160" opacity="0.2"></polygon><polygon points="48 160 128 80 208 160 48 160" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polygon></svg>`
};

export const phosphorIconCaretRight: PhosphorIcon = {
  name: 'caret-right',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <polygon points="96 48 176 128 96 208 96 48" opacity="0.2"></polygon>
      <polygon points="96 48 176 128 96 208 96 48" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></polygon>
  </svg>`
};

export const phosphorIconCaretLeft: PhosphorIcon = {
  name: 'caret-left',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
  <rect width="256" height="256" fill="none" stroke="none"></rect>
    <polygon points="160 208 80 128 160 48 160 208" opacity="0.2"></polygon>
    <polygon points="160 208 80 128 160 48 160 208" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polygon>
    </svg>`
};

export const phosphorIconCaretDoubleLeft: PhosphorIcon = {
  name: 'caret-double-left',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
  <rect width="256" height="256" fill="none" stroke="none"></rect>
  <polygon points="200 208 120 128 200 48 200 208" opacity="0.2"></polygon>
  <polygon points="200 208 120 128 200 48 200 208" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polygon>
  <polyline points="120 208 40 128 120 48" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
  </svg>`
};

export const phosphorIconCaretDoubleRight: PhosphorIcon = {
  name: 'caret-double-right',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
  <rect width="256" height="256" fill="none" stroke="none"></rect>
  <polygon points="56 48 136 128 56 208 56 48" opacity="0.2"></polygon>
  <polygon points="56 48 136 128 56 208 56 48" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polygon>
  <polyline points="136 48 216 128 136 208" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg>`
};

export const phosphorIconChartBar: PhosphorIcon = {
  name: 'chartbar',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <rect x="156" y="40" width="56" height="168" opacity="0.2"></rect>
      <line x1="228" y1="208" x2="28" y2="208" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <polyline points="100 208 100 88 156 88" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></polyline>
      <rect x="156" y="40" width="56" height="168" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></rect>
      <polyline points="44 208 44 136 100 136" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></polyline>
  </svg>`
};

export const phosphorIconCheckCircle: PhosphorIcon = {
  name: 'check-circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <circle cx="128" cy="128" r="96" opacity="0.2"></circle>
      <polyline points="172 104 113.3 160 84 132" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></polyline>
      <circle cx="128" cy="128" r="96" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></circle>
  </svg>`
};

export const phosphorIconCircleFill: PhosphorIcon = {
  name: 'circle-fill',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
  <circle cx="128" cy="128" r="96" opacity="1"></circle>
  <circle cx="128" cy="128" r="96" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="26">
  </circle>
  </svg>`
};

export const phosphorIconCircle: PhosphorIcon = {
  name: 'circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
  <circle cx="128" cy="128" r="96" opacity="0.2"></circle>
  <circle cx="128" cy="128" r="96" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="26">
  </circle>
  </svg>`
};

export const phosphorIconXCircle: PhosphorIcon = {
  name: 'x-circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000000" viewBox="0 0 256 256"><path d="M165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path></svg>`
};

export const phosphorIconMagnifyingGlass: PhosphorIcon = {
  name: 'magnifying-glass',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path></svg>`
};

export const phosphorIconClock: PhosphorIcon = {
  name: 'clock',
  data: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <circle cx="128" cy="128" r="96" opacity="0.2"></circle>
      <circle cx="128" cy="128" r="96" fill="none" stroke-miterlimit="10" stroke-width="16"></circle>
      <polyline points="128 72 128 128 184 128" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></polyline>
  </svg>`
};

export const phosphorIconFilePlus: PhosphorIcon = {
  name: 'fileplus',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <polygon points="152 32 152 88 208 88 152 32" opacity="0.2"></polygon>
      <path d="M200,224H56a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8h96l56,56V216A8,8,0,0,1,200,224Z" fill="none"
          stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
      <polyline points="152 32 152 88 208 88" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></polyline>
      <line x1="104" y1="152" x2="152" y2="152" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></line>
      <line x1="128" y1="128" x2="128" y2="176" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></line>
  </svg>`
};

export const phosphorIconFunnelSimple: PhosphorIcon = {
  name: 'funnel-simple',
  data: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <line x1="64" y1="128" x2="192" y2="128" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="24" y1="80" x2="232" y2="80" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="104" y1="176" x2="152" y2="176" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
  </svg>  `
};

export const phosphorIconGear: PhosphorIcon = {
  name: 'gear',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <path
          d="M211.8,133.1c.3-3.4,0-10.2,0-10.2l16.6-22.1a103.2,103.2,0,0,0-10.2-24.6l-27.3-3.9q-3.5-3.7-7.2-7.2l-3.9-27.3a102.2,102.2,0,0,0-24.6-10.2L133.1,44.2a57.9,57.9,0,0,0-10.2,0L100.8,27.6A103.2,103.2,0,0,0,76.2,37.8L72.3,65.1q-3.7,3.5-7.2,7.2L37.8,76.2a102.2,102.2,0,0,0-10.2,24.6l16.6,22.1c-.2,3.4,0,10.2,0,10.2L27.6,155.2a103.2,103.2,0,0,0,10.2,24.6l27.3,3.9q3.5,3.8,7.2,7.2l3.9,27.3a102.2,102.2,0,0,0,24.6,10.2l22.1-16.6a57.9,57.9,0,0,0,10.2,0l22.1,16.6a103.2,103.2,0,0,0,24.6-10.2l3.9-27.3c2.5-2.3,7.2-7.2,7.2-7.2l27.3-3.9a102.2,102.2,0,0,0,10.2-24.6ZM128,176a48,48,0,1,1,48-48A48,48,0,0,1,128,176Z"
          opacity="0.2"></path>
      <circle cx="128" cy="128" r="48" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></circle>
      <path
          d="M183.7,65.1q3.8,3.5,7.2,7.2l27.3,3.9a103.2,103.2,0,0,1,10.2,24.6l-16.6,22.1s.3,6.8,0,10.2l16.6,22.1a102.2,102.2,0,0,1-10.2,24.6l-27.3,3.9s-4.7,4.9-7.2,7.2l-3.9,27.3a103.2,103.2,0,0,1-24.6,10.2l-22.1-16.6a57.9,57.9,0,0,1-10.2,0l-22.1,16.6a102.2,102.2,0,0,1-24.6-10.2l-3.9-27.3q-3.7-3.5-7.2-7.2l-27.3-3.9a103.2,103.2,0,0,1-10.2-24.6l16.6-22.1s-.2-6.8,0-10.2L27.6,100.8A102.2,102.2,0,0,1,37.8,76.2l27.3-3.9q3.5-3.7,7.2-7.2l3.9-27.3a103.2,103.2,0,0,1,24.6-10.2l22.1,16.6a57.9,57.9,0,0,1,10.2,0l22.1-16.6a102.2,102.2,0,0,1,24.6,10.2Z"
          fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
  </svg>`
};

export const phosphorIconHourGlass: PhosphorIcon = {
  name: 'hourglass',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <polygon points="128 128 176 80 80 80 128 128" opacity="0.2"></polygon>
      <path d="M59.3,40H196.7a8,8,0,0,1,5.6,13.7L128,128,53.7,53.7A8,8,0,0,1,59.3,40Z" fill="none"
          stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
      <path d="M59.3,216H196.7a8,8,0,0,0,5.6-13.7L128,128,53.7,202.3A8,8,0,0,0,59.3,216Z" fill="none"
          stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
      <line x1="176" y1="80" x2="80" y2="80" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="128" y1="128" x2="128" y2="168" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></line>
  </svg>`
};

export const phosphorIconInfo: PhosphorIcon = {
  name: 'info',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <circle cx="128" cy="128" r="96" opacity="0.2"></circle>
      <circle cx="128" cy="128" r="96" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></circle>
      <polyline points="120 120 128 120 128 176 136 176" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></polyline>
      <circle cx="126" cy="84" r="12"></circle>
  </svg>`
};

export const phosphorIconLockSimpleOpen: PhosphorIcon = {
  name: 'lock-simple-open',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <rect x="40" y="88" width="176" height="128" rx="8" opacity="0.2"></rect>
      <rect x="40" y="88" width="176" height="128" rx="8" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></rect>
      <path d="M92,88V52a36,36,0,0,1,72,0" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></path>
  </svg>`
};

export const phosphorIconMap: PhosphorIcon = {
  name: 'map',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <polygon points="160 216 96 184 96 40 160 72 160 216" opacity="0.2"></polygon>
      <polyline points="96 184 32 200 32 56 96 40" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></polyline>
      <polygon points="160 216 96 184 96 40 160 72 160 216" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></polygon>
      <polyline points="160 72 224 56 224 200 160 216" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></polyline>
  </svg>`
};

export const phosphorIconMapPin: PhosphorIcon = {
  name: 'map-pin',
  data: `<svg viewBox="0 0 24 34" xmlns="http://www.w3.org/2000/svg">
  <path d="M12,0 C18.6286,0 24,5.32667 24,11.9 C24,14.5067 23.1619,17.4156 21.7524,19.38 L12,34 L2.24762,19.38 C0.838095,17.4156 0,14.4689 0,11.9 C0,5.32667 5.37143,0 12,0 Z" fill=""></path>
</svg>`
};

export const phosphorIconMoney: PhosphorIcon = {
  name: 'money',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <circle cx="128" cy="128" r="32" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></circle>
      <circle cx="128" cy="128" r="32" opacity="0.2"></circle>
      <path d="M232,64H176l64,56V72A8,8,0,0,0,232,64Z" opacity="0.2"></path>
      <path d="M232,192a8,8,0,0,0,8-8V136l-64,56Z" opacity="0.2"></path>
      <path d="M16,72v48L80,64H24A8,8,0,0,0,16,72Z" opacity="0.2"></path>
      <path d="M16,184a8,8,0,0,0,8,8H80L16,136Z" opacity="0.2"></path>
      <rect x="16" y="64" width="224" height="128" rx="8" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></rect>
      <line x1="176" y1="64" x2="240" y2="120" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="176" y1="192" x2="240" y2="136" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></line>
      <line x1="80" y1="64" x2="16" y2="120" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="80" y1="192" x2="16" y2="136" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
  </svg>`
};

export const phosphorIconNavigationArrow: PhosphorIcon = {
  name: 'navigation-arrow',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <path
          d="M37.4,47.6,96,216.9c2.5,7.3,12.9,7.1,15.2-.3L134.8,140a7.5,7.5,0,0,1,5.3-5.2l76.5-23.6c7.4-2.3,7.6-12.7.3-15.2L47.6,37.4A8,8,0,0,0,37.4,47.6Z"
          opacity="0.2"></path>
      <path
          d="M37.4,47.6,96,216.9c2.5,7.3,12.9,7.1,15.2-.3L134.8,140a7.5,7.5,0,0,1,5.3-5.2l76.5-23.6c7.4-2.3,7.6-12.7.3-15.2L47.6,37.4A8,8,0,0,0,37.4,47.6Z"
          fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
  </svg>`
};

export const phosphorIconPlay: PhosphorIcon = {
  name: 'play',
  data: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <path d="M228.1,121.2,84.2,33.2A8,8,0,0,0,72,40V216a8,8,0,0,0,12.2,6.8l143.9-88A7.9,7.9,0,0,0,228.1,121.2Z"
          opacity="0.2"></path>
      <path d="M228.1,121.2,84.2,33.2A8,8,0,0,0,72,40V216a8,8,0,0,0,12.2,6.8l143.9-88A7.9,7.9,0,0,0,228.1,121.2Z"
          fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
  </svg>`
};

export const phosphorIconPlugs: PhosphorIcon = {
  name: 'plugs',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <path d="M132,180l-31,31a24,24,0,0,1-34,0L45,189a24,24,0,0,1,0-34l31-31Z" opacity="0.2"></path>
      <path d="M180,132l31-31a24,24,0,0,0,0-34L189,45a24,24,0,0,0-34,0L124,76Z" opacity="0.2"></path>
      <path d="M132,180l-31,31a24,24,0,0,1-34,0L45,189a24,24,0,0,1,0-34l31-31" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></path>
      <line x1="56" y1="200" x2="24" y2="232" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="232" y1="24" x2="200" y2="56" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="144" y1="144" x2="120" y2="168" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="144" y1="144" x2="120" y2="168" fill="#231f20"></line>
      <line x1="112" y1="112" x2="88" y2="136" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="112" y1="112" x2="88" y2="136" fill="#231f20"></line>
      <path d="M180,132l31-31a24,24,0,0,0,0-34L189,45a24,24,0,0,0-34,0L124,76" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></path>
      <line x1="116" y1="68" x2="188" y2="140" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="68" y1="116" x2="140" y2="188" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
  </svg>`
};

export const phosphorIconPlus: PhosphorIcon = {
  name: 'plus',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <line x1="40" y1="128" x2="216" y2="128" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="26"></line>
      <line x1="128" y1="40" x2="128" y2="216" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="26"></line>
  </svg>`
};

export const phosphorIconRuler: PhosphorIcon = {
  name: 'ruler',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <rect x="26.2" y="82.7" width="203.6" height="90.51" rx="8" transform="translate(-53 128) rotate(-45)"
          opacity="0.2"></rect>
      <rect x="26.2" y="82.7" width="203.6" height="90.51" rx="8" transform="translate(-53 128) rotate(-45)" fill="none"
          stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></rect>
      <line x1="132" y1="60" x2="164" y2="92" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="96" y1="96" x2="128" y2="128" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="60" y1="132" x2="92" y2="164" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
  </svg>`
};

export const phosphorIconSignIn: PhosphorIcon = {
  name: 'sign-in',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <polyline points="94 170 136 128 94 86" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></polyline>
      <line x1="24" y1="128" x2="136" y2="128" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <path d="M136,40h56a8,8,0,0,1,8,8V208a8,8,0,0,1-8,8H136" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></path>
  </svg>`
};

export const phosphorIconTimer: PhosphorIcon = {
  name: 'timer',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <circle cx="128" cy="128" r="88" opacity="0.2"></circle>
      <circle cx="128" cy="128" r="88" fill="none" stroke-miterlimit="10" stroke-width="16"></circle>
      <line x1="128" y1="128" x2="167.6" y2="88.4" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></line>
      <line x1="104" y1="8" x2="152" y2="8" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
  </svg>`
};

export const phosphorIconTrash: PhosphorIcon = {
  name: 'trash',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <path d="M200,56V208a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V56Z" opacity="0.2"></path>
      <line x1="216" y1="56" x2="40" y2="56" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="104" y1="104" x2="104" y2="168" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></line>
      <line x1="152" y1="104" x2="152" y2="168" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></line>
      <path d="M200,56V208a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V56" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></path>
      <path d="M168,56V40a16,16,0,0,0-16-16H104A16,16,0,0,0,88,40V56" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></path>
  </svg>`
};

export const phosphorIconUserCircle: PhosphorIcon = {
  name: 'user-circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <path
          d="M128,32A96,96,0,0,0,63.8,199.4h0A72,72,0,0,1,128,160a40,40,0,1,1,40-40,40,40,0,0,1-40,40,72,72,0,0,1,64.2,39.4A96,96,0,0,0,128,32Z"
          opacity="0.2"></path>
      <circle cx="128" cy="128" r="96" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></circle>
      <circle cx="128" cy="120" r="40" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></circle>
      <path d="M63.8,199.4a72,72,0,0,1,128.4,0" fill="none" stroke-linecap="round"
          stroke-linejoin="round" stroke-width="16"></path>
  </svg>`
};

export const phosphorIconWarningCircle: PhosphorIcon = {
  name: 'warning-circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <circle cx="128" cy="128" r="96" opacity="0.2"></circle>
      <circle cx="128" cy="128" r="96" fill="none" stroke-miterlimit="10" stroke-width="16"></circle>
      <line x1="128" y1="80" x2="128" y2="136" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <circle cx="128" cy="172" r="5" fill="none" 
          alignment-baseline="" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></circle>
  </svg>`
};

export const phosphorIconWarning: PhosphorIcon = {
  name: 'warning',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <path d="M114.2,40l-88,152A16,16,0,0,0,40,216H216a16,16,0,0,0,13.8-24l-88-152A15.9,15.9,0,0,0,114.2,40Z"
          opacity="0.2"></path>
      <line x1="128" y1="112" x2="128" y2="144" fill="none" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <path d="M114.2,40l-88,152A16,16,0,0,0,40,216H216a16,16,0,0,0,13.8-24l-88-152A15.9,15.9,0,0,0,114.2,40Z" fill="none"
          alignment-baseline="" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
      <circle cx="128" cy="180" r="5" fill="none" 
          alignment-baseline="" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></circle>
  </svg>`
};

export const phosphorIconX: PhosphorIcon = {
  name: 'x',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" stroke="none"></rect>
      <line x1="200" y1="56" x2="56" y2="200" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
      <line x1="200" y1="200" x2="56" y2="56" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="16"></line>
  </svg>`
};

export const phosphorIconColumns: PhosphorIcon = {
  name: 'columns',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
    <rect width="256" height="256" fill="none" stroke="none"></rect>
    <rect x="-4" y="100" width="176" height="56" rx="8" transform="translate(212 44) rotate(90)" opacity="0.2"></rect>
    <rect x="84" y="100" width="176" height="56" rx="8" transform="translate(300 -44) rotate(90)" opacity="0.2"></rect>
    <rect x="-4" y="100" width="176" height="56" rx="8" transform="translate(212 44) rotate(90)" fill="none" stroke="parent" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></rect>
    <rect x="84" y="100" width="176" height="56" rx="8" transform="translate(300 -44) rotate(90)" fill="none" stroke="parent" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></rect>
    </svg>
    `
};

export const phosphorIconSwap: PhosphorIcon = {
  name: 'swap',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
    <rect width="256" height="256" fill="none" stroke="none"></rect>
    <path d="M208,40H96a8,8,0,0,0-8,8V96H48a8,8,0,0,0-8,8V208a8,8,0,0,0,8,8H160a8,8,0,0,0,8-8V160h40a8,8,0,0,0,8-8V48A8,8,0,0,0,208,40Z" opacity="0.2"></path>
    <path d="M80,160H208a8,8,0,0,0,8-8V48a8,8,0,0,0-8-8H96a8,8,0,0,0-8,8v8" fill="none" stroke="parent" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path>
    <polyline points="104 136 80 160 104 184" fill="none" stroke="parent" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
    <path d="M176,96H48a8,8,0,0,0-8,8V208a8,8,0,0,0,8,8H160a8,8,0,0,0,8-8v-8" fill="none" stroke="parent" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path
    ><polyline points="152 120 176 96 152 72" fill="none" stroke="parent" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
    </svg>`
};

export const phosphorIconDotsThreeVertical: PhosphorIcon = {
  name: 'dots-three-vertical',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
    <rect width="256" height="256" fill="none" stroke="none"></rect>
    <circle cx="128" cy="128" r="12"></circle>
    <circle cx="128" cy="64" r="12"></circle>
    <circle cx="128" cy="192" r="12"></circle>
    </svg>
    `
};

export const phosphorIconMapPinEmpty: PhosphorIcon = {
  name: 'map-pin-empty',
  data: `<svg viewBox="0 0 26 34" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C5.37143 0 0 5.32667 0 11.9C0 14.4689 0.838095 17.4156 2.24762 19.38L12 34L21.7524 19.38C23.1619 17.4156 24 14.5067 24 11.9C24 5.32667 18.6286 0 12 0ZM12 16.4333C9.48571 16.4333 7.42857 14.3933 7.42857 11.9C7.42857 9.40667 9.48571 7.36667 12 7.36667C14.5143 7.36667 16.5714 9.40667 16.5714 11.9C16.5714 14.3933 14.5143 16.4333 12 16.4333Z"/></svg>`
};

export const phosphorIconCube: PhosphorIcon = {
  name: 'cube',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 256 256"><path d="M33.1,74.6A8.1,8.1,0,0,0,32,78.7v98.6a8.1,8.1,0,0,0,4.1,7l88,49.5a7.6,7.6,0,0,0,3.9,1h0l.9-106.8L33.1,74.6Z" opacity="0.2"></path><path d="M224,177.3V78.7a8.1,8.1,0,0,0-4.1-7l-88-49.5a7.8,7.8,0,0,0-7.8,0l-88,49.5a8.1,8.1,0,0,0-4.1,7v98.6a8.1,8.1,0,0,0,4.1,7l88,49.5a7.8,7.8,0,0,0,7.8,0l88-49.5A8.1,8.1,0,0,0,224,177.3Z" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"></path><polyline points="222.9 74.6 128.9 128 33.1 74.6" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"></polyline><line x1="128.9" y1="128" x2="128" y2="234.8" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"></line></svg>`
};

export const phosphorIconReset: PhosphorIcon = {
  name: 'reset',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
  <polyline points="176.2 99.7 224.2 99.7 224.2 51.7" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="26"></polyline>
  <path d="M65.8,65.8a87.9,87.9,0,0,1,124.4,0l34,33.9" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="26"></path>
  <polyline points="79.8 156.3 31.8 156.3 31.8 204.3" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="26"></polyline>
  <path d="M190.2,190.2a87.9,87.9,0,0,1-124.4,0l-34-33.9" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="26"></path>
</svg>`
};

export const phosphorIconMonitor: PhosphorIcon = {
  name: 'monitor',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><rect x="32" y="48" width="192" height="144" rx="16" transform="translate(256 240) rotate(180)" opacity="0.2"></rect><rect x="32" y="48" width="192" height="144" rx="16" transform="translate(256 240) rotate(180)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></rect><line x1="160" y1="224" x2="96" y2="224" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></svg>
      `
};

export const phosphorIconBulletList: PhosphorIcon = {
  name: 'bullet-list',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"> <line x1="88" y1="64" x2="216" y2="64" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="88" y1="128" x2="216" y2="128" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="88" y1="192" x2="216" y2="192" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><circle cx="44" cy="64" r="12"></circle><circle cx="44" cy="128" r="12"></circle><circle cx="44" cy="192" r="12"></circle></svg>`
};

export const phosphorIconPen: PhosphorIcon = {
  name: 'pen',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M136,64l56,56,26.3-26.3a8,8,0,0,0,0-11.4L173.7,37.7a8,8,0,0,0-11.4,0Z" opacity="0.2"></path><line x1="136" y1="64" x2="192" y2="120" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><path d="M92.7,216H48a8,8,0,0,1-8-8V163.3a7.9,7.9,0,0,1,2.3-5.6l120-120a8,8,0,0,1,11.4,0l44.6,44.6a8,8,0,0,1,0,11.4l-120,120A7.9,7.9,0,0,1,92.7,216Z" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>`
};

export const phosphorIconPackage: PhosphorIcon = {
  name: 'package',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M33.1,74.6A8.1,8.1,0,0,0,32,78.7v98.6a8.1,8.1,0,0,0,4.1,7l88,49.5a7.6,7.6,0,0,0,3.9,1h0l.9-106.8L33.1,74.6Z" opacity="0.2"></path><path d="M224,177.3V78.7a8.1,8.1,0,0,0-4.1-7l-88-49.5a7.8,7.8,0,0,0-7.8,0l-88,49.5a8.1,8.1,0,0,0-4.1,7v98.6a8.1,8.1,0,0,0,4.1,7l88,49.5a7.8,7.8,0,0,0,7.8,0l88-49.5A8.1,8.1,0,0,0,224,177.3Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><polyline points="222.9 74.6 128.9 128 33.1 74.6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><line x1="128.9" y1="128" x2="128" y2="234.8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></svg>`
};

export const phosphorIconMinus: PhosphorIcon = {
  name: 'minus',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
    <line x1="40" y1="128" x2="216" y2="128" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="26"></line>
  </svg>`
};

export const phosphorIconMapPinSquare: PhosphorIcon = {
  name: 'map-pin-square',
  data: `<svg width="9" height="13" viewBox="0 0 23 26" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.47274 15.4046C0.1683 15.0697 0.000312245 14.6368 0 14.1883C0.000312245 11.9883 0.000312074 5.13395 0.000312074 1.82456C-1.71536e-07 1.34062 0.196715 0.876535 0.546117 0.534353C0.895832 0.192172 1.37013 0 1.86473 0C6.1809 0 16.8191 0 21.1353 0C22.1651 0 23 0.816959 23 1.82456C22.9997 5.13395 22.9997 11.9883 22.9997 14.1883C22.9997 14.6368 22.8317 15.0697 22.5273 15.4046C21.0766 17.0015 16.6168 21.9106 13.8334 24.9747C13.2408 25.627 12.3918 26 11.5 26C10.6082 26 9.75923 25.627 9.16659 24.9747C6.38323 21.9106 1.92343 17.0015 0.47274 15.4046V15.4046Z"/></svg>`
};

export const phosphorIconMapPinCube: PhosphorIcon = {
  name: 'map-pin-cube',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 256 256"><path d="M33.1,74.6A8.1,8.1,0,0,0,32,78.7v98.6a8.1,8.1,0,0,0,4.1,7l88,49.5a7.6,7.6,0,0,0,3.9,1h0l.9-106.8L33.1,74.6Z" opacity="0.2"></path><path d="M224,177.3V78.7a8.1,8.1,0,0,0-4.1-7l-88-49.5a7.8,7.8,0,0,0-7.8,0l-88,49.5a8.1,8.1,0,0,0-4.1,7v98.6a8.1,8.1,0,0,0,4.1,7l88,49.5a7.8,7.8,0,0,0,7.8,0l88-49.5A8.1,8.1,0,0,0,224,177.3Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"></path><polyline points="222.9 74.6 128.9 128 33.1 74.6" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"></polyline><line x1="128.9" y1="128" x2="128" y2="234.8" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"></line></svg>`
};

export const phosphorIconMapPinPlus: PhosphorIcon = {
  name: 'map-pin-plus',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 256 256"></rect><line x1="40" y1="128" x2="216" y2="128" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"></line><line x1="128" y1="40" x2="128" y2="216" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"></line></svg>`
};
export const phosphorIconMapPinMinus: PhosphorIcon = {
  name: 'map-pin-minus',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 256 256"></rect><line x1="40" y1="128" x2="216" y2="128" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"></line></svg>`
};
export const phosphorIconMapPinRelay: PhosphorIcon = {
  name: 'map-pin-relay',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 256 256"><polyline points="176.2 99.7 224.2 99.7 224.2 51.7" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><path d="M65.8,65.8a87.9,87.9,0,0,1,124.4,0l34,33.9" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><polyline points="79.8 156.3 31.8 156.3 31.8 204.3" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><path d="M190.2,190.2a87.9,87.9,0,0,1-124.4,0l-34-33.9" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>`
};

export const phosphorIconMapRoadDirection: PhosphorIcon = {
  name: 'map-pin-road-direction',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 256 256"><polygon points="96 48 176 128 96 208 96 48" opacity="0.2"></polygon><polygon points="96 48 176 128 96 208 96 48" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"></polygon></svg>`
};

export const phosphorIconExport: PhosphorIcon = {
  name: 'export',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect x="48" y="96" width="160" height="120" opacity="0.2"></rect><polyline points="86 58 128 16 170 58" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><line x1="128" y1="128" x2="128" y2="16" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><path d="M176,96h24a8,8,0,0,1,8,8V208a8,8,0,0,1-8,8H56a8,8,0,0,1-8-8V104a8,8,0,0,1,8-8H80" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>`
};

export const phosphorIconList: PhosphorIcon = {
  name: 'list',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><line x1="40" y1="128" x2="216" y2="128"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="40" y1="64" x2="216" y2="64"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="40" y1="192" x2="216" y2="192"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></svg>`
};

export const phosphorIconMinusCircle: PhosphorIcon = {
  name: 'minus-circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><circle cx="128" cy="128" r="96" opacity="0.2"></circle><circle cx="128" cy="128" r="96" fill="none"  stroke-miterlimit="10" stroke-width="16"></circle><line x1="88" y1="128" x2="168" y2="128" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></svg>`
};

export const phosphorIconPlusCircle: PhosphorIcon = {
  name: 'plus-circle',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><circle cx="128" cy="128" r="96" opacity="0.2"></circle><circle cx="128" cy="128" r="96" fill="none"  stroke-miterlimit="10" stroke-width="16"></circle><line x1="88" y1="128" x2="168" y2="128" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="128" y1="88" x2="128" y2="168" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></svg>`
};

export const phosphorIconSliders: PhosphorIcon = {
  name: 'sliders',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><circle cx="104" cy="84" r="20" opacity="0.2"></circle><line x1="148" y1="172" x2="40" y2="172" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="216" y1="172" x2="188" y2="172" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><circle cx="168" cy="172" r="20" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></circle><line x1="84" y1="84" x2="40" y2="84" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="216" y1="84" x2="124" y2="84" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><circle cx="104" cy="84" r="20" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></circle></svg>`
};

export const phosphorIconActivity: PhosphorIcon = {
  name: 'activity',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><polyline points="24 128 56 128 96 40 160 208 200 128 232 128" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline></svg>`
};

export const phosphorIconMapPinLine: PhosphorIcon = {
  name: 'map-pin-line',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M128,24a80,80,0,0,0-80,80c0,72,80,128,80,128s80-56,80-128A80,80,0,0,0,128,24Zm0,112a32,32,0,1,1,32-32A32,32,0,0,1,128,136Z" opacity="0.2"></path><line x1="56" y1="232" x2="200" y2="232"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><circle cx="128" cy="104" r="32" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></circle><path d="M208,104c0,72-80,128-80,128S48,176,48,104a80,80,0,0,1,160,0Z" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>
    `
};

export const phosphorIconBug: PhosphorIcon = {
  name: 'bug',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M208,128v16a80,80,0,0,1-160,0V128Z" opacity="0.2"></path><circle cx="155.9" cy="92" r="12"></circle><circle cx="99.9" cy="92" r="12"></circle><line x1="128" y1="128" x2="128" y2="224" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="244" y1="128" x2="12" y2="128" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><path d="M208,144a80,80,0,0,1-160,0V112a80,80,0,0,1,160,0Z" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><line x1="231.9" y1="188" x2="203.2" y2="171.4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="231.9" y1="68" x2="203.2" y2="84.6" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="24.1" y1="68" x2="52.8" y2="84.6" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="24.1" y1="188" x2="52.8" y2="171.4" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></svg>`
};

export const phosphorFlagCheckered: PhosphorIcon = {
  name: 'flag-checkered',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M216,48v64c-19.89,17.23-38.23,18.39-56,13.48v-64C177.76,66.39,196.11,65.23,216,48ZM96,98.52v-64C78.24,29.61,59.89,30.77,40,48v64C59.89,94.78,78.23,93.61,96,98.52v56c21.62,6,42.38,21,64,27v-56c-21.62-6-42.38-21-64-27Z" opacity="0.2"></path><path d="M219.32,40.75A8,8,0,0,0,210.76,42c-28,24.22-51.72,12.48-79.21-1.13C103.07,26.76,70.78,10.79,34.76,42A8,8,0,0,0,32,48V216a8,8,0,0,0,16,0V171.77c26.79-21.16,49.87-9.75,76.45,3.41,28.49,14.09,60.77,30.06,96.79-1.13a8,8,0,0,0,2.76-6V48A8,8,0,0,0,219.32,40.75ZM88,144.7a65.5,65.5,0,0,0-9.42-.71A60.06,60.06,0,0,0,48,152.43V115.78c14-11.06,27-13.22,40-10.88Zm0-56a60.06,60.06,0,0,0-40,7.75V51.78C62,40.72,75,38.57,88,40.91Zm64,81.63c-6.66-2.67-13.43-6-20.45-9.48-8.82-4.37-18-8.9-27.55-12.17v-39c6.66,2.66,13.43,6,20.45,9.48,8.82,4.37,18,8.9,27.55,12.17Zm0-56c-6.66-2.67-13.43-6-20.45-9.49-8.82-4.36-18-8.9-27.55-12.17v-47c6.66,2.66,13.43,6,20.45,9.48,8.82,4.36,18,8.9,27.55,12.17Zm56,49.92c-14,11.06-27,13.21-40,10.87v-39.8a65.5,65.5,0,0,0,9.42.72A60.11,60.11,0,0,0,208,127.57Zm0-56c-14,11.06-27,13.22-40,10.88V71.34a60.05,60.05,0,0,0,40-7.74Z"></path></svg>`
};

export const phosphorHashTag: PhosphorIcon = {
  name: 'hashtag',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M165.82,96l-11.64,64h-64l11.64-64Z" opacity="0.2"></path><path d="M224,88H175.4l8.47-46.57a8,8,0,0,0-15.74-2.86l-9,49.43H111.4l8.47-46.57a8,8,0,0,0-15.74-2.86L95.14,88H48a8,8,0,0,0,0,16H92.23L83.5,152H32a8,8,0,0,0,0,16H80.6l-8.47,46.57a8,8,0,0,0,6.44,9.3A7.79,7.79,0,0,0,80,224a8,8,0,0,0,7.86-6.57l9-49.43H144.6l-8.47,46.57a8,8,0,0,0,6.44,9.3A7.79,7.79,0,0,0,144,224a8,8,0,0,0,7.86-6.57l9-49.43H208a8,8,0,0,0,0-16H163.77l8.73-48H224a8,8,0,0,0,0-16Zm-76.5,64H99.77l8.73-48h47.73Z"></path></svg>`
};

export const phosphorBezierCurve: PhosphorIcon = {
  name: 'bezierCurve',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M152,80a24,24,0,1,1-24-24A24,24,0,0,1,152,80ZM40,152a24,24,0,1,0,24,24A24,24,0,0,0,40,152Zm176,0a24,24,0,1,0,24,24A24,24,0,0,0,216,152Z" opacity="0.2"></path>
  <path d="M221.07,144.41A96.68,96.68,0,0,0,181,88h59a8,8,0,0,0,0-16H159a32,32,0,0,0-62,0H16a8,8,0,0,0,0,16H75a96.68,96.68,0,0,0-40.07,56.41A32,32,0,1,0,51.08,146,80.6,80.6,0,0,1,99,93.44a32,32,0,0,0,58.06,0A80.6,80.6,0,0,1,204.92,146a32,32,0,1,0,16.15-1.57ZM56,176a16,16,0,1,1-16-16A16,16,0,0,1,56,176Zm72-80a16,16,0,1,1,16-16A16,16,0,0,1,128,96Zm88,96a16,16,0,1,1,16-16A16,16,0,0,1,216,192Z"></path>
  </svg>`
};

export const phosphorIconFatArrowLeft: PhosphorIcon = {
  name: 'fat-arrow-left',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
  <polygon points="120 32 24 128 120 224 120 176 184 176 184 80 120 80 120 32" opacity="0.2"/>
  <polygon points="120 32 24 128 120 224 120 176 184 176 184 80 120 80 120 32" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/>
  <line x1="216" y1="176" x2="216" y2="80" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/>
  </svg>`
};

export const phosphorIconFatArrowRight: PhosphorIcon = {
  name: 'fat-arrow-right',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M136,224V176H72V80h64V32l96,96Z" opacity="0.2"></path><path d="M237.66,122.34l-96-96A8,8,0,0,0,128,32V72H72a8,8,0,0,0-8,8v96a8,8,0,0,0,8,8h56v40a8,8,0,0,0,13.66,5.66l96-96A8,8,0,0,0,237.66,122.34ZM144,204.69V176a8,8,0,0,0-8-8H80V88h56a8,8,0,0,0,8-8V51.31L220.69,128ZM48,80v96a8,8,0,0,1-16,0V80a8,8,0,0,1,16,0Z"></path></svg>`
};

export const phosphorIconCaretCircleRight: PhosphorIcon = {
  name: 'carret-circle-right',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z" opacity="0.2"></path><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm29.66-93.66a8,8,0,0,1,0,11.32l-40,40a8,8,0,0,1-11.32-11.32L140.69,128,106.34,93.66a8,8,0,0,1,11.32-11.32Z"></path></svg>`
};

export const phosphorIconCaretCircleLeft: PhosphorIcon = {
  name: 'carret-circle-left',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z" opacity="0.2"></path><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM149.66,93.66,115.31,128l34.35,34.34a8,8,0,0,1-11.32,11.32l-40-40a8,8,0,0,1,0-11.32l40-40a8,8,0,0,1,11.32,11.32Z"></path></svg>`
};

export const phosphorIconQuestionMark: PhosphorIcon = {
  name: 'question-mark',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
  <path d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z" opacity="0.2"></path>
  <path d="M140,180a12,12,0,1,1-12-12A12,12,0,0,1,140,180ZM128,72c-22.06,0-40,16.15-40,36v4a8,8,0,0,0,16,0v-4c0-11,10.77-20,24-20s24,9,24,20-10.77,20-24,20a8,8,0,0,0-8,8v8a8,8,0,0,0,16,0v-.72c18.24-3.35,32-17.9,32-35.28C168,88.15,150.06,72,128,72Zm104,56A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z">
  </path>
  </svg>`
};

export const phosphorIconStar: PhosphorIcon = {
  name: 'star',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#00000" viewBox="0 0 256 256"><path d="M239.2,97.29a16,16,0,0,0-13.81-11L166,81.17,142.72,25.81h0a15.95,15.95,0,0,0-29.44,0L90.07,81.17,30.61,86.32a16,16,0,0,0-9.11,28.06L66.61,153.8,53.09,212.34a16,16,0,0,0,23.84,17.34l51-31,51.11,31a16,16,0,0,0,23.84-17.34l-13.51-58.6,45.1-39.36A16,16,0,0,0,239.2,97.29Zm-15.22,5-45.1,39.36a16,16,0,0,0-5.08,15.71L187.35,216v0l-51.07-31a15.9,15.9,0,0,0-16.54,0l-51,31h0L82.2,157.4a16,16,0,0,0-5.08-15.71L32,102.35a.37.37,0,0,1,0-.09l59.44-5.14a16,16,0,0,0,13.35-9.75L128,32.08l23.2,55.29a16,16,0,0,0,13.35,9.75L224,102.26S224,102.32,224,102.33Z"></path></svg>`
};

export const phosphorIconStarFilled: PhosphorIcon = {
  name: 'star-filled',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z"></path></svg>`
};

export const phosphorIconWarehouse: PhosphorIcon = {
  name: 'warehouse',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><line x1="16" y1="192" x2="240" y2="192" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="240" y1="48" x2="16" y2="96" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><polyline points="72 192 72 128 184 128 184 192" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="72" y1="160" x2="184" y2="160" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="32" y1="92.57" x2="32" y2="192" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="224" y1="51.43" x2="224" y2="192" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg>`
};

export const phosphorIconCaretDown2: PhosphorIcon = {
  name: 'caret-down-2',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><polyline points="208 96 128 176 48 96" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"/></svg>`
};

export const phosphorIconCaretUp2: PhosphorIcon = {
  name: 'caret-up-2',
  data: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><polyline points="48 160 128 80 208 160" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="22"/></svg>`
};

const iconInUse = [
  phosphorIconArrowDown,
  phosphorHashTag,
  phosphorBezierCurve,
  phosphorIconArrowRight,
  phosphorFlagCheckered,
  phosphorIconArrowUp,
  phosphorIconBell,
  phosphorIconBookmark,
  phosphorIconBriefcase,
  phosphorIconBuildings,
  phosphorIconBug,
  phosphorIconCalendar,
  phosphorIconCaretDown,
  phosphorIconCaretRight,
  phosphorIconChartBar,
  phosphorIconCheckCircle,
  phosphorIconCircle,
  phosphorIconCircleFill,
  phosphorIconXCircle,
  phosphorIconClock,
  phosphorIconColumns,
  phosphorIconDotsThreeVertical,
  phosphorIconEnvelope,
  phosphorIconFilePlus,
  phosphorIconFunnelSimple,
  phosphorIconGear,
  phosphorIconHourGlass,
  phosphorIconInfo,
  phosphorIconLockSimpleOpen,
  phosphorIconMap,
  phosphorIconMapPin,
  phosphorIconMoney,
  phosphorIconNavigationArrow,
  phosphorIconPlay,
  phosphorIconPlugs,
  phosphorIconPlus,
  phosphorIconMagnifyingGlass,
  phosphorIconRuler,
  phosphorIconSignIn,
  phosphorIconSwap,
  phosphorIconTimer,
  phosphorIconTrash,
  phosphorIconUserCircle,
  phosphorIconUser,
  phosphorIconUsersThree,
  phosphorIconWarningCircle,
  phosphorIconWarning,
  phosphorIconX,
  phosphorIconCube,
  phosphorIconMonitor,
  phosphorIconBulletList,
  phosphorIconPen,
  phosphorIconReset,
  phosphorIconMinus,
  phosphorIconMapPinEmpty,
  phosphorIconMapPinSquare,
  phosphorIconExport,
  phosphorIconList,
  phosphorIconMinusCircle,
  phosphorIconPlusCircle,
  phosphorIconSliders,
  phosphorIconActivity,
  phosphorIconMapPinLine,
  phosphorIconCaretUp,
  phosphorIconCaretLeft,
  phosphorIconCaretDoubleLeft,
  phosphorIconCaretDoubleRight,
  phosphorIconMapPinCube,
  phosphorIconMapRoadDirection,
  phosphorIconPackage,
  phosphorIconSquare,
  phosphorIconArrowCircleRight,
  phosphorIconFatArrowRight,
  phosphorIconFatArrowLeft,
  phosphorIconCaretCircleLeft,
  phosphorIconCaretCircleRight,
  phosphorIconQuestionMark,
  phosphorIconStar,
  phosphorIconStarFilled,
  phosphorIconWarehouse,
  phosphorIconCaretDown2,
  phosphorIconCaretUp2
];

export { iconInUse };
export type phosphorIcon =
  | 'user'
  | 'envelope'
  | 'buildings'
  | 'users-three'
  | 'arrow-down'
  | 'arrow-right'
  | 'arrow-up'
  | 'bell'
  | 'bookmark'
  | 'briefcase'
  | 'bug'
  | 'calendar'
  | 'caret-down'
  | 'caret-right'
  | 'chartbar'
  | 'check-circle'
  | 'circle'
  | 'circle-fill'
  | 'x-circle'
  | 'magnifying-glass'
  | 'clock'
  | 'columns'
  | 'dots-three-vertical'
  | 'fileplus'
  | 'funnel-simple'
  | 'gear'
  | 'hourglass'
  | 'info'
  | 'hashtag'
  | 'lock-simple-open'
  | 'map'
  | 'map-pin'
  | 'money'
  | 'navigation-arrow'
  | 'play'
  | 'plugs'
  | 'bezierCurve'
  | 'plus'
  | 'ruler'
  | 'sign-in'
  | 'swap'
  | 'timer'
  | 'trash'
  | 'user-circle'
  | 'warning-circle'
  | 'warning'
  | 'reset'
  | 'x'
  | 'monitor'
  | 'bullet-list'
  | 'pen'
  | 'cube'
  | 'minus'
  | 'map-pin-empty'
  | 'map-pin-square'
  | 'map-pin-plus'
  | 'map-pin-minus'
  | 'map-pin-cube'
  | 'map-pin-relay'
  | 'map-pin-road-direction'
  | 'export'
  | 'list'
  | 'minus-circle'
  | 'plus-circle'
  | 'sliders'
  | 'activity'
  | 'map-pin-line'
  | 'caret-up'
  | 'caret-left'
  | 'caret-double-left'
  | 'caret-double-right'
  | 'package'
  | 'square'
  | 'arrow-circle-right'
  | 'flag-checkered'
  | 'fat-arrow-right'
  | 'fat-arrow-left'
  | 'carret-circle-left'
  | 'carret-circle-right'
  | 'question-mark'
  | 'star'
  | 'star-filled'
  | 'warehouse'
  | 'caret-down-2'
  | 'caret-up-2';

export interface PhosphorIcon {
  name: phosphorIcon;
  data: string;
}
