const MARGIN = 15;

export class Tooltip {
  private tooltipDiv: HTMLDivElement;

  constructor(parentElement: ShadowRoot) {
    this.tooltipDiv = document.createElement('div');
    this.tooltipDiv.setAttribute('id', 'item_tooltip');
    parentElement.appendChild(this.tooltipDiv);
  }

  setContent(text: string): void {
    this.tooltipDiv.innerHTML = text;
  }

  show(): void {
    this.tooltipDiv.style.opacity = '1';
  }

  hide(): void {
    this.tooltipDiv.style.opacity = '0';
  }

  onMouseMove(canvas: HTMLCanvasElement, event: any): void {
    const pos = this.getCanvasRelativePosition(canvas, event);
    // if too far to the right, display the tooltip to the left
    if (pos.x + MARGIN + this.tooltipDiv.clientWidth + 5 >= canvas.clientWidth) {
      // display to the right
      this.tooltipDiv.style.left = pos.x - MARGIN - this.tooltipDiv.clientWidth + 'px';
    } else {
      // display to the left
      this.tooltipDiv.style.left = pos.x + MARGIN + 'px';
    }
    // if too far bottom, display the tooltip to the top
    if (pos.y + MARGIN + this.tooltipDiv.clientHeight + 5 >= canvas.clientHeight) {
      // to the top
      this.tooltipDiv.style.top = pos.y - MARGIN - this.tooltipDiv.clientHeight + 'px';
    } else {
      // to the bottom
      this.tooltipDiv.style.top = pos.y + MARGIN + 'px';
    }
  }

  private getCanvasRelativePosition(canvas: HTMLCanvasElement, event: any) {
    const rect = canvas.getBoundingClientRect();
    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top
    };
  }
}
