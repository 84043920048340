import { css } from 'lit';

export const visuStyle = css`
  :host {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow: hidden;
    display: block;
    height: 100%;
  }

  :host canvas {
    position: absolute;
    height: 100%;
    display: flex;
  }

  #packviz-menu {
    position: relative;
    padding: 10px;
    float: right;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #item_tooltip {
    position: relative;
    float: left;
    top: 0px;
    left: 0px;
    pointer-events: none;
    user-select: none;
    border-radius: 2px;
    /* backdrop-filter: blur(20px); */
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5px;
    max-width: 70%;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.1s;
    font-size: 0.8em;
  }

  #item_tooltip table {
    margin-top: 5px;
    text-align: left;
  }

  td {
    padding-right: 25px;
  }

  .has_tooltip {
    position: relative;
    margin: 5px;
  }

  .has_tooltip:before {
    content: attr(data-text);
    position: absolute;
    /* vertical center */
    top: 50%;
    transform: translateY(-50%);
    /* move to the right */
    right: 100%;
    margin-right: 20px;
    /* other styling */
    width: 100px;
    padding: 5px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    text-align: center;
    opacity: 0;
    transition: 0.5s opacity;
  }

  .has_tooltip:hover:before,
  .has_tooltip:hover:after {
    display: block;
    opacity: 1;
    pointer-events: none;
  }

  .has_tooltip:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    border: 10px solid #fff;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.5);
    opacity: 0;
    transition: 0.5s opacity;
  }

  .cube_icon {
    width: 60px;
    height: 60px;
    stroke-width: 2px;
    stroke: #666;
    opacity: 0.8;
  }

  a svg {
    cursor: pointer;
  }

  a svg:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  a svg:active {
    background-color: rgba(255, 255, 220, 0.6);
  }

  .active_menu_icon {
    background-color: rgba(255, 255, 220, 0.5);
  }

  .cube_side_inactive {
    fill: #508fa3;
  }

  .cube_side_active {
    fill: #f9f891;
  }

  .cube_icon_transparent polyline {
    fill: rgba(80, 143, 163, 0.5);
  }

  .hidden {
    display: none;
  }
`;
